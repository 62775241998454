import React from "react";
import { Container, Row } from "shards-react";
import {Buffer} from 'buffer';

var charPossibilities = 'ABCDF0123456789';
var charSplited = charPossibilities.split('');

export class CryptoView extends React.Component {
  state = {
    screens: [],
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    window.Buffer = Buffer;
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "/coinkey.bundle.js";
    script.async = true;
    document.body.appendChild(script);

    setTimeout( function () {
        const script2 = document.createElement("script");
        script2.src = "/bitcoin.js";
        script2.async = true;
        document.body.appendChild(script2);
    },1000)
  }

  componentWillUnmount() {
  }

  render() {
    var sty = {
        width:"728px",
        height:"90px",
        border:"0px",
        padding:0,
        overflow:"hidden",
        "background-color": "transparent"
    }

    const sty2 = {
        width:"160px",
        height:"600px",
        border:"0px",
        padding:0,
        overflow:"hidden",
        "background-color": "transparent"
    }
    var sty3 = JSON.parse(JSON.stringify(sty2));
    sty3.width = "120px";
    return (
      <Container fluid className="main-content-container px-4">

        <center>
            <iframe data-aa='2086132' loading='lazy' src='//ad.a-ads.com/2086132?size=728x90' style={sty}></iframe>
        
            <table border={0}>
                <tr>
                    <td>
                        <iframe data-aa='2086220' loading='lazy' src='//ad.a-ads.com/2086220?size=160x600' style={sty2}></iframe>
                    </td>
                    <td>
                    <h1>Liberium Crypto</h1>

                        <p>Vous trouverez ici pleins d'information concernant les crypto-monnaies.</p>

                        <ul>
                            <li>
                                <p><a href="https://r.honeygain.me/C3RV3A378A">HoneyGain</a> : Je suis en train de l'essayer. A date, facile d'installation.</p>
                                <p>Installer avec un docker</p>
                            </li>
                            <li>
                                <p><a href="https://livepeer.org/">LivePeer</a> : Vous êtes payé pour encoder des vidéos.</p>
                            </li>
                            <li>
                                <p><a href="https://www.golem.network/">Golem Network</a> : Partager votre puissance de PC et gagner des GLM</p>
                            </li>
                        </ul>
                    </td>
                    <td>
                    <iframe data-aa='2086248' loading='lazy' src='//ad.a-ads.com/2086248?size=120x600' style={sty3}></iframe>
                    </td>

                </tr>
            </table>

            
        </center>
    </Container>
    );
  }
}