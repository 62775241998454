import React from "react";
import { Container } from "shards-react";
import {api} from '../../services/autonome';
import { Table } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import {ImpotProfile} from "./profile";
import {ImpotInterface} from "./interface";

export class ImpotClient extends React.Component {
  state = {
    screens: []
  }

  constructor(props) {
    super(props);
    this.state = {
        userId: this.getUserId(),
        curPage: "client"
    }
    this.goProfile = this.goProfile.bind(this);
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  componentDidMount() {
    api.call('post', "user/usrLog", {module: "views", title:"impot"});
    window.gtag('event', 'page_view', {
      page_title: 'Impot client',
      page_location: '/impot/client',
      page_path: '/impot/client'
    });
  }

  componentWillUnmount() {
  }

  goProfile() {
    this.setState({curPage: "profile"});
  }

  renderEspace() {
    if (this.state.curPage === "client")
      return (
        <ImpotInterface
            goProfile={this.goProfile}
        ></ImpotInterface>
      )
    if (this.state.curPage === "profile")
        return (
            <ImpotProfile></ImpotProfile>
        )
  }

  render() {
    var sty = {
        float: "none", 
        margin: "0 auto"
    }    

    return (
      <Container fluid className="main-content-container px-4">

        <div class="row home-intro text-center">
          <div class="col-xs-12 col-md-8" style={sty}>
            <h1>Espace client</h1>            
          </div>
        </div>
        {this.renderEspace()}
    </Container>
    );
  }
}