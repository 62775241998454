import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import DirectionsIcon from '@material-ui/icons/Directions';
import Button from '@material-ui/core/Button';
import OffLineImg from '../../../img/offline.png';
import { RowingSharp } from '@material-ui/icons';
import axios from 'axios';

import {
    FormInput,
    FormGroup
  } from "shards-react";


class TorrentsView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            search: "",
            torrents: [],
            categorie: "",
            categories: []
        }
        this.torrents = {};
        this.state.userId = this.getUserId();
        this.handleChange = this.handleChange.bind(this);
    }

    selectCategory(row) {
        //console.log("cat selected:",row);
        var these = this;
        this.setState({categorie: row});
        setTimeout( function () {
            these.fetchData();
        },250);
    }

    fetchCategory() {
        var these = this;
        api.callModule("torrent", "getCategories", {noDev:true}, function (err,result) {
            console.log("from LB function:",result);
            these.setState({categories: result});
        });
    }

    fetchData() {
        var self = this;
        var data = {
            search: this.state.search
        }
        api.call('post', "admin/getTorrents", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            self.setState({torrents: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchCategory();
        this.fetchData();

        window.gtag('event', 'page_view', {
            page_title: 'Torrents',
            page_location: '/admin/torrents',
            page_path: '/admin/torrents'
        });
    }

    selectTorrent(torrent) {
        //alert(JSON.stringify(server));
        this.props.history.push({
            pathname: "/admin/torrent/" + torrent.id
        });
    }

    goProjects() {
        this.props.history.push({
            pathname: "/admin/projects"
        });
    }

    goModules() {
        this.props.history.push({
          pathname: "/admin/modules"
        });
    }

    goMinio() {
        this.props.history.push({
            pathname: "/admin/minio"
        });
    }

    download(row) {
        if (typeof this.torrents[row.id] !== "undefined") return;
        this.torrents[row.id] = true;

        api.call('get', "admin/doTorrent?torrentId=" + row.id).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
        });
    }

    handleChange(event) {
        console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
        var these = this;
        setTimeout( function () {
            these.fetchData();
        },100);
    }

    renderTorrents() {
        return this.state.torrents.map((row) => {
            var thumb = (<img width={100} src={row.pochette}/>);
            
            return (
                <tr>
                <td>{thumb}</td>
                <td>{row.titre}</td>
                <td>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<DirectionsIcon />}
                        onClick={() => this.download(row)}
                        >
                        Download
                    </Button>
                </td>
                </tr>
            );
          });
    }

    downloadMagnet() {
        axios.get('http://192.168.4.204:30554/doMagnet?magnet=' + this.state.magnet).then((response) => {
            alert("We will download");
        });
    }

    renderCategories() {
        return this.state.categories.map((row) => {
            return (
                <option value={row.categorie}>{row.categorie}</option>
            )
        });
    }

    render(){
        return(
            <>
            <h1>Torrents</h1>
            <p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goProjects()}
            >
              Projects
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goMinio()}
            >
              Minio
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goModules()}
            >
              Modules
            </Button>
            </p>
            <FormGroup>
                <label htmlFor="#magnet">Magnet URI:</label>
                <FormInput type="text" name="magnet" value={this.state.magnet} onChange={this.handleChange} />
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<DirectionsIcon />}
                    onClick={() => this.downloadMagnet()}
                    >
                    Download
                </Button>
            </FormGroup>
            <FormGroup>
                    <label htmlFor="#search">Recherche :</label>
                    <FormInput type="text" name="search" value={this.state.search} onChange={this.handleChange} />
            </FormGroup>
            <FormGroup>
                    <label htmlFor="#category">Category</label>
                    <select onChange={(e) => this.selectCategory(e.target.value)}>
                            <option value=""></option>
                            {this.renderCategories()}
                    </select>
            </FormGroup>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Image</th>
                    <th>Titre</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderTorrents()}
                </tbody>
            </Table>
            </>
        )
    }
}

export default withRouter(TorrentsView);