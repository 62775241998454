import React from "react";
import { Container, Row } from "shards-react";
import NewsCard from "../../components/newsCard";
import {api} from '../../services/autonome';
import Ad from '../../components/ads/AdItem';

class NewsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        news: [],
        loading: false,
        prevY: 0
    }
    this.curPage = 0;
  }

  getNews() {
    var these = this;
    this.setState({ loading: true });
    api.call('get', "news/getLatest?page=" + this.curPage, {}).then(result => {
        var newNews = this.state.news;
        newNews.push(...result.data)
        these.setState({news: newNews, loading: false});
    });
  }

  componentDidMount() {    
    this.getNews();

    var options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0
    };
  
    this.observer = new IntersectionObserver(
        this.handleObserver.bind(this),
        options
    );
    this.observer.observe(this.loadingRef);

    window.gtag('event', 'page_view', {
      page_title: 'News',
      page_location: '/news',
      page_path: '/news'
    });
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.state.prevY > y) {
      this.curPage++;
      this.getNews();
    }
    this.setState({ prevY: y });
  }

  componentWillUnmount() {
  }

  renderNews() {
    var i = 0;
    var pos = -1;
    var allNews = this.state.news.map((row2) => {
        i++;
        if (i%9 === 1 && i !== 1) {
          pos++;

          return (
            <>
              <Ad 
                pos={pos}
                size={"300x250"}
                page="news"
              />
              <NewsCard news={row2}/>
            </>
          )
        }
        return (
            <NewsCard news={row2}/>
        )
    });
    return allNews;
  }

  render() {
    const loadingCSS = {
        height: "100px",
        margin: "30px"
      };

    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };

    return (
        <Container fluid className="main-content-container px-4">
            <h1>Dernières nouvelles</h1>

                <div class="row">
                    <p>Nous sommes ravis d'annoncer une mise à jour majeure de notre plateforme qui vise à offrir une expérience médiatique encore plus enrichissante. Désormais, nous avons intégré une sélection complète des principaux médias canadiens, donnant ainsi à nos utilisateurs un accès direct à une gamme diversifiée de contenus journalistiques locaux. Bien que cette mise à jour soit en cours de déploiement, il est possible que certains ajustements soient nécessaires au cours des prochains jours pour assurer un fonctionnement optimal. Nous nous engageons à résoudre rapidement tout problème technique éventuel afin que vous puissiez profiter pleinement de cette nouvelle fonctionnalité et découvrir les actualités et les récits les plus pertinents du Canada. Nous vous remercions de votre patience et de votre fidélité à notre plateforme.</p>
                    {this.renderNews()}
                </div>
                <div
                    ref={loadingRef => (this.loadingRef = loadingRef)}
                    style={loadingCSS}
                >
                    <span style={loadingTextCSS}>Chargement...</span>
                </div>

        </Container>
    );
  }
}

export default NewsPage;