//https://www.bootdey.com/snippets/view/chat-app#html

import React from 'react';
import Button from '@material-ui/core/Button';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import EditIcon from '@material-ui/icons/Edit';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Message } from '../../components/chat/message';
import MessageIcon from '@material-ui/icons/Message';

import {api} from '../../services/autonome';
import axios from 'axios';
import { API_ROOT } from '../../api-config';

//import './discussion.css';

export class BannerBearView extends React.Component {

    constructor(props) {
        super(props)
        //console.log(props)
        this.state = {
            image: "",
            text1: "Voici le text #1"
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getUserId() {
        var existingTokens;
        try {
            existingTokens = JSON.parse(localStorage.getItem('tokens'));
            return existingTokens.UserId;
        } catch(e) {
            return null;
        }
    }

    handleChange(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value,
          is_error: false
        });
    }
    
    handleSubmit(event) {
        var these = this;

        var data = {
            blabla: "une string blabla ok"
        }
        api.call("post", "ia/bannerbear_webhook/3", data).then(result => {            
            console.log("Data from bannerbear:", result);
            these.setState({image:""});
        })
        return;

        var data = {
            "template" : 'RnxGpW5ljkpybEXrJ1',
            "modifications" : [
              {
               "name": "image_container",
               "image_url": "https://images.unsplash.com/photo-1541267226650-673e4bc869c7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1778&q=80"
              },
              {
                "name": "pretitle",
                "text": "Rejoignez-nous!",
                "color": null,
                "background": null
              },
              {
                "name": "title",
                "text": "Nouveau réseau social",
                "color": null,
                "background": null
              },
              {
                "name": "tag",
                "text": "100%\nQuébecois",
                "color": null,
                "background": null
              },
              {
                "name": "CTA",
                "text": "C'est gratuit",
                "color": null,
                "background": null
              }
            ]
        }
        api.call("post", "ia/bannerbear", data).then(result => {            
            console.log("Data from bannerbear:", result);
            these.setState({image:""});
        }).catch(function(err) {
            //alert(JSON.stringify(err));
            these.setState({});
        });
        window.gtag('event', 'send_to_bannerbear', {});
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter') {
            this.handleSubmit(null);
        }
    }

    componentWillUnmount() {
    }

    componentDidMount() {
        window.gtag('event', 'page_view', {
            page_title: 'Chat GPT',
            page_location: '/chatgpt',
            page_path: '/chatgpt'
        });
    }

    goBack() {
        if (window.history.length<=1) {
            this.props.history.push({
                pathname: "/home"
            });
        } else {
            window.history.go(-1);
        }
    }

    render(){
        var banner = <></>
        return(
            <>
                <div class="appHeader">
                    <div class="left">
                        <a onClick={() => this.goBack()} class="headerButton goBack">
                            <ion-icon name="chevron-back-outline" role="img" class="md hydrated" aria-label="chevron back outline"></ion-icon>
                        </a>
                    </div>
                    <div class="pageTitle">
                        Banner Bear
                    </div>
                    <div class="right">
                    </div>
                </div>
            <div class="card chat-app">
                <div class="chat">
                    <div class="chat-history">
                        <ul class="m-b-0">
                            {banner}
                        </ul>
                    </div>
                    <div class="chat-message"

                    >
                        <div class="input-group mb-0">
                            <textarea
                                type="text"
                                rows={4}
                                name="prompt"
                                class="form-control"
                                placeholder="Prompt"
                                value={this.state.prompt}
                                onChange={e => this.handleChange(e)}
                                onKeyPress={this.handleKeyPress} 
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<MessageIcon />}
                                onClick={() => this.handleSubmit()}
                            >
                                
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}