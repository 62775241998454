import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import Button from '@material-ui/core/Button';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { Form } from "react-bootstrap";

export class ContainerView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            logs: [],
            server: null,
            serverId: parseInt(props.match.params.serverId),
            containerId: parseInt(props.match.params.containerId),
            showNewContainer: false
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "hosting/server/" + this.state.serverId + "/container/" + this.state.containerId + "/logs", {}).then(result => {
            these.setState({logs: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();
    }

    renderContainers() {

    }

    handleChange(event) {
        //console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
        this.setState({errorMsg: null});
    }

    renderLogs() {
        return this.state.logs.map((row) => {
            return (
                <tr>
                <td>{row.message}</td>
                </tr>
            );
          });
    }

    render(){
        return(
            <>
            <h1>Container #{this.state.containerId}</h1>
            {this.renderLogs()}
            </>
        )
    }
}