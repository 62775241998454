import React from "react";
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';

import { Alert } from "react-bootstrap";

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

export class VirtualJobFormation extends React.Component {
  state = {
    screens: []
  }

  constructor(props) {
    super(props);
    this.state = {
      userId: this.getUserId(),
      message: "", 
      formation: {
        id: 3, 
        name: "La base de notre Marketing",
        video: "https://api.liberium.net/s/liberium-public-img/premiere_formation.mp4",
        questions : [
            {question:"Qui a créé Liberium?",answers:["Eric Grenier","Claude Grenier","Christian Grenier"], goodAnswer: "Eric Grenier"},
            {question:"En quelle année a été créer Liberium", answers:["2010","2020","2023"], goodAnswer: "2023"}
        ],
        groupToGive: "marketing",
        reward:"La base marketing de Liberium",
        pricePerJobAverage: 0.02,
        formationNeeded: [1,2]
      },
      rendu: 2,
      curQuestion: 0
    }
    this.handleChange = this.handleChange.bind(this);
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  componentDidMount() {
    var these = this;

    window.gtag('event', 'page_view', {
      page_title: 'Virtual Job Formation',
      page_location: '/virtual-job/formation',
      page_path: '/virtual-job/formation'
    });
  }

  nextStep() {
    this.setState({rendu: 2, curQuestion: 0});
  }

  componentWillUnmount() {
  }

  handleChange(event) {
    //console.log("Handle change", event.target.name);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
    this.setState({errorMsg: null});
  }

  goBack = () => {
    this.props.history.push({
        pathname: "/virtual-job" 
    });
  }

  renderMessage() {
    if (this.state.message.length === 0)
     return (<></>)
    return (
        <Alert variant="danger">
            {this.state.message}
        </Alert>
    )
  }

  renderAnswers() {
    return this.state.formation.questions[this.state.curQuestion].answers.map((row) => {
      return (
        <FormControlLabel value={row} control={<Radio />} label={row} />
      );
    });
  }

  choiceDone() {
    var self = this;
    if (this.state.formation.questions[this.state.curQuestion].goodAnswer === this.state.answerChoosed) {
        this.setState({message: ""});
        if (this.state.curQuestion === this.state.formation.questions.length -1) {
            // TODO - Appeler l'api
            self.setState({rendu:3});
        } else
            this.setState({curQuestion: this.state.curQuestion + 1});
    } else {
        this.setState({rendu:1, message: "Non ce n'est pas la bonne réponse. Veuillez ré-écouter la vidéo"});
    }
  }

  renderFelicitation() {
    return (
        <>
        <h1>Félicitation !!!</h1>

        <p>Vous venez d'obtenir avec succès : {this.state.formation.reward}</p>

        <p>Après avoir compléter cette formation, de nouvelles tâches s'afficheront.</p>

        <p>Vous pouvez continuer de suivre d'autres formations. Une seule formation n'est pas suffisant pour faire 8 heures en ligne.</p>
        </>
    )
  }

  renderQuestion() {
    return (
        <>
            <h2>Test de compréhension</h2>

            {this.renderMessage()}

            <FormControl component="fieldset">
                <FormLabel component="legend">{this.state.formation.questions[this.state.curQuestion].question}/</FormLabel>
                  <RadioGroup aria-label="Votre réponse" name="answerChoosed" value={this.state.answerChoosed} onChange={this.handleChange}>
                    {this.renderAnswers()}
                </RadioGroup>
            </FormControl>
            <br/><br/>
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => this.choiceDone()}
            >
                Mon choix est fait
            </Button>
        </>
    )
  }

  renderFormation(){
    if (this.state.rendu ===1)
        return (
            <>
                {this.renderMessage()}
                <video controls>
                    <source src={this.state.formation.video} type="video/mp4" />
                </video>
                <Button
                    variant="contained"
                    color="primary"
                    size="small" 
                    startIcon={<EditIcon />}
                    onClick={() => this.nextStep()}
                >
                    Étape suivante
                </Button>
            </>
        )
    if (this.state.rendu ===2)
        return this.renderQuestion();
    if (this.state.rendu === 3)
        return this.renderFelicitation();
    return (<></>)
  }

  render() {
    return (
        <>
            <h1>Formation Assistant Virtuel</h1>

            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => this.goBack()}
            >
                Retour
            </Button>

            {this.renderFormation()}
        </>
    );
  }
}