import React from 'react';
import { withRouter } from 'react-router'; 
import lightGreen from "../../img/green_light.jpeg";

export class ChatUser extends React.Component {

    constructor(props){
        super(props)
        this.onClick = this.onClick.bind(this);
    }

    onClick = () => {
        window.location.href = "#/discussion/" + this.props.userId;
        /*
        console.log("props:", this.props);
        this.props.history.push({
            pathname: "/discussion/" + this.props.userId
        });
        */
    }

    render() {
        var isOnline;
        if (this.props.isOnline)
            isOnline = (
                <img src={lightGreen} width={25}/>
            )
        var ligne;
            ligne = (
                <>
                <div>
                    <img src={"https://api.liberium.net/avatar/"+this.props.userId} alt="avatar" width={50}/>
                    {this.props.name}
                    {isOnline}
                </div>
                </>
            )
        return (
            <li class="clearfix" onClick={(e) => this.onClick()}>
                {ligne}
            </li>            
        )
    }
}

export default withRouter(ChatUser);