import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router';
import { Form, Modal } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

class CryptoModalExchange extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            show: true,
            asset: props.asset,
            symbol: "",
            symbols: [],
            orderType: "limit",
            side: "sell",
            amount: props.amount,
            wallets: [
            ],
            ticker: {}
        }
        this.state.userId = this.getUserId();
        this.closeModal = props.closeExchange;
    }

    fetchData() {
        var these = this;
        api.call('get', "v2/cryptoExchanger/symbolWith?exchanger=binance&asset=" + this.state.asset, {}).then(result => {            
            //console.log(result.data);
            these.setState({symbol: result.data[0], symbols: result.data});
            these.getTicker(result.data[0]);
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    handleSymbol(e) {
        this.setState({symbol: e.target.value, ticker: {bid:"n/a", ask:"n/a"}})
        this.getTicker(e.target.value);
    }

    getTicker(symbol) {
        var these = this;
        api.call('get', "v2/cryptoExchanger/getTicker?exchanger=binance&symbol=" + symbol, {}).then(result => {            
            these.setState({ticker: result.data});
        });
    }

    createOrder() {
        var these = this;
        var url = "v2/cryptoExchanger/createOrder?exchanger=binance" +
            "&symbol=" + this.state.symbol +
            "&orderType=" + this.state.orderType +
            "&side=" + this.state.side +
            "&amount=" + this.state.amount +
            "&price=" + this.state.price;
        console.log(url);
        api.call('get', url, {}).then(result => {
            these.setState({ticker: result.data});
        }); 
    }

    handleChange(event) {
        //console.log("Handle change", event.target);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    componentWillUnmount() {
        clearInterval(this.changeInt);
    }

    useBid() {
        this.setState({price:this.state.ticker.bid});
    }

    useAsk() {
        this.setState({price:this.state.ticker.ask});
    }

    componentDidMount() {
        var these = this;
        this.changeInt = setInterval( function () {            
            if (these.state.symbol.length > 0) {
                these.getTicker(these.state.symbol);
            }
        },2000);
        this.fetchData();
    }

    render() {
        var options = this.state.symbols.map((row) => {
            return (
                <option value={row}>{row}</option>
            )
        });
        return (
                    <Form>
                        <Form.Group className="mb-3" controlId="image">
                        <Form.Label>Symbol</Form.Label>
                        <select onChange={(e) => this.handleSymbol(e)}>
                            {options}
                        </select>
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId="image">
                        <Form.Label>orderType</Form.Label>
                        <Form.Control 
                            type="text"
                            name="orderType"
                            placeholder="limit" 
                            value={this.state.orderType} 
                            onChange={e => this.handleChange(e)}
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="side">
                        <Form.Label>Side</Form.Label>
                        <Form.Control 
                            type="text"
                            name="side"
                            placeholder="buy" 
                            value={this.state.side} 
                            onChange={e => this.handleChange(e)}
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="amount">
                        <Form.Label>amount</Form.Label>
                        <Form.Control 
                            type="text"
                            name="amount"
                            placeholder="0.001" 
                            value={this.state.amount} 
                            onChange={e => this.handleChange(e)}
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="price">
                        <Form.Label>price</Form.Label>
                        <Form.Control 
                            type="text"
                            name="price"
                            placeholder="1.25" 
                            value={this.state.price} 
                            onChange={e => this.handleChange(e)}
                        />
                        </Form.Group>

                        <p>Bid: <a onClick={(e)=>this.useBid()}>{this.state.ticker.bid}</a></p>
                        <p>Ask: <a onClick={(e)=>this.useAsk()}>{this.state.ticker.ask}</a></p>

                        <div class="container">
                            <div class="row">
                                <div class="col text-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<EditIcon />}
                                        onClick={() => this.createOrder()}
                                    >
                                        Create order
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>                
        )
    }
}

export default withRouter(CryptoModalExchange);