import React from "react";
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import {api} from '../../services/autonome';
import { Form, Modal } from "react-bootstrap";
import TimePicker from 'react-bootstrap-time-picker';
import noServiceIcon from '../../img/service-icon.png'
import DragAndDrop from '../../components/filedragdrop';
import Address from '../../components/liberium/address';
import { Table } from "react-bootstrap";
import axios from 'axios';
import MakeAdeposit from '../../components/finance/make_a_deposit';

const stripEmojis = (str) =>
  str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    )
    .replace(/\s+/g, ' ')
    .trim();

export class ModelOverview extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            model: this.props.model,
            show: false,
            disabled_ok: false,
            files: [],
            values: {amount:"0"},
            step: "form", // form ou quote
            theQuote: { amount: "n/d" },
            userProfile: this.props.userProfile,
            wallets: [],
            wallet: null,
            errorModal: "",
            currentLocation: {street_adress: "", city: ""}
        }
        if (typeof this.state.model.quote === "undefined")
            this.state.model.quote = false;
        this.handleDrop = this.handleDrop.bind(this);
        this.handleWallet = this.handleWallet.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        var these = this;
        this.state.model.fields.forEach(function(field) {
            these.state.values[field.name] = "";
            if (field.type==="hour") {
                these["handle_" + field.name] = function (time) {
                    var new_form = this.state.values;
                    new_form[field.name] = time;
                    this.setState({values:new_form});
                    this.setState({errorMsg: null});
                }.bind(these);
            }
            if (field.type==="address") {
                these.state.values[field.name] = {
                    street_adress: "",
                    city: ""
                }
            }
            if (typeof field.default !== "undefined") {
                if (field.default === "{getLocation}") {
                    these.getLocalisation(these.state.values[field.name], function () {
                        console.log("use home address instead")
                        these.state.values[field.name] = {street_adress: these.state.userProfile.street_adress, city: these.state.userProfile.city}
                    });
                } else if (field.default === "{userAddress}") {
                    these.state.values[field.name] = {street_adress: these.state.userProfile.street_adress, city: these.state.userProfile.city}
                } else {
                    if (field.default[0] === "[") {
                        var reste = field.default.substring(1);
                        //alert(reste);
                        reste = reste.slice(0, -1);
                        //alert(reste);
                        console.log("Reste:",reste);
                        api.call('get', "user/meta/" + reste, {}).then(result => {
                            console.log(result.data.value);
                            //alert(result.data.value);
                            if (field.type === "address") {
                                var content = JSON.parse(result.data.value);
                                these.state.values[field.name] = {
                                    street_adress: content.street_adress,
                                    city: content.city
                                }
                            } else {
                                these.state.values[field.name] = result.data.value;
                            }
                        });
                    } else
                        these.state.values[field.name] = field.default;
                }
            }
        });

        var modelId = parseInt(localStorage.getItem("modellId"));
        if (modelId === this.state.model.id) {
            localStorage.setItem("modellId", null);
            //alert(modelId);
            this.state.show = true;
        }
    }

    onPosition(valeur, coords) {
        var these = this;
        api.call('get', "geo/getMyAddress?lat=" + coords.latitude + "&long=" + coords.longitude, {}).then(result => {
          //alert(result.data.results[0].formatted_address);
          var splited = result.data.results[0].formatted_address.split(",");
          var i = 0;
          var new_datas = this.state;
          valeur.street_adress = splited[0];
          valeur.city = splited[1];
        })
    }
    
    getLocalisation(valeur, backupCb) {
        var these = this;
        if ("geolocation" in navigator) {
          console.log("geolocation Available");
        } else {
          return console.log("geolocation Not Available");
        }
        
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        }
    
        navigator.geolocation.getCurrentPosition(function(position) {
          console.log("Latitude is :", position.coords.latitude);
          console.log("Longitude is :", position.coords.longitude);
          if (position.coords.accuracy>=50) {
            return backupCb();
          }
          these.onPosition(valeur, position.coords);
          //alert(position.coords.latitude + " " + position.coords.longitude)
          //alert(position.coords.accuracy + "m près");
        }, function (err) {
            backupCb();
        }, options);
    }

    handleWallet(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ walletSelected: value, paymentOk: true });
    }

    handleDrop = (name, files) => {
        var self = this;
        for (var i = 0; i < files.length; i++) {
          if (!files[i].name) return;
            const data = new FormData();
            data.append('file', files[i]);
            this.setState({
                uploadMsg: "Upload en cours"
            });
            axios
            .post("https://api.liberium.net/s/stor-img", data)
            .then(function (response) {
                console.log(response);
                var new_form = self.state.values;
                new_form[name].push(response.data.url);;
                self.setState({values:new_form});
            })
        }
    }

    prepareValues(values) {
        var new_values = {}
        Object.keys(this.state.values).forEach(function(key) {
            //console.log(key,":", these.state.values[key]);
            new_values[key] = values[key];
            if (values[key].length > 0 && !Array.isArray(values[key])) {
                console.log("old value:",new_values[key]);
                new_values[key] = stripEmojis(values[key]).normalize("NFD").replace(/[\u0300-\u036f]/g, "").split("’").join("'");
                console.log("new value:",new_values[key]);
                console.log("------------------");
            } else if (typeof new_values[key] == "object") {
                console.log("Not in if:", key);
                console.log("typeof:", typeof new_values[key]);
                Object.keys(new_values[key]).forEach(function(key2) {
                    console.log("key2:",key2);
                    new_values[key][key2] = stripEmojis(values[key][key2]).normalize("NFD").replace(/[\u0300-\u036f]/g, "").split("’").join("'");
                });
                //new_values[field.name].street_adress = new_values[field.name].street_adress.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                //new_values[field.name].city = new_values[field.name].city.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            }
        });
        return new_values;
    }

    acceptQuote() {
        var these = this;
        this.setState({disabled_ok:true});
        var new_values = this.prepareValues(this.state.values)

        // TODO - Vérifier si les champs ont needed pour valider si le formulaire peut être envoyé                
        api.call('post', "tasks/acceptQuote", {modelId: this.state.model.id, values: new_values, theQuote: this.state.theQuote, walletId: this.state.walletSelected}).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            these.props.history.push({
                pathname: "/task/" + result.data.id
            });
        });
    }

    validateForm() {
        var these = this;
        var valid = true;
        var fieldError = "";
        var new_values = this.prepareValues(this.state.values)
        this.state.model.fields.forEach(function(field) {
            if (typeof field.saveMeta !== "undefined") {
                var content = ""
                if (field.type === "address") {
                    content = JSON.stringify({street_adress: new_values[field.name].street_adress, city: new_values[field.name].city})
                } else
                    content = these.state.values[field.name];
                //alert("saving Meta " + content);
                api.call('post', "user/meta/" + field.saveMeta, {value: content}).then(result => {
                });
            }
            if (typeof field.needed !== "undefined" && field.needed) {
                if (field.type === "address") {
                    if (new_values[field.name].street_adress.length == 0) {
                        fieldError = field.name + " doit être remplie au complet."
                        valid = false;
                    }
                    if (new_values[field.name].city.length == 0) {
                        valid = false;
                        fieldError = field.name + " doit être remplie au complet."
                    }
                } else if (field.type === "boolean") {
                } else if (new_values[field.name].length === 0) {
                    valid = false;
                    fieldError = field.name + " doit être remplie."
                }
            }
        });
        this.setState({errorModal: fieldError});
        return valid;
    }

    ok() {
        var these = this;
        this.setState({disabled_ok:true});
        var new_values = this.prepareValues(this.state.values)
        var valid = this.validateForm();
        if (valid) {
            if (this.state.values.amount.length == 0 || isNaN(this.state.values.amount)) {
                valid = false;
                this.setState({errorModal: "Indiquez le montant vous voulez payer"});
            }
        }
        if (valid)
            api.call('post', "task/new", {modelId: this.state.model.id, values: new_values}).then(result => {
                if (typeof result.data.error !== "undefined") {
                    these.setState({disabled_ok:false});
                    return alert("Une erreure s'est produite");
                }
                these.props.history.push({
                    pathname: "/task/" + result.data.id
                });
                window.gtag('event', 'created_task', {});
                if (this.state.model.isContract) {
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-439961082/hfxjCLjWkpoYEPqL5dEB',
                        'value': 1.0,
                        'currency': 'CAD'
                    });
                }
            })
        else {
            this.setState({disabled_ok:false});
        }
    }

    componentDidMount(){
        var these = this;
    }

    onOpen() {
        if (this.state.model.name=="Lift") {
            //this.getLocalisation();
        }
    }

    showModel() {
        this.onOpen();
        this.setState({show:!this.state.show});
        window.gtag('event', 'show_model', {
            modelId: this.state.model.id,
            modelName: this.state.model.name
        });
    }

    handleChange(event) {
        //console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state.values;
        new_form[name] = value;
        this.setState({values:new_form, errorMsg: null});
    }

    renderButton() {
        return (
            <Button
                variant="contained"
                disabled={this.state.disabled_apply}
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => this.showModel()}
            >
            Oui
            </Button>
        );
    }

    deleteFile(field, url) {
        var values = this.state.values;
        values[field] = values[field].filter(e => e !== url);
        this.setState({values: values});
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    closeModal() {
        this.setState({show: false, step:"form"})
    }

    getQuote() {
        var these = this;        
        var new_values = this.prepareValues(this.state.values)
        var valid = this.validateForm();
        if (valid) {
            this.setState({disabled_ok:true});
            api.getWallet(function(wallets) {
                wallets = wallets.wallets;
                console.log(wallets);
                these.setState({wallets: wallets, walletSelected: wallets[0].walletId, wallet: wallets[0]});
                api.call('post', "tasks/quote", {values: new_values, quote_svc: these.state.model.quote_svc}).then(result => {
                    these.setState({theQuote: result.data});
                    if (parseFloat(these.state.wallet.quantity) < parseFloat(result.data.amount))
                        these.setState({disabled_ok: false});
                    these.setState({step:"quote", disabled_ok:false});
                });               
            });            
        } else {
            alert("Not valid.")
        }
    }

    updateAddress(name, field, value) {
        var new_form = this.state.values;
        new_form[name][field] = value;
        this.setState({values:new_form});
    }

    render () {        
        var these = this;
        var fields = this.state.model.fields.map((field) => {
            var controlId = "cti_" + field.title
            if (field.type === "address")
                return (
                    <Address
                        key={controlId}
                        label={field.label}
                        muted={field.muted}
                        name={field.name}
                        values={this.state.values[field.name]} 
                        onUpdate={this.updateAddress}
                    />
                );

            if (field.type === "boolean")
                return (
                    <Form.Group className="mb-3" controlId={controlId} key={controlId}>
                        <Form.Check 
                            type='checkbox'
                            id={field.name}
                            name={field.name}
                            label={field.label}
                            value={this.state.values[field.name]} 
                            onChange={e => this.handleChange(e)}
                        />
                    </Form.Group>
                );
            if (field.type === "string")
                return (
                    <Form.Group className="mb-3" controlId={controlId} key={controlId}>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control 
                            type="text"
                            name={field.name}
                            placeholder={field.placeholder} 
                            value={this.state.values[field.name]} 
                            onChange={e => this.handleChange(e)}
                        />
                        <Form.Text className="text-muted">
                        {field.textMuted}
                        </Form.Text>
                    </Form.Group>
                );
            if (field.type === "integer")
                return (
                    <Form.Group className="mb-3" controlId={controlId} key={controlId}>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control 
                            type="text"
                            name={field.name}
                            placeholder={field.placeholder} 
                            value={this.state.values[field.name]} 
                            onChange={e => this.handleChange(e)}
                        />
                        <Form.Text className="text-muted">
                        {field.textMuted}
                        </Form.Text>
                    </Form.Group>
                );
            if (field.type === "date") 
                return (
                    <Form.Group className="mb-3" controlId={controlId} key={controlId}>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                            type="date"
                            name={field.name}
                            value={this.state.values[field.name]} 
                            onChange={e => this.handleChange(e)}
                        >                        
                        </Form.Control>
                        <Form.Text className="text-muted">
                        {field.textMuted}
                        </Form.Text>
                    </Form.Group>
                )
            if (field.type === "hour")
                return (
                    <Form.Group className="mb-3" controlId={controlId} key={controlId}>
                        <Form.Label>{field.label}</Form.Label>
                        <TimePicker
                        name={field.name}
                        value={this.state.values[field.name]}
                        onChange={this["handle_" + field.name]}
                        step={15} />
                        <Form.Text className="text-muted">
                        {field.textMuted}
                        </Form.Text>
                    </Form.Group>
                )
            if (field.type === "text") 
                return (
                    <Form.Group className="mb-3" controlId={controlId} key={controlId}>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control as="textarea" rows={3}
                            type="textarea"
                            name={field.name}
                            value={this.state.values[field.name]} 
                            onChange={e => this.handleChange(e)}
                        >                        
                        </Form.Control>
                        <Form.Text className="text-muted">
                        {field.textMuted}
                        </Form.Text>
                    </Form.Group>
                );
            if (field.type === "files") {
                var files = this.state.values[field.name].map((file) => {
                    return (
                            <tr key={file}>
                                <td component="th" scope="row">
                                    <img src={file} width={100}/>
                                </td>
                                <td align="right">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<EditIcon />}
                                        onClick={() => these.deleteFile(field.name, file)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        )
                });
                return (
                    <>
                    <Form.Label>{field.label}</Form.Label>

                    <DragAndDrop name={field.name} handleDrop={this.handleDrop}>
                        <h2>Déplacer des fichiers ici</h2>
                            <Table aria-label="customized table">
                                <thead>
                                <tr>
                                    <td>Photo</td>
                                    <td align="right">Action</td>
                                </tr>
                                </thead>
                                <tbody>
                                {files}
                                </tbody>
                            </Table>
                        <Form.Text className="text-muted">
                        {field.textMuted}
                        </Form.Text>
                    </DragAndDrop>
                    </>
                );
            }
        });
        var imgModel = (
            <img src={noServiceIcon} width='50' style={{margin: "10px 10px 10px 10px"}}/>
          )
        if (this.state.model.icon.length > 0)
          imgModel = (
              <img width="50" height="50" src={this.state.model.icon} style={{margin: "10px 10px 10px 10px"}}/>
            )
        var ending;
        if (this.state.model.quote) {
            ending = (
                <div class="container">
                    <div class="row">
                        <div class="col text-center">
                            <Button
                            variant="contained"
                            disabled={this.state.disabled_ok}
                            color="primary"
                            size="small"
                            startIcon={<CheckIcon />}
                            onClick={() => this.getQuote()}
                            >
                                Obtenir une quote
                            </Button>
                        </div>
                    </div>
                </div>
            )
        } else {
            if (this.state.model.isContract) {
                ending = (
                    <>
                        <Form.Group className="mb-3" controlId="amount">
                            <Form.Label>En cliquant terminé, vous recevrez des soumissions. Vous pourrez en accepter qu'une seule. En tout temps, vous pourrez annuler votre contrat.</Form.Label>                            
                        </Form.Group>

                            <div class="container">
                                <div class="row">
                                    <div class="col text-center">
                                        <Button
                                        variant="contained"
                                        disabled={this.state.disabled_ok}
                                        color="primary"
                                        size="small"
                                        startIcon={<CheckIcon />}
                                        onClick={() => this.ok()}
                                        >
                                            Terminé
                                        </Button>
                                    </div>
                                </div>
                            </div>
                    </>
                )
            } else {
                ending = (
                    <>
                        <Form.Group className="mb-3" controlId="amount">
                            <Form.Label>Montant vous voulez débourser pour cette tâche</Form.Label>
                            <Form.Control 
                                type="text"
                                name="amount"
                                placeholder="Montant" 
                                value={this.state.values.amount} 
                                onChange={e => this.handleChange(e)}
                            />
                            <Form.Text className="text-muted">
                            
                            </Form.Text>
                        </Form.Group>

                            <div class="container">
                                <div class="row">
                                    <div class="col text-center">
                                        <Button
                                        variant="contained"
                                        disabled={this.state.disabled_ok}
                                        color="primary"
                                        size="small"
                                        startIcon={<CheckIcon />}
                                        onClick={() => this.ok()}
                                        >
                                            Terminé
                                        </Button>
                                    </div>
                                </div>
                            </div>
                    </>
                )
            }
        }
        var the_modal;
        if (this.state.step === "form") {
            var errorMsg;
            if (this.state.errorModal.length > 0)
                errorMsg = (
                    <div class="alert alert-danger mb-1" role="alert">
                        {this.state.errorModal}
                    </div>
                )
            the_modal = (
            <>
                {errorMsg}
                {fields}
                {errorMsg}
                {ending}
            </>
            )
        }
        if (this.state.step === "quote") {
            var paiements = this.state.wallets.map((wallet) => {
                var disabled;
                var addTxt;
                if (parseFloat(this.state.theQuote.amount) > parseFloat(wallet.quantity)) {
                    disabled = true;
                    addTxt = (
                        <MakeAdeposit/>
                    )
                }
                return (
                <div>
                    <Form.Check
                        type="radio"
                        name="walletId"
                        label={wallet.asset}
                        id={`compte_$wallet.asset`}
                        disabled={disabled}
                        checked={wallet.walletId == this.state.walletSelected}
                        onChange={this.handleWallet}
                        value={wallet.walletId}
                    />
                    ${parseFloat(wallet.quantity).toFixed(2)} disponible
                    {addTxt}
                </div>
                )
            });

            the_modal = (
            <>
                <h1>Soumission</h1>
                Montant: {this.state.theQuote.amount}$
                <h2>Votre paiement</h2>
                {paiements}
                <div>
                    <Form.Check
                        type="radio"
                        name="walletId"
                        label="cash"
                        id="cash"
                        checked={"cash" == this.state.walletSelected}
                        onChange={this.handleWallet}
                        value={"cash"}
                    />
                    Paiement cash au travailleur Liberium
                </div>
                <div class="container">
                            <div class="row">
                                <div class="col text-center">
                                    <Button
                                    variant="contained"
                                    disabled={this.state.disabled_ok}
                                    color="primary"
                                    size="small"
                                    startIcon={<CheckIcon />}
                                    onClick={() => this.acceptQuote()}
                                    >
                                        Accepté
                                    </Button>
                                </div>
                            </div>
                        </div>
            </>
            )
        }
        //step: "form" // form ou step
        return (
            <>
            <li onClick={() => this.showModel()} id={this.state.model.id}>
                <a class="item">
                    {imgModel}
                    <div class="in">
                        <div>
                            <header>{this.state.model.name}</header>
                            <footer>{this.state.model.description}</footer>
                        </div>
                        <span class="text-muted"></span>
                    </div>
                </a>
            </li>
            
            <Modal
                size="lg"
                show={this.state.show}
                onHide={() => this.closeModal()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    {this.state.model.name}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {the_modal}
                    </Form>
                </Modal.Body>
            </Modal>
            </>
        )
    }
}

export default withRouter(ModelOverview);