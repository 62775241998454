import React from 'react'
import {
    Container,
    Row
} from "shards-react";
import { Form, Modal } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import {ListInvestors} from '../../../components/finance/list_investors';
import {api} from '../../../services/autonome';

export class InvestorsView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            orgId: props.match.params.orgId,
            business: [],
            overview: [],
            showNew: false,
            investor: {
                email: "",
                nbrTokens: 1000
            }
        }
    }

    getUserId() {
        var existingTokens;
        try{
            existingTokens = JSON.parse(localStorage.getItem('tokens'));
            return existingTokens.UserId;
        } catch(e) {
            return null;
        }
    }


    handleChange(event) {
        //console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state.investor;
        new_form[name] = value;
        this.setState({investor:new_form, errorMsg: null});
    }

    showAddInvestor() {
        this.setState({showNew: true});
    }

    addInvestor() {
        api.call('post', "organisation/" + this.state.orgId + "/addInvestor", {email: this.state.investor.email, nbrTokens: this.state.investor.nbrTokens}).then(result => {
            if (!result.data.success) alert("oops");
            this.setState({showNew: false});
            this.fetchOverview();
        });
    }

    componentDidMount(){
        //console.log("call fetch");
        this.fetchOverview();
    }

    fetchOverview() {
        api.call('get', "organisation/" + this.state.orgId + "/tokens/overview", {}).then(result => {
            console.log("Got tokens:", result);
            this.setState({
                overview: result.data,
            });
        });
    }

    render() {
        return(
            <Container fluid className="main-content-container px-4">

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SpeedDialIcon />}
                    onClick={() => this.showAddInvestor()}
                >
                    Ajout d'un investisseur
                </Button>

                <ListInvestors orgId={this.state.orgId}>

                </ListInvestors>

                <Modal
                    size="lg"
                    show={this.state.showNew}
                    onHide={() => this.setState({showNew: false})}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Nouveau investissement
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>                        
                        <h1>Remplissez le formulaire</h1>

                        <Form.Group className="mb-3" controlId="name" key="name">
                        <Form.Label>Email de l'investisseur</Form.Label>
                        <Form.Control 
                            type="text"
                            name="email"
                            placeholder="Email de l'investisseur"
                            value={this.state.investor.email}
                            onChange={e => this.handleChange(e)}
                        />
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="amount" key="amount">
                        <Form.Label>Nombre de token</Form.Label>
                        <Form.Control 
                            type="text"
                            name="nbrTokens"
                            placeholder="1000"
                            value={this.state.investor.nbrTokens}
                            onChange={e => this.handleChange(e)}
                        />
                    </Form.Group>

                        <center>
                            <Button
                                variant="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() => this.addInvestor()}
                            >
                                Créer
                            </Button>
                        </center>
                    </Modal.Body>
                </Modal>
            </Container>
        )
    }
}