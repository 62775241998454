import React from 'react'
import {
    Container,
    Row,
    Col,
    Button
  } from "shards-react";
import { makeStyles } from '@material-ui/core/styles';

import {api} from '../../../services/autonome';

const useStyles = makeStyles((theme) => ({
    root: {
      height: 380,
      transform: 'translateZ(0px)',
      flexGrow: 1,
    },
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
}));

export class BusinessOrdersView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            orgId: props.match.params.id,
            orders: [],
            total: 0
        }
    }

    fetchData() {
        api.call('get', "business/" + this.state.orgId + "/orders", {}).then(result => {
            console.log("Got orders:", result)
            if (typeof result.data.error != "undefined") return;
            var total = 0;
            result.data.forEach(function(one) {
                total += parseFloat(one.price);
            });
            this.setState({
                orders: result.data,
                total: total
            });
        });
    }

    handleNewTransaction() {
        this.props.history.push({
            pathname: "/business/" + this.state.orgId + "/transaction"
        });
    }

    clickItem = (item) => {
        this.props.history.push({
            pathname: "/business/" + this.state.orgId + "/item/" + item.itemId
        });
    }

    itemReady = (item) => {
        //console.log("item ready selected", item);
        //return alert(JSON.stringify(item));
        if (typeof item.encanId !== "undefined") {
            api.call('get', "business/" + this.state.orgId + "/order/" + item.id + "/ready", {}).then(result => {
                this.fetchData();
            });
        } else {
            api.call('get', "business/" + this.state.orgId + "/invoice_detail/" + item.id + "/ready", {}).then(result => {
                this.fetchData();
            });
        }
    }

    goItems = () => {
        this.props.history.push({
            pathname: "/business/" + this.state.orgId
        });
    }

    goClients = () => {
        this.props.history.push({
            pathname: "/business/" + this.state.orgId + "/clients"
        });
    }

    componentDidMount() {
        this.fetchData()
    }

    getOrders() {
        var self = this;
        if (this.state.orders.length == 0)
            return (
                <tr>
                    <td colspan="3">Vous n'avez aucune commande.</td>
                </tr>
            );
        return this.state.orders.map(row => {
            var status;
            if (row.state == "waiting")
                status = (
                    <div align="center">
                        <Button onClick={() => this.itemReady(row)}>Article prêt</Button>
                    </div>
                );
            if (row.state == "ready")
                status = (
                    <div align="center">
                        Nous arrivons.
                    </div>
                );
            if (row.state == "ga_received")
                status = (
                    <div align="center">
                        Nous avons reçu. Merci
                    </div>
                );
            return (
                <tr key={row.name}>
                    <td><a onClick={() => this.clickItem(row)}><img src={row.image} height={50}/></a></td>
                    <td><a onClick={() => this.clickItem(row)}>{row.item_name}</a></td>
                    <td>{row.entrepot_tag}</td>
                    <td align='right'>{parseFloat(row.price).toFixed(2)}$</td>
                    <td>{status}</td>
                </tr>
            );
        });
    }

    render(){
        var items = this.getOrders();
        return(
            <Container fluid className="main-content-container px-4">
             
                <h1 className="m-4">Vos commandes</h1>

                <Row>
                    <Col>
                        <Button onClick={() => this.goItems()}>Articles</Button>
                        <Button onClick={() => this.openScan()} disabled={true}>Scan</Button>
                        <Button onClick={() => this.goClients()} disabled={true}>Clients</Button>
                        <Button onClick={() => this.handleNewTransaction()}>Transaction</Button>
                    </Col>
                </Row>


                <Row>
                    <table className="table">
                        <tr>
                            <th>Image</th>
                            <th>Commande</th>
                            <th>Entrepot Tag</th>
                            <th>Prix</th>
                            <th><center>Action</center></th>
                        </tr>
                        {items}
                        <tr>
                            <td></td>
                            <td></td>
                            <td align='right'>Total: {this.state.total.toFixed(2)}$</td>
                            <td></td>
                        </tr>

                    </table>
                </Row>
            </Container>
        )
    }
}