import {api} from '../../services/autonome';
import React from "react";
import { Container } from "shards-react";
import Button from '@material-ui/core/Button';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import { Alert, Modal } from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'

export class RetraitView extends React.Component {
  state = {
    amount: 20,
    errorMsg: null,
    disabled: false,
    email: ""
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  fetchData() {
    var me = JSON.parse(localStorage.getItem('user'));
    this.setState({
      email: me.email,
      allDone: true
    });
  }

  handleChange(event) {
    //console.log("Handle change", event);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
    this.setState({errorMsg: null});
  }

  componentDidMount() {
    api.call('post', "user/usrLog", {module: "views", title:"retrait"});
    this.fetchData();
    window.gtag('event', 'page_view', {
      page_title: 'Retrait',
      page_location: '/finance/retrait',
      page_path: '/finance/retrait'
    })
  }

  componentWillUnmount() {
  }

  doWithdrawal() {
    var these = this;
    var amount = parseFloat(this.state.amount);
    if (this.state.email.length < 5) return this.setState({errorMsg: "Courriel invalid"});
    if (isNaN(amount)) {
        return this.setState({errorMsg: "Le montant doit être un nombre"})
    }
    if (amount<5) {
      return this.setState({errorMsg: "Le montant doit être suppérieur a 5"})
    } else {
      this.setState({disabled: true});
      api.call('post', "liberium/withdraw/new", {amount: this.state.amount, email: this.state.email}).then(result => {        
        if (result.data.error) {
      this.setState({disabled: true});
          return this.setState({disabled: false, errorMsg: result.data.error.message})
        } else {
          these.props.history.push({
            pathname: "/wallet"
          });
        }
      });
    }
  }

  render() {
    var errorMsg;
    if (this.state.errorMsg !== null)
        errorMsg = (
            <Alert variant="danger">
                {this.state.errorMsg}
            </Alert>
        );

    return (
      <Container fluid className="main-content-container px-4">

        <Breadcrumb>
            <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>
            <Breadcrumb.Item href="#/wallet">Porte feuille</Breadcrumb.Item>
            <Breadcrumb.Item active>Retrait</Breadcrumb.Item>
        </Breadcrumb>

        <div class="row home-intro text-center">
          <div class="col-xs-12 col-md-8">
            <h1>Faite un retrait interac</h1>
          
            <p>Quel est le montant que vous désirer retirer?</p>

            <div class="section mt-2">
            <div class="section-title">Interac</div>
            <div class="card">
            <div class="card-body">
                <form>
                  <div class="form-group basic">
                      <div class="input-wrapper">
                          <label class="label" for="amount1">Amount</label>
                          <input 
                            type="text"
                            class="form-control" 
                            id="amount1" 
                            placeholder="Entrer un montant"
                            value={this.state.amount}
                            name="amount"
                            onChange={e => this.handleChange(e)}
                          />
                          <i class="clear-input">
                              <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                          </i>
                      </div>
                  </div>
                  <div class="form-group basic">
                      <div class="input-wrapper">
                          <label class="label" for="amount1">Courriel</label>
                          <input 
                            type="text"
                            class="form-control" 
                            id="email" 
                            placeholder="Adresse couriel"
                            value={this.state.email}
                            name="email"
                            onChange={e => this.handleChange(e)}
                          />
                          <i class="clear-input">
                              <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                          </i>
                      </div>
                  </div>
                  <div class="form-group basic">
                      <div class="input-wrapper">
                          <label class="label" for="amount1">La réponse sera: liberium</label>
                      </div>
                  </div>
                  <div class="form-group basic">
                      <div class="input-wrapper">
                        <Button
                        variant="contained"
                        disabled={this.state.disabled}
                        color="primary"
                        size="small"
                        startIcon={<AttachMoneySharpIcon />}
                        onClick={() => this.doWithdrawal()}
                        >
                            Demande de paiement
                        </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
          </div>
        </div>
        <br/><br/>
    </Container>
    );
  }
}