import React, { useState, useEffect, Fragment } from 'react'
import { withRouter } from 'react-router'; 

import 'react-html5-camera-photo/build/css/index.css';
import { Message } from '../../../components/chat/message';
import DragAndDrop from '../../../components/filedragdrop';
import { LazyLoadImage } from "react-lazy-load-image-component";

import { Form, Modal } from "react-bootstrap";
import Table from 'react-bootstrap/Table';

import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

import {api} from '../../../services/autonome';
import axios from 'axios';
import { API_ROOT } from '../../../api-config';

const businessId = 19;

class AdminImpotClientView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            showCam: false,
            invoiceView: false,
            clientId: parseInt(props.match.params.id),
            client: {prenom:"", nom:""},
            images: [],
            amount: 70,
            page: "client",
            fromProvincial: 0,
            fromFederal: 0,
            isTerminated: false,
            messages: []
        }

        this.fileInput = React.createRef();
        this.handleNewPhoto = this.handleNewPhoto.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getUserId() {
        var existingTokens;
        try{
            existingTokens = JSON.parse(localStorage.getItem('tokens'));
            //alert(JSON.stringify(existingTokens));
            return existingTokens.UserId;
        } catch(e) {
            return null;
        }
      }

    componentDidMount() {
        this.fetchClient();
        window.gtag('event', 'page_view', {
            page_title: 'Impot',
            page_location: '/admin/impot',
            page_path: '/admin/impot'
        });
    }

    fetchClient() {
        var these = this;
        api.call('get', "impot/client/" + this.state.clientId, {}).then(result => {
            if (result.status === 200) {
              console.log("New client", result.data);
              this.setState({client:result.data});
              if (typeof result.data.profil !== "undefined" && result.data.profil !== null && result.data.profil.result !== null) {
                var resu = JSON.parse(result.data.profil.result);
                console.log("resultat:",resu);
                these.setState({
                    profil: JSON.parse(result.data.profil.profil),
                    fromProvincial: resu.fromProvincial,
                    fromFederal: resu.fromFederal
                });
              } else if (typeof result.data.profil !== "undefined" && result.data.profil !== null) {                
                these.setState({
                    profil: JSON.parse(result.data.profil.profil),
                    fromProvincial: "",
                    fromFederal: ""
                });
              }
              these.fetchPhotos();
              these.fetchMessages();
            } 
        }).catch(e => {
        });
    }

    fetchMessages() {
        var these = this;
        api.call("post", "admin/impot/get_comm", {clientId: this.state.clientId, year: 2023}).then(result => {
            these.setState({messages: result.data.messages, other: result.data.other})
        });
    }

    saveResult() {
        var nF = {
            fromProvincial: this.state.fromProvincial,
            fromFederal: this.state.fromFederal
        }
        api.call('post', "impot/client/" + this.state.clientId + "/2023/result", nF).then(result => {
            if (result.status === 200) {
            } 
        }).catch(e => {
        });
    }

    saveClient() {
        api.call('patch', "client/" + this.state.clientId, this.state.client).then(result => {
            if (result.status === 200) {
              console.log("New client", result.data);
              this.setState({client:result.data});
            } 
        }).catch(e => {
        });
    }

    onTerminate() {
        api.call('get', "impot/client/" + this.state.clientId + "/2023/terminate").then(result => {
            if (result.status === 200) {
                if (typeof result.data.error !== "undefined")
                    alert(result.data.error.message)
                else
                    alert("Merci");
            } 
        }).catch(e => {
            alert("error");
        });
    }

    fetchPhotos() {        
        if (typeof this.state.client.userId !== "undefined" && this.state.client.userId !== null) {
            console.log("fetchPhotos1");
            return api.call('get', "impot/user/" + this.state.client.userId + "/2023/photos").then(result => {
                if (result.status === 200) {
                    console.log("Got images", result.data);
                    this.setState({images:result.data});
                }
            }).catch(e => {
            });
        } else {
            console.log("fetchPhotos2");
            api.call('get', "impot/client/" + this.state.clientId +"/2023/photos").then(result => {
                if (result.status === 200) {
                    console.log("Got images", result.data);
                    this.setState({images:result.data});
                }
            }).catch(e => {
            });
        }
	}

    handleChangeSupport(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value,
          is_error: false
        });
    }

  handleSubmit(event) {
    var these = this;
    var msg = this.state.message;
    this.setState({message: ""});
    var data = {
        clientId: this.state.clientId,
        message: msg,
        year: 2023
    }
    api.call("post", "admin/impot/post_message", data).then(result => {
        var new_messages = these.state.messages;
        these.fetchMessages();
    }).catch(function(err) {
        //alert(JSON.stringify(err));
        these.setState({});
    });
  }

    handleResult(event) {
        var these = this;
        console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;

        this.setState(new_form);
    }

    handleChange(event) {
        var these = this;
        console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state.newClient;
        new_form[name] = value;

        this.setState({newClient:new_form});
    }

    handleAmount(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({amount:value});
    }

    handleFilter(event) {
        var these = this;
        console.log("Handle change filter", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state.client;
        new_form[name] = value;

        this.setState({newClient:new_form});
    }

    goNewClient() {
        this.setState({showNewClient: true});
    }

    goClients() {
        this.fetchClients();
        this.setState({showClients: true});
    }

    closeNewClient() {
        this.setState({showNewClient: false});
    }

    renderClient() {
        return (
            <Form>
                    <Form.Group className="mb-3" controlId="timeout">
                            <Form.Control
                            type="text"
                            name="prenom"
                            placeholder="prenom"
                            value={this.state.client.prenom}
                            onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="timeout">
                            <Form.Control
                            type="text"
                            name="nom"
                            placeholder="nom de famille"
                            value={this.state.client.nom}
                            onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Status</Form.Label>
                            <Form.Select 
                                aria-label="Default select example" 
                                value={this.state.client.status}
                                onChange={e=>this.handleFilter(e)}
                                name="status"
                            >
                                <option value=""></option>
                                <option value="numerisation">Étape de numérisation</option>
                                <option value="begining">Entrer les chiffres</option>
                                <option value="waiting">En attente</option>
                                <option value="closing">Finaliser l'impôt</option>
                                <option value="invoice">Impression & Facturation</option>
                                <option value="terminate">Terminé</option>
                            </Form.Select>
                        </Form.Group>
                        <div class="container">
                            <div class="row">
                                <div class="col text-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<EditIcon />}
                                        onClick={() => this.saveClient()}
                                    >
                                        Sauvegarder
                                    </Button>
                                </div>
                            </div>
                        </div>
            </Form>
        )
    }

    renderAdmin() {
        return (
            <>
                <h2>Documents envoyé au client</h2>
            </>
        )
    }

    handleImage(image) {
        window.open(image.imgUrl);
    }

    handleDrop = (name, files) => {
        var self = this;
        let fileList = this.state.files
        console.log(files);
        for (var i = 0; i < files.length; i++) {
          if (!files[i].name) return;
            //fileList.push(files[i].name)
            const data = new FormData();
            data.append('file', files[i]);
            data.append("clientId",this.state.clientId);
            data.append("numerizedBy",this.getUserId());
            data.append("year",2023);
            console.log("data:",data);
            this.setState({
                uploadMsg: "Upload en cours"
            });
            axios.post(API_ROOT + "impot/upload_image", data).then((response) => {
                this.setState({
                    uploadMsg: "Upload terminé"
                })
                self.fetchPhotos();
            })
        }
    }

    handleDropAdmin = (name, files) => {
        var self = this;
        let fileList = this.state.files
        console.log(files);
        for (var i = 0; i < files.length; i++) {
          if (!files[i].name) return;
            //fileList.push(files[i].name)
            const data = new FormData();
            data.append('file', files[i]);
            if (typeof this.state.client.userId !== "undefined" && this.state.client.userId !== null)
                data.append("userId",this.state.client.userId)
            else
                data.append("clientId",this.state.clientId);
            data.append("numerizedBy",this.getUserId());
            data.append("year",2023);
            console.log("data:",data);
            this.setState({
                uploadMsg: "Upload en cours"
            });
            axios.post(API_ROOT + "impot/upload_image/admin", data).then((response) => {
                this.setState({
                    uploadMsg: "Upload terminé"
                })
                self.fetchPhotos();
            })
        }
    }

    onDelete(img) {
        var self = this;
        console.log(img);
        api.call('get', "impot/image_delete/" + img.id).then(result => {
            self.fetchPhotos();
        }).catch(e => {
        });
    }

    renderFiles() {
        var k = 0;
        var images = this.state.images.map((img) => {
            //if (img.way !== "in") return; // A remettre pcq la faut j'affiche les factures
            k++;
            if (img.imgUrl.indexOf(".pdf")>-1)
                return (
                    <div className="col-sm-4" key={k}>
                    <a onClick={() => this.handleImage(img)}>
                        <h3>Fichier PDF</h3>
                    </a><br/>
                    <a onClick={(e) => this.onDelete(img)}>Effacer PDF</a>
                </div>
                )
            return (
                <div className="col-sm-4" key={k}>
                    <LazyLoadImage src={img.imgUrl} className={'img-fluid'} onClick={() => this.handleImage(img)} />
                    <a onClick={(e) => this.onDelete(img)}>Effacer</a>
                </div>
            )
        });
        return (
            <div>
                <div className="row">
                    <div className="col-md">
                        <div className="row no-gutters">
                            {images}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderOutFiles() {
        var k = 0;
        var images = this.state.images.map((img) => {
            if (img.way !== "out") return;
            k++;
            if (img.imgUrl.indexOf(".pdf")>-1)
                return (
                    <div className="col-sm-4" key={k}>
                    <a onClick={() => this.handleImage(img)}>
                        <h3>Fichier PDF</h3>
                    </a><br/>
                    <a onClick={(e) => this.onDelete(img)}>Effacer PDF</a>
                </div>
                )
            return (
                <div className="col-sm-4" key={k}>
                    <LazyLoadImage src={img.imgUrl} className={'img-fluid'} onClick={() => this.handleImage(img)} />
                    <a onClick={(e) => this.onDelete(img)}>Effacer</a>
                </div>
            )
        });
        return (
            <div>
            <div className="row">
                <div className="col-md">
                    <div className="row no-gutters">
                        {images}
                     </div>
                </div>
            </div>
        </div>
        )
    }

    addPhoto() {
        this.setState({showCam: true});
    }

    closeCam() {
        this.setState({showCam: false});
    }

    handleTakePhoto = (dataUri) => {
        //alert("pass " + API_ROOT);
        var self = this
        const data = new FormData();
        data.append("clientId",this.state.clientId);
        data.append("numerizedBy",this.getUserId());
        data.append('data', dataUri);
        data.append('year', 2023);
        this.setState({
          uploadMsg: "Upload en cours"
        });
        axios.post(API_ROOT + 'impot/upload_uri', data).then((response) => {
            this.setState({showCam: false});
            /*
            this.setState({
                uploadMsg: "Upload terminé"
            });
            */
            self.fetchPhotos();
        }).catch(function(err) {
            alert("catch: " + JSON.stringify(err));
        });
    }

    handleNewPhoto() {
        // Ok we send the photo
        var self = this;
        console.log("fileInput:",this.fileInput);
        console.log("length:", this.fileInput.current.files.length);
        for (var i = 0; i < this.fileInput.current.files.length; i++) {
          if (!this.fileInput.current.files[i].name) return;
            const data = new FormData();
            data.append('file', this.fileInput.current.files[i]);
            data.append("clientId",this.state.clientId);
            data.append("numerizedBy",this.getUserId());
            data.append("year",2023);
            
            this.setState({
                uploadMsg: "Upload en cours"
            });
            axios.post(API_ROOT + "impot/upload_image", data).then((response) => {
                this.setState({
                    uploadMsg: "Upload terminé"
                })
                self.fetchPhotos();
            })
        }
    }

    renderCam() {
        if (!this.state.showCam) return (<></>);
        return (
            <input 
                        type="file"
                        name="image"
                        accept="image/*"
                        capture="environment"
                        ref={this.fileInput}
                        onChange={this.handleNewPhoto}
            />
        )
    }

    openCreateInvoice() {
        this.setState({invoiceView: true});
    }

    createInvoice() {
        var these = this;
        var body = {
            clientId : this.state.clientId,
            amount: this.state.amount
        }
        api.call('post', "impot/create_invoice", body).then(result => {
            these.setState({invoiceView:false});
            if (result.status === 200) {
                setTimeout( function () {
                    these.fetchPhotos();
                },750);
            } 
        }).catch(e => {
        });
    }

    openAdmin() {
        this.setState({page:"manage"});
    }

    openClient() {
        this.setState({page:"client"});
    }

    goBack() {
        this.props.history.push({
            pathname: "/admin/impot"
        });
    }

    renderProfil() {
        return (
            <>
                <h2>Profil client</h2>
                <p>Prenom: {this.state.profil.prenom}</p>
                <p>Nom: {this.state.profil.nom}</p>
                <p>Sexe: {this.state.profil.sexe}</p>
                <p>NAS: {this.state.profil.nas}</p>
                <p>Telephone: {this.state.profil.telephone}</p>
                <p>Date de naissance: {this.state.profil.date_naissance}</p>
                <p>Vit seul? {this.state.profil.vitSeul}</p>
                <p>avec qui vit? {this.state.profil.avecQuiVit}</p>
                <p>Nom du conjoint: {this.state.profil.conjoint_name}</p>
                <p>Adresse: {this.state.profil.adresse}</p>
                <p>Ville: {this.state.profil.city}</p>
                <p>Code postal: {this.state.profil.zipcode}</p>
                <p>A des assurances: {this.state.profil.has_insurance}</p>
                <p>Propriétaire: {this.state.profil.is_proprio}</p>
                {this.state.profil.childrens.map((enfant)=> {
                    return (
                        <>
                            Nom de l'enfant: {enfant.nom}, {enfant.prenom}<br/>
                            Date de naissance: {enfant.date_naissance}<br/>
                            Numéro social: {enfant.nas}<br/>
                        </>
                    )
                })}
                <hr/>
            </>
        )
    }

    renderCommunication() {
        let list = <div classname="no-content-message">Il n'y a pas de messages</div>;
        if (this.state.messages.length > 0)
          list = this.state.messages.map(m => <Message key="{m.id}" id="{m.id}" other={this.state.other} receivedAt={m.receivedAt} fromUserId={m.fromUserId} senderName={m.senderName} text={m.message}></Message>);
        return (
          <>
            <hr/>
            <table width='100%'>
              <tr>
                <th colspan="2"><center>Communication</center></th>
              </tr>
              <tbody>
                <tr>
                  <td>
                  <div class="chat-history">
                        <ul class="m-b-0">
                            {list}
                        </ul>
                    </div>
                    <div class="chat-message clearfix">
                        <div class="input-group mb-0">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fa fa-send"></i></span>
                            </div>
                            <input
                                type="text"
                                name="message"
                                class="form-control"
                                placeholder="Enter text here..."
                                value={this.state.message}
                                onChange={e => this.handleChangeSupport(e)}
                                onKeyPress={this.handleKeyPress} 
                            />
                            <Button
                                disabled={this.state.sending}
                                variant="contained"
                                color="primary"
                                size="small"    
                                startIcon={<LocalAtmIcon />}
                                onClick={() => this.handleSubmit()}
                            >
                                Envoie
                            </Button>
                        </div>
                    </div>
    
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )
      }

    render() {
        if (this.state.page === "manage") {
            return (
                <>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => this.goBack()}
                >
                    Retour
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => this.openClient()}
                >
                    Gérer client
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => this.onTerminate()}
                    disabled={this.state.isTerminated}
                >
                    Client terminé
                </Button>
                <br/>
                <br/>
                <br/>
                {this.renderProfil()}

                <Form>
                    <Form.Group className="mb-3" controlId="timeout">
                        Retour d'impot fédéral:
                        <Form.Control
                            type="text"
                            name="fromFederal"
                            placeholder="Retour d'impot fédéral"
                            value={this.state.fromFederal}
                            onChange={e => this.handleResult(e)}
                        />
                        </Form.Group>
                    <Form.Group className="mb-3" controlId="timeout">
                        Retour d'impot provincial:
                        <Form.Control
                            type="text"
                            name="fromProvincial"
                            placeholder="Retour d'impot provincial"
                            value={this.state.fromProvincial}
                            onChange={e => this.handleResult(e)}
                        />
                        </Form.Group>
                        
                        <div class="container">
                            <div class="row">
                                <div class="col text-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<EditIcon />}
                                        onClick={() => this.saveResult()}
                                    >
                                        Sauvegarder
                                    </Button>
                                </div>
                            </div>
                        </div>
                </Form>

                <DragAndDrop handleDrop={this.handleDropAdmin}>
                    <h2>Envoyer les documents du client ici</h2>
                    <Table>
                        <thead>
                            <tr>
                                <th>Fichier</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderOutFiles()}
                        </tbody>
                    </Table>
                </DragAndDrop>
                {this.renderCommunication()}
                </>
            )
        }
        return(
            <>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => this.goBack()}
                >
                    Retour
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => this.openCreateInvoice()}
                >
                    Créer une facture
                </Button>
        
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => this.openAdmin()}
                >
                    Gérer client
                </Button>

                <h1>{this.state.client.prenom} {this.state.client.nom}</h1>
                {this.renderClient()}
                <hr/>
                <DragAndDrop handleDrop={this.handleDrop}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => this.addPhoto()}
                >
                    Ajouter photo
                </Button>
                {this.renderCam()}
                <Table>
                    <thead>
                        <tr>
                            <th>Fichier</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderFiles()}
                    </tbody>
                </Table>
                </DragAndDrop>

                <Modal
                    size="lg"
                    show={this.state.invoiceView}
                    onHide={() => this.setState({invoiceView: false})}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Créer une facture
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Créer une facture.</p>
                        <Form.Group className="mb-3" controlId="timeout">
                            <Form.Control
                            type="text"
                            name="amount"
                            placeholder="Montant de facture"
                            value={this.state.amount}
                            onChange={e => this.handleAmount(e)}
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.createInvoice()}
                        >
                            Créer facture
                        </Button>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default withRouter(AdminImpotClientView);


/*
<Camera
                        isImageMirror={false}
                        isMaxResolution={true}
                        idealResolution = {{width: 640, height: 480}}
                        isFullscreen={false}
                        idealFacingMode = {FACING_MODES.ENVIRONMENT}
                        onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
                        />
*/