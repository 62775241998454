import React from 'react';
import {api} from '../../services/autonome';

import Breadcrumb from 'react-bootstrap/Breadcrumb'

export class SettingsView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            notifications: []
        }
        this.state.userId = this.getUserId();
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    fetchData() {
        /*
      api.call('get', "user/notifications", {}).then(result => {
        if (typeof result.data.error != "undefined") return;
        this.setState({
            notifications: result.data
        });
      });
      */
    }

    componentDidMount() {
        this.fetchData();
        window.gtag('event', 'page_view', {
            page_title: 'Notifications',
            page_location: '/notifications',
            page_path: '/notifications',
            //send_to: '<GA_MEASUREMENT_ID>'
          })
    }

    render(){
        return(
            <>

                <Breadcrumb>
                    <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>                
                    <Breadcrumb.Item active>Configuration</Breadcrumb.Item>
                </Breadcrumb>

                <div class="section mt-3 text-center">
                    <div class="avatar-section">
                        <a href="#">
                            <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" class="imaged w100 rounded"/>
                            <span class="button">
                                <ion-icon name="camera-outline"></ion-icon>
                            </span>
                        </a>
                    </div>
                </div>

                <div class="listview-title mt-1">Theme</div>
                <ul class="listview image-listview text inset no-line">
                    <li>
                        <div class="item">
                            <div class="in">
                                <div>
                                    Dark Mode
                                </div>
                                <div class="form-check form-switch  ms-2">
                                    <input class="form-check-input dark-mode-switch" type="checkbox" id="darkmodeSwitch"/>
                                    <label class="form-check-label" for="darkmodeSwitch"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <div class="listview-title mt-1">Notifications</div>
                <ul class="listview image-listview text inset">
                    <li>
                        <div class="item">
                            <div class="in">
                                <div>
                                    Payment Alert
                                    <div class="text-muted">
                                        Send notification when new payment received
                                    </div>
                                </div>
                                <div class="form-check form-switch  ms-2">
                                    <input class="form-check-input" type="checkbox" id="SwitchCheckDefault1"/>
                                    <label class="form-check-label" for="SwitchCheckDefault1"></label>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="#" class="item">
                            <div class="in">
                                <div>Notification Sound</div>
                                <span class="text-primary">Beep</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </>
        )
    }
}
