import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import { Form, Modal } from "react-bootstrap";
import DirectionsIcon from '@material-ui/icons/Directions';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

class LoadbalanceView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            show: false,
            routes: [],
            server: null
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "hosting/routes", {}).then(result => {
            these.setState({routes: result.data});
        });
    }

    saveRoute() {
        var these = this;
        var url = 'hosting/lb/create';
        if (typeof this.state.route.id !== 'undefined')
            url = 'hosting/lb/' + this.state.route.id;
        api.call('post', url, this.state.route).then(result => {
            these.closeModal();
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();
    }

    closeModal() {
        this.setState({show: false});
        this.fetchData();
    }

    goDep() {
        this.props.history.push({
          pathname: "/admin/deployments"
        });
    }

    goServers() {
        this.props.history.push({
          pathname: "/admin/servers"
        });
    }

    createRoute() {

    }

    renderRoutes() {
        return this.state.servers.map((row) => {
            return (
                <tr onClick={(e)=>this.selectRoute(row)}>
                <td>{row.id}</td>
                <td>{row.hostname}</td>
                <td>{row.path}</td>
                <td>{row.connectIp}</td>
                <td>{row.connectPort}</td>
                <td>{row.rewriteUrl}</td>
                </tr>
            );
          });
    }


    render(){
        return(
            <>
            <h1>Routes</h1>
            <p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goDep()}
            >
              Deployments
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goServers()}
            >
              Servers
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.createRoute()}
            >
              Create new route
            </Button>
            </p>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Host name</th>
                    <th>Path</th>
                    <th>ConnectIp</th>
                    <th>Port</th>
                    <th>Rewrite</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRoutes()}
                </tbody>
            </Table>
            <Modal
                size="lg"
                show={this.state.show}
                onHide={() => this.closeModal()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Deployment
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="timeout">
                            <Form.Control
                            type="text"
                            name="hostname"
                            placeholder="liberium.net"
                            value={this.state.route.hostname}
                            onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="timeout">
                            <Form.Control
                            type="text"
                            name="path"
                            placeholder="/"
                            value={this.state.route.path}
                            onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="timeout">
                            <Form.Control
                            type="text"
                            name="connectIp"
                            placeholder="10.0.0.1"
                            value={this.state.route.connectIp}
                            onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="timeout">
                            <Form.Control
                            type="text"
                            name="port"
                            placeholder="3001"
                            value={this.state.route.port}
                            onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="timeout">
                            <Form.Control
                            type="text"
                            name="rewriteUrl"
                            placeholder="/"
                            value={this.state.route.rewriteUrl}
                            onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>
                        <div class="container">
                            <div class="row">
                                <div class="col text-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<EditIcon />}
                                        onClick={() => this.saveRoute()}
                                    >
                                        Sauvegarder
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            </>
        )
    }
}

export default withRouter(LoadbalanceView);