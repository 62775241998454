import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import { Modal } from "react-bootstrap";
import Button from '@material-ui/core/Button';

class TravailleursViews extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            tasks: [],
            showTask: false,
            task: null
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "virt_job/getLatestTasks", {}).then(result => {
            these.setState({tasks: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();
    }

    showResult(task){
        //alert(JSON.stringify(task));
        this.setState({showTask: true, task: task});
    }

    /*
    {"texte":"Restez a l'affut de nos concours! Les commercants locaux sont toujours heureux de pouvoir vous gater!\nhttps://peupleqc.com/post/20987","groupId":624354092930739,"waitingTaskId":27,"reallyDone":true,"records":[
        {"action":"click","x":213,"y":747,"curImage":"sc6A68D2ulZaYrAA2.jpeg"},
        {"action":"click","x":153,"y":673,"curImage":"sc1bx97k9Tr9QpsFt.jpeg"},{"action":"click","x":417,"y":152,"curImage":"sciF47u75FMY5KDqD.jpeg"},
        {"action":"click","x":416,"y":161,"curImage":"scG7FVUtSXiWDZVcq.jpeg"}
    ]}
    */

    renderTask() {
        if (this.state.task === null)
            return;
        var records;
        if (typeof this.state.task.metas.records !== "undefined") {
            console.log("records:", this.state.task.metas.records);
            console.log("typeof records:", typeof this.state.task.metas.records);
            if (typeof this.state.task.metas.records === "string")
                this.state.task.metas.records = JSON.parse(this.state.task.metas.records);
            records = this.state.task.metas.records.map((step)=>{
                if (typeof step.curImage !== "undefined")
                return (
                    <p>
                        <img src={"https://api.liberium.net/s/facebook-screens/" + step.curImage}/>
                        {step.action}
                    </p>
                )
            });
        }
        if (typeof this.state.task.metas.curImage !== "undefined") {
            return (
                <p>
                    <img src={this.state.task.metas.curImage}/>
                </p>
            )
        }
        return (
            <>
                {records}
            </>
        )
    }

    renderTasks() {
        return this.state.tasks.map((row) => {
            return (
                <tr>
                <td>{row.id}</td>
                <td>{row.virtAccountId}</td>
                <td>{row.userId}</td>
                <td>{row.task_type}</td>
                <td>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => this.showResult(row)}
                    >
                        Show
                    </Button>
                </td>
                </tr>
            );
          });
    }

    render(){
        return(
            <>
            <h1>Latest Tasks</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Compte</th>
                    <th>Utilisateur</th>
                    <th>Type</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderTasks()}
                </tbody>
            </Table>
            <Modal
                size="lg"
                show={this.state.showTask}
                onHide={() => this.setState({showTask: false})}
                aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Résultat de la tâche
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.renderTask()}
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default withRouter(TravailleursViews);