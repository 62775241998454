import React from "react";
import { withRouter } from 'react-router'; 
import {api} from '../../services/autonome';
import Avatar from "../../img/avatar1.jpg";
import {ChatBtn} from '../../components/liberium/chat_btn';
import StarRatings from 'react-star-ratings';

export class UserCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            user: props.user
        }
    }

    componentDidMount(){
    }

    render () {
        var chat;
        if (this.state.user.id !== null)
            chat = (
                <ChatBtn withUserId={this.state.user.id}/>
            )
        var avatar = (
            
            <></>
        )
        if (this.state.user.avatar && this.state.user.avatar.length > 5)
            avatar = (
                <img src={this.state.user.avatar} class="image"/>
            )
        return (

                <li>
                    <a class="item">
                        {avatar}
                        <div class="in">
                            <div>
                                <header>
                                    <strong>{this.state.user.firstname + " " + this.state.user.lastname}</strong>
                                </header>                                        
                                <footer>
                                    <StarRatings
                                    rating={this.state.user.rated}
                                    starRatedColor="gold"
                                    numberOfStars={5}
                                    starDimension="10px"
                                    starSpacing="0px"
                                    />
                                </footer>
                                {chat}
                            </div>
                        </div>
                    </a>
                </li>

        )
    }
}

export default withRouter(UserCard);