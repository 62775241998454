import React, { useState } from 'react'

import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import {
	Container,
	Form,
	FormGroup,
	FormCheckbox,
	FormInput,
	FormTextarea,
	FormSelect
  } from "shards-react";

export default class AddClientForm extends React.Component {
	constructor(props){
			super(props);
			this.state = {
				clicked: false,
				isLoading: true,
				item: null,
				canTerminate: false,
				form: {
					id: null,
					prenom: '',
					nom: '',
					email: '',
					telephone: '',
					adresse: '',
					city: '',
					zipcode: '',
					state: 'QC',
					country: 'CANADA',
					delivery_details: ''
				}
			}
	}

	handleChange(event) {
		console.log("Handle change", event);
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
	
		var new_form = this.state.form;
		new_form[name] = value;
		this.setState({
			form: new_form
		});
	}

	addClient() {
		if (!this.state.form.prenom || !this.state.form.nom) return
		this.props.addClient(this.state.form);
	}

	handleCancel() {

	}

	render() {
		return (
			<Container fluid className="main-content-container px-4 pb-4">
				<Form>
                    <FormGroup>
                    <label>Prenom :</label>
                    <FormInput name="prenom" value={this.state.form.prenom} onChange={e => this.handleChange(e)} size="lg" placeholder="Prénom" />
                    </FormGroup>
					<FormGroup>
                    <label>Nom :</label>
                    <FormInput name="nom" value={this.state.form.nom} onChange={e => this.handleChange(e)} size="lg" placeholder="Nom" />
                    </FormGroup>
                    <FormGroup>
                    <label>email :</label>
                    <FormInput name="email" value={this.state.form.email} onChange={e => this.handleChange(e)} size="lg" placeholder="email" />
                    </FormGroup>
                    <FormGroup>
                    <label>Téléphone :</label>
                    <FormInput name="telephone" value={this.state.form.telephone} onChange={e => this.handleChange(e)} size="lg" placeholder="Numéro de Téléphone" />
                    </FormGroup>

					<FormGroup>
                    <label>Adresse :</label>
                    <FormInput name="adresse" value={this.state.form.adresse} onChange={e => this.handleChange(e)} size="lg" placeholder="Adresse" />
                    </FormGroup>

					<FormGroup>
                    <label>City :</label>
                    <FormInput name="city" value={this.state.form.city} onChange={e => this.handleChange(e)} size="lg" placeholder="ville" />
                    </FormGroup>

					<FormGroup>
                    <label>Zipcode :</label>
                    <FormInput name="zipcode" value={this.state.form.zipcode} onChange={e => this.handleChange(e)} size="lg" placeholder="Code postale" />
                    </FormGroup>

					<FormGroup>
                    <label>State :</label>
                    <FormInput name="state" value={this.state.form.state} onChange={e => this.handleChange(e)} size="lg" placeholder="Province" />
                    </FormGroup>

					<FormGroup>
                    <label>Pays :</label>
                    <FormInput name="country" value={this.state.form.country} onChange={e => this.handleChange(e)} size="lg" placeholder="Pays" />
                    </FormGroup>

              	</Form>

			  	<Button
					variant="contained"
					color="primary"
					size="small"
					startIcon={<EditIcon />}
					onClick={() => this.addClient()}
					>
					Ajouter
            	</Button>

			</Container>
		)
	}
}