import React from "react";
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';


export class AdminFbGroups extends React.Component {
  state = {
    screens: []
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.intWall = null;
    this.state = {
      userId: this.getUserId(),
      groups: []
    }
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  componentDidMount() {
    this.getGroups();
  }

  componentWillUnmount() {
  }

  handleChange(event) {
    //console.log("Handle change", event.target.name);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
    this.setState({errorMsg: null});
  }

  getGroups() {
    var these = this;
    api.call('get', "ads/get_fb_groups", {}).then(result => {
        //alert(JSON.stringify(result.data));
        these.setState({groups: result.data});
    });
  }

  renderGroups() {
    return this.state.groups.map((row) => {
        var metas = JSON.parse(row.metas);
        return (
          <tr>
            <td>{row.name}</td>
            <td>{metas.cityName}</td>
            <td>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<AttachMoneySharpIcon />}
                onClick={() => this.goFormation(row.id)}
              >
                  Gérer
              </Button>
            </td>
          </tr>
        );
      });
  }

  render() {
    return (
      <>
        <h1>Gérer les groupes FaceBook</h1>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Nom du groupe</th>
                    <th>Ville</th>
                </tr>
            </thead>
            <tbody>
                {this.renderGroups()}
            </tbody>
        </Table>
      </>
    );
  }
}