import React from "react";
import { Form } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from "../../services/autonome";
import {Screen} from "../../components/marketing/screen";
import { API_ROOT } from '../../api-config';

const width = 450;
const height = 580;

export class SendMessageFbGroup extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.intWall = null;
    this.state = {
      userId: this.getUserId(),
      jobId: this.props.jobId,
      job: this.props.job,
      metas: JSON.parse(this.props.job.metas),
      currentImage: "1.png",
      name: "",
      screenId: this.props.job.virtAccountId,
      onFinish: this.props.onFinish,
      onClicked: false
    }
    this.img = React.createRef();
    this.onProblem = this.onProblem.bind(this);
    this.onDone = this.onDone.bind(this);
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  handleChange(event) {
    //console.log("Handle change", event.target.name);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
    this.setState({errorMsg: null});
  }
  
  onDone(records) {
    this.setState({onClicked: true});
    console.log("Ok send it to api");
    var these = this;
    var data = {
        taskId: this.state.jobId,
        reallyDone: true,
        records: records
    }
    console.log("done sent:", data);
    api.call('post', "virt_job/fbMessageSent", data).then(result => {
        if (typeof result.data.error !== "undefined") {
            return alert("Une erreure s'est produite");
        }
        this.state.onFinish();
    });
  }

  onMyDone(reallyDone) {
    this.setState({onClicked: true});
    console.log("Ok send it to api");
    var these = this;
    var data = {
        taskId: this.state.jobId,
        reallyDone: reallyDone
    }
    api.call('post', "virt_job/fbMessageSent", data).then(result => {
        if (typeof result.data.error !== "undefined") {
            return alert("Une erreure s'est produite");
        }
        this.state.onFinish();
    });
  }

  onProblem() {
    this.setState({onClicked: true});
    console.log("There is a problem. Send it to api");
    var these = this;
    var data = {
        taskId: this.state.jobId
    }
    console.log("state:", this.state)
    console.log("data to problem", data);
    api.call('post', "virt_job/problem", data).then(result => {
        if (typeof result.data.error !== "undefined") {
            return alert("Une erreure s'est produite");
        }
        these.state.onFinish();
    });
  }

  render() {
    return (
        <>
            <h1>Envoie de message sur un groupe</h1>
            <p>Envoyer ce message</p>
            <Screen
                screenId={this.state.job.virtAccountId}
                onParentContinue={this.onDone}
                onProblem={this.onProblem}
                texte={this.state.metas.texte}
                showCat={false}
            />
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<NewReleasesSharpIcon />}
                onClick={() => this.onMyDone("no_sense")}
                disabled = {this.state.onClicked}
            >
                Ca fait pas de sens
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<NewReleasesSharpIcon />}
                onClick={() => this.onMyDone(false)}
                disabled = {this.state.onClicked}
            >
                La group ne nous a pas accepté encore
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<NewReleasesSharpIcon />}
                onClick={() => this.onProblem()}
                disabled = {this.state.onClicked}
            >
                Il y a un probleme
            </Button>
        </>
    );
  }
}