import React from "react";
import { Redirect } from "react-router-dom";
import logo from "../img/liberium-en-tete.png";
import avatar1 from "../img/avatar1.jpg";
import {api} from '../services/autonome';

export class DefaultLayout extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            notifs: 0,
            userNotifs: 0,
            showAppBar: true,
            profile: {firstname: "", lastname: "", groups: []},
            avatar: avatar1,
            gotContracts: false,
            shopNear: false
        }
        let existingTokens;
        try {
          existingTokens = JSON.parse(localStorage.getItem("tokens"));
          this.state.connected = true;
        } catch (e) {
          this.state.connected = false;
        }
    }

    handleClick = (path) => {
        //e.preventDefault();
        //$('#sidebarPanel').modal('hide');
        this.props.history.push({
            pathname: path
        });
    }

    componentDidMount() {
        var these = this;
        this.fetchData();
    }

    fetchData() {
        var these = this;
        api.call('get', "users/profile", {}).then(result => {
            if (typeof result.data.error != "undefined") return;
            this.setState({
                profile: result.data
            });
            if (result.data.avatar.length > 5)
                this.setState({avatar: result.data.avatar});
        });
        // TODO - Faire une requete au lieu de 3
        api.call('get', "user/meta/userNotifs", {}).then(result => {
            console.log(result);
            if (typeof result.data === "undefined" || result.data === null || typeof result.data.error !== "undefined")
              return;
            if (result.data !== null)
              these.setState({
                userNotifs: parseInt(result.data.value)
              });
        });
        api.call('get', "liberium/isShopNear", {}).then(result => {
            if (typeof result.data.error != "undefined") return;
            //alert(JSON.stringify(result));
            these.setState({shopNear: result.data.aShopIsNear});
        });
        api.call('get', "tasks/gotContracts", {}).then(result => {
            if (typeof result.data.error != "undefined") return;
            these.setState({gotContracts: result.data.gotContracts});
        });
        api.call('get', "finance/cad_wallet", {}).then(result => {
            console.log("Wallet overview:", result);
            if (typeof result.data.error != "undefined") return;
            this.setState({
                wallets: result.data
            });

            var total = 0;
            result.data.forEach(function(res) {
                total = total + parseFloat(res.quantity);
            });

            this.setState({total: total});
        });
    }

    handleNotif() {
        this.setState({userNotifs:0});
        this.handleClick("/notifications");
    }

    render () {
        if (!this.state.connected)
            return (<Redirect to="/login" />)
        var virtual_job;
        if (this.state.profile.UserId === 1 || this.state.profile.UserId === 8 || this.state.profile.UserId === 140)
        virtual_job = (
            <li>
            <a onClick={() => {this.handleClick("/virtual-job")}} data-bs-dismiss="modal" class="item">
                <div class="icon-box bg-primary">
                    <ion-icon name="list-circle-outline"></ion-icon>
                </div>
                <div class="in">
                    Virtual Job
                </div>
            </a>
        </li>
        )
        var shop = (<></>)
        //if (this.state.shopNear)
        if (this.state.profile.UserId === 1)
            shop = (
                <li>
                    <a onClick={() => {this.handleClick("/shop")}} data-bs-dismiss="modal" class="item">
                        <div class="icon-box bg-primary">
                            <ion-icon name="bag-outline"></ion-icon>
                        </div>
                        <div class="in">
                            Boutique
                        </div>
                    </a>
                </li>
            )
        var conseil = (<></>);
        //if (this.state.profile.groups.indexOf("liberium_ca")>-1)
                conseil = (
                    <li>
                        <a onClick={() => {this.handleClick("/ca")}} data-bs-dismiss="modal" class="item">
                            <div class="icon-box bg-primary">
                                <ion-icon name="people-outline"></ion-icon>
                            </div>
                            <div class="in">
                                Conseil d'administration
                            </div>
                        </a>
                    </li>
                )
        var others;
        if (this.state.profile.UserId === 1)
            others = (
                <>
                <li>
                    <a onClick={() => {this.handleClick("/storage")}} data-bs-dismiss="modal" class="item">
                        <div class="icon-box bg-primary">
                            <ion-icon name="wallet-outline"></ion-icon>
                        </div>
                        <div class="in">
                            Storage
                        </div>
                    </a>
                </li>
                <li>
                    <a onClick={() => {this.handleClick("/organisation")}} data-bs-dismiss="modal" class="item">
                        <div class="icon-box bg-primary">
                            <ion-icon name="wallet-outline"></ion-icon>
                        </div>
                        <div class="in">
                            Organisation
                        </div>
                    </a>
                </li>
                <li>
                    <a onClick={() => {this.handleClick("/admin")}} data-bs-dismiss="modal" class="item">
                        <div class="icon-box bg-primary">
                            <ion-icon name="wallet-outline"></ion-icon>
                        </div>
                        <div class="in">
                            Admin
                        </div>
                    </a>
                </li>
                </>
            )
        return ( 
            <>
                <div class="appHeader bg-liberium text-light">
                    <div className="left">
                        <a className="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                            <ion-icon name="menu-outline"></ion-icon>
                        </a>
                    </div>
                    <div className="pageTitle">
                        <img src={logo} alt="logo" height={50}/>
                    </div>
                    <div className="right">
                        <a onClick={() => {this.handleNotif()}} className="headerButton">
                            <ion-icon className="icon" name="notifications-outline"></ion-icon>
                            <span className="badge badge-danger">{this.state.userNotifs}</span>
                        </a>
                        <a onClick={() => {this.handleClick("/user-profile")}} className="headerButton">
                            <img src={this.state.avatar} alt="image" class="imaged w32" />
                        </a>
                    </div>
                </div>
                <div id="appCapsule">
                    {this.props.children}
                    <div class="appFooter">
                        <div class="footer-title">
                            Copyright © Grenier & associé 2023 All Rights Reserved. <a href="/en/terms">Terms and conditions</a>
                        </div>
                    </div>
                </div>

                <div class="modal fade panelbox panelbox-left" id="sidebarPanel" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body p-0">
                            <div class="profileBox pt-2 pb-2">
                                <div class="image-wrapper">
                                    <img src={this.state.avatar} alt="image" class="imaged  w36"/>
                                </div>
                                <div class="in">
                                    <strong>{this.state.profile.firstname + " " + this.state.profile.lastname}</strong>
                                    <div class="text-muted">{this.state.profile.id}</div>
                                </div>
                                <a class="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                                    <ion-icon name="close-outline"></ion-icon>
                                </a>
                            </div>

                            <div class="sidebar-balance">
                                <div class="listview-title">Balance</div>
                                <div class="in">
                                    <h1 class="amount">$ {parseFloat(this.state.total).toFixed(2)}</h1>
                                </div>
                            </div>

                            <div class="action-group">
                                <a onClick={(e) => {this.handleClick("/finance/deposit")}} data-bs-dismiss="modal" class="action-button">
                                    <div class="in">
                                        <div class="iconbox">
                                            <ion-icon name="add-outline"></ion-icon>
                                        </div>
                                        Dépôt
                                    </div>
                                </a>
                                <a onClick={() => {this.handleClick("/finance/retrait")}} data-bs-dismiss="modal" class="action-button">
                                    <div class="in">
                                        <div class="iconbox">
                                            <ion-icon name="arrow-down-outline"></ion-icon>
                                        </div>
                                        Retrait
                                    </div>
                                </a>
                                <a onClick={() => {this.handleClick("/finance/send_money")}} data-bs-dismiss="modal" class="action-button">
                                    <div class="in">
                                        <div class="iconbox">
                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                        </div>
                                        Envoie
                                    </div>
                                </a>

                            </div>

                            <div class="listview-title mt-1">Menu</div>
                            <ul class="listview flush transparent no-line image-listview">
                                <li>
                                    <a onClick={() => {this.handleClick("/work")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="code-working-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Travail
                                        </div>
                                    </a>
                                </li>
                                
                                <li>
                                    <a onClick={() => {this.handleClick("/services")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="hand-left-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Services
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {this.handleClick("/inscription")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="apps-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Inscription
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {this.handleClick("/discussions")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="chatbubbles-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Messengers
                                        </div>
                                    </a>
                                </li>
                                {shop}
                                <li>
                                    <a onClick={() => {this.handleClick("/impot/client")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="documents-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Impôt 2023
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {this.handleClick("/wallet")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="wallet-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Portefeuille
                                        </div>
                                    </a>
                                </li>
                                {this.state.gotContracts && (
                                    <li>
                                    <a onClick={() => {this.handleClick("/myContracts")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="list-circle-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Mes contrats
                                        </div>
                                    </a>
                                </li>
                                )}                                
                                {virtual_job}
                                {others}
                                {conseil}
                            </ul>

                            <div class="listview-title mt-1">Autres</div>
                            <ul class="listview flush transparent no-line image-listview">
                             <li>
                                    <a onClick={() => {this.handleClick("/news")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="newspaper-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Nouvelles
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {this.handleClick("/marketing")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="megaphone-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Marketing
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {this.handleClick("/user-profile")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="settings-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Profile
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {this.handleClick("/support")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="chatbubble-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Support
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {this.handleClick("/logout")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="log-out-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Log out
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default DefaultLayout;

/*
<li>
                                    <a onClick={() => {this.handleClick("/chasse")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="game-controller-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Chasse
                                        </div>
                                    </a>
                                </li>
*/