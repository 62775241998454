import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from "shards-react";
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from '../../services/autonome';
import { API_ROOT } from '../../api-config';
import TextField from '@material-ui/core/TextField';

export class CheckFbGroupAbonnees extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            jobId: this.props.jobId,
            job: this.props.job,
            image: null,
            errorMsg: null,
            onFinish: this.props.onFinish,
            onClicked: false
        }
        var metas = JSON.parse(this.props.job.metas);
        this.state.metas = metas;
        console.log("metas:",metas);
        if (typeof metas.curImage !== "undefined")
            this.state.image = API_ROOT + "s/facebook-screens/" + metas.curImage;
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        console.log("newform:", new_form);
        console.log(value);
        this.setState(new_form);
        this.validateForm();
    }

    onDone() {        
        this.setState({onClicked: true});
        var these = this;
        var data = {
            taskId: this.state.jobId,
            nbrAbonnees: this.state.nbrAbonnees
        }
        console.log("Ok send it to api:", data);
        api.call('post', "virt_job/fbGroupAbonnees", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            these.state.onFinish();
        });
    }

    componentDidMount(){             
    }

    render () {
        return (
            <>
            <Card>
                <CardHeader>Combien il y a d'abonné au groupe?</CardHeader>
                <CardBody>                    
                    <p>Nom du group: <a href={"https://facebook.com/" + this.state.metas.groupId} target="_blank">{this.state.metas.groupName}</a></p>
                    {this.state.image !== null &&
                    <>
                    <p>Si vous ne voyez pas le nombre sur l'image, cliquez le lien ci-dessus.</p>
                     <img 
                        src={this.state.image}
                    />
                    </>
                    }
                    <br/>
                    <TextField 
                        id="standard-basic" 
                        label="Nombre d'abonnées"
                        name="nbrAbonnees"
                        value={this.state.nbrAbonnees}
                        onChange={(e)=>this.handleChange(e)}
                    />
                </CardBody>
                <CardFooter>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<NewReleasesSharpIcon />}
                        onClick={() => this.onDone(true)}
                        disabled = {this.state.onClicked}
                    >
                        Envoyer
                    </Button>
                </CardFooter>
            </Card>
            </>         
        )
    }
}

export default CheckFbGroupAbonnees;