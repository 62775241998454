import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import DirectionsIcon from '@material-ui/icons/Directions';
import Button from '@material-ui/core/Button';
import OffLineImg from '../../../img/offline.png';
import { Form, Modal } from "react-bootstrap";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

class GlustersView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            showAdd: false,
            bricks: [],
            server: null
        }
        this.state.userId = this.getUserId();
    }

    addBrick() {
        var these = this;
        var body = {
            serverId: this.state.serverId,
            dev: this.state.dev,
            space: this.state.space
        }
        console.log("sending:", body)
        api.call('post', "hosting/gluster/create_brick", body).then(result => {
        });
    }

    fetchData() {
        var these = this;
        api.call('get', "hosting/gluster/bricks", {}).then(result => {
            these.setState({bricks: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();
    }

    selectServer(server) {
        //alert(JSON.stringify(server));
        this.props.history.push({
            pathname: "/admin/server/" + server.id
        });
    }

    goProjects() {
        this.props.history.push({
            pathname: "/admin/projects"
        });
    }

    goDep() {
        this.props.history.push({
          pathname: "/admin/deployments"
        });
    }

    goLb() {
        this.props.history.push({
          pathname: "/admin/loadbalancer"
        });
    }

    goAddBrick() {
        this.setState({showAdd: true});
    }

    closeModal() {
        this.setState({showAdd: false});
    }

    handleChange(event) {
        console.log("Handle change", event.target);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    renderBricks() {
        return this.state.bricks.map((row) => {
            var onlineMsg = (<></>);
            if (row.isOffLine)
                onlineMsg = (
                    <img src={OffLineImg} width={30}/>
                )
            return (
                <tr>
                <td>{row.id}</td>
                <td>{row.serverId}</td>
                <td>{row.state}</td>
                <td>{row.dev}</td>
                <td>{row.size}</td>
                </tr>
            );
          });
    }


    render(){
        return(
            <>
            <h1>Gluster Administration</h1>
            <p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goAddBrick()}
            >
              Add brick
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goDep()}
            >
              Deployments
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goLb()}
            >
              Loadbalancer
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goProjects()}
            >
              Projects
            </Button>
            </p>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Server Id</th>
                    <th>State</th>
                    <th>Mount</th>
                    <th>Size</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderBricks()}
                </tbody>
            </Table>
            <Modal
                size="lg"
                show={this.state.showAdd}
                onHide={() => this.closeModal()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Deployment
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="serverId">
                        <Form.Label>server Id</Form.Label>
                        <Form.Control 
                            type="text"
                            name="serverId"
                            placeholder="1" 
                            value={this.state.serverId} 
                            onChange={e => this.handleChange(e)}
                        />
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId="dev">
                        <Form.Label>Dev</Form.Label>
                        <Form.Control 
                            type="text"
                            name="dev"
                            placeholder="/dev/sdb1" 
                            value={this.state.dev} 
                            onChange={e => this.handleChange(e)}
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="space">
                        <Form.Label>Size</Form.Label>
                        <Form.Control 
                            type="text"
                            name="space"
                            placeholder="1000000000" 
                            value={this.state.space} 
                            onChange={e => this.handleChange(e)}
                        />
                        </Form.Group>

                        <div class="container">
                            <div class="row">
                                <div class="col text-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<EditIcon />}
                                        onClick={() => this.addBrick()}
                                    >
                                        Ajouter brick
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            </>
        )
    }
}

export default withRouter(GlustersView);