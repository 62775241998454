import React, {Fragment} from 'react'
import {api} from '../../services/autonome';
import packageJson from '../../../package.json';
import { isThisSecond } from 'date-fns';

export default class AdForUser extends React.Component {
    state = {
        ad: null,
        show: false
    }

    constructor(props){
        super(props)
        this.state.userId = this.getUserId();
    }

    componentDidMount() {
        this.getAd();
    }

    getUserId() {
        var existingTokens;
        try{
            existingTokens = JSON.parse(localStorage.getItem('tokens'));
            //alert(JSON.stringify(existingTokens));
            return existingTokens.UserId;
        } catch(e) {
            return null;
        }
    }

    getAd() {
        var there = this;
        api.call('get', "ads/select/ad?v=" + packageJson.version + "&platform=" + navigator.platform + "&pos=" + this.props.pos + "&page=" + this.props.page, {}).then(result => {
            console.log("Ad", result);
            if (result.data===null) {
                there.setState({show: false})
            } else {
                there.setState({show:true, ad:result.data[there.props.pos]});
            }
            there.sendImpression();
        });
    }

    sendImpression() {
        var there = this;
        var price = parseFloat(this.state.ad.cpm)/1000;
        api.call('get', "ad/imp?v=" + packageJson.version + "&platform=" + navigator.platform + "&zoneId=" + this.props.zoneId + "&adId=" + this.state.ad.id + "&price=" + price + "&userId=" + this.state.userId, {}).then(result => {
        });
        if (typeof this.state.ad !== "undefined" && this.state.ad !== null)
            window.gtag('event', 'liberium_ad', {
                currency: "CAD",
                value: price,
            });
    }

    render(){
        if (!this.state.show || typeof this.state.ad === "undefined" || this.state.ad === null) return ( <> </>);
        var there = this;

        var adUrl = "https://api.liberium.net/ad/click?zoneId=" + this.props.zoneId + "&adId=" + this.state.ad.id + "&userId=" + this.state.userId + "&url="+this.state.ad.url;

        var btn = (<></>)
        if (this.state.ad.url.length > 0)
            btn = (
                <a href={adUrl} class="btn btn-primary">{this.state.ad.title}</a>
            )
        var img = (<></>)
        if (this.state.ad.image)
            img = <img src={this.state.ad.image} class="card-img-top" />
        return (
            <div class="card">
                {img}
                <div class="card-body">
                    <h5 class="card-title">{this.state.ad.title}</h5>
                    <p class="card-text">{this.state.description}</p>
                    {btn}
                </div>
            </div>
        )
    }
}