import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Navbar,
  NavItem,
  NavLink
} from "shards-react";

class UserActions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };
    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (
      <></>
    )

    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          <img
            className="user-avatar rounded-circle mr-2"
            src="https://cdn2.vectorstock.com/i/thumb-large/20/76/man-avatar-profile-vector-21372076.jpg"
            alt="User Avatar"
          />{" "}
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="/user-profile">
            <i className="material-icons">&#xE7FD;</i> Profil
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="logout" className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Déconnexion
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

export default UserActions;