import React from "react";
import {api} from '../../services/autonome';
import { Container } from "shards-react";
import FileUploader from "../../components/liberium/file_uploader"
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import AcUnitIcon from '@material-ui/icons/AcUnit';
import Button from '@material-ui/core/Button';

export class AdminOverview extends React.Component {
  state = {
    screens: []
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.intWall = null;
    this.state = {
      userId: this.getUserId(),
      aiTask: null,
      aiText: [],
      score: 0
    }
  }

  clean() {
    this.setState({aiText: [], score: "n/d"});
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  componentDidMount() {
    this.getJob();
  }

  componentWillUnmount() {
  }

  goNewsLetter() {
    this.props.history.push({
      pathname: "/admin/newsletter"
    }); 
  }

  goHosting() {
    this.props.history.push({
      pathname: "/admin/servers"
    }); 
  }

  goTorrents() {
    this.props.history.push({
      pathname: "/admin/torrents"
    }); 
  }

  goSupport() {
    this.props.history.push({
      pathname: "/admin/support"
    }); 
  }

  goMarketing() {
    this.props.history.push({
      pathname: "/admin/marketing"
    });
  }

  goVirtualAccounts() {
    this.props.history.push({
      pathname: "/admin/virtual-job"
    });
  }

  goNetwork() {
    this.props.history.push({
      pathname: "/admin/network-stats"
    });
  }

  goCrawler() {
    this.props.history.push({
      pathname: "/admin/crawler"
    });
  }

  goIde() {
    this.props.history.push({
      pathname: "/admin/ide"
    });
  }

  createFondLiberium() {
    var newFond = {
      name: "Liberium",
      currency: "CAD"
    }
    api.call('post', "organisation/create", newFond).then(result => {
    });
  }

  getJob() {
    // Fait une demande
    if (typeof process.env.REACT_APP_BACKEND_HOST === "undefined") return;
    var these = this;
    this.clean();
    api.call('get', "ai/facebook_screen/do_one", {}).then(result => {
      these.setState({aiTask: result.data});
      //alert(result.data.image);
      api.call('get', "ai/getFacebookImageMetas?image=" + result.data.image, {}).then(result => {
        var parsed = result.data;
        console.log(parsed);
        these.setState({aiText: parsed.texts, score: parsed.score});
      })
    });
  }

  concierge() {
    // Fait une demande
    api.call('get', "task/concierge", {}).then(result => {
      if (result.data.error) {
        alert("Désolé une erreure s'est produite");
      } else {
        alert("Merci votre demande a été fait.");
      }
    });
  }

  entretienBNB() {
    // Fait une demande
    api.call('get', "task/create_bnb", {}).then(result => {
      if (result.data.error) {
        alert("Désolé une erreure s'est produite");
      } else {
        alert("Merci votre demande a été fait.");
      }
    });
  }

  entretienBNBmaude() {
    // Fait une demande
    api.call('get', "task/create_maude", {}).then(result => {
      if (result.data.error) {
        alert("Désolé une erreure s'est produite");
      } else {
        alert("Merci votre demande a été fait.");
      }
    });
  }

  entretienChambre() {
    // Fait une demande
    api.call('get', "task/create_bnb2", {}).then(result => {
      if (result.data.error) {
        alert("Désolé une erreure s'est produite");
      } else {
        alert("Merci votre demande a été fait.");
      }
    });
  }

  renderEricServices() {
    return (
      <div>
        <Card>
          <CardMedia/>
          <CardContent>
            <Typography
              className={"MuiTypography--heading"}
              variant={"h6"}
              gutterBottom
            >
              Concierge
            </Typography>
            <Typography
              className={"MuiTypography--subheading"}
              variant={"caption"}
            >
              Concierge : 5.00$
            </Typography>
            <br/>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.concierge()}
          >
              Faire demande
          </Button>
          </CardContent>
        </Card>
      
        <Card>
          <CardMedia />
          <CardContent>
            <Typography
              className={"MuiTypography--heading"}
              variant={"h6"}
              gutterBottom
            >
              Entretien du BNB
            </Typography>
            <Typography
              className={"MuiTypography--subheading"}
              variant={"caption"}
            >
              Demande pour l'entretien du BNB   Coût du service : 40$
            </Typography>
            <br/>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.entretienBNB()}
            >
              Faire demande Kiki
            </Button>
            <br/>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.entretienBNBmaude()}
            >
              Faire demande Maude
            </Button>
            <br/>

            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.entretienChambre()}
            >
              Faire demande pour chambre
            </Button>
          </CardContent>
        </Card>

        <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.createFondLiberium()}
          >
              Create Liberium Fond
          </Button>

        </div>
    )
  }

  deleteScreen() {
    var these = this;
    api.call('post', "ai/facebook_screen/setTag/" + this.state.aiTask.id, {tags:"[]"}).then(result => {
      these.getJob();
      //alert(JSON.stringify(result.data));
    });
  }

  interest() {
    var these = this;
    api.call('post', "ai/facebook_screen/setTag/" + this.state.aiTask.id, {tags:"['interest']"}).then(result => {
      these.getJob();
      //alert(JSON.stringify(result.data));
    });
  }

  vehicule() {
    var these = this;
    api.call('post', "ai/facebook_screen/setTag/" + this.state.aiTask.id, {tags:"['vehicule']"}).then(result => {
      these.getJob();
      //alert(JSON.stringify(result.data));
    });
  }

  facebookErr() {
    var these = this;
    api.call('post', "ai/facebook_screen/setTag/" + this.state.aiTask.id, {tags:"['facebook_problem']"}).then(result => {
      these.getJob();
      //alert(JSON.stringify(result.data));
    }); 
  }

  publicite() {
    var these = this;
    api.call('post', "ai/facebook_screen/setTag/" + this.state.aiTask.id, {tags:"['facebook_ad']"}).then(result => {
      these.getJob();
      //alert(JSON.stringify(result.data));
    }); 
  }

  renderJob() {
    return;
    if (this.state.aiTask === null) return;
    var url = "http://10.1.244.2:9090/s/facebook-screens/" + this.state.aiTask.image;
    var texts;
    texts = this.state.aiText.map((row) => {
        return (
          <p>{row}</p>
        )
    });
    var newS = {
      "vertical-align":"top"
    }
    return (
      <>
        <h2>Valider cette image:</h2>
        <table>
          <tr>
            <td style={newS}>
            <img height={500} src={url}/>
            </td>
            <td style={newS}>
              <h1>Score: {this.state.score}</h1>
              <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.deleteScreen()}
            >
              Image à supprimer
        </Button>
        <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.facebookErr()}
            >
              Problème Facebook
        </Button>
        <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.interest()}
            >
              Intérêt à s'arrêter
        </Button>
        <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.vehicule()}
            >
              Véhicule
        </Button>
        <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.publicite()}
            >
              Publicité
        </Button>
            {texts}
        
            </td>
          </tr>
        </table>
        
        
      </>
    )
  }

  render() {
    var otherServices;
    if (this.state.userId===1)
      otherServices = this.renderEricServices();

    return (
        <Container fluid className="main-content-container px-4">
            <h1>Panneau administrateur</h1>

            {this.renderJob()}

            <hr/>

            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.goSupport()}
            >
              Support
            </Button>

            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.goIde()}
            >
              Ide
            </Button>

            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.goMarketing()}
            >
              Marketing
            </Button>

            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.goHosting()}
            >
              Hosting
            </Button>

            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.goTorrents()}
            >
              Torrents
            </Button>
            
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.goNewsLetter()}
            >
              NewsLetter
            </Button>

            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.goVirtualAccounts()}
            >
              Virtual Job
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.goCrawler()}
            >
              Crawler
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AcUnitIcon />}
              onClick={() => this.goNetwork()}
            >
              Network Ads
            </Button>
            <FileUploader></FileUploader>
            {otherServices}

        </Container>
    );
  }
}