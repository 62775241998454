import React from "react";
import { Container } from "shards-react";
import {api} from '../../services/autonome';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Table } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

export class MarketingView extends React.Component {
  state = {
    screens: []
  }

  constructor(props) {
    super(props);
    this.state = {
        genre: "travailleur",
        userId: this.getUserId(),
        errorMsg: "",
        categories: [],
        copied: {},
        isCopied: false
    }
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onChangeValue(event) {
    console.log(event.target.value);
    this.setState({genre:event.target.value});
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  getReferrals() {
      var these = this;
      api.call('get', "users/getStats", {}).then(result => {
        these.setState({level1: result.data.level1, level2: result.data.level2, level3: result.data.level3});
      });
      /*
      var body = {
        cmd:"askFunction",
        module: "referer",
        name: "getReferers",
        userId: this.state.userId
      }
      //console.log("=====================",body);
      api.callLb('post', "apis/internal/function", body).then(result => {
          console.log("from LB function:",result);
          these.setState({level1: result.data.level1});
      });
      */
  }

  componentDidMount() {
    var these = this;
    api.call('post', "user/usrLog", {module: "views", title:"marketing"});
    window.gtag('event', 'page_view', {
      page_title: 'Marketing',
      page_location: '/marketing',
      page_path: '/marketing'
    });
    api.call('get', "tasks/categories", {}).then(result => {
        if (typeof result.data.error != "undefined") return;
        these.setState({
          categories: result.data
        });
        var newState = {}
        result.data.forEach(function(one){
            newState[one.id] = false;
        });
        these.setState({copied: newState});
    });
    this.getReferrals();
  }

  componentWillUnmount() {
  }

  categoryLinks() {
    return this.state.categories.map((row) => {
      var url, texte;
        if (this.state.genre === "travailleur") {
          url = "https://liberium.net/landing_worker/" + row.id + "?refId=" + this.state.userId;
          texte = "Salut. J'aimerais te recommander cette plateforme pour trouver de nouveau contrat. L'inscription est totalement gratuite:\n" + url;
        } else {
          url = "https://liberium.net/landing/" + row.id + "?refId=" + this.state.userId;
          texte = "Salut. J'aimerais te recommander cette plateforme pour trouver le travailleur que tu recherches. L'inscription est totalement gratuite:\n" + url;
        }
        return (
            <>
            <h3>{row.name}</h3>
            <div>
                {url}
                <CopyToClipboard text={url}
                    onCopy={() => this.setState({copied: {[row.id]:true},isCopied: false})}>
                    <button>Copie addresse</button>
                </CopyToClipboard>
                <br/>
                <textarea name={"ta_" + row.id} value={texte}>
                </textarea>
                <br/>
                <CopyToClipboard text={texte}
                    onCopy={() => this.setState({copied: {[row.id]:true},isCopied: false})}>
                    <button>Copie texte</button>
                </CopyToClipboard>
                {this.state.copied[row.id] ? <span style={{color: 'red'}}>Copié.</span> : null}
            </div>
            </>
        );
    });
  }

  renderStats() {
    return (
      <div>
            <h2>Votre réseau :</h2>
            <Table>
                <thead>
                    <tr>
                        <td>Niveau</td>
                        <td>Nombre de membres</td>
                        <td>Commissions du mois</td>
                        <td>Commissions total</td>
                    </tr>
                </thead>
                <tbody>
                <tr>
                        <td align="center">1</td>
                        <td align="center">{this.state.level1}</td>
                        <td align="right">$0.00</td>
                        <td align="right">$0.00</td>
                    </tr>
                    <tr>
                        <td align="center">2</td>
                        <td align="center">{this.state.level2}</td>
                        <td align="right">$0.00</td>
                        <td align="right">$0.00</td>
                    </tr>
                    <tr>
                        <td align="center">3</td>
                        <td align="center">{this.state.level3}</td>
                        <td align="right">$0.00</td>
                        <td align="right">$0.00</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
  }

  renderEmail() {
    return (
      <>Par Email</>
    )
  }

  renderLinks() {
    var url = "https://liberium.net/?refId=" + this.state.userId;
    return (
      <>
        <h2>Votre lien</h2>

        <div>
            {url}
            <CopyToClipboard text={url}
                onCopy={() => this.setState({isCopied: true})}>
                <button>Copy</button>
            </CopyToClipboard>
            {this.state.isCopied ? <span style={{color: 'red'}}>Copié.</span> : null}
        </div>

        <div onChange={this.onChangeValue}>
          <input type="radio" value="client" name="genre" /> Pour un client
          <input type="radio" value="travailleur" name="genre" /> Pour un travailleur
        </div>

        {this.categoryLinks()}
      </>
    );
  }

  renderInfo() {
    return (
      <>
        <p>Vous trouverez dans notre section marketing des outils pour référer d'autres personnes a Liberium</p>
        <p>Lorsque les personnes référer utilisent la plateforme Liberium, vous gagnez un pourcentage de commission.</p>
      </>
    )
  }

  render() {
    var sty = {
        float: "none", 
        margin: "0 auto"
    }    

    return (
      <Container fluid className="main-content-container px-4">

        <div class="row home-intro text-center">
          <div class="col-xs-12 col-md-8" style={sty}>
            <h1>Réseau marketing</h1>
            <p>Le marketing de réseau à palier multiple est une stratégie de vente qui permet à des individus de vendre des produits ou services en recrutant d'autres personnes pour les aider à vendre également. En rejoignant le réseau, ces nouvelles recrues deviennent à leur tour des vendeurs et peuvent ainsi recruter d'autres personnes. Les vendeurs reçoivent une commission sur les ventes qu'ils réalisent et sur celles de leur équipe de recrutement.</p>

            <p>Le marketing de réseau à palier multiple est une façon de créer une entreprise sans les coûts initiaux et les risques associés à la création d'une entreprise traditionnelle. Au lieu de créer une entreprise seule, le vendeur peut rejoindre un réseau déjà existant et bénéficier de la formation et du soutien de l'entreprise.</p>

            <p>Débutez maintenant en faisant la promotion de Liberium:</p>

            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Statistiques">
                {this.renderStats()}
              </Tab>
              <Tab eventKey="info" title="Informations">
                {this.renderInfo()}
              </Tab>
              <Tab eventKey="liens" title="Vos liens">
                {this.renderLinks()}
              </Tab>
              <Tab eventKey="sociaux" title="Réseaux sociaux">
                <h1>Bientôt</h1>
                <p>Nous ajouterons des outils pour les réseaux sociaux très bientôt.</p>
              </Tab>
              <Tab eventKey="video" title="Vidéos">
                <h1>Bientôt</h1>
                <p>Nous ajouterons des vidéos sur le marketing. Restez a l'affut..</p>
              </Tab>
            </Tabs>

          </div>
        </div>
    </Container>
    );
  }
}