import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import DirectionsIcon from '@material-ui/icons/Directions';
import Button from '@material-ui/core/Button';
import OffLineImg from '../../../img/offline.png';
import Switch from '@material-ui/core/Switch';

class PostsViews extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            posts: []
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "virt_job/getLatestPosts", {}).then(result => {
            these.setState({posts: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();
    }

    renderPosts() {
        return this.state.posts.map((row) => {
            return (
                <tr>
                <td>{row.id}</td>
                <td>{row.virtAccountId}</td>
                <td><a href={row.link} target="_blank">{row.metas.texte}</a></td>
                <td><a href={"https://facebook.com/" + row.group.id} target="_blank">{row.group.name}</a></td>
                <td>{row.stats}</td>
                </tr>
            );
          });
    }

    render(){
        return(
            <>
            <h1>Posts</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Compte</th>
                    <th>Post</th>
                    <th>Group</th>
                    <th>Stats</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderPosts()}
                </tbody>
            </Table>
            </>
        )
    }
}

export default withRouter(PostsViews);