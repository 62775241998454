import React from "react";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import {api} from '../../services/autonome';
import { withRouter } from 'react-router';
import noServiceIcon from '../../img/service-icon.png'

export class TaskOverview extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            task: this.props.task,
            disabled_apply: false
        }
    }

    applyTask(task) {
        return;
        this.setState({disabled_apply: true});
        api.call('get', "tasks/apply/" + task.id, {}).then(result => {
            this.state.task.applied = true;
            this.setState({disabled_apply: false});
        });
    }

    unapplyTask(task) {
        return;
        this.setState({disabled_apply: true});
        api.call('get', "tasks/unapply/" + task.id, {}).then(result => {
            this.state.task.applied = false;
            this.setState({disabled_apply: false});
        });
    }

    componentDidMount(){
    }

    renderButton() {
        if (this.state.task.status === 0) {
            if (this.state.task.applied)
                return (
                    <Button
                    variant="contained"
                    disabled={this.state.disabled_apply}
                    color="primary"
                    size="small"
                    startIcon={<CancelIcon />}
                    onClick={() => this.unapplyTask(this.state.task)}
                    >
                    Annuler l'application
                    </Button>
                )
            else
                return (
                        <Button
                        variant="contained"
                        disabled={this.state.disabled_apply}
                        color="primary"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() => this.applyTask(this.state.task)}
                        >
                        J'applique
                        </Button>
                )
        }
        if (this.state.task.workerId === this.props.user)
          return (
                <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => this.jobDone()}
                >
                Terminé
                </Button>
          )
    }

    click() {
        this.props.history.push({
            pathname: "/task/" + this.state.task.id
        });
    }

    render () {
        var img = (
            <img src={noServiceIcon} width='75' class='image'/>
          )
          if (this.state.task.model.icon.length > 0)
            img = (
              <img width="75" height="75" src={this.state.task.model.icon} style={{margin: "10px 10px 10px 10px"}}/>
            )
        return (
            <li onClick={()=> this.click()} id={this.state.task.id}>
                <a class="item">
                   {img}                   
                    <div class="in">
                        <div>
                            <header>{this.state.task.model.name}</header>
                            Distance: {this.state.task.distance} km<br/>
                            Récompence: {this.state.task.reward.amount} {this.state.task.reward.crypto}
                            <footer>{this.state.task.details.description}</footer>
                        </div>
                    </div>
                </a>
            </li>  

        )
    }
}

export default withRouter(TaskOverview);