// Layout Types
import { VideoLayout, noLayout, ChasseLayout, LiberiumLayout, LiberiumWithoutBottom, UnloggedLayout, PublicLayout } from "./layouts";

import userGroupService from "./services/user-group-service";

// Route Views
import PublicHomePage from "./views/public/Home";
import HomePage from "./views/Home";
import NewsPage from "./views/public/news";
import {CreatorPage} from "./views/creator/index";
import LandingPage from "./views/landing";
import WorkerPage from "./views/worker";
import { ImpotHomePage } from "./views/impot/index";
import { ImpotClient } from "./views/impot/client";
import Logout from "./views/Logout";
import Login from "./views/Login";
import Signup from "./views/Signup";
import FormationsView from "./views/admin/formation/index";
import {FbAdView} from "./views/ads/fb_ad";
import PubliciteForm from "./views/ads/ad";
import { PixelsPage } from "./views/ads/pixels";
import { MarketingView } from "./views/marketing/index";
import { ChatGptView } from "./views/ia/chatgpt";
import { BannerBearView } from "./views/ia/bannerbear";
import { CryptoView } from "./views/crypto";
import ClientsPage from "./views/clients";
import { NewAdView } from "./views/ads/new_ad";
import { AdsForm } from "./views/ads/index";
import { ZonesForm } from "./views/ads/zones";
import { NetworkStats } from "./views/admin/ads/network";
import CrawlerView from "./views/admin/crawler/index";
import { AdminFbGroups } from "./views/admin/marketing/fb-groups";
import AdminVirtualJob from "./views/admin/virtual-job/index";
import PostsViews from "./views/admin/virtual-job/posts";
import TravailleursViews from "./views/admin/virtual-job/travailleurs";
import AdminMap from "./views/admin/map/index";
import AdminImpotOverview from "./views/admin/impot/home";
import AdminImpotClientView from "./views/admin/impot/client";
import CryptoWallets from "./views/admin/crypto/wallets";
import MinioView from "./views/admin/minio/index";
import TorrentsView from "./views/admin/torrents/index";
import MinioBucketView from "./views/admin/minio/bucket";
import ModulesView from "./views/admin/modules/index";
import ModuleView from "./views/admin/modules/module";
import CryptoOrdersView from "./views/admin/crypto/orders";
import { ChasseHome } from "./views/chasse";
import ForgotView from "./views/users/forgot_password";
import { Organisation } from "./views/organisation/index";
import { BusinessOrdersView } from "./views/organisation/stor/orders";
import Apple from "./views/public/apple";
import { Conseil } from "./views/organisation/ca/index";
import { BusinessView } from "./views/organisation/home";
import { BusinessTaskView } from "./views/organisation/tasks";
import { BusinessSettingsView } from "./views/organisation/settings";
import { BusinessSubscriptionView } from "./views/organisation/subscription";
import { InvestorsView } from "./views/organisation/investors/index";
import GlustersView from "./views/admin/gluster/index";
import {NewsLetter} from "./views/admin/newsletter";
import {AdminAddFondView} from "./views/admin/organisation/add-fund"
import { AdminItemView } from "./views/organisation/stor/item";
import MyVideosPage  from "./views/my_videos/index";
import { ServiceView } from "./views/services/index";
import { UserProfile } from "./views/users/profil";
import { Referral } from "./views/users/referral";
import { WelcomeView } from "./liberium/views/user/welcome";
import StorageView from "./views/storage/index";
import ShoppingView from "./views/shopping/index";
import ShoppingOrgView from "./views/shopping/org/index";
import { WorkView } from "./views/work/index";
import { ContractsView } from "./views/work/myContracts";
import ProjectsView from "./views/admin/project/index";
import ServersView from "./views/admin/servers/index";
import LoadbalanceView from "./views/admin/lb/index";
import { ServerView } from "./views/admin/server/index";
import { ContainerView } from "./views/admin/server/container";
import DeploymentsView from "./views/admin/deployments";

import { MyWorkView } from "./views/work/my_work";
import { AdminOverview } from "./views/admin/index";
import { AdminMarketing } from "./views/admin/marketing/index";
import { Screen } from "./views/admin/screen/index";
import { AdminSupportView } from "./views/admin/support";
import { Inscription } from "./views/work/inscription";
import { MarketPlaceHome } from "./views/marketplace/home";
import { DepositView } from "./views/finance/deposit";
import { RetraitView } from "./views/finance/retrait";
import { TaskView } from "./views/work/task";
import { WalletView } from "./views/finance/wallet";
import { SendMoneyView } from "./views/finance/send_money";
import { DiscussionView } from "./views/chat/discussion";
import DiscussionsView from "./views/chat/discussions";
import { SupportView } from "./views/users/support";
import { Notifications } from "./views/users/notifications";
import { SettingsView } from "./views/users/settings";
import { Ide } from "./views/admin/ide";
import PrivacyView from "./views/public/privacy";

import { VirtualJobDashBoard } from "./views/virtual-job/index";
import { VirtualJobView } from "./views/virtual-job/job";
import { VirtualJobFormation } from "./views/virtual-job/formations/index";

export default [
  {
    path: "/",
    exact: true,
    layout: PublicLayout,
    PrivateRoute: false,
    component: PublicHomePage
  },
  {
    path: "/impot",
    exact: true,
    layout: PublicLayout,
    PrivateRoute: false,
    component: ImpotHomePage
  },
  {
    path: "/impot/client",
    exact: true,
    layout: LiberiumLayout,
    PrivateRoute: false,
    component: ImpotClient
  },
  {
    path: "/creator",
    exact: true,
    layout: LiberiumLayout,
    PrivateRoute: false,
    component: CreatorPage
  },
  {
    path: "/virtual-job",
    exact: true,
    layout: LiberiumLayout,
    PrivateRoute: false,
    component: VirtualJobDashBoard
  },
  {
    path: "/virtual-job/formation/:id",
    exact: true,
    layout: LiberiumLayout,
    PrivateRoute: false,
    component: VirtualJobFormation
  },
  {
    path: "/virtual-job/job",
    exact: true,
    layout: noLayout,
    PrivateRoute: false,
    component: VirtualJobView
  },
  {
    path: "/landing/:id",
    exact: true,
    layout: PublicLayout,
    PrivateRoute: false,
    component: LandingPage
  },
  {
    path: "/landing_worker/:id",
    exact: true,
    layout: PublicLayout,
    PrivateRoute: false,
    component: WorkerPage
  },
  /*
  {
    path: "/crypto",
    exact: true,
    layout: PublicLayout,
    PrivateRoute: false,
    component: CryptoView
  },
  */  
  {
    path: "/l/welcome",
    exact: true,
    layout: LiberiumLayout,
    PrivateRoute: false,
    component: WelcomeView
  },
  {
    path: "/ia/chatgpt",
    exact: true,
    layout: LiberiumWithoutBottom,
    PrivateRoute: false,
    component: ChatGptView
  },
  {
    path: "/ia/bannerbear",
    exact: true,
    layout: LiberiumWithoutBottom,
    PrivateRoute: false,
    component: BannerBearView
  },  
  {
    path: "/news",
    exact: true,
    layout: LiberiumLayout,
    PrivateRoute: false,
    component: NewsPage
  },  
  {
    path: "/new_ad",
    exact: true,
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: NewAdView
  },
  {
    path: "/new_fb_ad",
    exact: true,
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: FbAdView
  },  
  {
    path: "/zones",
    exact: true,
    layout: LiberiumLayout,
    PrivateRoute: false,
    component: ZonesForm
  },
  {
    path: "/ads",
    exact: true,
    layout: LiberiumLayout,
    PrivateRoute: false,
    component: AdsForm
  },
  {
    path: "/pixels",
    exact: true,
    layout: LiberiumLayout,
    PrivateRoute: false,
    component: PixelsPage
  },
  {
    path: "/ad/:id",
    exact: true,
    layout: LiberiumLayout,
    PrivateRoute: false,
    component: PubliciteForm
  },
  {
    path: "/logout",
    exact: true,
    layout: UnloggedLayout,
    PrivateRoute: false,
    component: Logout
  },
  {
    path: "/user/forgot-password",
    exact: true,
    layout: UnloggedLayout,
    PrivateRoute: false,
    component: ForgotView
  },
  {
    path: "/login",
    exact: true,
    layout: UnloggedLayout,
    PrivateRoute: false,
    component: Login
  },
  {
    path: "/signup",
    exact: true,
    layout: UnloggedLayout,
    PrivateRoute: false,
    component: Signup
  },
  {
    path: "/chasse",
    layout: ChasseLayout,
    PrivateRoute: true,
    component: ChasseHome
  },
  {
    path: "/my_videos",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: MyVideosPage
  },
  {
    path: "/finance/deposit",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: DepositView
  },
  {
    path: "/finance/send_money",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: SendMoneyView
  },
  {
    path: "/finance/retrait",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: RetraitView
  },
  {
    path: "/home",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: HomePage
  },
  {
    path: "/storage",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: StorageView
  },
  {
    path: "/apple",
    layout: UnloggedLayout,
    PrivateRoute: true,
    component: Apple
  },
  {
    path: "/shop/org/:orgId",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: ShoppingOrgView,
    exact: true
  },
  {
    path: "/shop",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: ShoppingView,
    exact: true
  },
  {
    path: "/marketplace",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: MarketPlaceHome
  },
  {
    path: "/support",
    layout: LiberiumWithoutBottom,
    PrivateRoute: true,
    component: SupportView
  },
  {
    path: "/notifications",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: Notifications
  },
  {
    path: "/organisation",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: Organisation
  },
  {
    path: "/ca",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: Conseil
  },
  {
    path: "/marketing",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: MarketingView,
    exact: true
  },
  {
    path: "/business/:id",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: BusinessView,
    exact: true
  },
  {
    path: "/business/:id/tasks",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: BusinessTaskView,
    exact: true
  },
  {
    path: "/business/:id/settings",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: BusinessSettingsView,
    exact: true
  },
  {
    path: "/business/:id/subscription",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: BusinessSubscriptionView,
    exact: true
  },
  {
    path: "/business/:orgId/clients",
    layout: LiberiumLayout,
    PrivateRoute: true,
    //groups: [userGroupService.Admin],
    component: ClientsPage
  },
  {
    path: "/business/:orgId/investors",
    layout: LiberiumLayout,
    PrivateRoute: true,
    //groups: [userGroupService.Admin],
    component: InvestorsView
  },
  {
    path: "/business/:orgId/item/:id",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: AdminItemView,
    exact: true
  },
  {
    path: "/business/:id/orders",
    layout: LiberiumLayout,
    PrivateRoute: true,
    //groups: [userGroupService.Admin],
    component: BusinessOrdersView
  },
  {
    path: "/settings",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: SettingsView
  },
  {
    path: "/admin",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: AdminOverview,
    exact: true
  },
  {
    path: "/admin/network-stats",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: NetworkStats,
    exact: true
  },
  {
    path: "/admin/travailleurs",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: TravailleursViews,
    exact: true
  },
  {
    path: "/admin/virtual-job",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: AdminVirtualJob,
    exact: true
  },
  {
    path: "/admin/posts",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: PostsViews,
    exact: true
  },
  {
    path: "/admin/formations",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: FormationsView,
    exact: true
  },
  {
    path: "/admin/newsletter",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: NewsLetter,
    exact: true
  },
  {
    path: "/admin/crawler",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: CrawlerView,
    exact: true
  },
  {
    path: "/admin/map",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: AdminMap,
    exact: true
  },
  {
    path: "/admin/torrents",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: TorrentsView,
    exact: true
  },
  {
    path: "/admin/minio",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: MinioView,
    exact: true
  },
  {
    path: "/admin/minio/:bucketId",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: MinioBucketView,
    exact: true
  },  
  {
    path: "/admin/impot",
    layout: LiberiumLayout,
    PrivateRoute: true,
    //groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: AdminImpotOverview,
    exact: true
  },
  {
    path: "/admin/impot/:id",
    layout: LiberiumLayout,
    PrivateRoute: true,
    //groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: AdminImpotClientView,
    exact: true
  },
  {
    path: "/admin/projects",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: ProjectsView,
    exact: true
  },
  {
    path: "/admin/crypto/wallets",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: CryptoWallets,
    exact: true
  },
  {
    path: "/admin/crypto/orders",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: CryptoOrdersView,
    exact: true
  },
  {
    path: "/admin/deployments",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: DeploymentsView,
    exact: true
  },  
  {
    path: "/admin/glusters",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: GlustersView,
    exact: true
  },
  {
    path: "/admin/servers",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: ServersView,
    exact: true
  },
  {
    path: "/admin/loadbalancer",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: LoadbalanceView,
    exact: true
  },
  {
    path: "/admin/server/:id",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: ServerView,
    exact: true
  },
  {
    path: "/admin/server/:serverId/container/:containerId",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: ContainerView,
    exact: true
  },
  {
    path: "/admin/support",
    layout: LiberiumLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: AdminSupportView,
    exact: true
  },
  
  {
    path: "/admin/fb-groups",
    layout: noLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: AdminFbGroups,
    exact: true
  },
  {
    path: "/admin/marketing/:id",
    layout: noLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: AdminMarketing,
    exact: true
  },
  {
    path: "/admin/screen/:id",
    layout: UnloggedLayout,
    PrivateRoute: true,
    groups: [userGroupService.Admin,userGroupService.LiberiumAdmin],
    component: Screen,
    exact: true
  },
  {
    path: "/admin/modules",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: ModulesView,
    groups: [userGroupService.Admin],
    exact: true
  },
  {
    path: "/admin/module/:moduleName",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: ModuleView,
    groups: [userGroupService.Admin],
    exact: true
  },
  {
    path: "/admin/ide",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: Ide,
    groups: [userGroupService.Admin],
    exact: true
  },
  {
    path: "/admin/add-fund/:userId",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: AdminAddFondView,
    exact: true,
    groups: [userGroupService.Admin],
  },
  {
    path: "/inscription",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: Inscription
  },
  
  {
    path: "/discussions",
    layout: LiberiumWithoutBottom,
    PrivateRoute: true,
    component: DiscussionsView
  },
  {
    path: "/discussion/:withUserId",
    layout: LiberiumWithoutBottom,
    PrivateRoute: true,
    component: DiscussionView
  },
  {
    path: "/services",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: ServiceView
  },
  {
    path: "/task/:id",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: TaskView
  },
  {
    path: "/work",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: WorkView,
    exact: true
  },
  {
    path: "/myContracts",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: ContractsView,
    exact: true
  },
  {
    path: "/myWork",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: MyWorkView,
    exact: true
  },
  {
    path: "/wallet",
    layout: LiberiumLayout,
    PrivateRoute: true,
    component: WalletView,
    exact: true
  },
  {
    path: "/user-profile",
    layout: LiberiumLayout,
    component: UserProfile
  },
  {
    path: "/referral",
    layout: LiberiumLayout,
    component: Referral
  },
  {
    path: "/privacy",
    layout: LiberiumLayout,
    component: PrivacyView
  },
];