import React from "react";
import { Container } from "shards-react";
import {api} from '../../services/autonome';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

import css from "./index.css";

export class ImpotHomePage extends React.Component {

  state = {
    screens: []
  }

  constructor(props) {
    super(props);
    this.state = {
        userId: this.getUserId(),
    }
    this.classes = makeStyles((theme) => ({
      markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
      },
    }));
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  componentDidMount() {
    var these = this;
    api.call('post', "user/usrLog", {module: "views", title:"impot"});
    window.gtag('event', 'page_view', {
      page_title: 'Impot',
      page_location: '/impot',
      page_path: '/impot'
    });
  }

  signup() {
    this.props.history.push({
      pathname: "/signup"
    });
  }

  goInside() {
    this.props.history.push({
      pathname: "/impot/client"
    });
  }

  componentWillUnmount() {
  }

  renderButton() {
    if (this.state.userId) {
        return (
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => this.goInside()}
            >
                Entrer dans votre espace client
            </Button>
        )
    }
    
    return (
        <>
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => this.signup()}
            >
                Enregistrement
            </Button>
        </>
    )
  }

  render() {
    var sty = {
        float: "none", 
        margin: "0 auto"
    }
    return (
      <Container fluid className="main-content-container px-4">

        <div class="row home-intro text-center">
          <div class="col-xs-12 col-md-8" style={sty}>
            <h1>Impôt personnel</h1>
            <center><img src="https://api.liberium.net/s/liberium-public-img/impot_personnel.jpeg" width={200}/></center>
            <p>Travaillez avec le cabinet d'Eric Grenier pour vos déclarations d'impôt personnel. Depuis 1985, nous avons une expertise solide dans ce domaine. Eric Grenier est également le fondateur de Liberium.</p>

            <p>Plus besoin de vous déplacer, tout se fait en ligne. Il vous suffit de prendre des photos de vos documents depuis votre téléphone, directement sur notre plateforme.</p>

            <p>Une fois que nous avons terminé de préparer votre déclaration d'impôt, vous pourrez également la signer en ligne via notre plateforme.</p>

            <table class="center">
              <thead>
                <th colspan="2"><center>Nos prix</center></th>
              </thead>
              <tbody>
                <tr>
                  <td>Aide sociale</td>
                  <td>25$</td>
                </tr>
                <tr>
                  <td>Une personne qui travaille</td>
                  <td>60$</td>
                </tr>
                <tr>
                  <td>Un retraité</td>
                  <td>55$</td>
                </tr>
                <tr>
                  <td>Travailleur autonome</td>
                  <td>*</td>
                </tr>
              </tbody>
            </table>
            <br/><br/>
            <p>*Nous ne prendrons pas de travailleur autonome encore cette année.</p>

            {this.renderButton()}

            <br/><br/><br/><br/><br/><br/><br/><br/>
          </div>
        </div>
    </Container>
    );
  }
}