import React from "react";
import { Container } from "shards-react";
import {api} from '../../services/autonome';
import noServiceIcon from '../../img/service-icon.png'
import { Form } from "react-bootstrap";
import Ad from '../../components/ads/AdItem';

export class Inscription extends React.Component {
  state = {
    screens: [],
    categories: [],
    worker_kilometrage: 100,
    night_worker: false,
    completly_loaded: false
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    console.log("Handle change", event);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
    api.call('post', "user/meta/" + name, {value: value}).then(result=> {

    });
  }

  fetchData() {
    var these = this;
    api.call('get', "tasks/categories", {}).then(result => {
      if (typeof result.data.error != "undefined") return;
      these.setState({
        categories: result.data
      });
    });
    api.call('get', "user/meta/night_worker", {}).then(result => {
      if (typeof result.data === "undefined" || result.data === null || typeof result.data.error !== "undefined")
        return;
      var valeur = false;
      if (result.data.value === "1" || result.data.value === 1) {
        valeur = true;
      }
      these.setState({
        completly_loaded: true,
        night_worker: valeur
      });
      these.forceUpdate();
    });
    api.call('get', "user/meta/worker_kilometrage", {}).then(result => {
      console.log(result);
      if (typeof result.data === "undefined" || result.data === null || typeof result.data.error !== "undefined")
        return;
      if (result.data !== null)
        these.setState({
          worker_kilometrage: parseInt(result.data.value)
        });
    });    
  }

  componentDidMount() {
    api.call('post', "user/usrLog", {module: "views", title:"inscription"});
    this.fetchData();
    window.gtag('event', 'page_view', {
      page_title: 'Inscription',
      page_location: '/inscription',
      page_path: '/inscription',
      //send_to: '<GA_MEASUREMENT_ID>'
    })
  }

  componentWillUnmount() {
  }

  toggleCategory(row) {
    /*
    console.log(row.id);
    var cats2 = [];
    this.state.categories.forEach(function(cat) {
      //alert(cat.id + "===" + row.id);
      if (cat.id === row.id) {
        if (cat.selected)
          delete cat.selected
        else
          cat.selected = true;
      }
      cats2.push(cat);
    })
    this.setState({categories: cats2});
    */
    api.call('post', "tasks/users/model/" + row.id, {catId:row.id}).then(result => {
    });
    window.gtag('event', 'inscription', {});
  }

  renderCategories() {
    if (this.state.categories.length === 0)
      return (
          <p>
            En cours de chargement
          </p>
      );

    return this.state.categories.map((row) => {
      var row_name = "check_" + row.id;
      var checked = false;
      if (typeof row.selected !== "undefined") checked=true;
      var img = (
        <img src={noServiceIcon} class='image'/>
      )
      if (row.icon.length > 0)
        img = (
          <img src={row.icon} width={75}/>
        )
      return (
        <li>
          <a class="item">
              {img}                   
              <div class="in">
                  <div>
                      <header>                        
                        {row.name} ({row.counter})
                      </header>
                      <footer>{row.descriptionWorker}</footer>
                  </div>
                  <span class="text-muted">
                    <input class="form-check-input"
                      type="checkbox" 
                      value={this.state.categories[row.id]}
                      id={row_name} 
                      onChange={() => this.toggleCategory(row)}
                      defaultChecked={checked}
                    />
                  </span>
              </div>
          </a>
      </li>  
      );
    });
  }

  render() {
    if (!this.state.completly_loaded)
      return (
        <h1>Nous chargeons la page</h1>
      )
    return (
      <Container fluid className="main-content-container px-4">
          <h1>Distance que vous pouvez parcourir</h1>
          <p>Kilométrage: {this.state.worker_kilometrage} km</p>
          <Form.Range
            type="range" 
            class="form-range" 
            min="1" 
            max="200" 
            id="customRange2" 
            name="worker_kilometrage" 
            value={this.state.worker_kilometrage}
            onChange={this.handleChange}
          />
          <Form.Group className="mb-3">
            <Form.Check 
                type='checkbox'
                id="night_worker"
                name="night_worker"
                label="Voulez-vous recevoir des jobs de nuit? (prime de nuit)"
                defaultChecked={this.state.night_worker}
                onChange={e => this.handleChange(e)}
            />
          </Form.Group>
          <h1>Abonnez-vous aux catégories</h1>
          <p>
          Une ou plusieurs de ces catégories vous intéresseront surement.
          Recevez un message instantanément quand une quête devient disponible
          dans votre secteur.
          </p>
          <p>
            Sélectionner autant de catégorie que vous le souhaitez.
          </p>
          <div class="listview-title mt-2">Mes choix</div>
          <ul class="listview image-listview">
            {this.renderCategories()}
          </ul>
          <Ad 
            pos={1}
            size={"728x90"}
            page="inscription"
          />
      </Container>
    );
  }
}