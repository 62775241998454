import React from "react";
import {api} from '../../services/autonome';
import { Container } from "shards-react";
import Button from '@material-ui/core/Button';
import Form from 'react-bootstrap/Form';
import CheckIcon from '@material-ui/icons/Check';
import axios from 'axios';
import { API_ROOT } from '../../api-config';

function Iframe(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}

export class NewsLetter extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      title: "",
      texte: "",
      testSent: false
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    //console.log("Handle change", event.target.name);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  sendTest() {
    var these = this;
    api.call('post', "admin/sendTestEmail", {title: this.state.title, message: this.state.texte}).then(result => {
      these.setState({testSent:true});
    });
  }

  sendMass() {
    this.setState({testSent:false});
    api.call('post', "admin/sendMassEmail", {title: this.state.title, message: this.state.texte}).then(result => {      
    });
  }

  render() {
    const url = "https://api.liberium.net/admin/apercuEmail?message=" + encodeURIComponent(this.state.texte);
    const iframe = '<iframe height="1524" style="width: 100%;" scrolling="no" title="fx." src="' + url + '" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>'; 

    return (
        <Container fluid className="main-content-container px-4">
            <div class="row">
                <div class="col-sm">
                        <Form.Group className="mb-3">
                            <Form.Label>Sujet</Form.Label>
                            <Form.Control 
                                type="text"
                                name="title"
                                placeholder="Titre du email" 
                                value={this.state.title} 
                                onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={15}
                            type="textarea"
                            name="texte"
                            value={this.state.texte}
                            onChange={e => this.handleChange(e)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={!this.state.testSent}
                        startIcon={<CheckIcon />}
                        onClick={() => this.sendMass()}
                    >
                        Send Mass
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<CheckIcon />}
                        onClick={() => this.sendTest()}
                    >
                        Send Test
                    </Button>
                </div>
                <div class="col-sm">
                    <p>Aperçu</p>
                    <Iframe iframe={iframe} />,
                </div>
            </div>
                
        </Container>
    );
  }
}