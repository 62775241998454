import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import DirectionsIcon from '@material-ui/icons/Directions';
import Button from '@material-ui/core/Button';
import OffLineImg from '../../../img/offline.png';

class ServersView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            servers: [],
            server: null
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "hosting/servers", {}).then(result => {
            these.setState({servers: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();
    }

    selectServer(server) {
        //alert(JSON.stringify(server));
        this.props.history.push({
            pathname: "/admin/server/" + server.id
        });
    }

    goProjects() {
        this.props.history.push({
            pathname: "/admin/projects"
        });
    }

    goDep() {
        this.props.history.push({
          pathname: "/admin/deployments"
        });
    }

    goLb() {
        this.props.history.push({
          pathname: "/admin/loadbalancer"
        });
    }

    goModules() {
        this.props.history.push({
          pathname: "/admin/modules"
        });
    }

    goMinio() {
        this.props.history.push({
            pathname: "/admin/minio"
        });
    }

    renderServers() {
        return this.state.servers.map((row) => {
            var onlineMsg = (<></>);
            if (row.isOffLine)
                onlineMsg = (
                    <img src={OffLineImg} width={30}/>
                )
            return (
                <tr onClick={(e)=>this.selectServer(row)}>
                <td>{row.id}</td>
                <td>{row.name}</td>
                <td>{row.connectIp}</td>
                <td>{row.nbrContainers}</td>
                <td>{onlineMsg}</td>
                </tr>
            );
          });
    }


    render(){
        return(
            <>
            <h1>Servers</h1>
            <p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goDep()}
            >
              Deployments
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goLb()}
            >
              Loadbalancer
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goProjects()}
            >
              Projects
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goMinio()}
            >
              Minio
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goModules()}
            >
              Modules
            </Button>
            </p>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Server name</th>
                    <th>Remote IP</th>
                    <th>Nbr Containers</th>
                    <th>Last Ping</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderServers()}
                </tbody>
            </Table>
            </>
        )
    }
}

export default withRouter(ServersView);