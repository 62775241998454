import React from 'react'
import { Col, Card, CardHeader, CardBody } from "shards-react";

const ClientTable = props => (
    <Col>
        <Card small className="mb-4">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Liste des clients</h6>
          </CardHeader>
          <CardBody className="p-0 pb-3">
            <table className="table mb-0">
              <thead className="bg-light">
                <tr>
                  <th scope="col" className="border-0">
                  Prenom
                  </th>
                  <th scope="col" className="border-0">
                  Nom
                  </th>
                  <th scope="col" className="border-0">
                  Actions
                  </th>
                </tr>
              </thead>
              <tbody>
              {props.clients.length > 0 ? (
              props.clients.map(client => (
                <tr key={client.id}>
                  <td>{client.prenom}</td>
                  <td>{client.nom}</td>
                  <td>
                    <button
                        onClick={() => {
                        props.editRow(client)
                        }}
                        className="button muted-button"
                    >
                        Edit
                    </button>
                    <button
                        onClick={() => props.deleteClient(client.id)}
                        className="button muted-button"
                    >
                        Delete
                    </button>
                  </td>
                </tr>
                      ))
                      ) : (
                        <tr>
                          <td colSpan={3}>Aucun client</td>
                        </tr>
                      )}
                    </tbody>
            </table>
          </CardBody>
        </Card>
      </Col>
)

export default ClientTable