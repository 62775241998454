import React from "react";
import {
    Form,
    FormGroup,
    FormInput,
} from "shards-react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from "shards-react";

export class Address extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            street_adress: "",
            city: ""
        }
        if (typeof this.props.values !== "undefined") {
            //alert(JSON.stringify(this.props.values));
            this.state.street_adress = this.props.values.street_adress;
            this.state.city = this.props.values.city;            
        }
        this.dblClick = false;
    }

    handleChange(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.props.onUpdate(this.props.name, name, value);

        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    componentDidMount(){
    }

    render () {
        return (
            <>
            <Card>
                <CardHeader>{this.props.label}</CardHeader>
                <CardBody>
                    <Form>
                        <FormGroup>
                        <label>Adresse :</label>
                        <FormInput name="street_adress" value={this.state.street_adress} onChange={e => this.handleChange(e)} size="lg" placeholder="Adresse" />
                        </FormGroup>
                    </Form>

                    <Form>
                        <FormGroup>
                        <label>Ville</label>
                        <FormInput name="city" value={this.state.city} onChange={e => this.handleChange(e)} size="lg" placeholder="Ville" />
                        </FormGroup>
                    </Form>
                </CardBody>
                <CardFooter>{this.props.muted}</CardFooter>
            </Card>
            </>         
        )
    }
}

export default Address;
