import React from "react";
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Card from 'react-bootstrap/Card';
import { QuantityPicker } from 'react-qty-picker';
import { Form, Modal } from "react-bootstrap";
import {api} from '../../services/autonome';
//import './item.css';

export class ShopItem extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            item: props.item,
            quantity: 1,
            showBuy: false,
            address: props.address,
            onBuyingProcess: false
        }
        this.state.amountToPay = (parseFloat(this.state.item.salePrice)  * 1.1 + 0.25).toFixed(2)
    }

    setMontant() {
        var newAmount = (parseFloat(this.state.item.salePrice) * this.state.quantity * 1.1 + 0.25).toFixed(2);
        //console.log("New amount:", newAmount);
        this.setState({amountToPay: newAmount});
    }

    closeModal() {
        this.setState({showBuy: false});
    }

    componentDidMount() {
    }

    onClick() {
        this.setState({showBuy:!this.state.showBuy});
        window.gtag('event', 'open_buy_product', {
            productId: this.state.item.id
        });
    }

    onBuy() {
        this.setState({onBuyingProcess: true});
        api.call('post', "shop/buy/" + this.state.item.id, {quantity: this.state.quantity}).then(result => {
            if (!result.data.success) {
                return alert(result.data.message);
            }
            this.setState({onBuyingProcess: false,showBuy:false});
            window.gtag('event', 'purchase', {
                currency: "CAD",
                value: this.state.amountToPay,
                items: [
                    {
                        itemId:this.state.item.id,
                        quantity: this.state.quantity,
                        item_name: this.state.item.name
                    }
                ]
            });
            alert("Votre commande est passé");
        });
    }
/*{
    "id":2,
    "price":"25.00",
    "salePrice":"25.00",
    "discount":0,
    "shortDetails":"Boite android. C'Est pour rendre ta TV plus intelligente. Ca vient avec une belle manette",
    "description":"Boite android. C'Est pour rendre ta TV plus intelligente. Ca vient avec une belle manette",
    "stock":2,
    "new":false,
    "sale":true,
    "category":"Electronique",
    "pictures":["http://api.liberium.net/s/stor-img?fileName=2/X8PRO10.jpg"],
    "variants":[{"color":"","images":"http://api.liberium.net/s/stor-img?fileName=2/X8PRO10.jpg"}]
}*/
    render () {
        return (
            <>
            <Card style={{ width: '18rem', padding: '10px' }}>
                <Card.Img variant="top" src={this.state.item.pictures[0]} style={{margin: "10px 10px 10px 10px"}} onClick={(e)=>this.onClick()}/>
                <Card.Body>
                <Card.Title>{this.state.item.name}</Card.Title>
                <Card.Text>
                    <b>${this.state.item.salePrice}</b><br/><br/>

                    {this.state.item.shortDetails}
                </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <Button
                         variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<ShoppingCartIcon />}
                        onClick={() => this.onClick()}
                    >
                        Acheter maintenant
                    </Button>
                </Card.Footer>
             </Card>
            
                <Modal
                    size="lg"
                    show={this.state.showBuy}
                    onHide={() => this.closeModal()}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Etes-vous vous certain?
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <p>En cliquant ici, vous commander cet article. Ce montant sera déduit de votre compte Liberium. Si vous n'avez pas de fond, veuillez en <a href="/#/finance/deposit">déposer avant</a> de faire votre achat.</p>

                            <QuantityPicker value={this.state.quantity} min={1} onChange={(value)=>{ // here value is the final update value of the component
                            var these = this;
                                setTimeout( function (){
                                    these.setState({quantity: value});
                                    these.setMontant();
                                },10)
                            }}/>

                            <p>Montant: {this.state.amountToPay}$ (comprend les frais de transport)</p>

                            <p>Il vous sera livré à cette adresse: {this.state.address}</p>

                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<ShoppingCartIcon />}
                                disabled={this.state.onBuyingProcess}
                                onClick={() => this.onBuy()}
                            >
                                J'achète
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default withRouter(ShopItem);