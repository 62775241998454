import React from "react";
import { Container, Row } from "shards-react";
import Button from '@material-ui/core/Button';

class Apple extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state= {stats: {
    }}
  }

  connect() {
    this.props.history.push({
      pathname: "/login"
    });
  }

  signup() {
    this.props.history.push({
      pathname: "/signup"
    });
  }

  componentDidMount() {
    window.gtag('event', 'page_view', {
      page_title: 'Apple',
      page_location: '/apple',
      page_path: '/apple'
    });
  }

  componentWillUnmount() {
  }


  render() {
    var sty = {
        float: "none", 
        margin: "0 auto"
    }

    return (
        <Container fluid className="main-content-container px-4">
            <h1>App Store</h1>
            <p>
                Liberium n’est pas encore disponible sur l’App Store d’Apple. 
                Ces derniers ont des restrictions qui nous empêchent d’être sur l’App Store. 
                Nous faisons tout pour être disponibles pour les utilisateurs d’iPhone le plus rapidement possible. 
            </p>

            <p>
            Il est tout de même facile d’utiliser notre application via Safari.
            </p>

            <Row>
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => this.connect()}
              >
                  Connexion
              </Button>
              </Row>
              <br/>
              <Row>
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => this.signup()}
              >
                  Enregistrement
              </Button>
          </Row>
        </Container>
    );
  }
}

export default Apple;