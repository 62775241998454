import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router';
import CryptoModalExchange from "./exchange";
import { Form, Modal } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

class CryptoView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            showTrade: false,
            wallets: [
            ]
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "v2/cryptoExchanger/getBalance?exchanger=binance", {}).then(result => {            
            these.setState({wallets: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    handleChange(event) {
        console.log("Handle change", event.target);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    componentDidMount() {
        this.fetchData();
    }

    closeModal() {
        this.setState({showTrade: false});
        this.fetchData();
    }

    showTrade(row) {
        this.setState({amount: row.free, asset: row.asset, showTrade: true});
    }

    renderBalances() {
        return this.state.wallets.map((row) => {
            if (parseFloat(row.free) === 0) return;
            return (
                <tr>
                    <td>{row.asset}</td>
                    <td>{row.free}</td>
                    <td>n/a</td>
                    <td>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.showTrade(row)}
                        >
                            Trade
                        </Button>
                    </td>
                </tr>
            );
        });
    }

    render(){
        var showTrade = (<></>)
        if (this.state.showTrade)
            showTrade = (
                <Modal
                    size="lg"
                    show={this.state.showTrade}
                    onHide={() => this.closeModal()}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Create an order
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <CryptoModalExchange
                        asset={this.state.asset}
                        amount={this.state.amount}
                    />
                    </Modal.Body>
                </Modal>
                
            )   
        return(
            <>
            <h1>Balances</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Asset</th>
                    <th>Quantity</th>
                    <th>Worth in USD</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderBalances()}
                </tbody>
            </Table>
            {showTrade}
            </>
        )
    }
}

export default withRouter(CryptoView);