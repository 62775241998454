import React from "react";
import { Redirect } from "react-router-dom";
import { useAuth } from "../context/auth";
import {Store, Constants} from "../components/flux";


function LogoutView () {
    const { setAuthTokens } = useAuth();
    localStorage.setItem("tokens", null);
    setAuthTokens(null);
    Store.triggerAction({actionType: Constants.USER_TOKEN, payload: null});
    console.log("Executing logout");

    return <Redirect to="/login" push="true"/>;
}

export default LogoutView;