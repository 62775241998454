import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from "shards-react";
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from '../../services/autonome';

export class IsItLogged extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            jobId: this.props.jobId,
            job: this.props.job,
            image: "",
            errorMsg: null,
            onFinish: this.props.onFinish,
            onClicked: false
        }
        var metas = JSON.parse(this.props.job.metas);
        this.state.image = metas.image;
    }

    onDone(isItLogged) {
        console.log("Ok send it to api");
        this.setState({onClicked: true});
        var these = this;
        var data = {
            taskId: this.state.jobId,
            logged: isItLogged
        }
        api.call('post', "virt_job/isItlogged", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            this.state.onFinish();
        });
    }

    componentDidMount(){
    }

    render () {
        return (
            <>
            <Card>
                <CardHeader>Est-il logger?</CardHeader>
                <CardBody>
                    <p>Est-ce que ce facebook semble être bien connecté?</p>
                    <img src={this.state.image}/>
                </CardBody>
                <CardFooter>
                <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<NewReleasesSharpIcon />}
                            onClick={() => this.onDone(true)}
                            disabled = {this.state.onClicked}
                        >
                            Oui
                        </Button>

                <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<NewReleasesSharpIcon />}
                            onClick={() => this.onDone(false)}
                            disabled = {this.state.onClicked}
                        >
                            Non
                        </Button>
                </CardFooter>
            </Card>
            </>         
        )
    }
}

export default IsItLogged;
