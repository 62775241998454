import React from "react";
import {api} from '../services/autonome';
import { Container } from "shards-react";
import Ad from '../components/ads/AdItem';
import LibCard from '../components/LibCard';
import Cagnotte from '../components/cagnotte';
import Concours from '../components/concours';
import ProfileNotUpdated from '../components/users/profile_not_updated';
import IsAshopNear from '../components/shop/isAshopNear';

class HomeOverview extends React.Component {
  state = {
    screens: [],
    posts: [],
    validate: true,
    profile: {phonenumber:"999999999"}
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  fetchData() {
    var isValid = true;
    var count = 0;
    api.call('get', "users/profile", {}).then(result => {
      count++;
        if (typeof result.data.error !== "undefined") return;
        this.setState({
            profile: result.data
        });
        if (result.data.phonenumber.length < 5)
          isValid = false;

        this.setState({validate: isValid});
    });
    /*
    api.call('get', "wp/recentPosts", {}).then(result => {
      console.log("Posts:",result.data);
      this.setState({posts: result.data});
    });
    */
  }

  componentDidMount() {
    this.fetchData();
    window.gtag('event', 'page_view', {
          page_title: 'Member home',
          page_location: '/home',
          page_path: '/home'
    })
  }

  componentWillUnmount() {
  }

  renderPosts() {
    return this.state.posts.map((post) => {
      return (
        <li><a href={post.link}>{post.title.rendered}</a></li>
      )
    })
  }

  render() {
    var more_content;
    if (this.state.profile.phonenumber.length < 5) {
      more_content = (
        <ProfileNotUpdated>
        </ProfileNotUpdated>
      )
    }
    return (
      <Container fluid className="main-content-container px-4">
        <Ad 
          pos={0}
          size={"300x250"}
          page="member_home"
        />
        {more_content}
        <IsAshopNear/>
        <Cagnotte/>
        <Concours/>
        <LibCard
        imgUrl="https://api.liberium.net/s/liberium-public-img/handshake.png"
        title="Vous chercher une personne pour vous aider"
        description="Donner de l'ouvrage à une personne en échange d'argent"
        buttonTxt="Je veux engager"
        path="/services"
        >
        </LibCard>

        <LibCard
        imgUrl="https://api.liberium.net/s/liberium-public-img/352593313_785468153172590_5045944043450060822_n.png"
        title="Appliquer sur du travaille à effectuer"
        description="Vous êtes payé pour accomplir les tâches de nos clients."
        buttonTxt="Je veux être payé"
        path="/work"
        >
        </LibCard>

        <LibCard
        imgUrl="https://api.liberium.net/s/liberium-public-img/351446239_1538180707011947_1497690432658190570_n.png"
        title="Nous recrutons nos premiers travailleurs"
        description="Choisissez vos catégories pour lesquels vous aimeriez être engagés"
        buttonTxt="Inscription"
        path="/inscription"
        >
        </LibCard>

        <LibCard
        imgUrl="https://api.liberium.net/s/liberium-public-img/impot_personnel.jpeg"
        title="Impôt personnel"
        description="On fait votre impôt directement sur Liberium"
        buttonTxt="Impôt"
        path="/impot"
        >
        </LibCard>
        
      </Container>
    );
  }
}

export default HomeOverview;