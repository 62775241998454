import React from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from "shards-react";
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from '../../services/autonome';

export class IsQuebecGroup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            jobId: this.props.jobId,
            job: this.props.job,
            onFinish: this.props.onFinish,
            metas: JSON.parse(this.props.job.metas),
            onClicked: false,
            isQuebec: "",
            cityName: "",
            validate: false,
            isVente: "false",
            isAnimal: "false",
            isSpecialise: "false",
            cities: []
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleCity = this.handleCity.bind(this);
    }

    handleChange(event) {
        console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        console.log("newform:", new_form);
        console.log(value);
        this.setState(new_form);
        this.validateForm();
    }

    onDone() {
        var valid = this.validateForm();
        if (!valid) return;

        this.setState({onClicked: true});

        console.log("Ok send it to api");
        var these = this;
        var data = {
            taskId: this.state.jobId,
            isQuebec: this.state.isQuebec,
            isAnimal: this.state.isAnimal,
            isVente: this.state.isVente,
            isSpecialise: this.state.isSpecialise,
            cityName: this.state.cityName,
            cityId: this.state.cityId
        }
        //return console.log("toSend:", data);
        api.call('post', "virt_job/is_fb_group_quebec", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            this.state.onFinish();
        });
    }

    validateForm() {
        var errorMsg = null;
        if (this.state.isQuebec==="")
            errorMsg = "Choisissez oui ou non"
        if (this.state.isQuebec==="true" && this.state.cityName.length < 3)
            errorMsg = "Le nom de la ville doit être écrit"
        
        if (errorMsg) {
            this.setState({errorMsg: errorMsg, validate: false});
            return false;
        } else {
            this.setState({errorMsg: "", validate: true});
        }
        return true;
    }

    componentDidMount() {
        // Ca prend les villes du Québec, question que ça soit bien écrit
        var self = this;
        api.call('get', "geo/cities?country=Canada&state=Quebec").then(result => {
            self.setState({cities: result.data});
        });
    }

    handleCity(e) {
        var self = this;
        var found = null;
        if (e.target.value==="") {
            return this.setState({cityId: null});
        }
        this.state.cities.forEach(function(one){
            //console.log(one.id, "===", e.target.value)
            if (one.id === parseInt(e.target.value))
                found = one;
        })
        console.log("ville trouvé:", found);
        if (found===null) {
            return this.setState({cityId: null});
        }
        this.setState({cityName: found.name, cityId: found.id});
        setTimeout(function(){
            self.validateForm();
        },150);
    }

    render () {
        var forName = (<></>);
        var cities = this.state.cities.map((row) => {
            return (
                <option value={row.id}>{row.name}</option>
            )
        });
        if (this.state.isQuebec==="true") {
            forName = (
                <>
                    <br/>
                    <FormControl>
                        <InputLabel htmlFor="age-native-simple">Quelle ville</InputLabel>
                        <Select
                        native
                        value={this.state.cityId}
                        onChange={(e)=>this.handleCity(e)}
                        >
                            <option value={0}></option>
                        {cities}
                        </Select>
                    </FormControl>
                    <br/>
                    <TextField 
                        id="standard-basic" 
                        label="Nom de la ville"
                        name="cityName"
                        value={this.state.cityName}
                        onChange={(e)=>this.handleChange(e)}
                    />
                    
                </>
            )
        }
        return (
            <>
            <Card>
                <CardHeader>Est-ce un groupe pour le Québec?</CardHeader>
                <CardBody>
                    <p>Est-ce que ce groupe facebook est pour des utilisateurs du québec? Ca prend le nom d'une ville québéecoise dans le titre bien souvent.</p>

                    {this.state.errorMsg && <div className="alert alert-danger">{this.state.errorMsg}</div>}

                    <p>
                        <a href={"https://facebook.com/" + this.state.metas.id} target="_blank">{this.state.metas.name}</a>
                    </p>

                    <FormControl component="fieldset">
                        <FormLabel component="legend">Est-ce un groupe pour le Québec</FormLabel>
                        <RadioGroup aria-label="isQuebec" name="isQuebec" value={this.state.isQuebec} onChange={(e)=>this.handleChange(e)}>
                            <FormControlLabel value={"true"} control={<Radio />} label="Oui" />
                            <FormControlLabel value={"false"} control={<Radio />} label="Non" />                            
                        </RadioGroup>
                    </FormControl>
                    <br/>
                    {this.state.isQuebec ==="true" &&
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Est-ce un groupe pour des animaux</FormLabel>
                        <RadioGroup aria-label="isAnimal" name="isAnimal" value={this.state.isAnimal} onChange={(e)=>this.handleChange(e)}>
                            <FormControlLabel value={"true"} control={<Radio />} label="Oui" />
                            <FormControlLabel value={"false"} control={<Radio />} label="Non" />                            
                        </RadioGroup>
                    </FormControl>
                    }
                    <br/>
                    {this.state.isQuebec ==="true" &&
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Est-ce un groupe de vente</FormLabel>
                        <RadioGroup aria-label="isVente" name="isVente" value={this.state.isVente} onChange={(e)=>this.handleChange(e)}>
                            <FormControlLabel value={"true"} control={<Radio />} label="Oui" />
                            <FormControlLabel value={"false"} control={<Radio />} label="Non" />                            
                        </RadioGroup>
                    </FormControl>
                    }
                    <br/>
                    {this.state.isQuebec ==="true" &&
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Est-ce un groupe spécialisé</FormLabel>
                        <RadioGroup aria-label="isSpecialise" name="isSpecialise" value={this.state.isSpecialise} onChange={(e)=>this.handleChange(e)}>
                            <FormControlLabel value={"true"} control={<Radio />} label="Oui" />
                            <FormControlLabel value={"false"} control={<Radio />} label="Non" />                            
                        </RadioGroup>
                    </FormControl>
                    }

                    {forName}

                    {this.state.errorMsg && <div className="alert alert-danger">{this.state.errorMsg}</div>}
                </CardBody>
                <CardFooter>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<NewReleasesSharpIcon />}
                        onClick={() => this.onDone()}
                        disabled = {!this.state.validate || this.state.onClicked}
                    >
                        Envoyer
                    </Button>
                </CardFooter>
            </Card>
            </>         
        )
    }
}

export default IsQuebecGroup;
