import React from 'react';
import {MyTasksOverview} from '../../components/liberium/my_tasks_overview'

export class MyWorkView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
        }
    }

    componentDidMount(){
    }

    render(){
        return(
          <MyTasksOverview>
          </MyTasksOverview>
        )
    }
}