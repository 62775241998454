import {api} from '../../services/autonome';
import React from "react";
import { Container } from "shards-react";
import { Form, Alert, Modal } from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Table from 'react-bootstrap/Table';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Chart } from "react-google-charts";

const adsSizes = [
    "300x250",
    "728x90",
    "120x600",
    "160x600"
];

export class ZonesForm extends React.Component {
  state = {
    show: false,
    showZone: "",
    size: adsSizes[0],
    zones: [],
    showHtml: "",
    errorMsg : "",
    openNewZone: false,
    showStat: false
  }

  constructor(props) {
    super(props);
    this.handleSize = this.handleSize.bind(this);
  }

  fetchData() {
    var these = this;
    api.call('get', "ads/get_zones", {}).then(result => {
      var the_ads = [];
      result.data.forEach(function(one) {
        var parsed = JSON.parse(one.metas);
        if (typeof parsed.kindAd === "undefined")
          parsed.kindAd = 1;
        one.metas = parsed;
        the_ads.push(one);
      })
      //console.log("The ads:", the_ads);
      these.setState({zones: the_ads});
    });
  }

  addZone() {
    var these = this;
    api.call('post', "ads/new_zone", this.state).then(result => {
      these.setState({openNewZone:false});
      these.fetchData();
    });
  }

  componentDidMount() {
    api.call('post', "user/usrLog", {module: "views", title:"ads"});
    this.fetchData();
    window.gtag('event', 'page_view', {
      page_title: 'Ads',
      page_location: '/ads',
      page_path: '/ads'
    })
  }

  componentWillUnmount() {
  }

  closeModal() {
    this.setState({openNewZone: false});
  }

  closeModalStat() {
    this.setState({showStat: false});
  }

  getHtml(zoneId, size) {
    return "<script src='https://api.liberium.net/adserver/showAd?zoneId=" + zoneId + "&size=" + size +"'></script>";
  }

  showZone(zoneId) {
    var showHtml = this.getHtml(zoneId, this.state.size);
    this.setState({show:true, showZone: zoneId, showHtml: showHtml});
    window.gtag('event', 'show_zone', {
        zoneId: zoneId,
    });
  }

  handleChange(event) {
    console.log("Handle change", event);
    const target = event.target;
    console.log(target);
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
  }

  showState(zone) {
    //alert(JSON.stringify(zone));
    // zone = {id:1, zone: "jukenet"}
    var these = this;
    api.call('post', "ads/zone/stats", {zone: zone.zone}).then(result => {
      these.setState({showStat: true, stats:result.data});      
    });
  }

  renderZones() {
    var zones = this.state.zones.map((row) => {
        var zone = (<></>);
        if (row.metas.kindAd === 1)
          zone = (
            <>
              Url: <a href={row.url} target="_blank">{row.url}</a>
            </>
          )
        else if (row.metas.kindAd === 2) {
          var banner = row.metas.banners[0];
          if (typeof banner !== "undefined")
            zone = (
              <div>
                <img src={banner.url} width={200}/><br/>
                Url: <a href={row.url} target="_blank">{row.url}</a>
              </div>
            )
        }
        else if (row.metas.kindAd === 3) {
          if (typeof row.metas.html !== "undefined")
            zone = (
              <div dangerouslySetInnerHTML={{ __html: row.metas.html }} />
            )
        }
        else if (typeof row.metas.image !== "undefined")
            zone = (
                <>
                <p>{row.metas.title}</p>
                <img src={row.metas.image} width={250}/>
                </>
            )
        return (
            <tr onClick={(e)=>this.showZone(row.zone)}>
            <td>{row.zone}</td>
            <td>{zone}</td>
            <td><center>{row.impressions}</center></td>
            <td><center>{row.clicks}</center></td>
            <td>
              <a onClick={(e)=>this.showState(row)}>Stats</a>
            </td>
            </tr>
        );
      });
    return (
        <>
            <h1>Vos zones</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Zone</th>
                    <th><center>Impressions</center></th>
                    <th><center>Clicks</center></th>
                    <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {zones}
                </tbody>
            </Table>
        </>
    )
  }

  handleSize(e) {
    console.log("handing change", e.target.value);
    var showHtml = this.getHtml(this.state.showZone, e.target.value);
    console.log(showHtml);
    this.setState({size: e.target.value, showHtml: showHtml});
  }

  handleAd() {
    this.props.history.push({
        pathname: "/ads"
    });
  }

  handlePixels() {
    this.props.history.push({
      pathname: "/pixels"
    });
  }

  handleNewZone() {
    this.setState({openNewZone: true});
  }

  render() {
    var errorMsg;
    if (this.state.errorMsg !== null)
        errorMsg = (
            <Alert variant="danger">
                {this.state.errorMsg}
            </Alert>
        );

    var data = [];

    if (this.state.showStat) {
      data = [
            [
              "Day",
              "Impressions",
              "Clicks"
            ]
      ];

      console.log("-=-=-=-=-=-");
      console.log(this.state.stats);
      console.log("-=-=-=-=-=-");
      var i = 0;
      this.state.stats.forEach(function(one){
        i++;
        data.push([one.day, one.impressions, one.clicks]);
        //data.push(one.day, one.nbrImpressions);
      });
    }

    var options = adsSizes.map((row) => {
        return (
            <option value={row}>{row}</option>
        )
    });

    var options = {
      chart: {
        title: "Statistique d'une zone"
      },
    };

    return (
      <Container fluid className="main-content-container px-4">

        <Breadcrumb>
            <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>
            <Breadcrumb.Item active>Annonces</Breadcrumb.Item>
         </Breadcrumb>

        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
          <Button 
            startIcon={<AddIcon />}
            onClick={e => this.handleNewZone()}
          >
            Ajouter zone
          </Button>
          <Button
            startIcon={<AnnouncementIcon />}
            onClick={e => this.handleAd()}
          >
            Annonces
          </Button>
          <Button          
            startIcon={<EmojiPeopleIcon />}
            onClick={e => this.handlePixels()}
          >
            Pixels
          </Button>
        </ButtonGroup>

        {this.renderZones()}

        <Modal
          size="lg"
          show={this.state.openNewZone}
          onHide={() => this.closeModal()}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Code HTML
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="texte" key="texte">
              <Form.Label>Nom de la zone</Form.Label>
              <Form.Control
                  type="text"
                  name='zoneName'
                  value={this.state.zoneName} 
                  onChange={e => this.handleChange(e)}
              >
              </Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="texte" key="texte">
              <Form.Label>URL de votre site/page</Form.Label>
              <Form.Control
                  type="text"
                  name='url'
                  value={this.state.url} 
                  onChange={e => this.handleChange(e)}
              >
              </Form.Control>
              </Form.Group>
    
              <Button variant="primary" onClick={(e)=>this.addZone()}>Ajouter</Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={this.state.showStat}
          onHide={() => this.closeModalStat()}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Statistiques
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Chart
            chartType="Line"
            width="100%"
            height="400px"
            data={data}
            options={options}
          />
          </Modal.Body>
        </Modal>
    </Container>
    );
  }
}