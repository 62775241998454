import React, { createContext } from 'react';
import axios from 'axios';
import { API_ROOT } from '../api-config';
import { Store, Constants } from '../components/flux';

const UserContext = createContext({
  user: {username: ""},
  updateUser: () => {},
});

export class UserProvider extends React.Component {
  state = {
    user: {username:'user'}
  };

  constructor(props) {
    super(props);
    
    var existingTokens;
    try{
      existingTokens = JSON.parse(localStorage.getItem('tokens'));
      console.log(existingTokens);
    } catch(e) {
      console.log("CATCH QQCH")
      return;
    }
    if (existingTokens) {
      axios.get(API_ROOT + "me", { headers: { Authorization: `${existingTokens.token}` } }).then(result => {
        if (result.status === 200) {     
          localStorage.setItem('user', JSON.stringify(result.data));
          Store.triggerAction({actionType: Constants.USER_TOKEN, payload: result.data});
        } else {
        }
      }).catch(e => {
      });  
    }
  }

  componentDidMount() {
  }

  updateUser = newUser => {
    this.setState({ user: newUser });
    Store.triggerAction({actionType: Constants.USER_TOKEN, payload: newUser});
  };

  render() {
    return (
      <UserContext.Provider user={this.state.user}>
          {this.props.children}
      </UserContext.Provider>
    );
  }
}