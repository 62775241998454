import React from 'react';
import {api} from '../../services/autonome';

import Breadcrumb from 'react-bootstrap/Breadcrumb'
import {DiscussionView} from "../chat/discussion";

export class AdminSupportView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            supports: [],
            user: null
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "admin/chat/supports", {}).then(result => {
            these.setState({supports: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();

        window.gtag('event', 'page_view', {
            page_title: 'Support',
            page_location: '/admin/support',
            page_path: '/admin/support'
        });
    }

    renderSupport() {
        return this.state.supports.map((client) => {
            return (
                <div onClick={() => this.selectUser(client)}>
                    {client.userName}
                </div>
            )
        });
    }

    selectUser(user) {
        this.setState({user: user});
    }

    renderDiscussion() {
        if (this.state.user === null) return (<></>);
        return (
            <DiscussionView
                withUserId={this.state.user.userId}
                myUserId={0}
            >
            </DiscussionView>
        )
    }

    render(){
        return(
            <div>
                <Breadcrumb>
                  <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>
                  <Breadcrumb.Item href="#/admin">Admin</Breadcrumb.Item>
                  <Breadcrumb.Item active>Admin support</Breadcrumb.Item>
                </Breadcrumb>

                {this.renderSupport()}
                {this.renderDiscussion()}
            </div>
        )
    }
}