import {api} from '../../services/autonome';
import React from "react";
import { Container } from "shards-react";
import { Form, Alert, Modal } from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import EditIcon from '@material-ui/icons/Edit';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Address from '../../components/liberium/address';
import DragAndDrop from '../../components/filedragdrop';
import { Table } from "react-bootstrap";
import axios from 'axios';

export class NewAdView extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.updateAddress = this.updateAddress.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.state = {
      me: null,
      url: "",
      kindAd: null,
      siteDatas: null,
      address: {},
      cpm: 5,
      budget: 10,
      errorMsg : "",
      files: [],
      targetZones: [],
      targets: [],
      zones: [],
      page: "ad"
    }
  }

  selectImmobilier() {
    this.setState({kindAd: 10});
  }

  selectHtml() {
    this.setState({kindAd: 3});
  }

  selectFile() {
    this.setState({kindAd: 2});
  }

  goFbAd() {
    this.props.history.push({
      pathname: "/new_fb_ad"
  });
  }

  fetchData() {
  }

  getZones() {
    var these = this;
    api.call('get', "ads/get_zones", {}).then(result => {
      var the_zones = [];
      result.data.forEach(function(one) {
        var parsed = JSON.parse(one.metas);
        if (typeof parsed.kindAd === "undefined")
          parsed.kindAd = 1;
        one.metas = parsed;
        the_zones.push(one);
      })
      these.setState({zones: the_zones});
    });
  }

  validateForm() {
    if (this.state.kindAd === 10) {
      console.log("street:", this.state.address.street_adress)
      if (typeof this.state.address.street_adress === "undefined" || this.state.address.street_adress.length == 0) {
        this.setState({errorMsg: "L'adresse doit être remplis"});
        return false;
      }
      if (typeof this.state.address.city === "undefined" || this.state.address.city.length == 0) {
        this.setState({errorMsg: "Le nom de la ville doit être remplis"});
        return false;
      }
      if (parseFloat(this.state.cpm) <= 0) {
        this.setState({errorMsg: "Le CPM doit être suppérieur a 0"});
        return false;
      }
    }
    return true;
  }

  handleChange(event) {
    console.log("Handle change", event);
    const target = event.target;
    console.log(target);
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
  }

  updateAddress(name, field, value) {
    var new_form = this.state.address;
    new_form[field] = value;
    this.setState({address:new_form});
  }

  componentDidMount() {
    api.call('post', "user/usrLog", {module: "views", title:"new_ad"});
    var me = JSON.parse(localStorage.getItem('user'));
    this.setState({me: me });
    this.fetchData();
    window.gtag('event', 'page_view', {
      page_title: 'New ad',
      page_location: '/new_ad',
      page_path: '/new_ad'
    })
  }

  componentWillUnmount() {
  }

  addImmobilier() {
    console.log("Address:",this.state.address);
    if (this.validateForm()) {
      var new_ad = {
        url: this.state.url,
        kindAd: this.state.kindAd,
        image: this.state.siteDatas.image,
        address: this.state.address,
        title: this.state.siteDatas.title,
        cpm: this.state.cpm,
        budget: this.state.budget
      }
      api.call('post', "ads/new_ad", new_ad).then(result => {
        alert("Created");
      });
    }
  }

  addBanner() {
    var new_ad = {
      kindAd: this.state.kindAd,
      banners: this.state.files,
      cpm: this.state.cpm,
      url: this.state.url
    }
    api.call('post', "ads/new_ad", new_ad).then(result => {
      alert("Created");
    });
  }

  addHtml() {
    var new_ad = {
      kindAd: this.state.kindAd,
      html: this.state.html,
      cpm: this.state.cpm,
      size: this.state.size
    }
    api.call('post', "ads/new_ad", new_ad).then(result => {
      alert("Created");
    });
  }

  showTargets() {
    this.getZones();
    this.setState({page:"targets"})
  }

  renderZones() {
    var zones = this.state.zones.map((row) => {
      var zone = (<></>);
      if (row.metas.kindAd === 1)
        zone = (
          <>
            Url: <a href={row.url} target="_blank">{row.url}</a>
          </>
        )
      else if (row.metas.kindAd === 2) {
        var banner = row.metas.banners[0];
        if (typeof banner !== "undefined")
          zone = (
            <div>
              <img src={banner.url} width={200}/><br/>
              Url: <a href={row.url} target="_blank">{row.url}</a>
            </div>
          )
      }
      else if (row.metas.kindAd === 3) {
        if (typeof row.metas.html !== "undefined")
          zone = (
            <div dangerouslySetInnerHTML={{ __html: row.metas.html }} />
          )
      }
      else if (typeof row.metas.image !== "undefined")
          zone = (
              <>
              <p>{row.metas.title}</p>
              <img src={row.metas.image} width={250}/>
              </>
          )
      return (
          <tr>
          <td>{row.zone}</td>
          <td>{zone}</td>
          <td><center>{row.impressions}</center></td>
          <td><center>{row.clicks}</center></td>
          <td>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => this.addZone(row.zone)}
            >
              Ajouter
            </Button>
          </td>
          </tr>
      );
    });
    return (
      <Table striped bordered hover id="listZones">
        <thead>
          <tr>
            <th>#</th>
            <th>Zone</th>
            <th><center>Impressions</center></th>
            <th><center>Clicks</center></th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {zones}
        </tbody>
      </Table>
    )
  }

  renderTargets() {
    return (
      <>
        <h1>Ciblage</h1>
        {this.renderZones()}
      </>
    )
  }

  askUrl(e) {
    e.preventDefault();
    api.call('get', "ads/getFromUrl?url=" + this.state.url, {}).then(result => {
        this.setState({
            siteDatas: result.data
        });
        /*
        {
            title: 'Appartement - Condominium à vendre - Mascouche (Ville)',
            description: 'Appartement - Condominium à vendre - Mascouche (Ville) - 325 Place de Villandry  - 9482179',
            url: 'https://www.remax-quebec.com/fr/proprietes/condo-a-vendre/325-place-de-villandry-mascouche-ville-9482179',
            site_name: 'RE/MAX Québec',
            image: 'https://media.remax-quebec.com/img/www_medium/0135/m9482179-pri01-01.jpg?v=2023-05-08T16%3A48%3A20.000000Z',
            icon: '/favicon.ico',
            keywords: undefined
          }
          */
    });
  }

  renderHtml() {
    return (
      <div id="renderHtml">
        <h2>Code HTML</h2>
                  <Form.Group className="mb-3" controlId="texte" key="texte">
                    <Form.Label>Code HTML</Form.Label>
                    <Form.Control as="textarea" 
                      rows={3} 
                      name='html'
                      value={this.state.html} 
                      onChange={e => this.handleChange(e)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="texte" key="texte">
                    <Form.Label>Ad size (ex: "300x250")</Form.Label>
                    <Form.Control
                        type="text"
                        name='size'
                        value={this.state.size} 
                        onChange={e => this.handleChange(e)}
                    >
                    </Form.Control>
                </Form.Group>

                  <Form.Group className="mb-3" controlId="texte" key="texte">
                    <Form.Label>Coût par mille affichage</Form.Label>
                    <Form.Control
                        type="text"
                        name='cpm'
                        value={this.state.cpm} 
                        onChange={e => this.handleChange(e)}
                    >
                    </Form.Control>
                </Form.Group>
                
        <Button variant="primary" onClick={(e)=>this.addHtml()}>Ajouter votre annonce</Button>         
      </div>
    )
  }

  deleteFile(url) {
    var values = this.state.files;
    values = values.filter(e => e.url !== url);
    this.setState({files: values});
  }

  handleDrop = (name, files) => {
    var self = this;
    for (var i = 0; i < files.length; i++) {
      if (!files[i].name) return;
        const data = new FormData();
        data.append('file', files[i]);
        this.setState({
            uploadMsg: "Upload en cours"
        });
        axios
        .post("https://api.liberium.net/ads/new_image", data)
        //.post("http://localhost:9090/ads/new_image", data)
        .then(function (response) {
            console.log(response);
            var files = self.state.files;
            files.push({url:response.data.url, size: response.data.size});
            self.setState({files:files});
        })
    }
  }

  renderFile() {
    var these = this;
    var files = this.state.files.map((file) => {
      return (
        <tr key={file}>
            <td component="th" scope="row">
                <img src={file.url} width={100}/><br/>
                {file.size}
            </td>
            <td align="right">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => these.deleteFile(file.url)}
                >
                    Delete
                </Button>
            </td>
        </tr>
      )
    });
    return (
      <>
        <h2>Publicité Graphique</h2>
          <Form.Group className="mb-3" controlId="texte" key="texte">
            <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                name='url'
                value={this.state.url} 
                onChange={e => this.handleChange(e)}
              >
              </Form.Control>
          </Form.Group>

          <DragAndDrop name="graphs" handleDrop={this.handleDrop}>
            <h2>Déplacer des fichiers ici</h2>
            <Table aria-label="customized table">
              <thead>
                <tr>
                  <td>Photo</td>
                  <td align="right">Action</td>
                </tr>
              </thead>
              <tbody>
                {files}
              </tbody>
            </Table>
          </DragAndDrop>

                  <Form.Group className="mb-3" controlId="texte" key="texte">
                    <Form.Label>Coût par mille affichage</Form.Label>
                    <Form.Control
                        type="text"
                        name='cpm'
                        value={this.state.cpm} 
                        onChange={e => this.handleChange(e)}
                    >
                    </Form.Control>
                </Form.Group>
                
        <Button variant="primary" onClick={(e)=>this.addBanner()}>Ajouter votre annonce</Button>
      </>
    )
  }

  renderImmobillier() {
    var site = (<></>);
    if (this.state.siteDatas !== null)
        site = (
            <>
                <h2>Votre page</h2>
                <p>{this.state.siteDatas.title}</p>
                <img src={this.state.siteDatas.image} height={120}/>
                <Address
                  label="Addresse de l'immeuble"
                  muted=""
                  name="address"
                  values={this.state.adresse} 
                  onUpdate={this.updateAddress}
                />

                <h2>Budget</h2>
                <Form.Group className="mb-3" controlId="texte" key="texte">
                    <Form.Label>Coût par mille affichage</Form.Label>
                    <Form.Control
                        type="text"
                        name='cpm'
                        value={this.state.cpm} 
                        onChange={e => this.handleChange(e)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group className="mb-3" controlId="texte" key="texte">
                    <Form.Label>Maximum par jour</Form.Label>
                    <Form.Control
                        type="text"
                        name='budget'
                        value={this.state.budget} 
                        onChange={e => this.handleChange(e)}
                    >
                    </Form.Control>
                </Form.Group>

                <Button variant="primary" onClick={(e)=>this.addImmobilier()}>Ajouter votre annonce</Button>
            </>
        )
    var errorMsg;
    if (this.state.errorMsg.length > 0)
        errorMsg = (
            <div class="alert alert-danger mb-1" role="alert">
                {this.state.errorMsg}
            </div>
        )
    return (
        <>
            <h2>Immobilier</h2>
            {errorMsg}
            <Form.Group className="mb-3" controlId="texte" key="texte">
                <Form.Label>Adresse web de l'immeuble</Form.Label>
                <Form.Control
                    type="text"
                    name='url'
                    value={this.state.url} 
                    onChange={e => this.handleChange(e)}
                >
                </Form.Control>
            </Form.Group>
            
            <button type="submit" class="btn btn-primary" onClick={(e) => this.askUrl(e)}>Submit</button>

            <hr/>
            {site}
            {errorMsg}
        </>
    )
  }

  renderNewAd() {
    if (this.state.page === "targets")
      return this.renderTargets();

    if (this.state.kindAd === 2)
        return this.renderFile();
    if (this.state.kindAd === 3)
        return this.renderHtml();
    if (this.state.kindAd === 10)
        return this.renderImmobillier();
    var cardHtml;
    if (this.state.me !== null && this.state.me.id === 1)
      cardHtml = (
        <Card style={{ width: '18rem' }}>
          <Card.Body>
              <Card.Title>Code HTML</Card.Title>
              <Card.Text>
              Vous voulez afficher un autre réseau tel que AdSense.
              </Card.Text>
              <Button variant="primary" onClick={(e)=>this.selectHtml()}>Annoncer</Button>
          </Card.Body>
        </Card>
      )
      var cardFacebook;
      if (this.state.me !== null && this.state.me.id === 1)
      cardFacebook = (
          <Card style={{ width: '18rem' }}>
            <Card.Body>
                <Card.Title>Annonce FaceBook</Card.Title>
                <Card.Text>
                Vous voulez envoyer un message dans des groupes Facebook
                </Card.Text>
                <Button variant="primary" onClick={(e)=>this.goFbAd()}>Annoncer</Button>
            </Card.Body>
          </Card>
        )
    return (
      <>
        <Card style={{ width: '18rem' }}>
            <Card.Body>
                <Card.Title>Immobilier</Card.Title>
                <Card.Text>
                Vous êtes courtier et vous voulez afficher un immeuble sur le marché.
                </Card.Text>
                <Button variant="primary" onClick={(e)=>this.selectImmobilier()}>Annoncer</Button>
            </Card.Body>
        </Card>
        {cardHtml}
        {cardFacebook}
        <Card style={{ width: '18rem' }}>
          <Card.Body>
              <Card.Title>Graphique</Card.Title>
              <Card.Text>
                Envoyer vos fichiers
              </Card.Text>
              <Button variant="primary" onClick={(e)=>this.selectFile()}>Annoncer</Button>
          </Card.Body>
        </Card>
      </>
    )
  }

  render() {
    var errorMsg;
    if (this.state.errorMsg !== null)
        errorMsg = (
            <Alert variant="danger">
                {this.state.errorMsg}
            </Alert>
        );

    return (
      <Container fluid className="main-content-container px-4">

        <Breadcrumb>
            <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>
            <Breadcrumb.Item active>Nouvelle annonce</Breadcrumb.Item>
        </Breadcrumb>

        {this.renderNewAd()}
    </Container>
    );
  }
}