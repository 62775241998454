import React from "react";
import { Card, CardBody } from "shards-react";
import {api} from '../../services/autonome';
import { withRouter } from 'react-router'; 

export class Wallet extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            //history: props.history,
            loaded: false,
            wallets: [],
            total: "n/a",
        }
    }

    onClick = () => {
        this.props.history.push({
            pathname: "/wallet"
        });
    }

    fetchData() {
        api.call('get', "finance/cad_wallet", {}).then(result => {
            this.setState({loaded:true});

            console.log("Wallet overview:", result);
            if (typeof result.data.error != "undefined") return;
            this.setState({
                wallets: result.data
            });

            var total = 0;
            result.data.forEach(function(res) {
                total = total + parseFloat(res.quantity);
            });

            this.setState({total: total});
        });
    }

    componentDidMount(){
        api.call('post', "user/usrLog", {module: "views", title:"wallet"});
        this.fetchData();
    }

    render () {
        return (
            <div class="balance">
                <div class="left">
                    <span class="title">Dollar Canadien</span>
                    <h1 class="total">{parseFloat(this.state.total).toFixed(2)}$</h1>
                </div>
            </div>
        )
    }
}

export default withRouter(Wallet);