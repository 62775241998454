import React from 'react';
import {api} from '../../services/autonome';
import { Table } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import TaskOverview from '../../components/liberium/task_overview';
import ProfileNotUpdated from '../../components/users/profile_not_updated';
import NoInscription from '../../components/users/no_inscription';
import AdForUser from "../../components/ads/AdForUser";
import Ad from '../../components/ads/AdItem';

export class WorkView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            jobs: [],
            loaded: false,
            tasks: [],
            myWorks: [],
            reviews: [],
            newTaskEnabled: false,
            ratings: {},
            validate: true,
            filter: false,
            inscription: true
        }
        this.realReviews = {}
        this.state.userId = this.getUserId();
        this.getNewWork = this.getNewWork.bind(this)
        this.getTask = this.getTask.bind(this)
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    goMyWork = () => {
            this.props.history.push({
                pathname: "/myWork"
            });
    };

    handleChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;

      var new_form = this.state;
      new_form[name] = value;
      this.setState(new_form);
      this.setState({errorMsg: null});

      if (name==="filter")
        this.getNewWork();
  }

    createTask = () => {
      this.setState({newTaskEnabled: true});
    }

    getToBeReviewed() {
      this.setState({});
      var these = this;
      api.call('get', "tasks/toBeReview", {}).then(result => {     
        if (typeof result.data.error != "undefined") return;
        this.setState({
            reviews: result.data
        });
        result.data.forEach( function (one) {
          //alert(JSON.stringify(one));
          if (typeof these.realReviews[one.id] === "undefined")
            these.realReviews[one.id] = [];
        });
      });
    }

    getNewWork() {
      var filter = "";
      if (this.state.filter)
        filter = "?filter=1"
      api.call('get', "tasks/available" + filter, {}).then(result => {                
        this.setState({loaded:true});
        if (typeof result.data.error != "undefined") return;
        this.setState({
            tasks: result.data
        });
      });
      api.call('get', "tasks/myWorks", {}).then(result => {
        if (typeof result.data.error != "undefined") return;
        this.setState({
          myWorks: result.data
        });
      });
    }

    fetchData() {
      this.getNewWork();
    }

    jobDone(task, reloadFcn) {
      api.call('get', "task/" + task.id + "/done", {}).then(result => {  
        reloadFcn();
      });
    }

    subtaskDone(task, subtask, reloadFcn) {
      api.call('get', "task/" + task.id + "/subtask/" + subtask.id + "/done", {}).then(result => {  
        reloadFcn();
      });
    }

    download(url) {
      var element = document.createElement("a");
      var file = new Blob(
        [
          url
        ],
        { type: "image/*" }
      );
      element.href = URL.createObjectURL(file);
      element.download = "image.jpg";
      element.click();
    };

    getTask(task, reloadFcn, cb) {
      api.call('get', "tasks/get/" + task.id, {}).then(result => {
        // TODO - WTF ??? un reload??
        return window.location.reload();
        /*
        reloadFcn();
        if (typeof cb == "function")
          cb();
        */
      });
    }

    componentDidMount(){
      api.call('post', "user/usrLog", {module: "views", title:"work"});
      var these = this;
        this.fetchData();
        this.getToBeReviewed();

        window.gtag('event', 'page_view', {
          page_title: 'Travailleur',
          page_location: '/work',
          page_path: '/work',
          //send_to: '<GA_MEASUREMENT_ID>'
        })

        api.call('get', "tasks/categories", {}).then(result => {
          if (typeof result.data.error != "undefined") return;
          var found = false;
          result.data.forEach(function(one) {
            console.log(one);
            if (typeof one.selected !== "undefined")
              found = true;
          });
          these.setState({inscription: !found});
        });

        api.call('get', "users/profile", {}).then(result => {
          if (typeof result.data.error !== "undefined") return alert(result.data.error);        
          var isValid = true;
          if (result.data.phonenumber.length < 5) isValid = false;
          if (result.data.street_adress.length < 5) isValid = false;
          if (result.data.city.length < 5) isValid = false;
          if (result.data.zipcode.length < 5) isValid = false;
          //alert(isValid);
          these.setState({validate: isValid});
        });
    }

    allWorks() {
      if (this.state.tasks.length === 0)
        return (
            <tr>
                <td colspan={3}>Aucune tâche a faire</td>
            </tr>
        );

      var results = [];
      //this.state.tasks.map((row) => {
      var pos = 0;
      for (var i = 0; i < this.state.tasks.length; i++) {
        var row =this.state.tasks[i];
        var newRow = JSON.parse(JSON.stringify(row));
        newRow.details = JSON.parse(row.details);
        newRow.reward = JSON.parse(row.reward);
        results.push (
              <TaskOverview
                task={newRow}
                acceptTask={this.getTask} 
                reload={this.getNewWork}
                user={this.state.userId}
              ></TaskOverview>
            );
        if (i % 10 === 2) {
          pos++;
          results.push(<center><Ad 
            pos={pos}
            size={"300x250"}
            page="news"
          /></center>);
        }
      }
      return results;
    }

    allMyWorks() {
      if (this.state.myWorks.length === 0)
        return (
            <tr>
                <td colspan={3}>Aucune tâche a faire</td>
            </tr>
        );

      return this.state.myWorks.map((row) => {
        var newRow = JSON.parse(JSON.stringify(row));
        newRow.details = JSON.parse(row.details);
        newRow.reward = JSON.parse(row.reward);
            return (
              <TaskOverview
                task={newRow}
                acceptTask={this.getTask} 
                reload={this.getNewWork}
                user={this.state.userId}
              ></TaskOverview>
            );
      });
    }

    renderMyWorks() {
      if (this.state.myWorks.length == 0)
        return;
      return (
        <>
        <div class="listview-title mt-2">Mes quêtes</div>
        <ul class="listview image-listview">
          {this.allMyWorks()}
        </ul>
        </>
      );
    }

    renderTasks() {
      //if (this.state.tasks.length == 0)
      //  return;
      return (
        <>
        <h3>Quête disponible</h3>
        <Form.Group className="mb-3">
            <Form.Check 
                type='checkbox'
                id="filter"
                name="filter"
                label="Filtré avec mes inscriptions"
                value={this.state.filter} 
                onChange={e => this.handleChange(e)}
            />
        </Form.Group>
        <div class="listview-title mt-2">Quête disponible</div>
        <ul class="listview image-listview">
        {this.allWorks()}
        </ul>
        </>
      );
    }

    render(){
      if (!this.state.validate)
        return (
            <ProfileNotUpdated>
            </ProfileNotUpdated>
        )
      var other;
      if (this.state.inscription)
          other = (
            <NoInscription>
            </NoInscription>
          )
      var mytasks = this.renderMyWorks();
      var tasks = this.renderTasks();
        return(
            <div>
              <Breadcrumb>
                  <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>
                  <Breadcrumb.Item active>Travaille</Breadcrumb.Item>
              </Breadcrumb>
              {other}
              {mytasks}
              {tasks}
              <AdForUser
                pos={1}
                zoneId={5}
                page="works_page"
              />
          </div>
        )
    }
}

/*
            <Alert severity="warning">Désolé! Cette page n'est pas encore fonctionnel.</Alert>
*/