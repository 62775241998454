import {api} from '../../services/autonome';
import React from "react";
import { FormInput, Container, Row } from "shards-react";
import ReactGA from 'react-ga';
import Button from '@material-ui/core/Button';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import { Alert, Modal } from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import StripeCheckout from 'react-stripe-checkout';
import interac_logo from "../../img/INTERAC-logo.png";

const hostname = window && window.location && window.location.hostname;
var STRIPE_PUBLISHABLE;
if (hostname === "liberium.net" || hostname === "www.liberium.net") {
    STRIPE_PUBLISHABLE = "pk_live_51L8OrVDmbzE3RrkO4qzUY7SBpSyivYf5cAIUYPoFAB6HNsR7SViZzwpjsTTefjsstLU16w4EeyOmvCITk4tJEeJC00p9vt8VgN";
} else {
    STRIPE_PUBLISHABLE = "pk_test_51L8OrVDmbzE3RrkOEDQ20XrQaLwOmvrigsAEDNB9P45wcWcZ6MrDda4GKQkJko4NBBiKCXu28pRiORduQbc8bHZR00iy5JdqUw";
}

export class DepositView extends React.Component {
  state = {
    screens: [],
    amount: 20,
    errorMsg: null,
    enabled: true,
    showStripe: false,
    allDone: false,
    argentier: false,
    email: ""
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
}

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  fetchData() {
    var these = this;
    var me = JSON.parse(localStorage.getItem('user'));
    this.setState({
      email: me.email,
      allDone: true
    });
    //alert(me.email);
    /*
    api.call('get', "users/profile", {}).then(result => {
      if (typeof result.data.error != "undefined") return;
      
      //alert(JSON.stringify(result.data));
    });
    */
    api.call('get', "liberium/isArgentierNear", {}).then(result => {
      if (typeof result.data.error != "undefined") return;
      //alert(JSON.stringify(result.data));
      if (result.data.available)
        these.setState({available: true});
    });    
  }

  handleChange(event) {
    //console.log("Handle change", event);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
    this.setState({errorMsg: null});
  }

  componentDidMount() {
    api.call('post', "user/usrLog", {module: "views", title:"deposit"});
    this.fetchData();
    window.gtag('event', 'page_view', {
      page_title: 'Deposit',
      page_location: '/finance/deposit',
      page_path: '/finance/deposit',
      //send_to: '<GA_MEASUREMENT_ID>'
    })
  }

  componentWillUnmount() {
  }

  askDeposit() {
      var these = this;
      var amount = parseFloat(this.state.amount);
      if (isNaN(amount)) {
          return this.setState({errorMsg: "Le montant doit être un nombre"})
      }
      if (amount<20) {
        return this.setState({errorMsg: "Le montant doit être suppérieur ou égale à 20"})
      } else {
        api.call('post', "tasks/ask_deposit", {amount: this.state.amount}).then(result => {
          //alert(JSON.stringify(result));
          if (result.data.error) {
            return this.setState({errorMsg: result.data.error.message})
          } else
            these.props.history.push({
                pathname: "/task/" + result.data.id
            });
        });
        //return this.setState({enabled: false});
      }
  }

  onToken = (amount, description) => token => {
    var these = this;
    var data = {
        description,
        source: token.id,
        currency: "CAD",
        amount: amount*100
    }
    api.call('post', "liberium/deposit_charge", data).then(result => {  
        if (result.error) alert(result.error.message);
        these.props.history.push({
          pathname: "/wallet"
        });
    });
  }

  askCC() {
    var these = this;
    var amount = parseFloat(this.state.amount);
    if (isNaN(amount)) {
        return this.setState({errorMsg: "Le montant doit être un nombre"})
    }
    if (amount<5) {
      return this.setState({errorMsg: "Le montant doit être suppérieur a 5"})
    } else {
      api.call('post', "liberium/prepare_deposit", {amount: this.state.amount}).then(result => {        
        if (result.data.error) {
          return this.setState({errorMsg: result.data.error.message})
        } else {
          these.setState({showStripe: true});
        }
      });
    }
  }

  renderArgentier() {
    if (!this.state.available) return (<></>);
    return (
    <div class="section mt-2">
          <div class="section-title">Avec un argentier certifié</div>
          <div class="card">
          <div class="card-body">
            <form>
              <p>
                Un argentier est une personne de confiance choisi par Liberium. 
                En faisant une demande de dépôt, il se présentera
                chez vous pour que vous lui remettiez le montant de votre dépôt en main propre.<br/><br/>
                Montant minimum : 20$
              </p>
                <div class="form-group basic">
                    <div class="input-wrapper">
                        <label class="label" for="amount1">Amount</label>
                        <input 
                          type="text"
                          class="form-control" 
                          id="amount1" 
                          placeholder="Entrer un montant"
                          value={this.state.amount}
                          name="amount"
                          onChange={e => this.handleChange(e)}
                        />
                        <i class="clear-input">
                            <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                        </i>

                        <Button
                          variant="contained"
                          disabled={!this.state.enabled}
                          color="primary"
                          size="small"
                          startIcon={<AttachMoneySharpIcon />}
                          onClick={() => this.askDeposit()}
                      >
                          Envoie moi quelqu'un
                      </Button>
                    </div>
                </div>
              </form>
            </div>
          </div>
      </div>
    )
  }

  renderCC() {
    return (
      <div class="section mt-2">
          <div class="section-title">Par carte de crédit</div>
          <div class="card">
          <div class="card-body">
            <form>
                <div class="form-group basic">
                    <div class="input-wrapper">
                        <label class="label" for="amount1">Amount</label>
                        <input 
                          type="text"
                          class="form-control" 
                          id="amount1" 
                          placeholder="Entrer un montant"
                          value={this.state.amount}
                          name="amount"
                          onChange={e => this.handleChange(e)}
                        />
                        <i class="clear-input">
                            <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                        </i>
                    </div>
                </div>
              </form>
              <StripeCheckout
                name={"deposit user"}
                description={"deposit user"}
                amount={this.state.amount*100}
                token={this.onToken(this.state.amount, "deposit user")}
                currency={"CAD"}
                stripeKey={STRIPE_PUBLISHABLE}
                email={this.state.email}
              />
            </div>
          </div>
      </div>
    )
  }

  renderNews() {
    return (
      <p><a href="https://liberium.net/2023/06/16/privilegier-les-transferts-interac-une-solution-economique-pour-les-marchands-et-les-clients">Nous prévilégions les paiements interac.</a></p>
    )
  }

  renderInterac() {
    return (
      <div class="section mt-2">
          <div class="section-title">Par Interac</div>
          <div class="card">
            <div class="card-body">
              <div class="form-group basic">
                <img src={interac_logo} width={100}/>
                <p>Faites un paiement par Interac<br/><br/>
                      Paiement minimum: 20$ (aucun maximum)<br/>
                      Adresse de paiement: interac@grenierassocie.com<br/>
                      Question: {this.getUserId()}<br/>
                      Réponse: liberium
                </p>
              </div>
            </div>
          </div>
      </div>
    )
  }

  render() {
    if (!this.state.allDone) return (<></>);
    var sty = {
        float: "none", 
        margin: "0 auto"
    }

    var errorMsg;
    if (this.state.errorMsg !== null)
        errorMsg = (
            <Alert variant="danger">
                {this.state.errorMsg}
            </Alert>
        );

    return (
      <Container fluid className="main-content-container px-4">

        <Breadcrumb>
            <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>
            <Breadcrumb.Item href="#/wallet">Porte feuille</Breadcrumb.Item>
            <Breadcrumb.Item active>Dépôt</Breadcrumb.Item>
        </Breadcrumb>

        <div class="row home-intro text-center">
          <div class="col-xs-12 col-md-8" style={sty}>
            <h1>Faite un dépôt sur votre compte Liberium</h1>
          
            <p>Quel est le montant que vous désirer déposer?</p>

            {this.renderInterac()}
            {this.renderArgentier()}
            {this.renderNews()}
            {this.renderCC()}
          </div>
        </div>
        <br/><br/>
    </Container>
    );
  }
}
/*

            <hr class="small"/>
            Pour le moment, nous fonctionnons seulement en argent comptant.
            En faisant une demande de dépôt, un de nos travailleurs se présentera
            chez vous pour que vous lui remettiez le montant de votre dépôt en main propre.<br/><br/>
            Montant minimum : 20$
            {errorMsg}
            <Row>
            <div class="input-group mb-3">
              <span class="input-group-text">$</span>
              <input type="text" class="form-control"
                name="amount"
                aria-label="Montant du dépôt"
                value={this.state.amount}
                onChange={e => this.handleChange(e)}
              />
            </div>
            <Button
                variant="contained"
                disabled={!this.state.enabled}
                color="primary"
                size="small"
                startIcon={<AttachMoneySharpIcon />}
                onClick={() => this.askDeposit()}
            >
                Envoie moi quelqu'un
            </Button>
            </Row>
*/