import React from "react";
import {api} from '../../services/autonome';

import {VirtCreateAccount} from "./create-account";
import {IsItLogged} from "./is-it-logged";
import {VirtCreateService} from "./create-account-service";
import {ScreenStopped} from "./screen-stopped";
import {MarketingAttention} from "./marketing-attention";
import {IsQuebecGroup} from "./is-quebec-group";
import {AddGroup} from "./add_fb_group_to_account";
import {VirtImpotNewClient} from "./impot_new_client";
import {VirtImpotTed} from "./impot-ted";
import {VirtImpotNewComm} from "./impot_new_communication";
import {SendMessageFbGroup} from "./send_fb_group_message";
import {CheckFbGroup} from "./check_fb_group";
import {VideoToCheck} from "./video-to-check";
import {QuebecNewVerif} from "./quebec_new_verif";
import {CheckFbGroupAbonnees} from "./check_fbgroup_abonnees";

export class VirtualJobView extends React.Component {
  state = {
    screens: []
  }

  constructor(props) {
    super(props);
    this.state = {
      userId: this.getUserId(),
      memo: "",
      job: null
    }
    this.onDone = this.onDone.bind(this);
  }

  clean() {
    this.setState({job: null});
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
      this.props.history.push({
        pathname: "/login"
      });
        return null;
    }
  }

  componentDidMount() {
    var these = this;

    this.getCurrentTask();

    window.gtag('event', 'page_view', {
      page_title: 'Virtual Assistant',
      page_location: '/virtual-assistant',
      page_path: '/admin/virtual-assistant'
    });
  }

  componentWillUnmount() {
  }

  handleChange(event) {
    //console.log("Handle change", event.target.name);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
    this.setState({errorMsg: null});
  }
  
  getCurrentTask() {
    var these = this;
    this.clean();
    api.call('get', "virt_job/getCurrentTask", {}).then(result => {
        //alert(JSON.stringify(result.data));
        if(result.data.length > 0)
            these.setState({job: result.data[0], memo: "Paiment: $" + result.data[0].price})
        else
            these.getJob();
    });
  }

  getJob() {
    var these = this;
    this.clean();
    api.call('get', "virt_job/getAtask", {}).then(result => {
        //alert(JSON.stringify(result.data));
        these.setState({job: result.data, memo: "Paiment: $" + result.data.price});
        if (!result.data)
          setTimeout(function() {
            these.getJob();
          },1000);
    });
  }

  onDone() {
    var these = this;
    this.setState({job: null});
    setTimeout(function() {
      these.getJob();
    },250);
    window.gtag('event', 'virtual-job-done', {});
  }

  renderJob() {
    if (this.state.job.task_type === "create_account")
        return (
            <VirtCreateAccount
                jobId={this.state.job.id}
                onFinish={this.onDone}
            >
            </VirtCreateAccount>
        )
    if (this.state.job.task_type === "isLoggedIn")
        return (
            <IsItLogged
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </IsItLogged>
        )
      if (this.state.job.task_type === "create_k8s_service")
        return (
            <VirtCreateService
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </VirtCreateService>
        )
      if (this.state.job.task_type === "screen_stopped")
        return (
            <ScreenStopped
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </ScreenStopped>
        )
      if (this.state.job.task_type === "marketing_attention")
        return (
            <MarketingAttention
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </MarketingAttention>
        )
      if (this.state.job.task_type === "is_fb_group_quebec")
        return (
            <IsQuebecGroup
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </IsQuebecGroup>
        )
      if (this.state.job.task_type === "add_fb_group_to_account")
        return (
            <AddGroup
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </AddGroup>
        )
      if (this.state.job.task_type === "impot_new_client")
        return (
            <VirtImpotNewClient
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </VirtImpotNewClient>
        )
      if (this.state.job.task_type === "ted_impot")
        return (
            <VirtImpotTed
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </VirtImpotTed>
        )
      if (this.state.job.task_type === "impot_new_communication")
        return (
            <VirtImpotNewComm
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </VirtImpotNewComm>
        )
      if (this.state.job.task_type === "send_fb_group_message_human")
        return (
            <SendMessageFbGroup
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </SendMessageFbGroup>
        )
      if (this.state.job.task_type === "check_fb_group")
        return (
            <CheckFbGroup
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </CheckFbGroup>
        )
      if (this.state.job.task_type === "qc_news_verif")
        return (
            <QuebecNewVerif
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </QuebecNewVerif>
        )
      if (this.state.job.task_type === "check_fbgroup_abonnees")
        return (
            <CheckFbGroupAbonnees
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </CheckFbGroupAbonnees>
        )
        if (this.state.job.task_type === "check_video")
        return (
            <VideoToCheck
                jobId={this.state.job.id}
                job={this.state.job}
                onFinish={this.onDone}
            >
            </VideoToCheck>
        )
         
    return (
      <>
        Component ({this.state.job.task_type}) not found
      </>
    )
  }

  render() {
    if (this.state.job === null)
        return (
            <>Désolé, aucune job n'est disponible pour le moment.</>
        )
    return (
        <>
            <h1>Assistant Virtuel {this.state.memo}</h1>

            {this.renderJob()}
        </>
    );
  }
}