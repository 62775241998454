
class UserGroupService{
    Admin = "admin";
    TorrentManager = "torrentManager"
    Travailleur = "travailleur"
    LiberiumAdmin = "LiberiumAdmin"
    LiberiumCa = "liberium_ca"
  
    routeContainsGroups(routeGroup, searchedGroups) {
      if(!routeGroup || !routeGroup.length)
        return true;
      if(!searchedGroups || searchedGroups.length === 0)
        return false;
        
        for (let i = 0; i < searchedGroups.length; i++) {
          const group = searchedGroups[i];
          if(routeGroup.indexOf(group) > -1)
            return true;
        }
  
        return false;
    }
}

export default new UserGroupService()