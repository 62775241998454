import React from "react";
import { Card, CardBody } from "shards-react";
import {api} from '../../services/autonome';
import { withRouter } from 'react-router'; 
import {
    Container,
    Row
} from "shards-react";

export class ListInvestors extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            //history: props.history,
            orgId: props.orgId,
            loaded: false,
            overview: [],
            total: "n/a",
        }
    }

    fetchOverview() {
        api.call('get', "organisation/" + this.state.orgId + "/tokens/overview", {}).then(result => {
            console.log("Got tokens:", result);
            this.setState({
                overview: result.data,
            });
        });
    }

    renderTokens () {
        //console.log(comptes);
        var userId = null;
        return this.state.overview.map(row => {
            var username = row.username;
            if (userId==1)
                username = (
                    <button
                    className="add-item__button"
                    onClick={() => this.handleUser(row)}
                    >
                    {row.username}
                </button>
                )
            return ( 
                <tr key={row.userId}>
                    <td><span>{username}</span></td>
                    <td><span style={{float: "right"}}>{row.tokens}</span></td>
                    <td><span style={{float: "right"}}>{row.perc}%</span></td>
                    <td><span style={{float: "right"}}>{row.startingAt}$</span></td>
                    <td><span style={{float: "right"}}>{row.value}$</span></td>
                    <td><span style={{float: "right"}}>{row.profit_loss}$</span></td>
                </tr>
            );
        }
        )
    }

    componentDidMount(){
        this.fetchOverview();
    }

    render() {
        return(
            <Container fluid className="main-content-container px-4">

            <h1 className="m-4">Investisseurs</h1>

                <div className="w-100 d-flex flex-wrap">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col">Nom</th>
                                <th scope="col">Nbr Tokens</th>
                                <th scope="col">Participation</th>
                                <th scope="col">Départ</th>
                                <th scope="col">Valeur</th>
                                <th scope="col">Profit/perte</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTokens()}
                        </tbody>
                    </table>
                </div>
            </Container>
        )
    }
}

export default withRouter(ListInvestors);