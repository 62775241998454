import React from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from "shards-react";
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from '../../services/autonome';
//import VideoPlayer from "./video-player";
import ReactHlsPlayer from 'react-hls-player';

export class VideoToCheck extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            jobId: this.props.jobId,
            job: this.props.job,
            onFinish: this.props.onFinish,
            metas: JSON.parse(this.props.job.metas),
            onClicked: false,
            isQuebec: "",
            isFrancais: "",
            validate: false
        }
        this.handleChange = this.handleChange.bind(this);
        var metas = JSON.parse(this.props.job.metas);
        this.state.video = metas.video;
        this.state.username = metas.username;
        this.state.videoJsOptions = {
            autoplay: true,
            controls: true,
            responsive: true,
            fluid: true,
            experimentalSvgIcons: true,
            sources: [
              {
                src: "https://api.liberium.net/s/peupleqc-upload/"+this.state.video,
                type: "application/x-mpegURL",               
              },
            ],
          };
        
    }

    handleChange(event) {
        console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        console.log("newform:", new_form);
        console.log(value);

        if (name==="isFrancais" && value === "false")
            new_form["isQuebec"] = "false";

        this.setState(new_form);
        this.validateForm();
    }

    noFrench() {
        this.setState({onClicked: true});
        var these = this;
        var data = {
            taskId: this.state.jobId,
            isQuebec: 0,
            isFrancais: 0,
            username: this.state.username
        }
        api.call('post', "virt_job/check_video", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            these.state.onFinish();
        });
    }

    onDone() {
        var valid = this.validateForm();
        if (!valid) return;

        this.setState({onClicked: true});

        console.log("Ok send it to api");
        var these = this;
        var data = {
            taskId: this.state.jobId,
            isQuebec: this.state.isQuebec,
            isFrancais: this.state.isFrancais,
            username: this.state.username
        }
        //return console.log("toSend:", data);
        api.call('post', "virt_job/check_video", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            this.state.onFinish();
        });
    }

    validateForm() {
        var errorMsg = null;
        if (this.state.isQuebec==="")
            errorMsg = "Choisissez oui ou non"
        if (this.state.isFrancais==="")
            errorMsg = "Choisissez oui ou non"
        
        if (errorMsg) {
            this.setState({errorMsg: errorMsg, validate: false});
            return false;
        } else {
            this.setState({errorMsg: "", validate: true});
        }
        return true;
    }

    componentDidMount() {
    }

    render () {        
        return (
            <>
            <Card>
                <CardHeader>Est-ce une bonne vidéo?</CardHeader>
                <CardBody>
                    <p>Est-ce que cette vidéo est française et Québecois?</p>

                    {this.state.errorMsg && <div className="alert alert-danger">{this.state.errorMsg}</div>}                    
                    <ReactHlsPlayer
                        src={"https://api.liberium.net/s/peupleqc-upload/"+this.state.video}
                        autoPlay={true}
                        controls={true}
                        width="300"
                        height="300"
                    />
                    <br/>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Est-ce une vidéo Francaise?</FormLabel>
                        <RadioGroup aria-label="isFrancais" name="isFrancais" value={this.state.isFrancais} onChange={(e)=>this.handleChange(e)}>
                            <FormControlLabel value={"true"} control={<Radio />} label="Oui" />
                            <FormControlLabel value={"false"} control={<Radio />} label="Non" />                            
                        </RadioGroup>
                    </FormControl>
                    <br/>
                    {this.state.isFrancais === "true" &&
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Est-ce une vidéo québecoise?</FormLabel>
                        <RadioGroup aria-label="isQuebec" name="isQuebec" value={this.state.isQuebec} onChange={(e)=>this.handleChange(e)}>
                            <FormControlLabel value={"true"} control={<Radio />} label="Oui" />
                            <FormControlLabel value={"false"} control={<Radio />} label="Non" />                            
                        </RadioGroup>
                    </FormControl>
                    }

                    {this.state.errorMsg && <div className="alert alert-danger">{this.state.errorMsg}</div>}
                </CardBody>
                <CardFooter>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<NewReleasesSharpIcon />}
                        onClick={() => this.onDone()}
                        disabled = {!this.state.validate || this.state.onClicked}
                    >
                        Envoyer
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<NewReleasesSharpIcon />}
                        onClick={() => this.noFrench()}
                    >
                        Non pas français
                    </Button>
                </CardFooter>
            </Card>
            </>         
        )
    }
}

export default VideoToCheck;
