import React from 'react';
import {api} from '../../services/autonome';

import {
    Container,
    Form,
    FormGroup,
    FormInput,
    Alert
} from "shards-react";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {confirm} from 'react-bootstrap-confirmation';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

export class UserProfile extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            profile: {
                firstname: "",
                lastname: "",
                avatar: ""
            },
            errorMsg: ""
        }
        this.state.userId = this.getUserId();
        this.changeHandler = this.changeHandler.bind(this);
        this.OS="Unknown";
        if (navigator.appVersion.indexOf("Win")!=-1) this.OS="Windows";
        if (navigator.appVersion.indexOf("Mac")!=-1) this.OS="MacOS";
        if (navigator.appVersion.indexOf("X11")!=-1) this.OS="UNIX";
        if (navigator.appVersion.indexOf("Linux")!=-1) this.OS="Linux";
        if (navigator.appVersion.indexOf("Android")!=-1) this.OS="Android";
        if (navigator.appVersion.indexOf("iPhone")!=-1) this.OS="IOS";
        if (navigator.appVersion.indexOf("iPad")!=-1) this.OS="IOS";
        //alert(navigator.appVersion);
        //if (this.state.userId===1)
        //    alert(this.OS);
    }

    async display (message) {
        const result = await confirm(message);
        return result;
    }

    handleChange(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state.profile;
        new_form[name] = value;
        this.setState({
            profile: new_form
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    async deleteUser() {
        var these = this;
        var response = await this.display("Etes-vous certain de supprimer votre compte?");
        if (response) {
            api.call('post', "user/delete_user", {}).then(result => {
                localStorage.removeItem('tokens')
                these.props.history.push({
                    pathname: "/signup"
                });
            });            
        }
    }

    saveUser() {
        var these = this;
        if (this.state.profile.phonenumber.length < 5) return this.setState({errorMsg: "Numéro de téléphone incorrect"});
        if (this.state.profile.firstname.length < 2) return this.setState({errorMsg: "Prénom incorrect"});
        if (this.state.profile.lastname.length < 2) return this.setState({errorMsg: "Nom incorrect"});

        if (this.state.profile.street_adress.length < 2) return this.setState({errorMsg: "Adresse incorrect"});
        if (this.state.profile.city.length < 2) return this.setState({errorMsg: "Ville incorrect"});
        if (this.state.profile.zipcode.length < 2) return this.setState({errorMsg: "Code postal incorrect"});
        if (this.state.profile.state.length < 2) return this.setState({errorMsg: "Province incorrect"});
        if (this.state.profile.country.length < 2) return this.setState({errorMsg: "Pays incorrect"});
                
        api.call('post', "users/profile", this.state.profile).then(result => {
            if (typeof result.data.error != "undefined") return;
            var modelId = parseInt(localStorage.getItem("modellId"));
            if (modelId !== null) {
                return these.props.history.push({
                    pathname: "/services"
                });    
            }
            these.props.history.push({
                pathname: "/home"
            });
        });
    }

    fetchData() {
      api.call('get', "users/profile", {}).then(result => {
        if (typeof result.data.error != "undefined") return;
        this.setState({
            profile: result.data
        });
      });
    }

    changeHandler (event) {
        const data = new FormData();
        var filename = event.target.files[0];
        //alert(JSON.stringify(filename));
        if (typeof filename === "string")
            filename = filename.replace('file://', '');
        data.append('file', filename);
        this.setState({
            uploadMsg: "Upload en cours"
        });
        api.call('post', "me/avatar", data).then(result => {
            alert("Returned from uploading");
            if (typeof result.data.error != "undefined")
                return alert(JSON.stringify(result.data.error));
            //alert(JSON.stringify(result));
            this.setState({
                profile: {
                    avatar: result.data.url
                }
            });
        });
	};

    componentDidMount() {
        api.call('post', "user/usrLog", {module: "views", title:"profil"});
        this.fetchData();
        window.gtag('event', 'page_view', {
            page_title: 'Profil',
            page_location: '/profil',
            page_path: '/profil',
            //send_to: '<GA_MEASUREMENT_ID>'
          })
    }

    render(){
        var ale;
        if (this.state.errorMsg.length > 0)
            ale = (
                <Alert theme="danger">{this.state.errorMsg}</Alert>
            );
        var img = (<></>)
        if (this.state.profile.avatar.length > 5)
            img = (
                <img src={this.state.profile.avatar} class="image" width={300}/>
            )
        var upAvatar;
        if (this.OS!=="IOS")
            upAvatar = (
                <>
                {img}

                <div class="section mt-2">
                    <div class="section-title">Photo de profil</div>
                        <div class="card">
                            <div class="card-body">
                                <form>
                                    <div class="custom-file-upload" id="fileUpload1">
                                        <input
                                            type="file"
                                            id="fileuploadInput"
                                            accept=".png, .jpg, .jpeg" 
                                            onChange={this.changeHandler} 
                                        />
                                        <label for="fileuploadInput">
                                            <span>
                                                <strong>
                                                    <ion-icon name="arrow-up-circle-outline" role="img" class="md hydrated" aria-label="arrow up circle outline"></ion-icon>
                                                    <i>Upload a Photo</i>
                                                </strong>
                                            </span>
                                        </label>
                                    </div>

                                </form>

                            </div>
                        </div>
                    </div>
                </>
            )
        return(
            <Container fluid className="main-content-container px-4">

                <Breadcrumb>
                    <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>                
                    <Breadcrumb.Item active>Profile</Breadcrumb.Item>
                </Breadcrumb>

                {upAvatar}

                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                    <Button onClick={e => this.saveUser()}>Sauvegarder</Button>
                </ButtonGroup>

                {ale}

                <Form>
                    <FormGroup>
                    <label>Prénom :</label>
                    <FormInput name="firstname" value={this.state.profile.firstname} onChange={e => this.handleChange(e)} size="lg" placeholder="Prénom" />
                    </FormGroup>

                    <FormGroup>
                    <label>Nom :</label>
                    <FormInput name="lastname" value={this.state.profile.lastname} onChange={e => this.handleChange(e)} size="lg" placeholder="Nom" />
                    </FormGroup>
                </Form>

                <Form>
                    <FormGroup>
                    <label>Numéro de téléphone :</label>
                    <FormInput name="phonenumber" value={this.state.profile.phonenumber} onChange={e => this.handleChange(e)} size="lg" placeholder="Numéro de téléphone" />
                    </FormGroup>
                </Form>

                <Form>
                    <FormGroup>
                    <label>Adresse :</label>
                    <FormInput name="street_adress" value={this.state.profile.street_adress} onChange={e => this.handleChange(e)} size="lg" placeholder="Adresse" />
                    </FormGroup>
                </Form>

                <Form>
                    <FormGroup>
                    <label>Ville</label>
                    <FormInput name="city" value={this.state.profile.city} onChange={e => this.handleChange(e)} size="lg" placeholder="Ville" />
                    </FormGroup>
                </Form>

                <Form>
                    <FormGroup>
                    <label>Code postal :</label>
                    <FormInput name="zipcode" value={this.state.profile.zipcode} onChange={e => this.handleChange(e)} size="lg" placeholder="code postal" />
                    </FormGroup>
                </Form>

                <Form>
                    <FormGroup>
                    <label>Province :</label>
                    <FormInput name="state" value={this.state.profile.state} onChange={e => this.handleChange(e)} size="lg" placeholder="Province" />
                    </FormGroup>
                </Form>

                <Form>
                    <FormGroup>
                    <label>Pays :</label>
                    <FormInput name="country" value={this.state.profile.country} onChange={e => this.handleChange(e)} size="lg" placeholder="Pays" />
                    </FormGroup>
                </Form>

                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                    <Button onClick={e => this.saveUser()}>Sauvegarder</Button>
                </ButtonGroup>
                <br/><hr/><br/>
                <ButtonGroup variant="contained" color="alert" aria-label="contained primary button group">
                    <Button onClick={e => this.deleteUser()}>Supprimer mon compte</Button>
                </ButtonGroup>
            <br/><br/>
            </Container>
        )
    }
}

/*
            <Alert severity="warning">Désolé! Cette page n'est pas encore fonctionnel.</Alert>
*/