import React from "react";
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {api} from '../../services/autonome';

export class IsAshopNear extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            isAshopNear: false
        }
    }

    componentDidMount() {
        var these = this;
        api.call('get', "liberium/isShopNear", {}).then(result => {
            if (typeof result.data.error != "undefined") return;
            //alert(JSON.stringify(result));
            these.setState({isAshopNear: result.data.aShopIsNear});
        });
    }

    onClick = () => {
        this.props.history.push({
            pathname: "/shop"
        });
    }

    render () {
        if (!this.state.isAshopNear) return (<></>);
        return (
            <div class="col-sm-6 col-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Boutique</h5>
                        <p class="card-text">Boutique à proximité. Faites des achats. Bientôt disponible!</p>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<ShoppingCartIcon />}
                            onClick={() => this.onClick()}
                        >
                            Boutique
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(IsAshopNear);