import React from "react";
import {api} from '../../services/autonome';
import { Container } from "shards-react";
import { Form, Modal } from "react-bootstrap";
import { Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { API_ROOT } from '../../api-config';

const projects = [
  "liberium",
  "impot"
]

const cat_liberium = [
  "Pour un client",
  "Pour un travailleur"
]

/*
const textes = [
  "Bonjour %name%, j'aimerais vous presenter une plateforme pour trouver des contrats. Ca s'appel Liberium:\n" +
        "https://liberium.net/fb_model/%ref%?utm_source=facebook&utm_medium=post_link&utm_campaign=camille\n\n" +
        "La categorie qui vous interessera est: %categorie%\n" +
        "Si vous avez des questions, n'hesitez surtout pas ici, ou en PV.",
  "Bonjour %name%, j'aimerais vous presenter une plateforme pour trouver des travailleurs autonomes. Ca s'appel Liberium:\n" +
        "https://liberium.net/fb_model/%ref%?utm_source=facebook&utm_medium=post_link&utm_campaign=camille\n\n" +
        "La categorie qui vous interessera est: %categorie%\n" +
        "Si vous avez des questions, n'hesitez surtout pas ici, ou en PV.",
  "Bonjour %name%, si ça t'interesse, j'aimerais te presenter une plateforme pour trouver des jobines. Ca s'appel Liberium:\n" +
        "https://liberium.net/?utm_source=facebook&utm_medium=post_link&utm_campaign=camille\n\n" +
        "Si tu as des questions, n'hesite surtout pas ici, ou en PV.",
]
*/

export class Screen extends React.Component {
  state = {
    screens: []
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.intWall = null;
    this.state = {
      userId: this.getUserId(),
      screenId: parseInt(props.screenId),
      aiTask: null,
      aiText: [],
      score: 0,
      count: 0,
      currentImage: "1.png",
      ai_status: {score: "n/d"},
      project: projects[0],
      texte: cat_liberium[0],
      texteNum: 0,
      name: "",
      categories: [],
      categorie: "",
      categorieId: 0,
      modelPos: 0,
      showCat: true,
      disabled: false
    }
    if (typeof props.texte !== "undefined") {
      this.state.texte = props.texte;
      this.state.showCat = false;
    }
    if (typeof props.showCat !== "undefined") {
      
      this.state.showCat =  props.showCat;
    }
    this.state.showDone = props.showDone;
    if (typeof this.state.showDone === "undefinef")
      this.state.showDone = true;
    this.intervalTimer = null;
    this.records = [];
    this.thesetRecords = null;
    if (typeof props.setRecords !== "undefined")
      this.thesetRecords = props.setRecords;
    this.img = React.createRef();
    //this.ref = React.useRef(null)
    this.onClick = this.onClick.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSelectCat = this.onSelectCat.bind(this);
    this.onSelectProject = this.onSelectProject.bind(this);

    this.onParentContinue = props.onParentContinue;
    this.onProblem = props.onProblem;
    this.gettingJob = false;
    this.closing = false;
  }

  clean() {
    this.setState({aiText: [], score: "n/d"});
  }

  setRecords() {
    if (this.thesetRecords !== null)
      this.thesetRecords(this.records);
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  componentDidMount() {
    var these = this;
    api.call('get', "tasks/categories", {}).then(result => {
      //alert(JSON.stringify(result.data));
      var models = [];
      result.data.forEach(function(model){
        model.fields = JSON.parse(model.fields);
        models.push(model);
      });
      these.setState({categories: models});
    });

    this.getJob();
    var these = this;
    /*
    this.intervalTimer = setTimeout(function() {
        these.getJob();
    },1000);
    */

    window.gtag('event', 'page_view', {
      page_title: 'Admin Marketing',
      page_location: '/admin/marketing',
      page_path: '/admin/marketing'
    });
  }

  componentWillUnmount() {
    clearInterval(this.intervalTimer);
    this.closing = true;
  }

  handleChange(event) {
    //console.log("Handle change", event.target.name);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
    this.setState({errorMsg: null});
  }

  getJob() {
    if (this.gettingJob) return console.log("actually getting a job");
    if (this.closing) return;
    this.gettingJob = true;
    var these = this;
    this.clean();
    api.call('get', "ai/facebook_screen/current?id=" + this.state.screenId, {}).then(result => {
        //alert(JSON.stringify(result.data));
        these.setState({currentImageFile: result.data.currentFile, currentImage: result.data.current, count: result.data.count, ai_status: result.data.ai_status});
        
        this.intervalTimer = setTimeout(function() {
          these.gettingJob = false;
          these.getJob();
        },500);
    });
  }

  FindPosition(oElement) {
    if(typeof( oElement.offsetParent ) != "undefined")
    {
      for(var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent)
      {
        posX += oElement.offsetLeft;
        posY += oElement.offsetTop;
      }
        return [ posX, posY ];
      }
      else
      {
        return [ oElement.x, oElement.y ];
      }
  }

  onClick(e) {
    console.log(e)
    var PosX = 0;
    var PosY = 0;
    var ImgPos;
    const imgElement = document.querySelector('#currentImage');
    ImgPos = this.FindPosition(imgElement);
    if (!e) var e = window.event;
    if (e.pageX || e.pageY)
    {
      PosX = e.pageX;
      PosY = e.pageY;
    }
    else if (e.clientX || e.clientY)
      {
        PosX = e.clientX + document.body.scrollLeft
          + document.documentElement.scrollLeft;
        PosY = e.clientY + document.body.scrollTop
          + document.documentElement.scrollTop;
      }
    PosX = PosX - ImgPos[0];
    PosY = PosY - ImgPos[1];
    console.log(PosX + "x" + PosY);
    api.call('get', "ai/facebook_screen/click?id=" + this.state.screenId + "&x=" + PosX + "&y=" + PosY, {}).then(result => {
        //these.getJob();
    });
    this.records.push(
        {action:"click", x: PosX, y: PosY, curImage: this.state.currentImageFile}
    );
    this.setRecords();
    console.log("records:",this.records);
  }

  sendText() {    
    var self = this;
    var txtToSend = this.state.texte.split("\n").join("%%%%");
    console.log("Sending text:", txtToSend);
    api.call('post', "ai/facebook_screen/sendText?id=" + this.state.screenId, {text:txtToSend}, {}).then(result => {
      console.log("afterSendText")
      setTimeout(function(){
        api.call('get', "ai/facebook_screen/sendEnter?id=" + self.state.screenId, {}).then(result => {
        });
      },500);
    });
    this.records.push(
        {action:"sendText", text: this.state.texte, curImage: this.state.currentImageFile}
    );
    this.setRecords()
  }

  sendRestart() {
    console.log("Sending Restart")
    this.setState({disabled: true});
    this.closing = true;
    /*
    // On fait le restart dans onProblem
    api.call('get', "ai/facebook_screen/restart?id=" + this.state.screenId, {}).then(result => {
      //these.getJob();
    });
    */
    this.onProblem();
    //this.onParentContinue(null);
  }
  sendTab() {
    console.log("Sending Enter")
    api.call('get', "ai/facebook_screen/tab?id=" + this.state.screenId, {}).then(result => {
      //these.getJob();
    });
    window.gtag('event', 'facebook_send_post', {});
    this.records.push(
        {action:"sendTab", curImage: this.state.currentImageFile}
    );
    this.setRecords()
  }

  sendEnter() {
    console.log("Sending Enter")
    api.call('get', "ai/facebook_screen/sendEnter?id=" + this.state.screenId, {}).then(result => {
      //these.getJob();
    });
    window.gtag('event', 'facebook_send_post', {});
    this.records.push(
        {action:"sendEnter", curImage: this.state.currentImageFile}
    );
    this.setRecords()
  }
  sendShiftEnter() {
    console.log("Sending Shift+Enter")
    api.call('get', "ai/facebook_screen/shiftEnter?id=" + this.state.screenId, {}).then(result => {
      //these.getJob();
    });
    this.records.push(
        {action:"sendShiftEnter", curImage: this.state.currentImageFile}
    );
    this.setRecords()
  }
  scrollDown() {
    api.call('get', "ai/facebook_screen/scrollDown?id=" + this.state.screenId, {}).then(result => {
      //these.getJob();
    });
    this.records.push(
        {action:"scrollDown", curImage: this.state.currentImageFile}
    );
    this.setRecords()
  }
  scrollUp() {
    api.call('get', "ai/facebook_screen/scrollUp?id=" + this.state.screenId, {}).then(result => {
      //these.getJob();
    });
    this.records.push(
        {action:"scrollUp", curImage: this.state.currentImageFile}
    );
    this.setRecords()
  }
  sendFalse() {
    var these = this;
    api.call('get', "ai/facebook_screen/falseStop?id=" + this.state.count, {}).then(result => {
      //alert("Thanks, it will continue");
      // TODO - Click to continue
      these.sendContinue();
    });
    window.gtag('event', 'facebook_false_stop', {});
  }

  itsDone() {
    this.setState({disabled: true});
    this.closing = true;
    this.records.push(
      {action:"lastScreen", curImage: this.state.currentImageFile}
    );
    this.setRecords()
    this.onParentContinue(this.records);
  }

  sendContinue() {
    console.log("Sending continue")
    this.setState({disabled: true});
    this.closing = true;
    var texte = "\n";
    var these = this;
    this.records.push(
      {action:"lastScreen", curImage: this.state.currentImageFile}
    );
    this.setRecords()
    api.call('get', "ai/facebook_screen/sendContinue?id=" + this.state.screenId, {}).then(result => {
      //these.getJob();
      //window.close();
      these.onParentContinue(this.records);
    });
    window.gtag('event', 'facebook_continue', {});
  }

  changeText() {
    var these = this;
    setTimeout( function () {
      var texte;
      if (these.state.project === "impot") {
        texte = "Salut. Savais-tu que tu pouvais faire tes impôts 100% en ligne grace a Liberium?\n" +
            "https://liberium.net/impot";
      } else {
        console.log("current e:", these.state.texteNum);
        texte = cat_liberium[these.state.texteNum];
        if (parseInt(these.state.texteNum)===0) {
          console.log("Client",these.state.categories[these.state.modelPos]);
          if (typeof these.state.categories[these.state.modelPos].fields.textToClient !== "undefined" && these.state.categories[these.state.modelPos].fields.textToClient.length > 0) {
            texte = these.state.categories[these.state.modelPos].fields.textToClient + "\n" +
              "https://liberium.net/landing/" + these.state.categorieId + "?refId=1";;
          } else {
            texte = "Salut. J'aimerais te recommander cette plateforme pour trouver le travailleur que tu recherches. L'inscription est totalement gratuite:\n" +
            "https://liberium.net/landing/" + these.state.categorieId + "?refId=1";     
          }
        } else {
          console.log("Travailleur");
          if (typeof these.state.categories[these.state.modelPos].fields.textToWorker !== "undefined" && these.state.categories[these.state.modelPos].fields.textToWorker.length > 0) {
            texte = these.state.categories[these.state.modelPos].fields.textToWorker + "\n" +
              "https://liberium.net/landing/" + these.state.categorieId + "?refId=1";;
          } else {
            texte = "Salut. J'aimerais te recommander cette plateforme pour trouver de nouveaux contrats. L'inscription est totalement gratuite:\n" +
            "https://liberium.net/landing_worker/" + these.state.categorieId + "?refId=1";
          }
        }
      }
      //console.log(texte);
      these.setState({texte:texte.normalize("NFD").replace(/\p{Diacritic}/gu, "")});
    },100);
  }

  onSelectProject(e) {
    this.setState({project:e});
    this.changeText();
    this.records.push(
        {action:"selectProject", project: e, curImage: this.state.currentImageFile}
    );
    this.setRecords()
  }

  onSelect(e) {
    console.log("Choosed:", e);
    this.setState({texteNum:e});
    this.changeText();
    this.records.push(
        {action:"selectKind", kind: e, curImage: this.state.currentImageFile}
    );
    this.setRecords()
  }

  onSelectCat(e) {
    this.setState({modelPos:e, categorie:this.state.categories[e].name, categorieId: this.state.categories[e].id});
    this.changeText();
    this.records.push(
        {action:"selectCat", category: e, curImage: this.state.currentImageFile}
    );
    this.setRecords()
  }

  render() {
    //var url = API_ROOT + "s/facebook-screens/" + this.state.currentImage;
    var url = API_ROOT + "ai/facebook_screen/currentImg?id=" + this.state.screenId + "&ts=" + Math.floor(Date.now() / 1000);
    var texts;
    texts = this.state.aiText.map((row) => {
        return (
          <p>{row}</p>
        )
    });
    var newS = {
      "vertical-align":"top"
    }
    var j = -1;
    var items = cat_liberium.map((one) => {
      j++;
      return (
        <Dropdown.Item eventKey={j}>{one}</Dropdown.Item>
      )
    })
    var k = -1;
    var categories = this.state.categories.map((one) => {
      k++;
      return (
        <Dropdown.Item eventKey={k}>{one.name}</Dropdown.Item>
      )
    })
    var projectComponents;
    if (this.state.project === "impot")
      projectComponents = (
        <>
        </>
      )
    if (this.state.project === "liberium")
      projectComponents = (
        <>
          <DropdownButton
            variant="secondary"
            name="selText"
            onSelect={this.onSelect}
          >
            {items}
          </DropdownButton>
          <DropdownButton
            variant="secondary"
            name="selText"
            onSelect={this.onSelectCat}
          >
            {categories}
          </DropdownButton>
        </>
      )
    return (
      <>
            <img
              id="currentImage"
                ref={this.img}
                src={url}
                onMouseDown={(e) => this.onClick(e)}
            />

        <div>
          {this.state.showCat && 
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<CheckIcon />}
              disabled={this.state.disabled}
              onClick={() => this.sendContinue()}
            >
              Continue
            </Button>
          }
          {(!this.state.showCat || this.state.showDone) && 
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<CheckIcon />}
              disabled={this.state.disabled}
              onClick={() => this.itsDone()}
            >
              C'est fait
            </Button>
          }
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.scrollDown()}
                disabled={this.state.disabled}
              >
                  Scroll Down
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.scrollUp()}
                disabled={this.state.disabled}
              >
                  Scroll Up
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.sendTab()}
                disabled={this.state.disabled}
              >
                  Tab
              </Button>

            {this.state.showCat && 
            <div>
              <h1>Score: {this.state.ai_status.score}</h1>
                Projet:
                <Select
                    labelId="tax-year-label"
                    id="tax-year-select"
                    value={this.state.project}
                    onChange={(event) => this.onSelectProject(event.target.value)}
                >
                    {projects.map((project) => (
                    <MenuItem key={project} value={project}>
                        {project}
                    </MenuItem>
                    ))}
                </Select>
                {projectComponents}
              </div>
              
            }
              
              <Form.Group className="mb-3" controlId="texte" key="texte">
                <Form.Label>Texte</Form.Label>
                  <Form.Control as="textarea" rows={5}
                    type="textarea"
                    name="texte"
                    value={this.state.texte} 
                    onChange={e => this.handleChange(e)}
                  >
                  </Form.Control>
              </Form.Group>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.sendText()}
                disabled={this.state.disabled}
              >
                Envoyé
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.sendEnter()}
                disabled={this.state.disabled}
              >
                Enter
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.sendShiftEnter()}
                disabled={this.state.disabled}
              >
                Shitf+Enter
              </Button>
              <hr/>              
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.sendRestart()}
                disabled={this.state.disabled}
              >
                  Restart
              </Button>              
              {texts}
          </div>
      </>
    )
  }
}