import React from "react";
import {
    Form,
    FormGroup,
    FormInput,
} from "shards-react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from "shards-react";
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from '../../services/autonome';

export class VirtImpotTed extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            jobId: this.props.jobId,
            job: this.props.job,
            onFinish: this.props.onFinish,
            metas: JSON.parse(this.props.job.metas)
        }
        this.state.metas.profil = JSON.parse(this.state.metas.profil);
    }

    handleChange(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    onDone() {
        console.log("Ok send it to api");
        var these = this;
        var data = {
            taskId: this.state.jobId,
            screen_port: this.state.servicePort
        }
        api.call('post', "virt_job/jobDone", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            alert("Merci")
            this.state.onFinish();
        });
    }

    abandon() {
        var data = {
            taskId: this.state.jobId
        }
        api.call('post', "virt_job/abandon", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            this.state.onFinish();
        });
    }

    componentDidMount(){
    }

    render () {
        console.log(this.state.metas.profil);
        return (
            <>
            <Card>
                <CardHeader>Envoie TED</CardHeader>
                <CardBody>
                    <p>Envoyer les impots par TED de ce client.</p>

                    {this.state.errorMsg && <div className="alert alert-danger">{this.state.errorMsg}</div>}

                    <p>
                        Nom: {this.state.metas.profil.nom} {this.state.metas.profil.prenom}
                    </p>

                    <p>
                        Telephone: {this.state.metas.profil.telephone}
                    </p>

                    <p>
                        NAS: {this.state.metas.profil.nas}
                    </p>

                    {this.state.errorMsg && <div className="alert alert-danger">{this.state.errorMsg}</div>}
                </CardBody>
                <CardFooter>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<NewReleasesSharpIcon />}
                            onClick={() => this.onDone()}
                        >
                            C'est fait
                        </Button>
                </CardFooter>
            </Card>
            </>         
        )
    }
}

export default VirtImpotTed;
