import React from "react";
import {
    Form,
    FormGroup,
    FormInput,
} from "shards-react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from "shards-react";
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from '../../services/autonome';
import axios from 'axios';
import { API_ROOT } from '../../api-config';


export class VirtCreateAccount extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            jobId: this.props.jobId,
            onFinish: this.props.onFinish,
/*
            email: "tonvendeur70@gmail.com",
            prenom: "Camille",
            nom: "Lajeunesse",
            gmail_pwd: "Fac2013dane!",
            facebook_pwd: "Fac2013dane!",
            image_url: "https://api.liberium.net/s/liberium-public-img/camille_lajeunesse.jpg",
*/
            email: "",
            prenom: "",
            nom: "",
            gmail_pwd: "",
            facebook_pwd: "",
            image_url: "",

            errorMsg: null,
            onClicked: false
        }
        this.onFileUpload = this.onFileUpload.bind(this);
        //alert(this.state.jobId);
    }

    handleChange(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    onDone() {
        var valid = this.validateForm();
        if (!valid) return;

        this.setState({onClicked: true});

        console.log("Ok send it to api");
        var these = this;
        var data = {
            taskId: this.state.jobId,
            prenom: this.state.prenom,
            nom: this.state.nom,
            email: this.state.email,
            image_url: this.state.image_url,
            gmail_pwd: this.state.gmail_pwd,
            facebook_pwd: this.state.facebook_pwd
        }
        api.call('post', "virt_job/createAccount", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            alert("Merci")
            this.state.onFinish();
            //window.location.reload();
        });
    }

    abandon() {
        var self = this;
        this.setState({onClicked: true});
        var data = {
            taskId: this.state.jobId
        }
        api.call('post', "virt_job/abandon", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            setTimeout(function() {
                self.state.onFinish();
            },5000);
        });
    }

    validateForm() {
        var errorMsg = null;

        if (this.state.prenom.length === 0) errorMsg = "Le prénom doit être spécifié";
        if (this.state.nom.length === 0) errorMsg = "Le nom doit être spécifié";
        if (this.state.email.length === 0) errorMsg = "Le email doit être spécifié";
        if (this.state.gmail_pwd.length === 0) errorMsg = "Le mot de passe doit être spécifié";
        if (this.state.facebook_pwd.length === 0) errorMsg = "Le mot de passe doit être spécifié";
        if (this.state.image_url.length === 0) errorMsg = "Une photo de profile doit être spécifié";

        this.setState({errorMsg: errorMsg});
        if (errorMsg) return false;
        return true;
    }

    onFileChange = event => { 
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] });
    }

    onFileUpload = () => { 
        var these = this;
        // Create an object of formData 
        const formData = new FormData(); 
       
        // Update the formData object 
        formData.append( 
          "file", 
          this.state.selectedFile, 
          this.state.selectedFile.name 
        ); 
       
        // Details of the uploaded file 
        console.log(this.state.selectedFile); 
       
        // Request made to the backend api 
        // Send formData object
        axios
        .post(API_ROOT + "s/liberium-public-img", formData)
        .then(function (response) {
            console.log("response from upload:",response);
            these.setState({image_url: response.data.url})
          })
    }

    componentDidMount(){
    }

    render () {
        return (
            <>
            <Card>
                <CardHeader>Création de compte</CardHeader>
                <CardBody>
                    <p>Vous devez créer un personnage, avec son compte Gmail et FaceBook. Vous devez aussi faire générer une image du personnage.</p>

                    {this.state.errorMsg && <div className="alert alert-danger">{this.state.errorMsg}</div>}

                    <Form>
                        <FormGroup>
                        <label>Prenom :</label>
                        <FormInput name="prenom" value={this.state.prenom} onChange={e => this.handleChange(e)} size="lg" placeholder="Prenom" />
                        </FormGroup>
                    </Form>

                    <Form>
                        <FormGroup>
                        <label>Nom de famille :</label>
                        <FormInput name="nom" value={this.state.nom} onChange={e => this.handleChange(e)} size="lg" placeholder="Nom de famille" />
                        </FormGroup>
                    </Form>

                    <Form>
                        <FormGroup>
                        <label>Image :</label>
                        <input type="file" onChange={this.onFileChange} />
                        <a onClick={this.onFileUpload} class="btn btn-primary">Envoie</a><br/>
                        {this.state.image_url}
                        </FormGroup>
                    </Form>

                    <Form>
                        <FormGroup>
                        <label>Adresse courriel :</label>
                        <FormInput name="email" value={this.state.email} onChange={e => this.handleChange(e)} size="lg" placeholder="Email" />
                        </FormGroup>
                    </Form>

                    <Form>
                        <FormGroup>
                        <label>Mot de passe gmail</label>
                        <FormInput name="gmail_pwd" value={this.state.gmail_pwd} onChange={e => this.handleChange(e)} size="lg" placeholder="Password" />
                        </FormGroup>
                    </Form>

                    <Form>
                        <FormGroup>
                        <label>Mot de passe Facebook</label>
                        <FormInput name="facebook_pwd" value={this.state.facebook_pwd} onChange={e => this.handleChange(e)} size="lg" placeholder="Password" />
                        </FormGroup>
                    </Form>

                    {this.state.errorMsg && <div className="alert alert-danger">{this.state.errorMsg}</div>}


                </CardBody>
                <CardFooter>
                <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<NewReleasesSharpIcon />}
                            onClick={() => this.onDone()}
                            disabled = {this.state.onClicked}
                        >
                            C'est fait
                        </Button>

                <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<NewReleasesSharpIcon />}
                            onClick={() => this.abandon()}
                            disabled = {this.state.onClicked}
                        >
                            Abandonner
                        </Button>
                </CardFooter>
            </Card>
            </>         
        )
    }
}

export default VirtCreateAccount;
