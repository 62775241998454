import React from "react";
import { withRouter } from "react-router-dom";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput
} from "shards-react";


class Main extends React.Component {
  state = {
      searchText: ""
  };

  handleRoute = route => () => {
      this.props.history.push({ pathname: route });
  };

  handleSearchInput = event => {
      this.setState({
          searchText: event.target.value
      });
  };

  handleSearchSubmit = () => {
      if (this.state.searchText) {
          let text = this.state.searchText;
          this.setState({ searchText: "" })
          this.props.history.push({
              pathname: "/search",
              search: `term=${text}`
          });
      } else {
          alert("Please enter some search text!");
      }
  };

  handleSearchKeyUp = event => {
      event.preventDefault();
      if (event.key === 'Enter' && event.keyCode === 13) {
          this.handleSearchSubmit();
      }
  }

  handleFormSubmit = e => e.preventDefault();

  render() {
    return (
      <></>
    )
      return (
        <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex" onSubmit={this.handleFormSubmit}>
        <InputGroup seamless className="ml-3">
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <i className="material-icons">search</i>
            </InputGroupText>
          </InputGroupAddon>
          <FormInput
            className="navbar-search"
            onChange={this.handleSearchInput}
            value={this.state.searchText}
            onKeyUp={this.handleSearchKeyUp}
            placeholder="Search for something..."
          />
        </InputGroup>
      </Form>
      );
  }
}

export default withRouter(Main);
