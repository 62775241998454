import React from "react";

export class ShowValue extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            img: props.img,
            header: props.header,
            footer: props.footer
        }
    }

    render () {
        var img;
        if (typeof this.props.img !== "undefined")
            img = (<img src={this.props.img} alt="image" class="image"/>)
        return (
            <li>
                <a class="item">
                    {img}
                    <div class="in">
                        <div>
                            <header>
                                {this.props.header}
                            </header>                                        
                            <footer>
                                {this.props.footer}
                            </footer>
                            {this.props.other}
                            {this.props.children}
                        </div>
                    </div>
                </a>
            </li>
        )
    }
}

export default ShowValue;