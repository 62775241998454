import React from "react";
import { withRouter } from "react-router-dom";

import {
  Form,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";

class SidebarSearch extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      searchText: ''
    }
  }

handleRoute = route => () => {
  this.props.history.push({ pathname: route });
};

handleSearchInput = event => {
  this.setState({
      searchText: event.target.value
  });
};

handleSearchSubmit = () => {
  if (this.state.searchText) {
      let text = this.state.searchText;
      this.setState({ searchText: "" })
      this.props.closeSideBar();
      this.props.history.push({
          pathname: "/search",
          search: `term=${text}`
      });
  } else {
      alert("Please enter some search text!");
  }
};

performSearch(){
  
}

handleSearchKeyUp = event => {
    event.preventDefault();
    if (event.key === 'Enter' && event.keyCode === 13) {
        this.handleSearchSubmit();
    }
}

handleFormSubmit = e => e.preventDefault();

render(){

    return (
      <Form className="main-sidebar__search w-100 border-right d-sm-flex d-md-none d-lg-none" style={{ display: "flex", minHeight: "45px" }} onSubmit={this.handleFormSubmit}>
        <InputGroup seamless className="ml-3">
          <InputGroupAddon type="prepend">
            <InputGroupText>
              <i onClick={this.handleSearchSubmit.bind(this)} className="material-icons">search</i>
            </InputGroupText>
            <FormInput
              className="navbar-search"
              placeholder="Search for something..."
              aria-label="Search"
              onChange={this.handleSearchInput.bind(this)}
              value={this.state.searchText}
              onKeyUp={this.handleSearchKeyUp.bind(this)}
              />
          </InputGroupAddon>
        </InputGroup>
      </Form>
   )
  }
};

export default withRouter(SidebarSearch)