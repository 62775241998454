import React, { useState, useEffect } from 'react';
import {
  Container,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Badge,
  ListGroup,
  ListGroupItem,
  Row,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button
} from "shards-react";
import Viewer from 'react-viewer';

import Uploader from '../../components/impots/ImpotUploader';

const EditClientForm = props => {
  const [ client, setClient ] = useState(props.currentClient)
  const [ photos, setPhotos ] = useState(props.photos)
  const [ showPhotos, setShowPhotos ] = useState(false);
  const [ curPhoto, setCurrentPhoto] = useState("");
  const [ numPhoto, setNumPhoto ] = useState(0);

  useEffect(
    () => {
      setClient(props.currentClient)
    },
    [ props ]
  )

  const handleInputChange = event => {
    const { name, value } = event.target

    setClient({ ...client, [name]: value })
  }

  const handleCancel = event => {
		props.cancel();
  }
  
  const handleShowImages = event => {
    if (photos.length == 0) return;
    console.log("Showing image");
    setShowPhotos(true);
    setCurrentPhoto(photos[numPhoto]);
  }
  const handlePrevImage = event => {
    console.log(numPhoto);
    if (numPhoto > 0)
      setNumPhoto(numPhoto-1)
    else return;
    setCurrentPhoto(photos[numPhoto-1]);
  }
  const handleNextImage = event => {    
    if (numPhoto < photos.length - 1)
      setNumPhoto(numPhoto+1)
    else 
      return;
    console.log(numPhoto);
    setCurrentPhoto(photos[numPhoto+1]);
  }
  
  return (
    <Card >
    <Row><Col lg="8">
      <form
        onSubmit={event => {
          event.preventDefault()
          props.updateClient(client.id, client)
        }}>
        <Row form>
                {/* First Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="prenom">Prenom</label>
                  <FormInput
                    id="prenom"
                    name="prenom"
                    placeholder="Prenom"
                    value={client.prenom}
                    onChange={handleInputChange}
                  />
                </Col>
                {/* Last Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="nom">Nom</label>
                  <FormInput
                    id="nom"
                    name="nom"
                    placeholder="Nom de famille"
                    value={client.nom}
                    onChange={handleInputChange}
                  />
                </Col>

                <Col md="6" className="form-group">
                  <label htmlFor="nom">Téléphone</label>
                  <FormInput
                    id="telephone"
                    name="telephone"
                    placeholder="Numéro de téléphone"
                    value={client.telephone}
                    onChange={handleInputChange}
                  />
                </Col>

                <Col md="6" className="form-group">
                  <label htmlFor="nom">Courriel</label>
                  <FormInput
                    id="email"
                    name="email"
                    placeholder="Adresse courriel"
                    value={client.email}
                    onChange={handleInputChange}
                  />
                </Col>

                <Col md="6" className="form-group">
                  <label htmlFor="nom">Adresse</label>
                  <FormInput
                    id="adresse"
                    name="adresse"
                    placeholder="Adresse"
                    value={client.adresse}
                    onChange={handleInputChange}
                  />
                </Col>

                <Col md="6" className="form-group">
                  <label htmlFor="nom">Ville</label>
                  <FormInput
                    id="city"
                    name="city"
                    placeholder="Ville"
                    value={client.city}
                    onChange={handleInputChange}
                  />
                </Col>

                <Col md="6" className="form-group">
                  <label htmlFor="nom">Code postal</label>
                  <FormInput
                    id="zipcode"
                    name="zipcode"
                    placeholder="Code postal"
                    value={client.zipcode}
                    onChange={handleInputChange}
                  />
                </Col>

                <Col md="6" className="form-group">
                  <label htmlFor="nom">Province</label>
                  <FormInput
                    id="state"
                    name="state"
                    placeholder="Province"
                    value={client.state}
                    onChange={handleInputChange}
                  />
                </Col>

                <Col md="6" className="form-group">
                  <label htmlFor="nom">Pays</label>
                  <FormInput
                    id="country"
                    name="country"
                    placeholder="Pays"
                    value={client.country}
                    onChange={handleInputChange}
                  />
                </Col>

                <Col md="6" className="form-group">
                  <label htmlFor="nom">Détails livraison</label>
                  <FormInput
                    id="delivery_details"
                    name="delivery_details"
                    placeholder="Détails sur la livraison"
                    value={client.delivery_details}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
              <Button theme="accent">Mettre à jour</Button>
              <Button theme="accent" onClick={() => handleCancel()} className="button muted-button">
                Cancel
              </Button>
      </form>
      </Col></Row>
    </Card>
  )
}

export default EditClientForm;