import React from 'react'
import {
    Container,
    FormSelect,
    Form,
    FormInput,
    FormGroup
} from "shards-react";

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import {api} from '../../services/autonome';

export class SendMoneyView extends React.Component {

    constructor(props) {
        super(props)
        //console.log(props)
        this.state = {
            sending: false,
            fromAccountId: props.match.params.fromId,
            Amount: 0,
            ToEmail: "",
            accounts: [],
            error_msg: "",
            is_error: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value,
          is_error: false
        });
    }

    handleSubmit(event) {
        this.setState({sending: true});
        var these = this;
        var data = {
            fromWalletId: this.state.fromAccountId,
            toEmail: this.state.ToEmail,
            amount: this.state.Amount
        }
        //alert(JSON.stringify(this.state));
        //event.preventDefault();
        api.call("post", "send_money", data).then(result => {
            //console.log("After create new tr");
            //console.log(result);
            setTimeout( function () {
                these.props.history.goBack();
            },1500); // Give time to be updated
        }).catch(function(err) {
            //alert(JSON.stringify(err));
            these.setState({sending: false, is_error: true, error_msg: "Une erreure s'est produite."});
        });
    }

    componentDidMount() {
        api.call('post', "user/usrLog", {module: "views", title:"send_money"});
        var self = this;
        api.call("get", "finance/cad_wallet", {}).then(result => {
            //console.log("Got wallet list");
            self.setState({ accounts: result.data, fromAccountId: result.data.filter(acc => acc.asset == 'CAD')[0].walletId})
        });
    }

    render(){
        return(
            <Container fluid className="main-content-container px-4">
            <Breadcrumb>
                <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>
                <Breadcrumb.Item href="#/wallet">Porte feuille</Breadcrumb.Item>
                <Breadcrumb.Item active>Envoie d'argent</Breadcrumb.Item>
            </Breadcrumb>

            <div className="w-100">
                
                <h1 className="m-4">Envoyer de l'argent</h1>

                <Form onSubmit={this.handleSubmit}>

                <FormGroup>
                    <label htmlFor="#username">De quel compte?</label>
                    <FormSelect name="fromAccountId" value={this.state.fromAccountId} onChange={this.handleChange}>
                    {this.state.accounts.filter(acc => acc.asset == 'CAD').map((wallet) => <option key={wallet.walletId} value={wallet.walletI}>Wallet #{wallet.walletId} - {wallet.quantity}$</option>)}
                    </FormSelect>
                </FormGroup>
                <FormGroup>
                    <label htmlFor="#username">Courriel du destinataire :</label>                    
                    <FormInput type="text" name="ToEmail" value={this.state.ToEmail} onChange={this.handleChange} />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="#username">Montant :</label>                    
                    <FormInput type="text" name="Amount" value={this.state.Amount} onChange={this.handleChange} />
                </FormGroup>

                <Button
                    disabled={this.state.sending}
                    variant="contained"
                    color="primary"
                    size="small"    
                    startIcon={<LocalAtmIcon />}
                    onClick={() => this.handleSubmit()}
                >
                    Envoyer argent
                </Button>
                {this.state.is_error && <Alert severity="warning">{this.state.error_msg}</Alert>}
            
                </Form>

            </div>
            </Container>
        )
    }
}