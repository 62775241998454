import React, { useRef, useState } from "react";
import {api} from '../../../services/autonome';
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import DirectionsIcon from '@material-ui/icons/Directions';
import { Table, Form, Modal } from "react-bootstrap";
import CheckIcon from '@material-ui/icons/Check';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";
import DragAndDrop from '../../../components/filedragdrop';
import axios from 'axios';

class ModuleView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            module: {name:"", npms:[], functions: [], init_function: "", routes: []},
            moduleName: props.match.params.moduleName,
            selectedFunction: null,
            showNewFunction: false,
            showNewInput: false,
            showNewRoute: false,
            output: "",
            showCreateBucket: false,
            inputs: {},
            files: []
        }
        //this.editorRef = useRef(null);
        this.state.userId = this.getUserId();
        this.changeContentType = this.changeContentType.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.handleEditorInitChange = this.handleEditorInitChange.bind(this);
        this.handleEditorDidMount = this.handleEditorDidMount.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    handleDrop = (name, files) => {
        var self = this;
        for (var i = 0; i < files.length; i++) {
          if (!files[i].name) return;
            const data = new FormData();
            data.append('file', files[i]);
            this.setState({
                uploadMsg: "Upload en cours"
            });
            axios
            .post("https://api.liberium.net/s/mod-" + this.state.moduleName, data)
            .then(function (response) {
                console.log(response);
                self.fetchS3();
            })
        }
        /*
        var self = this;
        for (var i = 0; i < files.length; i++) {
          if (!files[i].name) return;
            console.log("Uploading file to S3");
            var these = this;
            var body = {
                cmd:"askFunction",
                module: "cephfs_s3",
                name: "upload",
                file: files[i]
            }
            api.callLb('post', "apis/internal/function", body).then(result => {
                result = result.data;
                console.log("from LB function:",result);
            });
        }
        */
    }

    fetchData() {
        var these = this;
        var body = {
            cmd:"askFunction",
            module: "modules",
            name: "getModule",
            moduleName: this.state.moduleName
        }
        api.callLb('post', "apis/internal/function", body).then(result => {
            result = result.data;
            console.log("from LB function:",result);
            these.setState({module: {
                name: result.name,
                functions: JSON.parse(result.functions),
                npms: result.npms,
                init_function: result.init_function,
                routes: JSON.parse(result.routes)
            }});
        });

        this.fetchS3();
    }

    updateSDK() {
        var these = this;
        var body = {
            cmd:"askFunction",
            module: "modules",
            name: "createSdk"
        }
        api.callLb('post', "apis/internal/function", body).then(result => {
            result = result.data;
            console.log("from LB function:",result);
        });
    }

    fetchS3() {
        var these = this;
        var body2 = {
            cmd:"askFunction",
            module: "cephfs_s3",
            name: "listObjects",
            bucketName: "mod-" + this.state.moduleName
        }
        api.callLb('post', "apis/internal/function", body2).then(result => {
            result = result.data;
            console.log("from LB function:",result);
            if (typeof result.error !== "undefined") {
                these.setState({showCreateBucket: true});
            } else
                these.setState({files: result});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();

        window.gtag('event', 'page_view', {
            page_title: 'Modules',
            page_location: '/admin/modules',
            page_path: '/admin/modules'
        });
    }

    selectFunction(row) {
        console.log("selectFunction hooked");
        var selected = null;
        var module = this.state.module;
        module.functions.forEach(function(one) {
            if (one.name === row.name)
                selected = one;
        });
        console.log(typeof selected.inputs);
        if (typeof selected.inputs !== "object")
            selected.inputs = JSON.parse(selected.inputs);
        console.log("selected:",module)
        this.setState({selectedFunction:selected});
    }

    saveModule(callback) {
        console.log("Saving module:", this.state.module);
        var mod = JSON.parse(JSON.stringify(this.state.module));
        console.log(mod.npms);
        mod.npms = JSON.parse(mod.npms);
        var body = mod;
        body.cmd = "askFunction";
        body.module = "modules";
        body.name = "saveModule";
        body.moduleName = this.state.moduleName;
        console.log(body);
        api.callLb('post', "apis/internal/function", body).then(result => {
            if (typeof callback === "function")
                callback();
        });
    }

    handleChange(event) {
        var these = this;
        //console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state.selectedFunction;
        new_form[name] = value;

        var functions = this.state.module.functions;
        functions.forEach(function(one) {
            if (one.name === these.state.selectedFunction.name)
                one = these.state.selectedFunction;
        })
        var module = this.state.module;
        module.functions = functions;
        this.setState({selectedFunction:new_form, errorMsg: null, module: module});
    }

    createFunction() {
        var functions = this.state.module.functions;
        var newFunction = {name: this.state.newFunctionName, inputs: [], code: ""}
        functions.push(newFunction);
        var module = this.state.module;
        module.functions = functions;
        this.setState({module: module, showNewFunction: false, selectedFunction: newFunction, newFunctionName:""});
    }

    handleModal(event) {
        //console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({newFunctionName: value});
    }

    handleInput(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({newInputName: value});
    }

    handleNpms(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        var mod = this.state.module;
        mod.npms = value;
        this.setState({module: mod});
    }

    handleInputRun(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var form = this.state.inputs;
        form[name] = value
        this.setState({inputs: form});
    }

    handleNewRoute(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var form = this.state;
        form[name] = value
        this.setState(form);
    }

    handleInit(event) {
        //console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state.module;
        new_form[name] = value;
        this.setState({module: new_form});
    }

    changeContentType(event) {
        var these = this;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state.selectedFunction;
        new_form[name] = value;
        console.log(new_form);

        var functions = this.state.module.functions;
        functions.forEach(function(one) {
            if (one.name === these.state.selectedFunction.name)
                one = these.state.selectedFunction;
        })
        var module = this.state.module;
        module.functions = functions;
        this.setState({selectedFunction:new_form, errorMsg: null, module: module});
    }

    openInput() {
        this.setState({showNewInput: true});
    }

    saveNewRoute() {
        var lists = this.state.module.routes;
        lists.push({hostname: this.state.newRouteHostname, path:this.state.newRoutePath,function:this.state.newRouteFunction});
        var module = this.state.module;
        module.routes = lists;
        this.setState({module: module});
    }

    saveInput() {
        var these = this;
        var func = this.state.selectedFunction;
        func.inputs.push({name:this.state.newInputName});

        var functions = this.state.module.functions;
        functions.forEach(function(one) {
            if (one.name === these.state.selectedFunction.name)
                one = func;
        })
        var module = this.state.module;
        module.functions = functions;
        this.setState({selectedFunction:func, module: module, showNewInput: false, newInputName: ""});
    }
    
    closeInput() {
        this.setState({showNewInput: false});
    }

    deleteInput(row) {

    }

    handleEditorInitChange(value, event) {
        var these = this;
        console.log("Handle change init function");
        const target = event.target;
        var mod = this.state.module;
        mod.init_function = value;

        this.setState({mod: value});
    }

    handleEditorChange(value, event) {
        var these = this;
        //console.log("Handle change", event.target.name);
        const target = event.target;
        
        var new_form = this.state.selectedFunction;
        new_form.codes = value;

        var functions = this.state.module.functions;
        functions.forEach(function(one) {
            if (one.name === these.state.selectedFunction.name)
                one = these.state.selectedFunction;
        })
        var module = this.state.module;
        module.functions = functions;
        this.setState({selectedFunction:new_form, errorMsg: null, module: module});
    }

    handleEditorDidMount(editor, monaco) {
        //console.log("onMount: the editor instance:", editor);
        //console.log("onMount: the monaco instance:", monaco)
        //editorRef.current = editor;
    }

    renderFunction() {
        if (this.state.selectedFunction === null) return;
        //alert(JSON.stringify(this.state.selectedFunction));
        var inputs = this.state.selectedFunction.inputs.map((row) => {
                return (
                    <li>{row.name}<Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => this.deleteInput(row)}
                >
                    -
                </Button></li>
                )
        });
        return (
            <>
            <h2>Function: {this.state.selectedFunction.name}</h2>
            <Form.Select 
                aria-label="Default select example"
                name="contentType"
                value={this.state.selectedFunction.contentType}
                onChange={this.changeContentType}
            >
                <option value="js">Node.js</option>
                <option value="html">Html</option>
            </Form.Select>
            <h3>Inputs
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<CheckIcon />}
                    onClick={() => this.openInput()}
                >
                    +
                </Button>
            </h3>
            <ul>
            {inputs}
            </ul>

            <Editor
                height="90vh"
                defaultLanguage="javascript"
                value={this.state.selectedFunction.codes}
                onChange={this.handleEditorChange}
                onMount={this.handleEditorDidMount}
                options={{
                    theme: 'vs-dark',
                }}
            >
            </Editor>
            </>
        )
    }

    renderFunctions() {
        return this.state.module.functions.map((row) => {
            return (
                <tr onClick={(e)=>this.selectFunction(row)}>
                <td>{row.name}</td>
                </tr>
            );
          });
    }

    renderModule() {
        return (
            <>
                <h1>{this.state.module.name}</h1>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <table>
                                {this.renderFunctions()}
                            </table>
                        </div>
                        <div class="col-6">
                            {this.renderFunction()}
                        </div>
                        <div class="col">
                            {this.renderTest()}
                        </div>
                    </div>
                </div>
                <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20 }}>
                    <div>
                        
                    </div>
                    <div></div>
                    <div>                        
                        
                    </div>
                </div>
            </>
        )
    }

    goServers() {
        this.props.history.push({
          pathname: "/admin/servers"
        });
    }

    edit(row) {
        this.props.history.push({
          pathname: "/admin/ide/module/" + row.id
        });
    }

    newRoute() {
        this.setState({showNewRoute: true});
    }

    closeNewRoute() {
        this.setState({showNewRoute: false});
    }

    newFunction() {
        this.setState({showNewFunction: true});
    }

    closeModal() {
        this.setState({showNewFunction: false});
    }

    deploy() {
        var these = this;
        var body = {
            cmd:"askFunction",
            module: "modules",
            name: "deployModule",
            moduleName: this.state.moduleName
        }
        api.callLb('post', "apis/internal/function", body).then(result => {
            result = result.data;
            console.log("from LB function:",result);
            alert("Deploy command sent");
        });
        this.updateSDK();
    }

    runFunction() {
        this.setState({output:"Loading..."});
        var these = this;
        this.saveModule(function () {
            var body = {
                cmd:"askFunction",
                module: "test_function",
                name: "testFunction",
                functionToTest: these.state.selectedFunction.name,
                moduleName: these.state.moduleName
            }
            Object.keys(these.state.inputs).forEach(function(key) {
                body[key] = these.state.inputs[key];
            })
            api.callLb('post', "apis/internal/function", body).then(result => {
                result = result.data;
                console.log("from LB function:",result);
                //alert(JSON.stringify(result));
                these.setState({output:result.split('\n').join("<br/>")});
            });
        });
    }

    renderTest() {
        var inputs = (<></>)
        if (this.state.selectedFunction)
            var inputs = this.state.selectedFunction.inputs.map((row) => {
                return (
                    <li>                        
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>{row.name}</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name={row.name}
                                    value={this.state.inputs[row.name]} 
                                    onChange={e => this.handleInputRun(e)}
                                />
                            </Form.Group>
                        </Form>
                    </li>
                )
            });
        return (
            <>
            <h2>Test function</h2>
            <ul>
                {inputs}
            </ul>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.runFunction()}
            >
              Run
            </Button>
            <div className="content" dangerouslySetInnerHTML={{__html: this.state.output}}></div>
            </>
        )
    }

    removeRoute(row) {
        var lists = this.state.module.routes;
        const index = lists.indexOf(row);
        if (index > -1) {
            lists.splice(index, 1);
        }
        var module = this.state.module;
        module.routes = lists;
        this.setState({module: module});
    }

    renderRoutes() {
        return this.state.module.routes.map((row) => {
            return (
                <tr onClick={(e)=>this.selectFunction(row)}>
                <td>{row.hostname}</td>
                <td>{row.path}</td>
                <td>{row.function}</td>
                <td><a onClick={()=>this.removeRoute(row)}>Remove</a></td>
                </tr>
            );
          });
    }

    createStorage() {
        this.setState({showCreateBucket: false});
        var these = this;
        var body2 = {
            cmd:"askFunction",
            module: "cephfs_s3",
            name: "makeBucket",
            bucketName: "mod-" + this.state.moduleName
        }
        api.callLb('post', "apis/internal/function", body2).then(result => {
            result = result.data;
            console.log("from LB function:",result);
        });
    }

    removeFile(fichier) {
        var these = this;
        var body2 = {
            cmd:"askFunction",
            module: "cephfs_s3",
            name: "deleteFile",
            bucketName: "mod-" + this.state.moduleName,
            fileName: fichier
        }
        api.callLb('post', "apis/internal/function", body2).then(result => {
            result = result.data;
            console.log("from LB function:",result);
            these.fetchS3();
        });
    }

    renderStorage() {
        if (this.state.showCreateBucket)
            return (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<DirectionsIcon />}
                    onClick={() => this.createStorage()}
                    >
                Create Storage
                </Button>
            );
        var files = this.state.files.map((row) => {
            var url = "https://api.liberium.net/s/mod-" + this.state.moduleName + "/" + row.name;
            return (
                <tr>
                    <td><a href={url} target="_blank">{row.name}</a></td>
                    <td>{row.size}</td>
                    <td><a onClick={(e)=>this.removeFile(row.name)}>Delete</a></td>
                </tr>
            )
        });
        return (
        <>
            <DragAndDrop name="uploadFile" handleDrop={this.handleDrop}>            
                <h1>Drag file right here</h1>
            </DragAndDrop>
            <Table>
                    <thead>
                        <tr>
                            <td>File</td>
                            <td>Size</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {files}
                    </tbody>
                </Table>
        </>
        )
    }

    renderModel() {
        return (
            <>
            </>
        )
    }

    render(){
        return(
            <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.saveModule()}
            >
              Save
            </Button>

            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="home" title="Functions">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<DirectionsIcon />}
                        onClick={() => this.newFunction()}
                    >
                        New function
                    </Button>
                    {this.renderModule()}
                </Tab>
                <Tab eventKey="init" title="Init Code">
                    <Form.Group className="mb-3">
                        <Form.Label>Init Module Code</Form.Label>
                        <Editor
                            height="90vh"
                            defaultLanguage="javascript"
                            value={this.state.module.init_function}
                            onChange={this.handleEditorInitChange}
                            options={{
                                theme: 'vs-dark',
                            }}
                        >
                        </Editor>
                    </Form.Group>
                </Tab>
                <Tab eventKey="model" title="Model">
                    {this.renderModel()}
                </Tab>
                <Tab eventKey="routes" title="Routes">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<DirectionsIcon />}
                        onClick={() => this.newRoute()}
                    >
                        New route
                    </Button>
                    <Table aria-label="customized table">
                        <thead>
                            <tr>
                                <th>Hostname</th>
                                <th>Path</th>
                                <th>Function</th>
                            </tr>
                        </thead>
                        <tbody>                        
                            {this.renderRoutes()}
                        </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="s3" title="Storage">
                    {this.renderStorage()}
                </Tab>
                <Tab eventKey="settings" title="Configuration">
                    <Form.Group className="mb-3">
                        <Form.Label>NPMs</Form.Label>
                        <Form.Control 
                            type="text"
                            name="npms"
                            value={this.state.module.npms} 
                            onChange={e => this.handleNpms(e)}
                        />
                    </Form.Group>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<DirectionsIcon />}
                        onClick={() => this.deploy()}
                    >
                        Deploy
                    </Button>
                </Tab>
            </Tabs>

            <Modal
                size="lg"
                show={this.state.showNewFunction}
                onHide={() => this.closeModal()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    New function
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Function name</Form.Label>
                        <Form.Control 
                            type="text"
                            name="newFunctionName"
                            value={this.state.newFunctionName} 
                            onChange={e => this.handleModal(e)}
                        />
                    </Form.Group>
                    </Form>
                    <div class="container">
                        <div class="row">
                            <div class="col text-center">
                                <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<CheckIcon />}
                                onClick={() => this.createFunction()}
                                >
                                    Create
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={this.state.showNewInput}
                onHide={() => this.closeInput()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    New input
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Input variable name</Form.Label>
                        <Form.Control 
                            type="text"
                            name="newInputName"
                            value={this.state.newInputName} 
                            onChange={e => this.handleInput(e)}
                        />
                    </Form.Group>
                    </Form>
                    <div class="container">
                        <div class="row">
                            <div class="col text-center">
                                <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<CheckIcon />}
                                onClick={() => this.saveInput()}
                                >
                                    Create
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={this.state.showNewRoute}
                onHide={() => this.closeNewRoute()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    New route
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Hostname (liberium.net)</Form.Label>
                        <Form.Control 
                            type="text"
                            name="newRouteHostname"
                            value={this.state.newRouteHostname} 
                            onChange={e => this.handleNewRoute(e)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Route path</Form.Label>
                        <Form.Control 
                            type="text"
                            name="newRoutePath"
                            value={this.state.newRoutePath} 
                            onChange={e => this.handleNewRoute(e)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Route function</Form.Label>
                        <Form.Control 
                            type="text"
                            name="newRouteFunction"
                            value={this.state.newRouteFunction} 
                            onChange={e => this.handleNewRoute(e)}
                        />
                    </Form.Group>
                    </Form>
                    <div class="container">
                        <div class="row">
                            <div class="col text-center">
                                <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<CheckIcon />}
                                onClick={() => this.saveNewRoute()}
                                >
                                    Create
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            </>
        )
    }
}

export default withRouter(ModuleView);