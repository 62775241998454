import React from "react";
import {api} from '../../../services/autonome';
import { Container } from "shards-react";
import Ad from '../../../components/ads/AdItem';
import ProfileNotUpdated from '../../../components/users/profile_not_updated';
import ShopItem from '../../../components/shop/item';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from '@material-ui/core/Button';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';

class ShoppingOrgView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        items: [],
        businessId: props.match.params.orgId,
        business: {logo_url:""},
        items: [],
        subscriptions: [],
        address: "(en chargement)"
      }
    this.canvasRef = React.createRef();
  }

  fetchData() {
    var isValid = true;
    var count = 0;
    api.call('get', "users/profile", {}).then(result => {
      if (typeof result.data.error != "undefined") return;
      this.setState({
          address: result.data.street_adress + "," + result.data.city
      });
    });
    api.call('get','businesse/' + this.state.businessId, {}).then(result => {
        if (typeof result.data.error != "undefined") return;
        this.setState({
            business: result.data
        });
    });

    api.call('get','organisation/' + this.state.businessId + "/subscriptions", {}).then(result => {
        //console.log(result.data);
        if (typeof result.data.error != "undefined") return;
        this.setState({
            subscriptions: result.data
        });
    });
    /*
    api.call('get', "shopping/products", {}).then(result => {
      count++;
        if (typeof result.data.error !== "undefined") return;
        this.setState({
            items: result.data
        });
    });
    */
  }

  componentDidMount() {
    this.fetchData();
    window.gtag('event', 'page_view', {
          page_title: 'Shopping',
          page_location: '/shop/org/' + this.state.businessId,
          page_path: '/shop/org/' + this.state.businessId
    })
  }

  componentWillUnmount() {
  }

  splitArrayIntoChunksOfLen(arr, len) {
    var results = [];
    for (let i = 0; i < arr.length; i += len) {
      results.push(arr.slice(i, i + len));
    }
    return results;
  }

  renderItems() {
    if (this.state.items.length === 0) return (<></>);
    console.log("items:",this.state.items);
    var splited = this.splitArrayIntoChunksOfLen(this.state.items,3);
    console.log("splited:",splited);
    var group = 0;
    return splited.map((row) => {
      group++;
      //console.log(row);
      var j = 0;
      var rowItems = row.map((row2) => {
        j++;
        return (
            <ShopItem key={row2.id} item={row2} address={this.state.address}/>
        )
      });
      if (j==1)
        return (
          <div>          
            {rowItems}
            </div>
        )

      return (
        <CardGroup key={group}>
            {rowItems}
        </CardGroup>
      )
    });
  }

  renderSubscriptions() {
    if (this.state.subscriptions.length === 0) return (<></>);
    return this.state.subscriptions.map((row) => {
        var description = row.description.split("\n").join("<br/>");
        return (
            <>
                <h2>{row.name}</h2>
                <p><div dangerouslySetInnerHTML={{__html: description}} /></p>
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<AttachMoneySharpIcon />}
                    onClick={() => this.goDeposit()}
                >
                    Abonnez-vous
                </Button>
            </>
        )
    });
  }

  render() {
    var items = this.renderItems();
    return (
      <Container fluid className="main-content-container px-4">
        <img src={this.state.business.logoUrl} width="100%"/>
        <h1>Boutique</h1>

        {this.renderSubscriptions()}
        <p>Nous aimons l'achat local. Ces articles sont dans un entrepot prêt de chez vous.</p>
        
        <h1>Articles</h1>

        {items}
      </Container>
    );
  }
}

export default ShoppingOrgView;