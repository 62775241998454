import {api} from '../../services/autonome';
import React from "react";
import { Container } from "shards-react";
import { Form, Alert, Modal } from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Table from 'react-bootstrap/Table';
import { PagesRounded } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ViewListIcon from '@material-ui/icons/ViewList';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export class PixelsPage extends React.Component {
  state = {
    show: false,
    stats: {},
    errorMsg : ""
  }

  constructor(props) {
    super(props);
  }

  fetchData() {
    var these = this;
    api.call('get', "ads/pixels_stats", {}).then(result => {
      var the_ads = [];
      result.data.forEach(function(one) {
        var parsed = JSON.parse(one.metas);
        if (typeof parsed.kindAd === "undefined")
          parsed.kindAd = 1;
        one.metas = parsed;
        the_ads.push(one);
      })
      //console.log("The ads:", the_ads);
      these.setState({ads: the_ads});
    });
  }

  handleZones() {
    this.props.history.push({
        pathname: "/zones"
    });
  }

  componentDidMount() {
    api.call('post', "user/usrLog", {module: "views", title:"ads"});
    this.fetchData();
    window.gtag('event', 'page_view', {
      page_title: 'Pixels',
      page_location: '/pixels',
      page_path: '/pixels'
    })
  }

  componentWillUnmount() {
  }

  handleAd() {
    this.props.history.push({
        pathname: "/ads"
    });
}

  render() {
    return (
      <Container fluid className="main-content-container px-4">

        <Breadcrumb>
            <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>
            <Breadcrumb.Item active>Pixels</Breadcrumb.Item>
        </Breadcrumb>

        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button
                startIcon={<AnnouncementIcon />}
                onClick={e => this.handleAd()}
            >
                Annonces
            </Button>
          <Button 
            startIcon={<ViewListIcon />}
            onClick={e => this.handleZones()}
          >
                Zones
          </Button>
        </ButtonGroup>

        <h1>Pixels</h1>

        <p>Le meilleur moyen de faire revenir vos utilisateurs est en utilisant un pixel.</p>

        <p>C'est un bout de code que vous placez sur votre site web qui vont tagger vos visiteurs sur notre réseau publicitaire.</p>

        <p>En reciblant des personnes qui non pas complété un formulaire, ou en reciblant des visiteurs qui ont déja acheter sur votre boutique, votre "retour-sur-investisttement" sera très bon.</p>

        <p>Placez ce bout de code sur votre sites</p>

        <h1>Statistiques</h1>

        <p>Nous n'avons collecter aucune donnée pour le moment.</p>

    </Container>
    );
  }
}