import React, { Component } from "react";
import { Breadcrumb, Card, Form, Button, Table } from "react-bootstrap";
import {api} from '../../services/autonome';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import DragAndDrop from '../../components/filedragdrop';
import axios from 'axios';
import {FbAdView} from "./fb_ad";

class PubliciteForm extends Component {
  constructor(props) {
    super(props);

    this.adId = parseInt(props.match.params.id);

    this.state = {
      typePublicite: "image",
      titre: "",
      description: "",
      image: null,
      url: "",
      isActive: true,
      ad: {
        id:"",
        metas: {
          kindAd:0
        }
      }
    };

    this.handleOnOff = this.handleOnOff.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  save() {
    var body = this.state.ad;
    body.metas = JSON.stringify(body.metas)
    api.call('patch', "ads/ad/" + this.adId, body).then(result => {
      alert("saved");
    });
  }

  fetchData() {
    var these = this;
    api.call('get', "ads/get_ad/" + this.adId, {}).then(result => {
      result.data.metas = JSON.parse(result.data.metas);
      these.setState({ad: result.data});
      console.log("ad:", result.data);
    });
  }

  handleActiveChange = (event) => {
    this.setState({ isActive: event.target.checked });
  };

  handleTypeChange(event) {
    this.setState({ typePublicite: event.target.value });
  }

  handleTitreChange(event) {
    this.setState({ titre: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  handleImageChange(event) {
    this.setState({ image: event.target.files[0] });
  }

  handleUrlChange(event) {
    this.setState({ url: event.target.value });
  }


  handleChange(event) {
    console.log("Handle change", event);
    const target = event.target;
    console.log(target);
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state.ad;
    new_form[name] = value;
    this.setState({ad:new_form});
  }


  handleChangeHtml(event) {
    console.log("Handle change", event);
    const target = event.target;
    console.log(target);
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state.ad;
    new_form.metas.html = value;
    this.setState({ad:new_form});
  }

  handleStats() {

  }

  handleSubmit(event) {
    event.preventDefault();
  }

  handleOnOff() {
    var ad = this.state.ad;
    ad.enabled = !ad.enabled;
    api.call('post', "ads/set_ad_enable", {adId: ad.id, enabled: ad.enabled}).then(result => {
    });    
    this.setState({ad: ad});
  }

  goBack() {
    this.props.history.push({
        pathname: "/ads"
    });
  }

  handleDrop = (name, files) => {
    var self = this;
    for (var i = 0; i < files.length; i++) {
      if (!files[i].name) return;
        const data = new FormData();
        data.append('file', files[i]);
        this.setState({
            uploadMsg: "Upload en cours"
        });
        axios
        .post("https://api.liberium.net/ads/new_image", data)
        //.post("http://localhost:9090/ads/new_image", data)
        .then(function (response) {
            console.log(response);
            var ad = self.state.ad;
            ad.metas.banners.push({url:response.data.url, size: response.data.size});
            self.setState({ad:ad});
        })
    }
  }


  renderHtml() {
    return (
      <div id="renderHtml">
        <h2>Code HTML</h2>
            <Form.Group className="mb-3" controlId="texte" key="texte">
              <Form.Label>Code HTML</Form.Label>
              <Form.Control as="textarea" 
                rows={3} 
                name='html'
                value={this.state.ad.metas.html} 
                onChange={e => this.handleChangeHtml(e)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="texte" key="texte">
              <Form.Label>Ad size (ex: "300x250")</Form.Label>
              <Form.Control
                  type="text"
                  name='size'
                  value={this.state.ad.metas.size} 
                  onChange={e => this.handleChange(e)}
              >
              </Form.Control>
          </Form.Group>
      </div>
    )
  }

  renderFile() {
    var these = this;
    var files = this.state.ad.metas.banners.map((file) => {
      return (
        <tr key={file}>
            <td component="th" scope="row">
                <img src={file.url} width={100}/><br/>
                {file.size}
            </td>
            <td align="right">
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<EditIcon />}
                  onClick={() => these.deleteFile(file.url)}
                >
                    Delete
                </Button>
            </td>
        </tr>
      )
    });
    return (
      <>
        <h2>Publicité Graphique</h2>
          <Form.Group className="mb-3" controlId="texte" key="texte">
            <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                name='url'
                value={this.state.ad.url} 
                onChange={e => this.handleChange(e)}
              >
              </Form.Control>
          </Form.Group>

          <DragAndDrop name="graphs" handleDrop={this.handleDrop}>
            <h2>Déplacer des fichiers ici</h2>
            <Table aria-label="customized table">
              <thead>
                <tr>
                  <td>Photo</td>
                  <td align="right">Action</td>
                </tr>
              </thead>
              <tbody>
                {files}
              </tbody>
            </Table>
          </DragAndDrop>
      </>
    )
  }
  
  renderFbAd() {
    return (
      <FbAdView
        ad={this.state.ad}
      >

      </FbAdView>
    )
  }

  renderAd() {
    if (this.state.page === "targets")
      return this.renderTargets();

    if (this.state.ad.metas.kindAd === 2)
        return this.renderFile();
    if (this.state.ad.metas.kindAd === 3)
        return this.renderHtml();
    if (this.state.ad.metas.kindAd === 10)
        return this.renderImmobillier();
    if (this.state.ad.metas.kindAd === 50)
        return this.renderFbAd();
  }

  renderCpm() {
    return (
      <Form.Group className="mb-3" controlId="texte" key="texte">
      <Form.Label>Coût par mille affichage</Form.Label>
      <Form.Control
          type="text"
          name='maxPrice'
          value={this.state.ad.maxPrice} 
          onChange={e => this.handleChange(e)}
      >
      </Form.Control>
      </Form.Group>
    )
  }

  renderBottom(){
    return (
      <>
      {this.state.ad.metas.kindAd !== 50 && this.renderCpm()}

      {this.state.ad.metas.kindAd !== 50 &&
        <Button variant="primary" onClick={(e)=>this.save()}>Sauvegarder</Button>
      } 
      </>
    )
  }

  renderStats() {
    return (
      <>
          <Alert severity="success">
            {this.state.ad.impressions} impressions total
          </Alert>
          <Alert severity="success">
            {this.state.ad.clicks} cliques total
          </Alert>
          </>
    )
  }

  render() {
    return (
      <>
      <Breadcrumb>
            <Breadcrumb.Item href="#/ads">Annonces</Breadcrumb.Item>
            <Breadcrumb.Item active>Annonce #{this.state.ad.id}</Breadcrumb.Item>
        </Breadcrumb>
      <Card>
        <Card.Body>
            {this.renderStats()}
            {this.state.ad.metas.kindAd !== 50 && 
              <Form.Group controlId="activePublicite">
                <Form.Label>Annonce active :</Form.Label>
                <Form.Check
                type="switch"
                id="custom-switch"
                checked={this.state.ad.enabled}
                onChange={(e)=>this.handleOnOff()}
                />
              </Form.Group>
            }
            {this.renderAd()}
            {this.renderBottom()}
        </Card.Body>
    </Card>
    </>
    )}
  }

export default PubliciteForm;