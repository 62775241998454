//https://www.bootdey.com/snippets/view/chat-app#html

import React from 'react'
import { ChatUser } from './chat_user';
import {api} from '../../services/autonome';
import { withRouter } from 'react-router'; 

export class DiscussionsView extends React.Component {

    constructor(props) {
        super(props)
        //console.log(props)

        this.state = {
            users: []
        }
    }

    getUserId() {
        var existingTokens;
        try {
            existingTokens = JSON.parse(localStorage.getItem('tokens'));
            return existingTokens.UserId;
        } catch(e) {
            return null;
        }
    }

    fetchMessages(callback) {
        var these = this;
        //alert(this.state.getController);
        api.call("post", "chat/get_discussions_with", {withUserId: this.state.withUserId}).then(result => {
            //console.log("Got messages:", result.data);
            //alert(JSON.stringify(result.data.other))
            these.setState({ users: result.data})
            if (typeof callback === "function")
                callback();
        });
    }

    componentWillUnmount() {
    }

    componentDidMount() {
        api.call('post', "user/usrLog", {module: "views", title:"discussions"});
        var these = this;
        this.fetchMessages();
        window.gtag('event', 'page_view', {
            page_title: 'Discussions',
            page_location: '/discussions',
            page_path: '/discussions'
        });
    }

    goBack() {
        if (window.history.length<=1) {
            this.props.history.push({
                pathname: "/home"
            });
        } else {
            window.history.go(-1);
        }
    }

    render(){
        let list = <div classname="no-content-message">Il n'y a pas de messages.</div>;
        if (this.state.users.length > 0)
            list = this.state.users.map(m => <ChatUser 
                key={m.id} 
                userId={m.userId} 
                name={m.name}
                isOnline={m.isOnline}>
            </ChatUser>);

        return(
            <>
                <div class="appHeader">
                    <div class="left">
                        <a onClick={() => this.goBack()} class="headerButton goBack">
                            <ion-icon name="chevron-back-outline" role="img" class="md hydrated" aria-label="chevron back outline"></ion-icon>
                        </a>
                    </div>
                    <div class="pageTitle">
                    Discussions
                    </div>
                    <div class="right">
                    </div>
                </div>
                <br/><br/><br/>
                <div class="card chat-app">
                    <div class="chat">
                        <div class="chat-history">
                            <ul class="m-b-0">
                                {list}
                            </ul>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(DiscussionsView);