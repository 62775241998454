import React from "react";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Circle } from '@react-google-maps/api';
import {api} from '../../../services/autonome';
import Form from 'react-bootstrap/Form';

const options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 1000,
    zIndex: 1
  }
  
  const containerStyle = {
    width: '100%',
    height: '500px'
  };

export class AdminMap extends React.Component {

    constructor(props) {
        super(props);
        this.defaultProps = {
            center: {
                lat: 46.5429,
                lng: -72.748
            },
            zoom: 11
        };
        this.state = {
            categories: [],
            mapItems: []
        }
        this.changeModel = this.changeModel.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    changeModel(event) {
        var these = this;
        event.target.value = parseInt(event.target.value);
        this.setState({selectedModel: event.target.value});
        this.state.categories.forEach(function(one) {
          console.log(one.id, "===", event.target.value);
          if (one.id === parseInt(event.target.value)) {
            these.setState({model: one});
            api.call('get', "liberium/map/" + one.id, {}).then(result => {
                console.log("Got data:", result.data);
                these.setState({
                  mapItems: result.data
                });
                console.log(result.data);
            });
          }
        });
      }

    fetchData() {
        var these = this;
        api.call('get', "tasks/categories", {}).then(result => {
            these.setState({
              categories: result.data
            });
        });
      }

    render() {
        var cats = this.state.categories.map((row) => {
            var enable = "(pas activé)"
            if (row.enabled) enable = "";
            return (
              <option
                value={row.id}
                >
                {row.name} {enable}
              </option>
            );
          });

        var markers = this.state.mapItems.map((one)=>{
            console.log(one);
            var center = {
                lat: parseFloat(one.latitude),
                lng: parseFloat(one.longitude)
            }
            return (
                <Circle
                    center={center}
                    options={options}
                />
            )
        });
        return (
            <>
                <Form.Select
                    aria-label="Default select example"
                    onChange={this.changeModel}
                    value={this.state.selectedModel}            
                >
                    <option></option>
                    {cats}
                </Form.Select>
                <LoadScript
                    googleMapsApiKey="AIzaSyB2VdVBM9UwEPHUGPX-F-XetiDhOWNUYJw"
                >
                    <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={this.defaultProps.center}
                    zoom={10}
                    >
                        {markers}
                    </GoogleMap>
                </LoadScript>
            </>
        );
    }
}

export default AdminMap;