import React from "react";
import { Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { Message } from '../../components/chat/message';
import {ImpotProfile} from "./profile";
import DragAndDrop from '../../components/filedragdrop';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SendIcon from '@material-ui/icons/Send';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CreateIcon from '@material-ui/icons/Create';
import Table from 'react-bootstrap/Table';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Form, Modal } from "react-bootstrap";
import SignatureCanvas from 'react-signature-canvas';
import Alert from '@material-ui/lab/Alert';

import {api} from '../../services/autonome';
import axios from 'axios';
import { API_ROOT } from '../../api-config';

import StripeCheckout from 'react-stripe-checkout';

import css from "./index.css";

const hostname = window && window.location && window.location.hostname;
var STRIPE_PUBLISHABLE;
if (hostname === "liberium.net" || hostname === "www.liberium.net") {
    STRIPE_PUBLISHABLE = "pk_live_51L8OrVDmbzE3RrkO4qzUY7SBpSyivYf5cAIUYPoFAB6HNsR7SViZzwpjsTTefjsstLU16w4EeyOmvCITk4tJEeJC00p9vt8VgN";
} else {
    STRIPE_PUBLISHABLE = "pk_test_51L8OrVDmbzE3RrkOEDQ20XrQaLwOmvrigsAEDNB9P45wcWcZ6MrDda4GKQkJko4NBBiKCXu28pRiORduQbc8bHZR00iy5JdqUw";
}

//const years = Array.from({ length: 24 }, (_, index) => 2000 + index);
const years = [2023];

export class ImpotInterface extends React.Component {
  state = {
    screens: []
  }

  constructor(props) {
    super(props);
    this.state = {
        userId: this.getUserId(),
        libProfil: {
          state: "beginning"
        },
        profil: null,
        year: "2023",
        yearSelected: null,
        years: {},
        page: "",
        images: [],
        showCam: false,
        showSignature: false,
        isTerminated: false,
        showResult: false,
        waitingPayment: false,
        amount: null,
        result: null,
        messages: []
    }
    this.handleChange = this.handleChange.bind(this);
    this.onProfilDone = this.onProfilDone.bind(this);
    this.fileInput = React.createRef();
    this.handleNewPhoto = this.handleNewPhoto.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sigCanvas = null;
  }

  createNewProfil() {
    console.log("Me", this.state.me);
    console.log("addresse", this.state.me.address);
    return {
      prenom: this.state.me.firstname,
      nom: this.state.me.lastname,
      nas: "",
      sexe: this.state.me.sexe,
      telephone: this.state.me.phonenumber,
      vitSeul: "",
      date_naissance: "",
      has_insurance: "0",
      conjoint_name: "",
      has_childrens: "",
      is_proprio: "0",
      childrens: [],
      adresse: this.state.me.address.street_adress,
      city: this.state.me.address.city,
      province: this.state.me.address.state,
      zipcode: this.state.me.address.zipcode,
      country: this.state.me.address.country
    }
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    var new_form = this.state.profil;
    new_form[name] = value;
    //alert(target.value);
    this.setState({profil:new_form});
  }

  handleChangeSupport(event) {
    //console.log("Handle change", event);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      is_error: false
    });
  }

  handleSubmit(event) {
    var these = this;
    var msg = this.state.message;
    this.setState({message: ""});
    var data = {
        message: msg,
        year: this.state.yearSelected
    }
    api.call("post", "impot/post_message", data).then(result => {
        var new_messages = these.state.messages;
        these.fetchMessages();
    }).catch(function(err) {
        //alert(JSON.stringify(err));
        these.setState({});
    });
  }

  componentDidMount() {
    var me = JSON.parse(localStorage.getItem('user'));
    this.setState({me: me });
  }

  componentWillUnmount() {
  }

  closeModal() {
    this.setState({waitingPayment: false});
  }
  
  closeSignature() {
    this.setState({showSignature: false});
  }

  openSignature() {
    this.setState({showSignature: true});
  }

  onYearChange(year) {
    this.setState({year: year});
  }

  onProfilDone(profil) {
    this.setState({profil: profil, page: "interface", libProfil: {state:null}});
  }

  fetchPhotos() {
    api.call('get', "impot/user/" + this.state.userId + "/" + this.state.yearSelected +"/photos").then(result => {
        if (result.status === 200) {
            console.log("Got images", result.data);
            this.setState({images:result.data});
        }
    }).catch(e => {
    });
  }

  onToken = (amount, description) => token => {    
    var these = this;
    var data = {
        description,
        source: token.id,
        currency: "CAD",
        amount: amount*100,
        profilId: this.state.profilId
    }
    api.call('post', "impot/payment", data).then(result => {  
        if (result.error) return alert(result.error.message);                        
        these.setState({waitingPayment: false, showResult: true, libProfil: {state:"payment_done"}});
        alert("Merci pour votre paiement");
    });
  }

  onYearSelect() {
    var these = this;
    this.setState({yearSelected: this.state.year});

    // On va chercher voir s'il a un profile pour cette année la
    api.call('get', "impot/profile?year=" + this.state.year, {}).then(result => {
      console.log("Got profil:", result);
      if (result.data === null) {
        these.setState({
          profil: this.createNewProfil(),
          page: "profil"
        })
      } else {
        var isT = false;
        var wP = false;
        var showResult = false;
        if (result.data.profil.state === "begining")
          isT = true;
        if (result.data.profil.state ==="waiting_payment") {
          wP = true;
          isT = true;
        }
        if (result.data.profil.state ==="payment_done") {
          showResult = true;
          isT = true;
        }
        if (result.data.profil.state ==="signed") {
          showResult = true;
          isT = true;
        }        
        var amount = null;
        if (typeof result.data.amount !== "undefined")
          amount = parseFloat(result.data.amount).toFixed(2);
        var theResult = null;
        if (result.data.profil.result !== null)
          theResult = JSON.parse(result.data.profil.result);
        these.setState({
            libProfil: result.data.profil,
            profil: JSON.parse(result.data.profil.profil),
            profilId: result.data.profil.id,
            page: "impot",
            result: theResult,
            isTerminated: isT,
            waitingPayment: wP,
            showResult: showResult,
            amount: amount
        });
        these.fetchPhotos();
        these.fetchMessages();
      }
  });
  }

  onTerminate() {
    api.call('get', "impot/ready?year=" + this.state.year, {}).then(result => {
      this.setstate({libProfil:{state:"idle"},isTerminated:true});
      alert("Merci, vous recevrez un SMS dès que nous avons terminé");
    });
  }

  onBack() {
    this.setState({yearSelected: null});
  }

  onProfile() {
    //this.props.goProfile();
    this.setState({page:"profil"})
  }

  onDelete(img) {
    var self = this;
    console.log(img);
    api.call('get', "impot/image_delete/" + img.id).then(result => {
        self.fetchPhotos();
    }).catch(e => {
    });
  }

  fetchMessages() {
    var these = this;    
    api.call("post", "impot/get_comm", {}).then(result => {
        these.setState({messages: result.data.messages, other: result.data.other})
    });
  }

  renderFiles() {
    var k = 0;
    var images = this.state.images.map((img) => {
      if (img.way !== "in") return;
        k++;
        if (img.imgUrl.indexOf(".pdf")>-1)
            return (
                <div className="col-sm-4" key={k}>
                <a onClick={() => this.handleImage(img)}>
                    <h3>Fichier PDF</h3>
                </a><br/>
                <a onClick={(e) => this.onDelete(img)}>Effacer PDF</a>
            </div>
            )
        return (
            <div className="col-sm-4" key={k}>
                <LazyLoadImage src={img.imgUrl} className={'img-fluid'} onClick={() => this.handleImage(img)} />
                <a onClick={(e) => this.onDelete(img)}>Effacer</a>
            </div>
        )
    });
    return (
        <div className="row">
            <div className="col-md">
                <div className="row no-gutters">
                    {images}
                 </div>
            </div>
        </div>
    )
  }

  renderTerminate() {
    var remboursement = (
      <>Votre remboursement: bientôt disponible</>
    )
    var signature;
    if (this.state.libProfil.state !== "signed")
      signature = (
        <>
          Nous avons besoin de votre signature<br/><br/>
          <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CreateIcon />}
                onClick={() => this.openSignature()}
          >
            Signer ici
          </Button>
        </>
      )
    else
      signature = (
        <>
          Vous avez signé votre impôt.
        </>
      )
    if (this.state.result !== null) {
      remboursement = (
        <>
         <Alert severity="info">Votre remboursement fédéral: {this.state.result.fromFederal}$</Alert>
         <Alert severity="info">Votre remboursement provincial: {this.state.result.fromProvincial}$</Alert>
          <br/>
          {signature}
        </>
      )
    }
    return (
      <div>
        {remboursement}
      </div>
    )
  }

  renderFilesOut() {
    var k = 0;
    var images = this.state.images.map((img) => {
      if (img.way !== "out") return;
        k++;
        if (img.imgUrl.indexOf(".pdf")>-1)
            return (
                <div className="col-sm-4" key={k}>
                <a onClick={() => this.handleImage(img)}>
                    <h3>Fichier PDF</h3>
                </a>
            </div>
            )
        return (
            <div className="col-sm-4" key={k}>
                <LazyLoadImage src={img.imgUrl} className={'img-fluid'} onClick={() => this.handleImage(img)} />
            </div>
        )
    });
    return (
        <div>
        <div className="row">
            <div className="col-md">
                <div className="row no-gutters">
                    {images}
                 </div>
            </div>
        </div>
    </div>
    )
  }

  addPhoto() {
    this.setState({showCam: true});
  }

  closeCam() {
    this.setState({showCam: false});
  }

  handleTakePhoto = (dataUri) => {
    //alert("pass " + API_ROOT);
    var self = this
    const data = new FormData();
    data.append("year",this.state.yearSelected);
    data.append("userId",this.state.userId);
    data.append('data', dataUri);
    this.setState({
      uploadMsg: "Upload en cours"
    });
    axios.post(API_ROOT + 'impot/upload_uri', data).then((response) => {
        this.setState({showCam: false});
        /*
        this.setState({
            uploadMsg: "Upload terminé"
        });
        */
        self.fetchPhotos();
    }).catch(function(err) {
        alert("catch: " + JSON.stringify(err));
    });
  }

  handleNewPhoto() {
    // Ok we send the photo
    var self = this;
    console.log("fileInput:",this.fileInput);
    console.log("length:", this.fileInput.current.files.length);
    for (var i = 0; i < this.fileInput.current.files.length; i++) {
      if (!this.fileInput.current.files[i].name) return;
        const data = new FormData();
        data.append('file', this.fileInput.current.files[i]);
        data.append("userId",this.state.userId);
        data.append("year",this.state.yearSelected);
        
        this.setState({
            uploadMsg: "Upload en cours"
        });
        axios.post(API_ROOT + "impot/upload_image", data).then((response) => {
            this.setState({
                uploadMsg: "Upload terminé"
            })
            self.fetchPhotos();
        })
    }
  }

  renderCam() {
    if (!this.state.showCam) return (<></>);
    return (
        <input 
            type="file"
            name="image"
            accept="image/*"
            capture="environment"
            ref={this.fileInput}
            onChange={this.handleNewPhoto}
        />
    )
  }

  renderCreateYear() {
    return (
        <center>
            <FormControl>
                <h2>Année d'impôt</h2>
                <Select
                    labelId="tax-year-label"
                    id="tax-year-select"
                    value={this.state.year}
                    onChange={(event) => this.onYearChange(event.target.value)}
                >
                    {years.map((year) => (
                    <MenuItem key={year} value={year}>
                        {year}
                    </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <br/><br/><br/>
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<NavigateNextIcon />}
                onClick={() => this.onYearSelect()}
            >
                Suivant
            </Button>
            <br/><br/><br/>
            <br/><br/><br/>
        </center>
    )
  }

  renderProfil() {
    return (
      <ImpotProfile
        year={this.state.yearSelected}
        profil={this.state.profil}
        onProfilDone={this.onProfilDone}
      />
    )
  }

  nosDocuments() {    
    if (this.state.libProfil === null || this.state.libProfil.state === null)
      return (<></>);
    var info;
    if (this.state.libProfil.state==="begining")
      info = (
        <p>
        Nous n'avons pas encore préparé votre impôt. Une fois que nous aurons
        complété votre impôt, vous recevrez ici tous les documents que nous avons
        produit.
        </p>
      )
    return (
      <>
      <h2>Nos documents</h2>
      {info}
      {this.renderFilesOut()}
      </>
    )
  }

  vosDocuments() {
    var noPhotos = (<></>);
    if (this.state.images.length === 0)
      noPhotos = (<p>Si vous n'avez aucun document a nous envoyer, vous pouvez faire terminer maintenant.</p>);
    return (
      <>
        <p>
          Veuillez soumettre tout vos documents. Une fois que vous avez tout envoyé,
          cliquez le bouton Terminer.
        </p>
        {noPhotos}
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={this.state.isTerminated}
          startIcon={<SendIcon />}
          onClick={() => this.onTerminate()}
        >
            Terminer
        </Button>
      </>
    )
  }

  sendSignature() {
    var self = this;
    const canvas = this.sigCanvas.getCanvas();
    canvas.toBlob((blob) => {
      console.log(blob);
      const data = new FormData();
      data.append("year",this.state.yearSelected);
      data.append("userId",this.state.userId);
      data.append("numerizedBy",this.state.userId);
      data.append('signature', blob, 'signature.png');
      this.setState({
        uploadMsg: "Upload en cours"
      });
      axios.post(API_ROOT + 'impot/upload_signature', data).then((response) => {
        self.fetchPhotos();        
        self.setState({showSignature: false, state: "signed"});
      }).catch(function(err) {
          alert("catch: " + JSON.stringify(err));
      });
    })
  }

  renderCommunication() {
    let list = <div classname="no-content-message">Il n'y a pas de messages</div>;
    if (this.state.messages.length > 0)
      list = this.state.messages.map(m => <Message key="{m.id}" id="{m.id}" other={this.state.other} receivedAt={m.receivedAt} fromUserId={m.fromUserId} senderName={m.senderName} text={m.message}></Message>);
    return (
      <>
        <hr/>
        <table width='100%'>
          <thead>
            <th colspan="2"><center>Communication</center></th>
          </thead>
          <tbody>
            <tr>
              <td>
              <div class="chat-history">
                    <ul class="m-b-0">
                        {list}
                    </ul>
                </div>
                <div class="chat-message clearfix">
                    <div class="input-group mb-0">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-send"></i></span>
                        </div>
                        <input
                            type="text"
                            name="message"
                            class="form-control"
                            placeholder="Enter text here..."
                            value={this.state.message}
                            onChange={e => this.handleChangeSupport(e)}
                            onKeyPress={this.handleKeyPress} 
                        />
                        <Button
                            disabled={this.state.sending}
                            variant="contained"
                            color="primary"
                            size="small"    
                            startIcon={<LocalAtmIcon />}
                            onClick={() => this.handleSubmit()}
                        >
                            Envoie
                        </Button>
                    </div>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }

  renderPage() {
    if (this.state.yearSelected === null)
        return this.renderCreateYear();
    if (this.state.page === "profil")
      return this.renderProfil();
    var showResult;
    if (this.state.showResult)
      showResult = this.renderTerminate();
    return (
        <>
            <div>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => this.onBack()}
                >
                    Retour
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AccountCircleIcon />}
                    onClick={() => this.onProfile()}
                >
                    Profile
                </Button>
            </div>
            <center><h1>{this.state.yearSelected}</h1></center>
          {showResult}          
          {this.nosDocuments()}
          <DragAndDrop handleDrop={this.handleDrop}>
            <h2>Vos documents soumis</h2>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={this.state.isTerminated}
                    startIcon={<AddAPhotoIcon />}
                    onClick={() => this.addPhoto()}
                >
                    Ajouter photo
                </Button>
                {this.renderCam()}
                <Table>
                    <thead>
                        <tr>
                            <th>Fichier</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                      <div>
                        {this.renderFiles()}
                        </div>
                    </tbody>
                </Table>
          </DragAndDrop>
          
          {this.vosDocuments()}

          {this.renderCommunication()}
        </>
    )
  }

  render() {
    var email = "";
    if (typeof this.state.me !== "undefined")
      email = this.state.me.email
    return (
      <>
      {this.renderPage()}
      <Modal
        size="lg"
        show={this.state.waitingPayment}
        onHide={() => this.closeModal()}
        aria-labelledby="example-modal-sizes-title-lg"
          >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Impôt terminé
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
                <p>Nous avons terminé votre impôt. Votre facture est de {this.state.amount}$</p>
                <p>Nous utilisons la compagnie Stripe.</p>
            </Form>
            <StripeCheckout
                name={"Paiment preparation d'impot"}
                description={"Paiment preparation d'impot"}
                amount={this.state.amount*100}
                token={this.onToken(this.state.amount, "Paiment preparation d'impot")}
                currency={"CAD"}
                stripeKey={STRIPE_PUBLISHABLE}
                email={email}
              />
          </Modal.Body>
        </Modal>

        <Modal
        size="lg"
        show={this.state.showSignature}
        onHide={() => this.closeSignature()}
        aria-labelledby="example-modal-sizes-title-lg"
          >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Signature
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SignatureCanvas
              ref={(ref) => { this.sigCanvas = ref }}
              canvasProps={{
                width: 500, 
                height: 200, 
                className: 'sigCanvas'
              }} />
              <br/>
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SendIcon />}
                onClick={() => this.sendSignature()}
            >
              Terminer
            </Button>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}