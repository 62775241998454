import React from "react";
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import { Alert } from "shards-react";
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';

export class MakeAdeposit extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            task: this.props.task
        }
    }

    componentDidMount(){
        window.gtag('event', 'page_view', {
            page_title: 'Dépôt',
            page_location: '/finance/deposit',
            page_path: '/finance/deposit'
        });
    }

    goDeposit() {
        this.props.history.push({
            pathname: "/finance/deposit"
        });
    }

    render () {
        return (
            <Alert theme="primary">
                La balance de votre compte est insuffisant.
                Vous pouvez effectuer un dépôt!
                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<AttachMoneySharpIcon />}
                    onClick={() => this.goDeposit()}
                >
                    Faites un dépôt
                </Button>
            </Alert>
        )
    }
}

export default withRouter(MakeAdeposit);