import React from 'react';
import box_blue from '../../img/chasse/box_blue.png';

export default class BlueBox extends React.Component {

    render() {
        return (
            <div>
                {this.props.caption}
                <div
                    style={{  
                        backgroundImage: "url(" + box_blue + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        height: "auto",
                        width: "auto",
                        "max-width": "100px",
                        "max-height": "300px",
                        color: "white"            
                    }}
                    >
                    <p
                    style={{
                        "margin-left": "10px"
                    }}
                    >
                    {this.props.text}
                </p>
                </div>
            </div>
        )
    }
}