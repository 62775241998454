import React from "react";
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from '../services/autonome';

export class Concours extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            eligible: false,
            imgUrl: "https://api.liberium.net/s/liberium-public-img/352558802_1016609039504842_462569366596146560_n.png"
        }
    }

    getUserId() {
        var existingTokens;
        try{
            existingTokens = JSON.parse(localStorage.getItem('tokens'));
            //alert(JSON.stringify(existingTokens));
            return existingTokens.UserId;
        } catch(e) {
            return null;
        }
    }

    componentDidMount(){
        this.fetchData();
    }

    fetchData() {
        var these = this;
        var body = {
            cmd:"askFunction",
            module: "contest",
            name: "isEligibleMonthly",
            userId: this.getUserId()
        }
        api.callLb('post', "apis/internal/function", body).then(result => {
            console.log("from LB function:",result);
            these.setState({eligible: result.data.eligible});
        });
    }

    render () {
        var eligibility;
        if (this.state.eligible)
            eligibility = "Vous êtes éligible pour le concours"
        else 
            eligibility = "Vous n'êtes pas encore éligible pour le concours. Plus de détails bientôt.";
        return (
            <div class="col-sm-6 col-12">
                <div class="card">
                    <img src={this.state.imgUrl} alt="Card image cap" width="100%" height="122" class="card-img-top" />
                    <div class="card-body">
                        <h5 class="card-title">Carte cadeau 100$ a gagner</h5>
                        <p>{eligibility}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Concours);