import React from "react";
import DragAndDrop from '../../components/filedragdrop';
import {api} from '../../services/autonome';
import { Container } from "shards-react";
import Ad from '../../components/ads/AdItem';
import { Table } from "react-bootstrap";
import axios from 'axios';
import { API_ROOT } from '../../api-config';

class MyVideosPage extends React.Component {
  state = {
    screens: [],
    validate: true,
    videos: []
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.userId = this.getUserId();
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
}

  fetchData() {
  }

  componentDidMount() {
    this.fetchData();
    window.gtag('event', 'page_view', {
          page_title: 'My Videos page',
          page_location: '/my_videos',
          page_path: '/my_videos'
    });
  }

  componentWillUnmount() {
  }


  handleDrop = (name, files) => {
    var self = this;
    for (var i = 0; i < files.length; i++) {
      if (!files[i].name) return;
        const data = new FormData();
        data.append('file', files[i]);
        data.append('userId', this.userId);
        this.setState({
            uploadMsg: "Upload en cours"
        });
        axios
        .post(API_ROOT + "video/upload", data)
        .then(function (response) {
            console.log(response);
            // Reload la liste
        })
    }
  }

  render() {
    return (
      <>
        <DragAndDrop handleDrop={this.handleDrop}>
            <h1>Mes videos</h1>
            <Table aria-label="customized table" width={"100%"}>
              <thead>
                <tr>
                  <td>Photo</td>
                  <td align="right">Action</td>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </Table>
        </DragAndDrop>
        
      </>
    );
  }
}

export default MyVideosPage;