import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router';
import { Form, Modal } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

class DeploymentsView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            show: false,
            deployments: [],
            selected: null,
            services: [
            ]
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "hosting/deployments", {}).then(result => {            
            these.setState({deployments: result.data});
        });
    }

    saveDep() {
        var these = this;
        api.call('post', "hosting/deployment/" + this.state.selected.id, {
            enabled: this.state.enabled,
            serviceName: this.state.serviceName,
            replicas: this.state.replicas,
            cpu_weights: this.state.cpu_weights,
            timeout: this.state.timeout,
            image: this.state.image,
            args: this.state.args,
            useTemp: this.state.useTemp,
            startTemp: this.state.startTemp,
            services: this.state.services
        }).then(result => {
            these.closeModal();
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    handleChange(event) {
        console.log("Handle change", event.target);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    addService() {
        var services = this.state.services;
        services.push({path:this.state.path,port:this.state.port,order:this.state.order,hostname:this.state.hostname});
        this.setState({path:"", port:"", services: services});
    }

    selectDep(row) {
        console.log("row:", row);
        var parsed = JSON.parse(row.metas);
        var services = parsed.services;

        var timeout = parsed.timeout;
        if (typeof timeout === "undefined")
            timeout = 600;

        var useTemp = parsed.useTemp;
        if (typeof useTemp === "undefined")
            useTemp = false;
        var startTemp = parsed.startTemp;
        if (typeof startTemp === "undefined")
            startTemp = "0";

        var parsed = JSON.parse(row.metas);

        this.setState({
            selected: row, 
            enabled: row.enabled,
            show:!this.state.show, 
            timeout: timeout,
            image: row.image,
            args: row.args,
            replicas: parsed.replicas,
            cpu_weights: parsed.cpu_weights,
            useTemp: useTemp,
            startTemp: startTemp,
            services: services
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    closeModal() {
        this.setState({show: false});
        this.fetchData();
    }

    goServers() {
        this.props.history.push({
          pathname: "/admin/servers"
        });
    }

    removeService(row) {
        console.log("Row to remove:", row)        
        var services = this.state.services;
        var pos = services.indexOf(row);
        console.log("pos", pos);
        services.splice(pos,1);
        this.setState({services: services}); 
    }

    renderDeployments() {
        return this.state.deployments.map((row) => {
            var states = JSON.parse(row.states);
            var metas = JSON.parse(row.metas);
            var status = (
                <>({states.containers.length}/{metas.replicas})</>
            )
            return (
                <tr onClick={(e)=>this.selectDep(row)}>
                <td>{row.id}</td>
                <td>{row.name}</td>
                <td>{row.image}</td>
                <td>{status}</td>
                </tr>
            );
          });
    }

    render(){
        var serviceRows = (<></>)
        if (typeof this.state.services === "undefined")
            this.state.services = [];
        if (this.state.services.length>0)
            serviceRows = this.state.services.map((row) => {
                return (
                    <tr>
                        <td>{row.hostname}</td>
                        <td>{row.path}</td>
                        <td>{row.port}</td>
                        <td>{row.order}</td>
                        <td>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<DeleteIcon />}
                                onClick={() => this.removeService(row)}
                            >
                            </Button>
                        </td>
                    </tr>
                )
            });
        var useTemp = (<></>);
        if (this.state.useTemp)
            useTemp = (
                <>
                    <Form.Group className="mb-3" controlId="startTemp">
                        <Form.Label>Start at which celcius</Form.Label>
                        <Form.Control 
                            type="text"
                            name="startTemp"
                            placeholder="-2" 
                            value={this.state.startTemp} 
                            onChange={e => this.handleChange(e)}
                        />
                        <Form.Text className="text-muted">
                            Si la température, descend en dessous, il partira
                        </Form.Text>
                    </Form.Group>
                </>
            )
        return(
            <>
                <p>
                    <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => this.goServers()}
                    >
                    Servers
                    </Button>
                </p>
            <h1>Deployments</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Deployment name</th>
                    <th>Image</th>
                    <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderDeployments()}
                </tbody>
            </Table>
            <Modal
                size="lg"
                show={this.state.show}
                onHide={() => this.closeModal()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Deployment
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="useTemp">
                        <Form.Check
                            type='checkbox'
                            label='Enabled'
                            name="enabled"
                            checked={this.state.enabled}
                            value={this.state.enabled}
                            onChange={(e) => this.handleChange(e)}
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="image">
                        <Form.Label>Service name</Form.Label>
                        <Form.Control 
                            type="text"
                            name="serviceName"
                            placeholder="serviceName" 
                            value={this.state.serviceName} 
                            onChange={e => this.handleChange(e)}
                        />
                        <Form.Text className="text-muted">
                            Pour le DNS
                        </Form.Text>
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId="image">
                        <Form.Label>Image</Form.Label>
                        <Form.Control 
                            type="text"
                            name="image"
                            placeholder="docker/image" 
                            value={this.state.image} 
                            onChange={e => this.handleChange(e)}
                        />
                        <Form.Text className="text-muted">
                            L'image qui doit être lancé
                        </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="args">
                        <Form.Label>Args</Form.Label>
                        <Form.Control 
                            type="text"
                            name="args"
                            placeholder="-d -P" 
                            value={this.state.args} 
                            onChange={e => this.handleChange(e)}
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="replicas">
                        <Form.Label>Replication</Form.Label>
                        <Form.Control 
                            type="text"
                            name="replicas"
                            placeholder="1" 
                            value={this.state.replicas} 
                            onChange={e => this.handleChange(e)}
                        />
                        <Form.Text className="text-muted">
                            Le nombre de replication vous désirer avoir
                        </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="replicas">
                        <Form.Label>Poids CPU</Form.Label>
                        <Form.Control 
                            type="text"
                            name="cpu_weights"
                            placeholder="1"
                            value={this.state.cpu_weights} 
                            onChange={e => this.handleChange(e)}
                        />
                        <Form.Text className="text-muted">
                            Le nombre de replication vous désirer avoir
                        </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="timeout">
                        <Form.Label>Timeout</Form.Label>
                        <Form.Control 
                            type="text"
                            name="timeout"
                            placeholder="600" 
                            value={this.state.timeout} 
                            onChange={e => this.handleChange(e)}
                        />
                        <Form.Text className="text-muted">
                            Nombre de secondes
                        </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="useTemp">
                        <Form.Check
                            type='checkbox'
                            label='Use temperature API'
                            name="useTemp"
                            checked={this.state.useTemp}
                            value={this.state.useTemp}
                            onChange={(e) => this.handleChange(e)}
                        />
                        </Form.Group>
                        {useTemp}
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>Hostname</th>
                                <th>Path</th>
                                <th>Port</th>
                                <th>Order</th>
                                </tr>
                            </thead>
                            <tbody>
                                {serviceRows}
                                <tr>
                                    <td>
                                    <Form.Group className="mb-3" controlId="timeout">
                                        <Form.Control
                                            type="text"
                                            name="hostname"
                                            placeholder="liberium.net"
                                            value={this.state.hostname}
                                            onChange={e => this.handleChange(e)}
                                        />
                                        </Form.Group>
                                    </td>
                                    <td>
                                    <Form.Group className="mb-3" controlId="timeout">
                                        <Form.Control
                                            type="text"
                                            name="path"
                                            placeholder="/*"
                                            value={this.state.path}
                                            onChange={e => this.handleChange(e)}
                                        />
                                        </Form.Group>
                                    </td>
                                    <td>
                                    <Form.Group className="mb-3" controlId="timeout">
                                        <Form.Control 
                                            type="text"
                                            name="port"
                                            placeholder="3000" 
                                            value={this.state.port} 
                                            onChange={e => this.handleChange(e)}
                                        />
                                        </Form.Group>
                                    </td>
                                    <td>
                                    <Form.Group className="mb-3" controlId="order">
                                        <Form.Control 
                                            type="text"
                                            name="order"
                                            placeholder="10" 
                                            value={this.state.order} 
                                            onChange={e => this.handleChange(e)}
                                        />
                                        </Form.Group>
                                    </td>
                                    <td>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<AddIcon />}
                                            onClick={() => this.addService()}
                                        >
                                        </Button>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>

                        <div class="container">
                            <div class="row">
                                <div class="col text-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<EditIcon />}
                                        onClick={() => this.saveDep()}
                                    >
                                        Sauvegarder
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            </>
        )
    }
}

export default withRouter(DeploymentsView);