//https://www.bootdey.com/snippets/view/chat-app#html

import React from 'react'
import {
    Container
} from "shards-react";

import Button from '@material-ui/core/Button';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Message } from '../../components/chat/message';
import {api} from '../../services/autonome';
import UserCard from "../../components/liberium/userCard";

import '../chat/discussion.css';

export class SupportView extends React.Component {

    constructor(props) {
        super(props)
        //console.log(props)
        this.state = {
            withUserId: 0,
            userId: this.getUserId(),
            messages: [],
            other: {
                id: 0,
                firstname: "",
                lastname: ""
            },
            message: "",
            timer: null,
            supportUser: {
                avatar: null,
                firstname: "Support",
                lastname: "liberium",
                id: null
            }
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getUserId() {
        var existingTokens;
        try{
            existingTokens = JSON.parse(localStorage.getItem('tokens'));
            //alert(JSON.stringify(existingTokens));
            return existingTokens.UserId;
        } catch(e) {
            return null;
        }
      }

    handleChange(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value,
          is_error: false
        });
    }

    handleSubmit(event) {
        var these = this;
        var msg = this.state.message;
        this.setState({message: ""});
        var data = {
            message: msg
        }
        api.call("post", "chat/post_support", data).then(result => {
            var new_messages = these.state.messages;
            these.fetchMessages();
        }).catch(function(err) {
            //alert(JSON.stringify(err));
            these.setState({});
        });
    }

    fetchMessages() {
        var these = this;
        api.call("post", "chat/get_support", {}).then(result => {
            //console.log("Got messages:", result.data);
            these.setState({messages: result.data.messages})
        });
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter') {
            this.handleSubmit(null);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentDidMount() {
        api.call('post', "user/usrLog", {module: "views", title:"support"});
        var these = this;
        this.fetchMessages();
        window.gtag('event', 'page_view', {
            page_title: 'Discussion',
            page_location: '/discussion',
            page_path: '/discussion'
        });
        this.timer = setInterval(() => {
            these.fetchMessages();
        }, 3000);
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    render(){
        let list = <div classname="no-content-message">There is no messages to show</div>;
        if (this.state.messages.length > 0)
            list = this.state.messages.map(m => <Message key="{m.id}" id="{m.id}" other={this.state.supportUser} receivedAt={m.receivedAt} fromUserId={m.fromUserId} senderName={m.senderName} text={m.message}></Message>);

        return(
            <Container fluid className="main-content-container px-4">
            <Breadcrumb>
                <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>
                <Breadcrumb.Item href="#/wallet">Chat</Breadcrumb.Item>
                <Breadcrumb.Item active>Discussion avec </Breadcrumb.Item>
            </Breadcrumb>

            <div class="chat">
                <UserCard user={this.state.supportUser}/>
                <div class="chat-history">
                    <ul class="m-b-0">
                        {list}
                    </ul>
                </div>
                <div class="chat-message clearfix">
                    <div class="input-group mb-0">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-send"></i></span>
                        </div>
                        <input
                            type="text"
                            name="message"
                            class="form-control"
                            placeholder="Enter text here..."
                            value={this.state.message}
                            onChange={e => this.handleChange(e)}
                            onKeyPress={this.handleKeyPress} 
                        />
                        <Button
                            disabled={this.state.sending}
                            variant="contained"
                            color="primary"
                            size="small"    
                            startIcon={<LocalAtmIcon />}
                            onClick={() => this.handleSubmit()}
                        >
                            Envoie
                        </Button>
                    </div>
                </div>
                <div style={{ float:"left", clear: "both" }}
                    ref={(el) => { this.messagesEnd = el; }}>
                </div>
            </div>

            </Container>
        )
    }
}