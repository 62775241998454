import React from "react";
import axios from 'axios';
import { API_ROOT } from '../../api-config';

export class FileUploader extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            loaded: false,
            imgUrl: "",
            selectedFile: null
        }
        this.onFileUpload = this.onFileUpload.bind(this);
    }

    onFileChange = event => { 
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] }); 
    }

    onFileUpload = () => { 
        var these = this;
        // Create an object of formData 
        const formData = new FormData(); 
       
        // Update the formData object 
        formData.append( 
          "file", 
          this.state.selectedFile, 
          this.state.selectedFile.name 
        ); 
       
        // Details of the uploaded file 
        //console.log(this.state.selectedFile); 
       
        // Request made to the backend api 
        // Send formData object
        axios
        .post(API_ROOT + "s/liberium-public-img", formData)
        .then(function (response) {
            //console.log(response);
            these.setState({imgUrl: response.data.url})
          })
      }; 
  
    componentDidMount(){
    }

    onClick = () => {
    }

    fileData = () => { 
        if (this.state.selectedFile) { 
            
          return ( 
            <div> 
              <h2>Fichier:</h2>

              <p>File Name: {this.state.selectedFile.name}</p> 
              <p>File Type: {this.state.selectedFile.type}</p> 
              <p> 
                Last Modified:{" "} 
                {this.state.selectedFile.lastModifiedDate.toDateString()}
              </p> 
              <p>Image URL: {this.state.imgUrl}</p>
            </div> 
          ); 
        } else { 
          return ( 
            <div> 
              <br /> 
              <h4>Choose before Pressing the Upload button</h4> 
            </div> 
          ); 
        } 
      }; 
  

    render () {
        return (
            <div class="col-sm-6 col-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Envoie un fichier sur le serveur</h5>
                        <p class="card-text">Selectionner votre fichier :
                            <input type="file" onChange={this.onFileChange} />
                        </p>
                        <a onClick={this.onFileUpload} class="btn btn-primary">Envoie</a>
                        {this.fileData()}
                    </div>
                </div>
            </div>
        )
    }
}

export default FileUploader;
