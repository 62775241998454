import React from 'react'
import {
    Container,
    Row,
    Col
  } from "shards-react";

import Button from '@material-ui/core/Button';
import DragAndDrop from '../../components/filedragdrop';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import AddIcon from '@material-ui/icons/Add';
import PeopleIcon from '@material-ui/icons/People';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import axios from 'axios';
import { API_ROOT } from '../../api-config';
import {api} from '../../services/autonome';

const useStyles = makeStyles((theme) => ({
    root: {
      height: 380,
      transform: 'translateZ(0px)',
      flexGrow: 1,
    },
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }));

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);
  

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
}))(TableCell);

export class BusinessSettingsView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            speedOpen: false,
            scanOpened: false,
            businessId: props.match.params.id,
            business : {name:"", logo_url: null},
            items: [],
            withCode: null,
            barcode: "",
            filters: {
                withoutPhotos: false
            }
        }

        this.handleScan = this.handleScan.bind(this)
    }
    
    handleScan(data){
        alert(data);
    }
    
    goItem = (item) => {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/item/" + item.id
        });
    }

    goClients() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/clients"
        });
    }

    goInvestors() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/investors"
        });
    }

    goOrders() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/orders"
        });
    }
    
    goFactures() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/factures"
        });
    }

    goInvoice() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/invoice"
        });
    }

    openScan() {
        this.setState({scanOpened: true})
    }

    deleteItem(row) {
        var self = this;
        var url = "store/item/" + row.id;
        api.call('delete', url, {}).then(result => {
            self.fetchData();
        });
    }

    fetchData() {
        api.call('get','businesse/' + this.state.businessId, {}).then(result => {
            //console.log(result.data);
            if (typeof result.data.error != "undefined") return;
            var isAdmin = true;
            if (typeof result.data.perms !== "undefined" && result.data.perms == "items") {
                isAdmin = false;
                //alert('not admin')
            }
            this.setState({
                business: result.data,
                isAdmin: isAdmin
            });
        });
    }

    componentDidMount(){
        this.fetchData()
    }

    handleChange(event) {
        console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    handleNewTransaction() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/transaction"
        });
    }

    handleDrop = (name, files) => {
        var self = this;
        let fileList = this.state.files
        console.log(files);
        for (var i = 0; i < files.length; i++) {
          if (!files[i].name) return;
            //fileList.push(files[i].name)
            const data = new FormData();
            data.append('file', files[i]);
            console.log("data:",data);
    
            axios.post(API_ROOT + "business/" + this.state.businessId + "/upload_logo", data).then((response) => {
              console.log("Response:", response);
              self.setState({
                    uploadMsg: "Upload terminé"
                })
                if (response.data.status) {
                  var new_form = self.state.business;
                  new_form["logo_url"] = response.data.data.fileUrl;
                  console.log("New logo:",response.data.data.fileUrl);
                  self.setState({business:new_form});
                } else {
                  alert("error somewhere");
                }
            })
        }
    }

    render(){
        var client_disabled =  false;
        var invoice_disabled = false;
        var transaction_disabled = false;
        if (!this.state.isAdmin) {
            client_disabled = true;
            invoice_disabled = true;
            transaction_disabled = true;
        }

        return(
            <Container fluid className="main-content-container px-4">
                <h1 className="m-4">{this.state.business.name}</h1>
                <Row>
                    <Col>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => this.createItem()}>Ajouter un article</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<ViewWeekIcon />}
                            onClick={() => this.openScan()} disabled={false}>Scan</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<PeopleIcon />}
                            onClick={() => this.goClients()} disabled={client_disabled}>Clients</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<ReceiptIcon />}
                            onClick={() => this.goInvoice()} disabled={invoice_disabled}>Facture</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            enabled={false}
                            onClick={() => this.goOrders()}>Commandes</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.goFactures()}>Factures</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AccountBalanceIcon />}
                            onClick={() => this.handleNewTransaction()} disabled={transaction_disabled}>Transaction</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AccountBalanceIcon />}
                            onClick={() => this.goInvestors()}>Investisseurs</Button>
                    </Col>
                </Row>

                <br/>

                <DragAndDrop handleDrop={this.handleDrop}>
                    <h1>Logo</h1>

                    <img src={this.state.business.logoUrl} width={100}/>
                </DragAndDrop>

            </Container>
        )
    }
}