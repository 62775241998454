import React, { useState, useEffect, Fragment } from 'react'
import {
    Container,
    Row,
    Col,
  } from "shards-react";
import PageTitle from "../../components/common/PageTitle";

import AddClientForm from './new'
import EditClientForm from './edit'
import ClientTable from './table'

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import axios from 'axios';
import {api} from '../../services/autonome';
import { API_ROOT } from '../../api-config';

const Clients = (props) => {
  // Data
  const businessId = props.match.params.orgId;
	const clientsData = []
	const initialFormState = { id: null, prenom: '', nom: '' }

  // Setting state
  const [ showClient, setShowClient ] = useState(false);
  const [ isError, setIsError ] = useState(false);
  const [ clients, setClients ] = useState(clientsData)
  const [ photos, setPhotos ] = useState([]);
	const [ currentClient, setCurrentClient ] = useState(initialFormState)
  const [ editing, setEditing ] = useState(false)

    // CRUD operations
    const cancel = (client) => {
        setEditing(false);
        setShowClient(false);
    }

    const goBack = () => {
      props.history.push({
          pathname: "/business/" + businessId
      });
    }

    const getPhotos = (client, cb) => {
        axios.get(API_ROOT + "impot/client/" + client.id, {}).then(result => {
            if (result.status === 200) {
              console.log("Got images", result.data);
              var photos = [];
              result.data.forEach(function(one) {
                photos.push({src: one.url});
              });
              setPhotos(photos);
            }
            cb();
        }).catch(e => {
            cb();
        });
	}
    
	const addClient = client => {
    client.businessId = businessId;
        api.call('post', "client", client).then(result => {
            if (result.status === 200) {
              console.log("New client", result.data);
              client.id = result.data.id;
              setEditing(true);
              setClients([ ...clients, result.data ])
              setCurrentClient(client);      
            } else {
              setIsError(true);
            }
          }).catch(e => {
            setIsError(true);
          });
	}

	const deleteClient = id => {
		setEditing(false)
		setClients(clients.filter(client => client.id !== id))
	}

	const updateClient = (id, updatedClient) => {
    axios.patch(API_ROOT + "client/" + id, updatedClient).then(result => {
      // Reload list
      axios.get(API_ROOT + "business/" + businessId + "/clients", {}  ).then(result => {
        if (result.status === 200) {
          console.log("New client", result.data);
          setClients(result.data);
        } 
      }).catch(e => {
      });
    }).catch(e => {
      alert("OOPS");
    });

    setEditing(false);
    setShowClient(false);
		setClients(clients.map(client => (client.id === id ? updatedClient : client)))
	}

	const editRow = client => {
        getPhotos(client, function() {
            setEditing(true)
            setShowClient(true);
            setCurrentClient(client);
        });        
    }
    
    const handleNewClient = event => {
        setEditing(false);
        setShowClient(true);
    }

    // Faut que ca sexecute une seule fois
    useEffect(() => {
        api.call('get', "business/" + businessId + "/clients", {}).then(result => {
            if (result.status === 200) {
              console.log("New client", result.data);
              setClients(result.data);
            } 
          }).catch(e => {
          });
    }, [businessId]);

	return (
		<Container fluid className="main-content-container px-4">

            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Clientele" className="text-sm-left" />
            </Row>

            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
              <Button onClick={e => goBack()}>Retour</Button>
              <Button onClick={e => handleNewClient()}>Ajouter nouveau client</Button>
            </ButtonGroup>

			<Row><Col>
				<div className={showClient ? 'hidden' : ''}>
					<ClientTable clients={clients} editRow={editRow} deleteUser={deleteClient}/>
				</div>
        <div className={showClient ? '' : 'hidden'} >
					{editing ? (
						<Fragment>
							<h2>Edit client</h2>
							<EditClientForm
								editing={editing}
								setEditing={setEditing}
								currentClient={currentClient}
                                updateClient={updateClient}
                                cancel={cancel}
                                photos={photos}
							/>
						</Fragment>
					) : (
						<Fragment>
							<h2>Add client</h2>
							<AddClientForm addClient={addClient} cancel={cancel}/>
						</Fragment>
					)}
				</div>
      </Col></Row>
		</Container>
	)
}

export default Clients;