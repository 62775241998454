import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

import axios from 'axios';

import { useAuth } from "../context/auth";
import { API_ROOT } from '../api-config';
import logoCarre from '../img/logo-carre.jpg';

function Signup() {
  const [isError, setIsError] = useState(false);
  const [sending, setSending] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [sexe, setSexe] = useState("homme");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthTokens } = useAuth();

  window.gtag('event', 'page_view', {
    page_title: 'Signup',
    page_location: '/signup',
    page_path: '/signup',
    //send_to: '<GA_MEASUREMENT_ID>'
  })

  function postSignup() {
    setSending(true);
    var refId = localStorage.getItem("refId");
    var newUser = {
      username: userName,
      email: email,
      firstname: firstName,
      lastname: lastName,
      password: password,
      phonenumber: phonenumber
    }
    if (refId !== null && refId !== "null")
      newUser.referedBy = refId;
    axios.post(API_ROOT + "user/register", newUser).then(result => {
      //console.log(result);
      if (result.status === 200) {
        result = result.data;
        if (result.error) {
          console.log("An error", result.error);
          setIsError(true);
          setSending(false);
          setErrorMsg(result.error.message);
          return;
        }

        try {
          setAuthTokens(result.authToken);
        } catch (e) {
          console.log('ERROR', e);
        }

        //setLoggedIn(true);
        window.fbq('track', 'CompleteRegistration');
      } else {
        setSending(false);
        setIsError(true);
        setErrorMsg(result.error.message);
      }
    }).catch(e => {
      setSending(false);
      setIsError(true);
    });
  }

  let existingTokens;
  try {
    existingTokens = JSON.parse(localStorage.getItem('tokens'));
  } catch (e) {
    existingTokens = null;
  }

  if (existingTokens !== null) {
    var red = localStorage.getItem("redirect");
    if (red !== null)
      return <Redirect to={red} />;
    var modelId = localStorage.getItem("modellId");
    if (modelId !== null)
      return <Redirect to="/services" />;
    return <Redirect to="/user-profile" />;
  }

  return (
    <div id="appCapsule">
        <div class="section mt-2 text-center">
          <img src={logoCarre} class="image"/>
            <h1>Enregistre-toi</h1>
        </div>
        <div class="section mb-5 p-2">
            <form onSubmit={e => { e.preventDefault(); postSignup(); }}>
                <div class="card">
                    <div class="card-body">
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="email1">Courriel</label>
                                <input
                                  type="email" 
                                  class="form-control" 
                                  id="email1" 
                                  placeholder="Votre adresse courriel"
                                  value={email}
                                  required
                                  onChange={e => {
                                    setEmail(e.target.value);
                                  }}
                                />
                                <i class="clear-input">
                                    <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="username">Surnom</label>
                                <input
                                  type="text" 
                                  class="form-control" 
                                  id="username" 
                                  placeholder="Votre surnom"
                                  value={userName}
                                  required
                                  onChange={e => {
                                    setUserName(e.target.value);
                                  }}
                                />
                                <i class="clear-input">
                                    <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="firstName">Prénom</label>
                                <input
                                  type="text" 
                                  class="form-control" 
                                  id="firstName" 
                                  placeholder="Votre prénom"
                                  value={firstName}
                                  required
                                  onChange={e => {
                                    setFirstName(e.target.value);
                                  }}
                                />
                                <i class="clear-input">
                                    <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="lastName">Nom de famille</label>
                                <input
                                  type="text" 
                                  class="form-control" 
                                  id="lastName" 
                                  placeholder="Votre nom de famille"
                                  value={lastName}
                                  required
                                  onChange={e => {
                                    setLastName(e.target.value);
                                  }}
                                />
                                <i class="clear-input">
                                    <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="phonenumber">Numéro de téléphone</label>
                                <input
                                  type="text" 
                                  class="form-control" 
                                  id="phonenumber" 
                                  placeholder="Votre numéro de cellulaire"
                                  value={phonenumber}
                                  required
                                  onChange={e => {
                                    setPhonenumber(e.target.value);
                                  }}
                                />
                                <i class="clear-input">
                                    <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="password1">Mot de passe</label>
                                <input
                                  type="password"
                                  class="form-control" 
                                  id="password1" 
                                  autoComplete="off" 
                                  placeholder="Votre mot de passe"
                                  value={password}
                                  required
                                  onChange={e => {
                                    setPassword(e.target.value);
                                  }}
                                />
                                <i class="clear-input">
                                    <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        {isError && <div className="alert alert-danger">{errorMsg}</div>}

                    </div>
                </div>

                <div class="form-links mt-2">
                    <div>
                      <Link className="" to="/login">Connexion</Link>
                    </div>
                    <div><Link className="text-muted" to="/user/forgot-password">Mot de passe oublié?</Link></div>
                </div>

                <div class="form-button-group transparent">
                    <button 
                      disabled={sending}
                      type="submit" 
                      class="btn btn-primary btn-block btn-lg">
                        Je veux m'inscrire
                    </button>
                </div>
                <br/><br/>
            </form>
        </div>
    </div>
  )
}

export default Signup;
