import React from "react";
import {api} from '../../services/autonome';
import { Container } from "shards-react";
import DragAndDrop from '../../components/filedragdrop';
import Button from '@material-ui/core/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import Form from 'react-bootstrap/Form';

import axios from 'axios';
import { API_ROOT } from '../../api-config';

export class Ide extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      userId: this.getUserId(),
      categories: [],
      selectedModel: 0,
      model: null
    }
    this.changeModel = this.changeModel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.changeField = this.changeField.bind(this);
  }

  saveModel() {
    var newModel = JSON.parse(JSON.stringify(this.state.model));
    newModel.fields = JSON.stringify({
      fields:newModel.fields,
      commission: this.state.model.commission,
      textToWorker: this.state.model.textToWorker,
      textToClient: this.state.model.textToClient
    });
    console.log(newModel.reviews);
    newModel.reviews = JSON.stringify(newModel.reviews);
    console.log(newModel);
    api.call('post', "tasks/model", newModel).then(result => {
      //alert(JSON.stringify(result));
    });
  }

  handleChange(event) {
    //console.log("Handle change", event.target.name);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state.model;
    new_form[name] = value;
    this.setState({model:new_form});
    this.setState({errorMsg: null});
  }

  removeField(field) {
    var new_form = this.state.model;
    delete new_form.fields[field];
    this.setState({model:new_form});
  }

  insertField() {
    var new_form = this.state.model;
    new_form.fields.push({name:"", type:"text", label:"", description:""});
    this.setState({model:new_form});
  }

  insertReviewOwner() {
    var new_form = this.state.model;
    new_form.reviews.rate_owner.push({name:"", label:"", description:""});
    this.setState({model:new_form});
  }

  removeWorkerReview(idx) {
    var new_form = this.state.model;
    new_form.reviews.rate_worker = new_form.reviews.rate_worker.splice(idx, 1);
    this.setState({model:new_form});
  }

  insertReviewWorder() {
    var new_form = this.state.model;
    new_form.reviews.rate_worker.push({name:"", label:"", description:""});
    this.setState({model:new_form});
  }

  removeOwnerReview(idx) {
    var new_form = this.state.model;
    alert(JSON.stringify(new_form.reviews.rate_owner));
    if (new_form.reviews.rate_owner.length==1)
      new_form.reviews.rate_owner = []
    else
      new_form.reviews.rate_owner = new_form.reviews.rate_owner.splice(idx, 1);
    alert(JSON.stringify(new_form.reviews.rate_owner));
    this.setState({model:new_form});
  }

  changeField(field, event) {
    console.log("Handle change", field, event.target.name, event.target.value);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state.model;
    if (target.type === 'checkbox') {
      
      new_form.fields[field][name] = value;
    } else
      new_form.fields[field][name] = event.target.value;
    this.setState({model:new_form});
    //this.setState({errorMsg: null});
  }

  removeWorkerReview(idx) {
    var new_form = this.state.model;
    delete new_form.reviews.rate_worker[idx];
    this.setState({model:new_form});
  }

  arraymove(arr, fromIndex, toIndex) {    
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  upField(idx) {
    var new_form = this.state.model;
    this.arraymove(new_form.fields, idx, idx-1)
    this.setState({model:new_form});
  }

  changeWorkerReview(field, event) {
    console.log("Handle change", field, event.target.name);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state.model;
    new_form.reviews.rate_worker[field][name] = value;
    this.setState({model:new_form});
    //this.setState({errorMsg: null});
  }
  
  changeOwnerReview(field, event) {
    console.log("Handle change", field, event.target.name);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state.model;
    new_form.reviews.rate_owner[field][name] = value;
    this.setState({model:new_form});
    //this.setState({errorMsg: null});
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  componentDidMount() {
    var these = this;
    api.call('get', "tasks/categories", {}).then(result => {
      //console.log(result);
      if (typeof result.data.error != "undefined") return;

      result.data.forEach(function(one) {
        var the_fields = JSON.parse(one.fields);
        one.fields = the_fields.fields;
        if (typeof the_fields.commission !== "undefined")
          one.commission = the_fields.commission
        else
          one.commission = "0";
          if (typeof the_fields.textToWorker !== "undefined")
          one.textToWorker = the_fields.textToWorker
        else
          one.textToWorker = "";
        if (typeof the_fields.textToClient !== "undefined")
          one.textToClient = the_fields.textToClient
        else
          one.textToClient = "";
        if (one.reviews.length == 0) {
          return one.reviews = {rate_owner:[], rate_worker:[]}
        }
        one.reviews = JSON.parse(one.reviews);
      });
      these.setState({
        categories: result.data
      });
      // TODO : A enlever après l'avoir terminé de le developper
      this.setState({selectedModel: 2});
      this.state.categories.forEach(function(one) {
        if (one.id === 2)
          these.setState({model: one});
      });
    });
  }

  componentWillUnmount() {
  }

  handleDropIcon = (name, files) => {
    var self = this;
    let fileList = this.state.files
    console.log(files);
    for (var i = 0; i < files.length; i++) {
      if (!files[i].name) return;
        //fileList.push(files[i].name)
        const data = new FormData();
        data.append('file', files[i]);
        console.log("data:",data);

        axios.post(API_ROOT + "liberium/upload_image", data).then((response) => {
          console.log("Response:", response);
            this.setState({
                uploadMsg: "Upload terminé"
            })
            if (response.data.status) {
              var new_form = self.state.model;
              new_form["icon"] = response.data.data.fileUrl;
              this.setState({model:new_form});
            } else {
              alert("error somewhere");
            }
        })
    }
}

  handleDrop = (name, files) => {
    var self = this;
    let fileList = this.state.files
    console.log(files);
    for (var i = 0; i < files.length; i++) {
      if (!files[i].name) return;
        //fileList.push(files[i].name)
        const data = new FormData();
        data.append('file', files[i]);
        console.log("data:",data);

        axios.post(API_ROOT + "liberium/upload_image", data).then((response) => {
          console.log("Response:", response);
            this.setState({
                uploadMsg: "Upload terminé"
            })
            if (response.data.status) {
              var new_form = self.state.model;
              new_form["pubUrl"] = response.data.data.fileUrl;
              this.setState({model:new_form});
            } else {
              alert("error somewhere");
            }
        })
    }
}

  chooseCategory(row) {
    this.setState({selectedModel: row});
  }

  changeModel(event) {
    var these = this;
    event.target.value = parseInt(event.target.value);
    this.setState({selectedModel: event.target.value});
    this.state.categories.forEach(function(one) {
      //console.log(one.id, "===", event.target.value);
      if (one.id === parseInt(event.target.value)) {
        these.setState({model: one});
      }
    });
  }

  renderReviewsWorker() {
    //alert(JSON.stringify(this.state.model.reviews.rate_worker));
    //"rate_worker":[{"name":"apparence","label":"Apparence","description":"Etait-il habillé convenablement"},{"name":"speed","label":"Rapidité","description":"Est-il venu vous voir rapidement"}]}
    var fields = this.state.model.reviews.rate_worker.map((row, idx) => {
      return (
        <>
        <Form.Group className="mb-3" controlId="fromName">
          <Form.Label>Nom du champ</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nom du champ"
            value={row.name}
            name="name"
            onChange={e => this.changeWorkerReview(idx, e)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="fromName">
          <Form.Label>Label du champ</Form.Label>
          <Form.Control
            type="text"
            placeholder="Label du champ"
            value={row.label}
            name="label"
            onChange={e => this.changeWorkerReview(idx, e)}
          />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="fromName">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Description"
            value={row.description}
            name="description"
            onChange={e => this.changeWorkerReview(idx, e)}
          />
        </Form.Group>

        <Button
          variant="danger"
          color="error"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => this.removeWorkerReview(idx)}
        >
          Enlever
        </Button>
        <hr/>
        </>
      )
    });
    return (
      <>
      <h1>Pour le travailleur</h1>
      <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => this.insertReviewWorder()}
      >
          Ajouter un
      </Button>
      {fields}
      </>
    )
  }

  renderReviewsOwner() {
    //{"rate_owner":[{"name":"communication","label":"Communication","description":"A-t-il bien communiquer comment le rejoindre?"}],
    var fields = this.state.model.reviews.rate_owner.map((row, idx) => {
      return (
        <>
        <Form.Group className="mb-3" controlId="fromName">
          <Form.Label>Nom du champ</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nom du champ"
            value={row.name}
            name="name"
            onChange={e => this.changeOwnerReview(idx, e)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="fromName">
          <Form.Label>Label du champ</Form.Label>
          <Form.Control
            type="text"
            placeholder="Label du champ"
            value={row.label}
            name="label"
            onChange={e => this.changeOwnerReview(idx, e)}
          />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="fromName">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Description"
            value={row.description}
            name="description"
            onChange={e => this.changeOwnerReview(idx, e)}
          />
        </Form.Group>

        <Button
          variant="danger"
          color="error"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => this.removeOwnerReview(idx)}
        >
          Enlever
        </Button>
        <hr/>
        </>
      )
    });
    return (
      <>
      <h1>Pour le donneur d'ouvrage</h1>
      <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => this.insertReviewOwner()}
      >
          Ajouter un
      </Button>
      {fields}
      </>
    )
  }

  renderFields() {
    //{"label":"Montant du dépot","name" : "amount", "type":"string","textMuted":"Montant que le client veut déposer dans son compte"}
    var fields = this.state.model.fields.map((row, idx) => {
      console.log(row.needed);
      if (row.needed === "true" || row.needed)
        row.needed = true
      else
        row.needed = false;
      var monter = (
        <Button
        variant="danger"
        color="error"
        size="small"
        startIcon={<DeleteIcon />}
        onClick={() => this.upField(idx)}
      >
        Monter
      </Button>
      );
      if (idx == 0)
        monter = (<></>);
      return (
        <>
        <Form.Group className="mb-3" controlId="fromName">
          <Form.Label>Nom du champ</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nom du champ"
            value={row.name}
            name="name"
            onChange={e => this.changeField(idx, e)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="fromName">
          <Form.Label>Type du champ</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={e => this.changeField(idx, e)}
            value={row.type}
            id="type"
            name="type"
          >
            <option value="text">Texte</option>
            <option value="integer">Integer</option>
            <option value="date">Date</option>
            <option value="hour">Heure</option>
            <option value="boolean">Boolean</option>
            <option value="address">Address</option>
            <option value="files">Files</option>
            <option value="image">Image</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="needed">
          <Form.Label>Needed</Form.Label>
          <Form.Check
            type="checkbox"
            placeholder="Champ nécessaire"
            value={row.needed}
            checked={row.needed === true}
            name="needed"
            onChange={e => this.changeField(idx, e)}
          />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="fromName">
          <Form.Label>Label du champ</Form.Label>
          <Form.Control
            type="text"
            placeholder="Label du champ"
            value={row.label}
            name="label"
            onChange={e => this.changeField(idx, e)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="fromName">
          <Form.Label>Autre texte</Form.Label>
          <Form.Control
            type="text"
            placeholder="Autre texte"
            value={row.textMuted}
            name="textMuted"
            onChange={e => this.changeField(idx, e)}
          />
        </Form.Group>

        {monter}

        <Button
          variant="danger"
          color="error"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => this.removeField(idx)}
        >
          Enlever
        </Button>
        <hr/>
        </>
      )
    });
    return (
      <>
      <h1>Champs</h1>
      <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => this.insertField()}
      >
          Ajouter un champ
      </Button>
      {fields}
      </>
    )
  }

  renderMarketing() {
    return (
      <>
        <DragAndDrop handleDrop={this.handleDrop}>
          <h2>Publicité sur FaceBook</h2>

          <img src={this.state.model.pubUrl}/>
        </DragAndDrop>
        <h2>Textes marketing</h2>

        <Form.Group className="mb-3" controlId="fromName">
            <Form.Label>Texte pour travailleur</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Texte qu'on envoie au travailleur"
              value={this.state.model.textToWorker}
              name="textToWorker"
              rows={5}
              onChange={e => this.handleChange(e)}
            />
        </Form.Group>
        <Form.Group className="mb-3" controlId="fromName">
            <Form.Label>Texte pour le client</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Texte qu'on envoie au client"
              value={this.state.model.textToClient}
              name="textToClient"
              rows={5}
              onChange={e => this.handleChange(e)}
            />
        </Form.Group>
      </>
    )
  }

  renderModel() {    
    if (this.state.model === null) return;
    return (
      <>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        startIcon={<CheckIcon />}
        onClick={() => this.saveModel()}
      >
        Sauvegarder
      </Button>
      <Tabs
        defaultActiveKey="home"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="home" title="Configuration">
          <Form.Group className="mb-3" controlId="fromName">
            <Form.Label>Nom du model</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nom du modèle"
              value={this.state.model.name}
              name="name"
              onChange={e => this.handleChange(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="fromName">
            <Form.Label>Activé</Form.Label>
            <Form.Check
              label="Est activé?"
              name="enabled"
              id="enabled"
              checked={this.state.model.enabled}
              onChange={e => this.handleChange(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="fromName">
            <Form.Label>Visible</Form.Label>
            <Form.Check
              label="Est-il visible?"
              name="visible"
              id="visible"
              checked={this.state.model.visible}
              onChange={e => this.handleChange(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="fromName">
            <Form.Label>Commission</Form.Label>
            <Form.Control
              type="text"
              placeholder="0.05"
              value={this.state.model.commission}
              name="commission"
              onChange={e => this.handleChange(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="fromName">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Description"
              value={this.state.model.description}
              name="description"
              rows={5}
              onChange={e => this.handleChange(e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="fromName">
            <Form.Label>Description pour travailleur</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Description pour travailleur"
              value={this.state.model.descriptionWorker}
              name="descriptionWorker"
              rows={5}
              onChange={e => this.handleChange(e)}
            />
          </Form.Group>

          <DragAndDrop handleDrop={this.handleDropIcon}>
          <Form.Group className="mb-3" controlId="icon">
            <img src={this.state.model.icon} width={100} class="image"/>
            <Form.Label>Icone</Form.Label>
            <Form.Control
              type="text"
              placeholder="Icone du modèle"
              value={this.state.model.icon}
              name="icon"
              onChange={e => this.handleChange(e)}
            />
          </Form.Group>
          </DragAndDrop>
        </Tab>
        <Tab eventKey="champs" title="Champs">
          {this.renderFields()}
        </Tab>
        <Tab eventKey="review_worker" title="Reviews Worker">
          {this.renderReviewsWorker()}
        </Tab>
        <Tab eventKey="review_owner" title="Reviews Owner">
          {this.renderReviewsOwner()}
        </Tab>
        <Tab eventKey="marketing" title="Marketing">
          {this.renderMarketing()}
        </Tab>
      </Tabs>
      </>
    )
  }

  render() {
    var cats = this.state.categories.map((row) => {
      var enable = "(pas activé)"
      if (row.enabled) enable = "";
      return (
        <option
          value={row.id}
          >
          {row.name} {enable}
        </option>
      );
    });
    return (
        <Container fluid className="main-content-container px-4">
          <Form.Select
            aria-label="Default select example"
            onChange={this.changeModel}
            value={this.state.selectedModel}            
          >
            <option></option>
            {cats}
          </Form.Select>
          {this.renderModel()}
        </Container>
    );
  }
}