import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import Button from '@material-ui/core/Button';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TransformIcon from '@material-ui/icons/Transform';
import { Form, Modal } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';
import EditIcon from '@material-ui/icons/Edit';

const currentVersion = "0.2.38";
const currentUrl = "https://api.liberium.net/s/liberium-modules/liberium-os-38.tar.gz";

export class ServerView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            show: false,
            migrateShow: false,
            migrateTo: null,
            serverChoosed: null,
            containers: [],
            selectedContainer: {image:"",args:""},
            templates: [],
            servers: [],
            server: {
                name: "",
                version: currentVersion,
                metas: {
                    cpus: {
                        cpus:[{model:"loading..."}]
                    },
                    networks: {}
                }
            },
            fields: [],
            id: parseInt(props.match.params.id),
            showNewContainer: false
        }
        this.state.userId = this.getUserId();
        this.changeTemplate = this.changeTemplate.bind(this);
    }

    fetchData() {
        var these = this;
        api.call('get', "hosting/server/" + this.state.id, {}).then(result => {
            
            result.data.metas = JSON.parse(result.data.metas);
            console.log("typeof", Array.isArray(result.data.metas.gpus));
            if (!Array.isArray(result.data.metas.gpus)) {
                result.data.metas.gpus = [];
                //result.data.metas.gpus = [result.data.metas.gpus]
            }
            these.setState({server: result.data, name: result.data.name, enabled: result.data.enabled});
        });
        api.call('get', "hosting/server/" + this.state.id + "/containers", {}).then(result => {
            these.setState({containers: result.data});
        });
        api.call('get', "hosting/servers", {}).then(result => {
            these.setState({servers: result.data});
        });
    }

    saveServer() {
        api.call('post', "hosting/server/" + this.state.id, {
            name: this.state.name,
            enabled: this.state.enabled
        }).then(result => {
        });
    }

    fetchTemplate() {
        var these = this;
        api.call('get', "hosting/templates", {}).then(result => {
            these.setState({templates: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        var these = this;
        this.fetchData();
        this.fetchTemplate();
        setInterval( function () {
            these.fetchData();
        },10000)
    }

    selectServer(server) {
        this.setState({server: server});
    }

    onStop(container) {
        var these = this;
        api.call('get', "hosting/server/" + this.state.id + "/container/" + container.id + "/stop", {}).then(result => {
            alert("stopped");
            these.fetchData();
        });
    }

    rebootHost() {
        var these = this;
        api.call('get', "hosting/server/" + this.state.id + "/reboot", {}).then(result => {
            alert("rebooting");
        });     
    }

    onStart(container) {
        var these = this;
        api.call('get', "hosting/server/" + this.state.id + "/container/" + container.id + "/start", {}).then(result => {
            alert("started");
            these.fetchData();
        });
    }

    onView(container) {
        this.props.history.push({
            pathname: "/admin/server/" + this.state.id + "/container/" + container.id
        });
    }

    onDelete(container) {
        var these = this;
        api.call('delete', "hosting/container/" + container.id, {}).then(result => {
            alert("deleted");
            these.fetchData();
        });
    }

    updateHost() {
        api.call('get', "hosting/server/" + this.state.id + "/updateVersion?url=" + currentUrl, {}).then(result => {
            alert("Successfully sent update...");
        });
    }

    selectContainer(row) {
        var parsed = JSON.parse(row.metas);
        if (typeof parsed.essentialService === "undefined")
            parsed.essentialService = false;
        if (typeof parsed.delayStart === "undefined")
            parsed.delayStart = "0";
        if (typeof parsed.serviceName === "undefined")
            parsed.serviceName = "";
        if (typeof parsed.launchCmd === "undefined")
            parsed.launchCmd = "";

        this.setState({
            image: row.image,
            args: row.args,
            launchCmd: parsed.launchCmd,
            selectedContainer: row,
            serviceName: parsed.serviceName,
            essentialService: parsed.essentialService,
            delayStart: parsed.delayStart,
            show:!this.state.show
        });
    }

    onMigrate(row) {
        this.setState({
            selectedContainer: row,
            migrateShow: true
        });
    }

    createContainer() {
        var these = this;        
        var newContainer = {
            serverId: this.state.id,
            image: this.state.image,
            args: this.state.args,
            state: "creating"
        }
        api.call('post', "hosting/server/" + this.state.id + "/container/create", newContainer).then(result => {
            alert("starting");
            these.fetchData();
        });
    }

    renderGpus() {
        console.log("GPUS:",this.state.server.metas.gpus)
        if (typeof this.state.server.metas.gpus === "undefined") return (<></>);
        var rows = this.state.server.metas.gpus.map((row) => {
            return ( <tr><td>{row.product}</td></tr> )
        });

        return (
            <table>
                {rows}
            </table>
        )
    }

    renderCpus() {
        if (typeof this.state.server.metas.cpus === "undefined") return (<></>);
        var rows = this.state.server.metas.cpus.cpus.map((row) => {
            return ( <tr><td>{row.model}</td></tr> )
        });
        return (
            <table>
                {rows}
            </table>
        )
    }

    renderNetwork() {
        if (typeof this.state.server.metas.networks === "undefined") return (<></>);
        var rows = Object.keys(this.state.server.metas.networks).map((id) => {
            var row = this.state.server.metas.networks[id];
            return ( <tr><td>{id}</td><td>{row[0].address}</td></tr> )
        });
        return (
            <table>
                <thead>
                    <tr>
                        <td>ID</td>
                        <td>IP</td>
                    </tr>
                </thead>
                {rows}
            </table>
        )
    }

    renderContainers() {
        return this.state.containers.map((row) => {
            var metas = JSON.parse(row.metas);
            var btn = (<></>)
            var delBtn = (<></>)
            if (row.state === "stopped")
            btn = (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PlayArrowIcon />}
                    onClick={() => this.onStart(row)}
                >
                    Start
                </Button>
            )
            if (row.state === "running")
            btn = (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<StopIcon />}
                    onClick={() => this.onStop(row)}
                >
                    Stop
                </Button>
            )
            var status = row.status;
            if (row.state === "stopped" || row.state === "problem") {
                status = "";
                delBtn = (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<TransformIcon />}
                        onClick={() => this.onDelete(row)}
                    >
                        Delete
                    </Button>
                )
            }

            var allBtn = (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<PlayArrowIcon />}
                        onClick={() => this.selectContainer(row)}
                    >
                        Edit
                    </Button>
                    {btn}
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<TransformIcon />}
                        onClick={() => this.onMigrate(row)}
                    >
                        Migrate
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<VisibilityIcon />}
                        onClick={() => this.onView(row)}
                    >
                        View
                    </Button>
                    {delBtn}
                    </>
            )
            if (typeof metas.deploymentId !== "undefined")
                allBtn = (<>Managed by Deployment</>);
            return (
                <tr>
                <td>{row.id}</td>
                <td>{row.image}</td>
                <td>{row.state}</td>
                <td>{status}</td>
                <td>{row.lastPing}</td>
                <td>
                    {allBtn}
                </td>
                </tr>
            );
          });
    }

    handleChange(event) {
        //console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
        this.setState({errorMsg: null});
    }

    doChangeField() {
        var these = this;
        var newArgs = this.state.template.args;
        console.log("newArgs:", newArgs);
        this.state.fields.forEach(function (one) {
            console.log("check field:", one.name);
            newArgs = newArgs.replace("%" + one.name + "%", these.state[one.name]);
            console.log("newArgs:", newArgs);
        });
        console.log("final Args:", newArgs);
        this.setState({args: newArgs});
    }

    handleField(event) {
        //console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
        this.setState({errorMsg: null});

        this.doChangeField();
    }

    newContainer() {
        this.setState({showNewContainer: !this.state.showNewContainer});
    }

    changeTemplate(event) {
        var these = this;
        event.target.value = parseInt(event.target.value);
        this.setState({selectedTemplate: event.target.value});
        this.state.templates.forEach(function(one) {
          console.log(one.id, "===", event.target.value);
          if (one.id === parseInt(event.target.value)) {
            these.setState({template: one, image: one.image, args: one.args, fields: JSON.parse(one.fields)});
            //alert("found one");
          }
        });
    }

    closeModal() {
        this.setState({show: false});
        this.fetchData();
    }

    saveContainer() {
        var these = this;
        api.call('post', "hosting/server/" + this.state.id + "/container/" + this.state.selectedContainer.id, {
            replicas: this.state.replicas,
            timeout: this.state.timeout,
            image: this.state.image,
            essentialService: this.state.essentialService,
            serviceName: this.state.serviceName,
            launchCmd: this.state.launchCmd,
            delayStart: this.state.delayStart,
            args: this.state.args
        }).then(result => {
            these.closeModal();
        });
    }

    renderNewContainer() {
        if (!this.state.showNewContainer)
            return (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PlayArrowIcon />}
                    onClick={() => this.newContainer()}
                >
                    Nouveau container
                </Button>
            )
        var templates = this.state.templates.map((row) => {
            return (
                <option
                    value={row.id}
                    >
                    {row.name}
                </option>
            );
        });
        var fields = this.state.fields.map((row) => {
            return (
                <Form.Group className="mb-3" controlId="texte" key="texte">
                    <Form.Label>{row.name}</Form.Label>
                    <Form.Control
                        type="text"
                        name={row.name}
                        value={this.state[row.name]} 
                        onChange={e => this.handleField(e)}
                    >
                    </Form.Control>
                </Form.Group>
            );
        });
        return (
            <><Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<StopIcon />}
                onClick={() => this.newContainer()}
            >
                Nouveau container
            </Button>

            <Form.Select
                aria-label="Default select example"
                onChange={this.changeTemplate}
                value={this.state.selectedTemplate}            
            >
                <option></option>
                {templates}
            </Form.Select>

            {fields}

            <Form.Group className="mb-3" controlId="texte" key="texte">
                <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="text"
                    name="image"
                    value={this.state.image} 
                    onChange={e => this.handleChange(e)}
                  >
                  </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="texte" key="texte">
                <Form.Label>Args</Form.Label>
                  <Form.Control
                    type="text"
                    name="args"
                    value={this.state.args} 
                    onChange={e => this.handleChange(e)}
                  >
                  </Form.Control>
            </Form.Group>
            <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PlayArrowIcon />}
                    onClick={() => this.createContainer()}
                >
                    Créer
                </Button>
            </>
        )
    }

    renderEdit() {
        return (
            <>
                <Form.Group className="mb-3" controlId="useTemp">
                        <Form.Check
                            type='checkbox'
                            label='Enabled'
                            name="enabled"
                            checked={this.state.enabled}
                            value={this.state.enabled}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </Form.Group>
                <Form.Group className="mb-3" controlId="texte" key="texte">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={this.state.name} 
                        onChange={e => this.handleChange(e)}
                    >
                    </Form.Control>
                </Form.Group>
                <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<PlayArrowIcon />}
                        onClick={() => this.saveServer()}
                    >
                        
                    Save
                </Button>
            </>
        )
    }

    renderModal() {
        if (!this.state.show) return (<></>);
        var essentialsAttribs = (<></>)
        if (this.state.essentialService)
            essentialsAttribs = (
                <Form.Group className="mb-3" controlId="timeout">
                        <Form.Label>Start delay</Form.Label>
                        <Form.Control 
                            type="text"
                            name="delayStart"
                            placeholder="30" 
                            value={this.state.delayStart} 
                            onChange={e => this.handleChange(e)}
                        />
                        <Form.Text className="text-muted">
                            Le temps en seconde avant de le partir
                        </Form.Text>
                </Form.Group>
            )
        return (
            <Modal
                size="lg"
                show={this.state.show}
                onHide={() => this.closeModal()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Container
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="image">
                        <Form.Label>Service name</Form.Label>
                        <Form.Control 
                            type="text"
                            name="serviceName"
                            placeholder="" 
                            value={this.state.serviceName}
                            onChange={e => this.handleChange(e)}
                        />
                        <Form.Text className="text-muted">
                            Nom de domaine: .service
                        </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="image">
                        <Form.Label>Image</Form.Label>
                        <Form.Control 
                            type="text"
                            name="image"
                            placeholder="docker/image:v1" 
                            value={this.state.image}
                            onChange={e => this.handleChange(e)}
                        />
                        <Form.Text className="text-muted">
                            L'image qui doit être lancé
                        </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="args">
                        <Form.Label>Args</Form.Label>
                        <Form.Control 
                            type="text"
                            name="args"
                            placeholder="-d -P" 
                            value={this.state.args} 
                            onChange={e => this.handleChange(e)}
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="launch">
                        <Form.Label>Launch cmd</Form.Label>
                        <Form.Control 
                            type="text"
                            name="launchCmd"
                            placeholder="" 
                            value={this.state.launchCmd}
                            onChange={e => this.handleChange(e)}
                        />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="timeout">
                        <Form.Label>Timeout</Form.Label>
                        <Form.Control 
                            type="text"
                            name="timeout"
                            placeholder="600" 
                            value={this.state.selectedContainer.timeout} 
                            onChange={e => this.handleChange(e)}
                        />
                        <Form.Text className="text-muted">
                            L'image qui doit être lancé
                        </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="essentialService">
                        <Form.Check
                            type='checkbox'
                            label='Est-ce un service essentiel'
                            name="essentialService"
                            checked={this.state.essentialService}
                            value={this.state.essentialService}
                            onChange={(e) => this.handleChange(e)}
                        />
                        </Form.Group>

                        {essentialsAttribs}

                        <div class="container">
                            <div class="row">
                                <div class="col text-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<EditIcon />}
                                        onClick={() => this.saveContainer()}
                                    >
                                        Sauvegarder
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }

    closeMigrate() {
        this.setState({migrateShow: false});
    }

    migrateTo(serverId) {
        this.setState({migrateTo: serverId});
    }

    goMigration() {
        var these = this;
        api.call('get', "hosting/migrate/" + this.state.selectedContainer.id + "/" + this.state.migrateTo, {
            replicas: this.state.replicas,
            timeout: this.state.timeout,
            image: this.state.image,
            essentialService: this.state.essentialService,
            delayStart: this.state.delayStart,
            args: this.state.args
        }).then(result => {
            these.closeMigrate();
        });
    }

    renderMigrate() {
        var servers = this.state.servers.map((row) => {
            if (row.enabled && !row.isOffLine && row.id !== this.state.id)
            return ( <Dropdown.Item onClick={() => this.migrateTo(row.id)}>{row.id}</Dropdown.Item> )
        });
        return (
            <Modal
                size="lg"
                show={this.state.migrateShow}
                onHide={() => this.closeMigrate()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Servers
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                        Vers quel serveur
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {servers}
                        </Dropdown.Menu>
                        </Dropdown>

                        <p>Migration to: {this.state.migrateTo}</p>

                        <div class="container">
                            <div class="row">
                                <div class="col text-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<EditIcon />}
                                        onClick={() => this.goMigration()}
                                    >
                                        Transfert
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        )
    }

    render(){        
        var versionBtn = (<></>);
        if (this.state.server.version !== currentVersion)
            versionBtn = (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PlayArrowIcon />}
                    onClick={() => this.updateHost()}
                >
                    Update
                </Button>
            )
        return(
            <>
                <h1>Server {this.state.server.name}</h1>
                {this.renderMigrate()}
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
                >
                <Tab eventKey="home" title="Home">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<PlayArrowIcon />}
                        onClick={() => this.rebootHost()}
                    >
                        Reboot
                    </Button>
                    {versionBtn}

            <h1>Containers</h1>
            {this.renderNewContainer()}
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>State</th>
                    <th>Status</th>
                    <th>Last Ping</th>
                    <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderContainers()}
                </tbody>
            </Table>
                </Tab>
                <Tab eventKey="edit" title="Edit">
                    <h1>Edit</h1>
                    {this.renderEdit()}
                </Tab>
                <Tab eventKey="network" title="Network">
                    {this.renderNetwork()}
                </Tab>
                <Tab eventKey="cpu" title="CPU">
                    {this.renderCpus()}
                </Tab>
                <Tab eventKey="gpu" title="GPU">
                {this.renderGpus()}
                </Tab>
            </Tabs>
            {this.renderModal()}
            </>
        )
    }
}