import React from "react";
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import {
    Alert
} from "shards-react";
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';

export class ProfileNotUpdated extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            task: this.props.task
        }
    }

    componentDidMount(){
    }

    goProfile() {
        this.props.history.push({
            pathname: "/user-profile"
        });
    }

    render () {
        return (
            <Alert theme="danger">
                <p>
                    Votre profile n'est pas à jour!
                    Pour être efficace, nous avons besoin de votre adresse exacte.
                </p>

                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AccountCircleSharpIcon />}
                    onClick={() => this.goProfile()}
                >
                    Profil
                </Button>
            </Alert>
        )
    }
}

export default withRouter(ProfileNotUpdated);