import {api} from '../../services/autonome';
import React from "react";
import { Container } from "shards-react";
import { Form, Alert } from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Table } from "react-bootstrap";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

export class FbAdView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      me: null,
      texte: "Bonjour gens de %cityName%.\nSaviez-vous que?",
      groups: [],
      checked: {},
      ad: props.ad
    }
    var self = this;
    console.log("ad", this.state.ad)
    if (typeof this.state.ad !== "undefined") {
      Object.keys(this.state.ad.metas.groups).forEach(function(one){
        self.state.checked[one] = true;
      });
      this.state.texte = props.ad.metas.texte;
    }
    console.log("props", props.ad)
    console.log("state:",this.state);
  }

  fetchData() {
    this.getGroups();
  }

  getGroups() {
    var these = this;
    api.call('get', "ads/get_fb_groups", {}).then(result => {
      console.log("groups:", result.data)
      these.setState({groups: result.data});
    });
  }

  validateForm() {
    return true;
  }

  handleChange(event) {
    console.log("Handle change", event);
    const target = event.target;
    console.log(target);
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
  }

  handleCheck(id) {
    var checked = this.state.checked;
    if (typeof checked[id] === "undefined")
        checked[id] = true
    else 
        checked[id] = !checked[id];
    this.setState({checked: checked});
  }

  componentDidMount() {
    api.call('post', "user/usrLog", {module: "views", title:"new_fb_ad"});
    var me = JSON.parse(localStorage.getItem('user'));
    this.setState({me: me });
    this.fetchData();
    window.gtag('event', 'page_view', {
      page_title: 'New facebook ad',
      page_location: '/new_fb_ad',
      page_path: '/new_fb_ad'
    })
  }

  componentWillUnmount() {
  }

  sendAd() {
    if (typeof this.state.ad !== "undefined" && this.state.ad !== null) {
      var ad = {
        id: this.state.ad.id,
        groups: this.state.checked
      }
      api.call('post', "ads/update_fb_ad", ad).then(result => {
        alert("done");
      });
    } else {
      var new_ad = {
        texte: this.state.texte,
        groups: this.state.checked
      }
      api.call('post', "ads/new_fb_ad", new_ad).then(result => {
        alert("Created");
      });
    }
  }

  renderGroups() {
    var groups = this.state.groups.map((row) => {
      var checked=(<></>)
      if (typeof this.state.ad.metas.groups[row.id] !== "undefined" 
          && typeof this.state.ad.metas.groups[row.id].sent !== "undefined" 
          && this.state.ad.metas.groups[row.id].sent)
        checked = (<CheckIcon/>);
      return (
          <tr>
            <td>
                <Checkbox checked={this.state.checked[row.id]} onChange={(e)=>this.handleCheck(row.id)} name="gilad" />
            </td>
            <td>
              {row.name}
              {checked}
            </td>
          </tr>
      );
    });
    return (
      <Table striped bordered hover id="listZones">
        <thead>
          <tr>
            <th>#</th>
            <th>Group Name</th>
          </tr>
        </thead>
        <tbody>
          {groups}
        </tbody>
      </Table>
    )
  }

  render() {
    var errorMsg;
    if (this.state.errorMsg !== null)
        errorMsg = (
            <Alert variant="danger">
                {this.state.errorMsg}
            </Alert>
        );

    return (
      <Container fluid className="main-content-container px-4">

        <Breadcrumb>
            <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>
            <Breadcrumb.Item active>Nouvelle annonce facebook</Breadcrumb.Item>
        </Breadcrumb>

        <TextareaAutosize
            aria-label="minimum height" 
            minRows={5}
            value={this.state.texte}
            placeholder="Texte a envoyé"
            name="texte"
            onChange={(e)=>this.handleChange(e)}
        />

        <div>
            Variable: %cityName%
        </div>

        {this.renderGroups()}

        <Button variant="primary" onClick={(e)=>this.sendAd()}>Envoyer</Button>
    </Container>
    );
  }
}