import React from 'react'
import {
    Container,
    Form,
    FormInput,
    FormGroup
  } from "shards-react";

import {api} from '../../../services/autonome';

export class AdminAddFondView extends React.Component {
    
    constructor(props) {
        super(props)
        console.log(props)
        this.state = {
            sending: false,
            userId: props.match.params.userId,
            amount: 0,
            nbrToken: 0
        }
        this.handleChange = this.handleChange.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
        //this.handleTransferToken = this.handleTransferToken(this);
    }

    handleChange(event) {
        console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
          [name]: value
        });
    }
    
    handleSubmit(event) {
        //return alert("wrong function")
        this.setState({sending: true});
        var these = this;
        var data = {
            fondId: 1,
            userId: this.state.userId,
            amount: this.state.amount,
            currency: "CAD"
        }
        //alert(JSON.stringify(data));
        //return;
        api.call("post", "fond/token/new", data).then(result => {
            console.log("After adding fund");
            console.log(result);
            setTimeout( function () {
                these.props.history.goBack();
            },1500); // Give time to be updated
        })
    }

    handleTransferToken(event) {
        var these = this;
        //return alert("transfertoken??");
        this.setState({sending: true});
        var data = {
            fondId: 1,
            userId: this.state.userId,
            toUserId: this.state.toUserId,
            amount: this.state.nbrToken
        }
        //alert(JSON.stringify(data));
        //return;
        api.call("post", "fond/token/transfer", data).then(result => {
            console.log("After adding fund");
            console.log(result);
            setTimeout( function () {
                these.props.history.goBack();
            },1500); // Give time to be updated
        })
    }

    componentDidMount(){
    }

    render(){
        return(
            <Container fluid className="main-content-container px-4">
            <div className="w-100">
                
                <h1 className="m-4">Add Fund</h1>

                <FormGroup>
                    <label htmlFor="#amount">Montant en CAD :</label>
                    <FormInput type="text" name="amount" value={this.state.amount} onChange={this.handleChange} />
                </FormGroup>
                <button
                    disabled={this.state.sending}
                    className="add-item__button"
                    onClick={() => this.handleSubmit()}
                    >
                    Add Fund
                </button>

                <h1 className="m-4">Transfer Token</h1>

                <FormGroup>
                    <label htmlFor="#amount">Nombre de tokens :</label>
                    <FormInput type="text" name="nbrToken" value={this.state.nbrToken} onChange={this.handleChange} />
                </FormGroup>
                <FormGroup>
                    <label htmlFor="#toUserId">Au user Id :</label>
                    <FormInput type="text" name="toUserId" value={this.state.toUserId} onChange={this.handleChange} />
                </FormGroup>
                <button
                    disabled={this.state.sending}
                    className="add-item__button"
                    onClick={() => this.handleTransferToken()}
                    >
                    Transfer
                </button>
            </div>

            </Container>
        )
    }
}