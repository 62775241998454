//https://www.bootdey.com/snippets/view/chat-app#html

import React from 'react';
import Button from '@material-ui/core/Button';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import EditIcon from '@material-ui/icons/Edit';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Message } from '../../components/chat/message';
import MessageIcon from '@material-ui/icons/Message';

import {api} from '../../services/autonome';
import axios from 'axios';
import { API_ROOT } from '../../api-config';

//import './discussion.css';

export class ChatGptView extends React.Component {

    constructor(props) {
        super(props)
        //console.log(props)
        this.state = {
            messages: [{message:"Bonjour, comment je peux vous aider?"}],
            prompt: "Donne moi des bons commentaires sur le quebec au canada"
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    getUserId() {
        var existingTokens;
        try {
            existingTokens = JSON.parse(localStorage.getItem('tokens'));
            return existingTokens.UserId;
        } catch(e) {
            return null;
        }
    }

    handleChange(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value,
          is_error: false
        });
    }
    
    handleSubmit(event) {
        var these = this;
        var prompt = this.state.prompt;
        api.call("post", "ia/chatgpt", {prompt: prompt}).then(result => {            
            console.log("Data from gpt:", result);
            var splited = result.data.message.split("\n");
            const keys = Object.keys(splited);
            const paragraphs = [];
            for (var i = 0; i < keys.length; i++){
                console.log(splited[keys[i]]);
                // This part is to make sure you are not re-rendering your title.
                if(keys[i] !== "title"){
                    paragraphs.push(<p>{splited[keys[i]]}</p>);
                }      
            }

            these.setState({messages:[{message:paragraphs}]});
        }).catch(function(err) {
            //alert(JSON.stringify(err));
            these.setState({});
        });
        window.gtag('event', 'send_to_gpt', {});
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter') {
            this.handleSubmit(null);
        }
    }

    componentWillUnmount() {
    }

    componentDidMount() {
        window.gtag('event', 'page_view', {
            page_title: 'Chat GPT',
            page_location: '/chatgpt',
            page_path: '/chatgpt'
        });
    }

    goBack() {
        if (window.history.length<=1) {
            this.props.history.push({
                pathname: "/home"
            });
        } else {
            window.history.go(-1);
        }
    }

    render(){
        let list = <div classname="no-content-message">Il n'y a pas de messages.</div>;
        //console.log("=============");
        list = this.state.messages.map(m => <p key="{m.id}" id="{m.id}">{m.message}</p>);
        //console.log("=============");

        return(
            <>
                <div class="appHeader">
                    <div class="left">
                        <a onClick={() => this.goBack()} class="headerButton goBack">
                            <ion-icon name="chevron-back-outline" role="img" class="md hydrated" aria-label="chevron back outline"></ion-icon>
                        </a>
                    </div>
                    <div class="pageTitle">
                        Chat GPT
                    </div>
                    <div class="right">
                    </div>
                </div>
            <div class="card chat-app">
                <div class="chat">
                    <div class="chat-history">
                        <ul class="m-b-0">
                            {list}
                        </ul>
                    </div>
                    <div class="chat-message"

                    >
                        <div class="input-group mb-0">
                            <textarea
                                type="text"
                                rows={4}
                                name="prompt"
                                class="form-control"
                                placeholder="Prompt"
                                value={this.state.prompt}
                                onChange={e => this.handleChange(e)}
                                onKeyPress={this.handleKeyPress} 
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<MessageIcon />}
                                onClick={() => this.handleSubmit()}
                            >
                                
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}