import React from "react";
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';

export class LibCard extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            loaded: false,
            imgUrl: props.imgUrl,
            title: props.title,
            description: props.description,
            buttonTxt: props.buttonTxt,
            path: props.path
        }
        this.icon = (<NewReleasesSharpIcon/>)
        if (typeof props.icon !== "undefined")
            this.icon = props.icon;
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount(){
    }

    onClick = () => {
        this.props.history.push({
            pathname: this.state.path
        });
    }

    render () {
        return (
            <div class="col-sm-6 col-12">
                <div class="card">
                    <img src={this.state.imgUrl} alt="Card image cap" width="100%" height="122" class="card-img-top" />
                    <div class="card-body">
                        <h5 class="card-title">{this.state.title}</h5>
                        <p class="card-text">{this.state.description}</p>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={this.icon}
                            onClick={() => this.onClick()}
                        >
                            {this.state.buttonTxt}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(LibCard);
