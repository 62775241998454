import React from "react";
import { Container, Row } from "shards-react";
import Button from '@material-ui/core/Button';
import {api} from '../services/autonome';

class LandingPage extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state= {
        modelId: parseInt(props.match.params.id),
        model: {name:"",description:"",pubUrl: "", icon:""}
    }
    localStorage.setItem("modellId", props.match.params.id);
  }

  connect() {
    this.props.history.push({
      pathname: "/login"
    });
  }

  signup() {    
    this.props.history.push({
      pathname: "/signup"
    });
  }

  componentDidMount() {
    window.gtag('event', 'page_view', {
      page_title: 'Landing Page',
      page_location: '/landing',
      page_path: '/landing'
    })
    this.fetchData();
  }

  fetchData() {
    var these = this;
    console.log(this.state);
    api.call('get', "tasks/model/" + this.state.modelId, {}).then(result => {
        these.setState({model: result.data});
        console.log("model:",result.data)
    });
  }

  render() {
    var contract;
    if (this.state.model.isContract)
        contract = (
            <div>
                <h2>Contrat chez Liberium</h2>

                <p>Liberium révolutionne les contrats. Lorsque vous acceptez un contrat
                    avec l'un de nos travailleurs, vous payez la totalité du contrat a Liberium et Liberium
                    s'occupe de payer le travailleur. Mais le travailleur est payé au prorata du contrat une fois par jour.
                </p>

                <p>Donc, le travailleur reçoit un montant chaque jour, mais jamais la totalité.</p>

                <p>Si le travailleur ne vous satisfait pas durant le contrat, vous pouvez annuler
                    le contrat a tout moment. Puisque Liberium n'a pas payé la totalité au travailleur, nous vous
                    remboursons le montant restant et ce, sans poser de question.</p>

            </div>
        )

    return (
      <Container fluid className="main-content-container px-4" style={{ backgroundColor: 'white' }}>        

        <p><img src={this.state.model.pubUrl} class="img-fluid"/></p>
        <h1>{this.state.model.name}</h1>
        
        <p>{this.state.model.description}</p>

        <h2>Recevez des soumissions</h2>

        <p>Lorsque vous demander ce service, vous recevrez dans les minutes ou les heures
            qui viennent des soumissions de personne qui désire faire l'ouvrage.
        </p>

        {contract}

        <center>
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => this.signup()}
            >
                La magie est a l'intérieur<br/>
                Abonne-toi
            </Button>
        </center>

        <br/><br/>

        <center>Grenier & associé - Copyright 2023</center>
    </Container>
    );
  }
}

export default LandingPage;