import React from 'react';
import box_hint from '../../img/chasse/hint.png';

export default class HintBox extends React.Component {

    render() {
        return (
            <div>
                <div
                    style={{  
                        backgroundImage: "url(" + box_hint + ")",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        width: "320px",
                        height: "150px",                        
                        "max-width": "320px",
                        "max-height": "150px",
                        color: "white"            
                    }}
                    >
                    <div
                    style={{
                        "margin-top": "20px",
                        "margin-left": "150px"
                    }}
                    >
                        <br/><br/>
                    {this.props.text}
                    </div>
                </div>
            </div>
        )
    }
}