//https://www.bootdey.com/snippets/view/chat-app#html

import React from 'react';
import Button from '@material-ui/core/Button';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import EditIcon from '@material-ui/icons/Edit';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Message } from '../../components/chat/message';
import MessageIcon from '@material-ui/icons/Message';

import {api} from '../../services/autonome';
import axios from 'axios';
import { API_ROOT } from '../../api-config';

import './discussion.css';

export class DiscussionView extends React.Component {

    constructor(props) {
        super(props)
        //console.log(props)
        var withUserId;
        if (typeof props.match !== "undefined" && typeof props.match.params !== "undefined" && props.match.params.withUserId)
            withUserId = props.match.params.withUserId;
        if (props.withUserId)
            withUserId = props.withUserId;
        var myUserId = this.getUserId();
        if (typeof props.myUserId !== "undefined" && props.myUserId === 0)
            myUserId = props.myUserId;
        var getController = "chat/get_discussion";
        var postController = "chat/post_message";
        if (myUserId===0) {
            getController = "chat/get_support";
            postController = "chat/post_support";
        }
        this.state = {
            getController: getController,
            postController: postController,
            postImgController: "chat/post_img",
            withUserId: withUserId,
            userId: myUserId,
            messages: [],
            other: {
                id: 0,
                firstname: "",
                lastname: ""
            },
            message: "",
            timer: null,
            showCam: false
        }
        var gd;
        if (this.state.userId < this.state.withUserId)
            gd = this.state.userId + "_" + this.state.withUserId
        else
            gd = this.state.withUserId + "_" + this.state.userId;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNewPhoto = this.handleNewPhoto.bind(this);
        this.inputFile = React.createRef();
    }

    addPhoto() {
        var these = this;
        this.setState({showCam: true});
        setTimeout( function () {
            these.inputFile.current.click();
        },150);
    }

    closeCam() {
        this.setState({showCam: false});
    }

    getUserId() {
        var existingTokens;
        try {
            existingTokens = JSON.parse(localStorage.getItem('tokens'));
            return existingTokens.UserId;
        } catch(e) {
            return null;
        }
    }

    handleChange(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value,
          is_error: false
        });
    }
    
    handleSubmit(event) {
        var these = this;
        var msg = this.state.message;
        this.setState({message: ""});
        var data = {
            withUserId: this.state.withUserId,
            message: msg
        }
        //alert(this.state.postController);
        //return alert(JSON.stringify(data));
        api.call("post", this.state.postController, data).then(result => {
            var new_messages = these.state.messages;
            these.fetchMessages();
        }).catch(function(err) {
            //alert(JSON.stringify(err));
            these.setState({});
        });
        window.gtag('event', 'send_message_discussion', {});
    }

    handleNewPhoto() {
        // Ok we send the photo
        console.log("this:",this);
        var self = this;
        console.log("inputFile:",this.inputFile);
        console.log("length:", this.inputFile.current.files.length);
        for (var i = 0; i < this.inputFile.current.files.length; i++) {
          if (!this.inputFile.current.files[i].name) return;
            const data = new FormData();
            data.append('file', this.inputFile.current.files[i]);
            data.append("fromUserId", this.getUserId());
            data.append("withUserId", this.state.withUserId);
            
            this.setState({
                uploadMsg: "Upload en cours"
            });
            axios.post(API_ROOT + this.state.postImgController, data).then((response) => {
                this.setState({
                    uploadMsg: "Upload terminé",
                    showCam: false
                })
                self.fetchMessages();
            })
        }
    }

    fetchMessages(callback) {
        var these = this;
        //alert(this.state.getController);
        api.call("post", this.state.getController, {withUserId: this.state.withUserId}).then(result => {
            //console.log("Got messages:", result.data);
            //alert(JSON.stringify(result.data.other))
            these.setState({ other: result.data.other, messages: result.data.messages})
            if (typeof callback === "function")
                callback();
        });
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter') {
            this.handleSubmit(null);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    componentDidMount() {
        api.call('post', "user/usrLog", {module: "views", title:"discussion"});
        var these = this;
        this.fetchMessages(function() {
            setTimeout( function (){
                these.messagesEnd.scrollIntoView({ behavior: "smooth" });
            },250);
        });
        window.gtag('event', 'page_view', {
            page_title: 'Discussion',
            page_location: '/discussion',
            page_path: '/discussion'
        });
        this.timer = setInterval    (() => {
            these.fetchMessages();
        }, 3000);
    }

    goBack() {
        if (window.history.length<=1) {
            this.props.history.push({
                pathname: "/home"
            });
        } else {
            window.history.go(-1);
        }
    }

    renderCam() {
        if (!this.state.showCam) return (<></>);
        return (
            <input 
                type="file"
                name="image"
                ref={this.inputFile}
                accept="image/*"
                capture="environment"
                onChange={this.handleNewPhoto}
            />
        )
    }

    render(){
        let list = <div classname="no-content-message">Il n'y a pas de messages.</div>;
        //console.log("=============");
        list = this.state.messages.map(m => <Message key="{m.id}" id="{m.id}" other={this.state.other} receivedAt={m.receivedAt} fromUserId={m.fromUserId} senderName={m.senderName} text={m.message} img={m.image}></Message>);
        //console.log("=============");

        var avatar = (
            <img src={"//api.liberium.net/avatar/" + this.state.withUserId} alt="avatar" class="image"/>
        )
        return(
            <>
                <div class="appHeader">
                    <div class="left">
                        <a onClick={() => this.goBack()} class="headerButton goBack">
                            <ion-icon name="chevron-back-outline" role="img" class="md hydrated" aria-label="chevron back outline"></ion-icon>
                        </a>
                    </div>
                    <div class="pageTitle">
                    Discussion avec {this.state.other.firstname}
                    </div>
                    <div class="right">
                    </div>
                </div>
            <div class="card chat-app">
                <div class="chat">
                    <div class="chat-header clearfix">
                        <div class="row">
                            <div class="col-lg-6">
                                <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info">
                                    {avatar}
                                </a>
                                <div class="chat-about">
                                    <h6 class="m-b-0">{this.state.other.firstname} {this.state.other.lastname}</h6>
                                    <small>En ligne il y a {this.state.other.lastCheckin}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-history">
                        <ul class="m-b-0">
                            {list}
                        </ul>
                        <div style={{ float:"left", clear: "both" }}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                    </div>
                    <div class="chat-message"
                        style={{
                            position: "fixed",
                            bottom: 0,
                            overflow: "hidden",
                            "white-space": "nowrap"
                        }}
                    >
                        <div class="input-group mb-0">
                            <input
                                type="text"
                                name="message"
                                class="form-control"
                                placeholder="Votre message"
                                value={this.state.message}
                                onChange={e => this.handleChange(e)}
                                onKeyPress={this.handleKeyPress} 
                            />
                            <Button
                                disabled={this.state.sending}
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<MessageIcon />}
                                onClick={() => this.handleSubmit()}
                            >
                                
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<CameraAltIcon />}
                                onClick={() => this.addPhoto()}
                            >
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {this.renderCam()}
            </>
        )
    }
}