import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import DirectionsIcon from '@material-ui/icons/Directions';
import Button from '@material-ui/core/Button';
import OffLineImg from '../../../img/offline.png';
import Switch from '@material-ui/core/Switch';

class AdminVirtualJob extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            accounts: [],
            account: null
        }
        this.state.userId = this.getUserId();
        this.handleOnOff = this.handleOnOff.bind(this);
    }

    fetchData() {
        var these = this;
        api.call('get', "virt_job/getAccounts?showAll=true", {}).then(result => {
            these.setState({accounts: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();
    }

    handleOnOff(account) {
        account.enabled = !account.enabled;
        var self = this;
        api.call('post', "virt_job/set_acccount_enable", {accountId: account.id, enabled: account.enabled}).then(result => {
            self.fetchData();
        });
    }

    selectServer(server) {
        //alert(JSON.stringify(server));
        this.props.history.push({
            pathname: "/admin/server/" + server.id
        });
    }

    goPosts() {
        this.props.history.push({
            pathname: "/admin/posts"
        });
    }

    goTravailleur() {
        this.props.history.push({
            pathname: "/admin/travailleurs"
        });
    }

    goScreen(idScreen) {
        this.props.history.push({
            pathname: "/admin/marketing/" + idScreen
        });
    }

    renderAccounts() {
        return this.state.accounts.map((row) => {
            return (
                <tr>
                <td>{row.id}</td>
                <td>{row.prenom} {row.nom}</td>
                <td>{row.facebook_groups}</td>
                <td>{row.credits} / {row.max_credits}</td>
                <td>{row.facebook_state}</td>
                <td>
                    <Switch
                        checked={row.enabled}
                        onChange={(e)=>this.handleOnOff(row)}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </td>
                <td>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<DirectionsIcon />}
                        onClick={() => this.goScreen(row.id)}
                        >
                        Go
                    </Button>
                </td>
                </tr>
            );
          });
    }

    render(){
        return(
            <>
            <h1>Accounts</h1>
            <p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goPosts()}
            >
              Posts
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goTravailleur()}
            >
              Travailleurs
            </Button>
            </p>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Nom du compte</th>
                    <th>Nombre de groupe</th>
                    <th>Crédits</th>
                    <th>Etat</th>
                    <th>Actif</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderAccounts()}
                </tbody>
            </Table>
            </>
        )
    }
}

export default withRouter(AdminVirtualJob);