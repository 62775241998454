import React from "react";
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';

export class NewsCard extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            loaded: false,
            articleId: props.news.articleId,
            imgUrl: props.news.image,
            title: props.news.title,
            description: props.news.description,
            url: props.news.url
        }
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount(){
    }

    onClick = () => {
        this.props.history.push({
            pathname: this.state.path
        });
    }

    render () {
        var url = "https://api.liberium.net/news/click?articleId=" + this.state.articleId + "&url=" + this.state.url;
        //var url = "http://localhost:9090/news/click?articleId=" + this.state.articleId + "&url=" + this.state.url;
        return (
            <div class="col-md-4">
                <div class="card">
                    <a href={url} target="_blank">
                        <img src={this.state.imgUrl} alt="Card image cap" width="100%" height="122" class="card-img-top" />
                    </a>
                    <div class="card-body">
                        <h5 class="card-title"><a href={url} target="_blank">{this.state.title}</a></h5>
                        <p class="card-text">{this.state.description}</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(NewsCard);
