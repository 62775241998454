import React from 'react'
import {
    Container,
    Row,
    Col,
    FormGroup,
    FormInput,
  } from "shards-react";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import MoneyIcon from '@material-ui/icons/AttachMoney';
import DeleteIcon from '@material-ui/icons/Delete';
import { Form, Modal } from "react-bootstrap";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import AddIcon from '@material-ui/icons/Add';
import PeopleIcon from '@material-ui/icons/People';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import {api} from '../../services/autonome';

const useStyles = makeStyles((theme) => ({
    root: {
      height: 380,
      transform: 'translateZ(0px)',
      flexGrow: 1,
    },
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }));

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);
  

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  

export class BusinessTaskView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            taskOpen: false,
            businessId: props.match.params.id,
            business : {name:""},
            tasks: [],
            task: {
                title: "",
                description: "",
                groups: []
            }
        }
    }
    
    handleScan(data){
        alert(data);
    }
    
    goTache = (item) => {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/task/" + item.id
        });
    }

    goClients() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/clients"
        });
    }

    goTasks() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/tasks"
        });
    }

    goInvestors() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/investors"
        });
    }

    goOrders() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/orders"
        });
    }
    
    goFactures() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/factures"
        });
    }

    goInvoice() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/invoice"
        });
    }

    deleteTask(row) {
        var self = this;
        var url = "business/" + this.state.businessId + "/task/" + row.id;
        api.call('delete', url, {}).then(result => {
            self.fetchData();
        });
    }

    fetchData() {
        var url = "businesse/" + this.state.businessId + "/tasks";
        api.call('get', url, {}).then(result => {
            if (typeof result.data.error != "undefined") return;
            this.setState({
                tasks: result.data
            });
        });

        api.call('get','businesse/' + this.state.businessId, {}).then(result => {
            //console.log(result.data);
            if (typeof result.data.error != "undefined") return;
            var isAdmin = true;
            if (typeof result.data.perms !== "undefined" && result.data.perms == "items") {
                isAdmin = false;
            }
            this.setState({
                business: result.data,
                isAdmin: isAdmin
            });
        });
    }

    componentDidMount(){
        this.fetchData()
    }

    handleChange(event) {
        console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    getTaches() {
        var self = this;
        if (this.state.tasks.length == 0)
            return (
                <tr>
                    <td colspan="3">Vous n'avez aucune tâche en demande.</td>
                </tr>

            );
        return this.state.tasks.map((row) => (
                <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">{row.id} - 
                <a onClick={() => self.goItem(row)}>{row.name}</a>
                </StyledTableCell>
                <StyledTableCell align="right">{row.status}</StyledTableCell>
                <StyledTableCell align="right">
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => self.goTache(row)}
                >
                    Edit
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<DeleteIcon />}
                    onClick={() => self.deleteItem(row)}
                >
                    Delete
                </Button>

                </StyledTableCell>
                </StyledTableRow>
            ));
    }

    handleClose() {
        this.setState({taskOpen:false});
    };
    
    goNewTask () {
        this.setState({taskOpen:true});
    };

    goSettings() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/settings"
        });
    }

    goSubscription() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/subscription"
        });
    }

    render(){
        var taches = this.getTaches();

        var client_disabled =  false;
        var invoice_disabled = false;
        var transaction_disabled = false;
        if (!this.state.isAdmin) {
            client_disabled = true;
            invoice_disabled = true;
            transaction_disabled = true;
        }

        return(
            <Container fluid className="main-content-container px-4">
             
                <h1 className="m-4">{this.state.business.name}</h1>

                <Row>
                    <Col>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<PeopleIcon />}
                            onClick={() => this.goClients()} disabled={client_disabled}>Clients</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<ReceiptIcon />}
                            onClick={() => this.goInvoice()} disabled={invoice_disabled}>Facture</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            enabled={false}
                            onClick={() => this.goOrders()}>Commandes</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            enabled={false}
                            onClick={() => this.goSubscription()}>Abonnements</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.goFactures()}>Factures</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AccountBalanceIcon />}
                            onClick={() => this.handleNewTransaction()} disabled={transaction_disabled}>Transaction</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AccountBalanceIcon />}
                            onClick={() => this.goInvestors()}>Investisseurs</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AccountBalanceIcon />}
                            onClick={() => this.goSettings()}>Settings</Button>
                    </Col>
                </Row>

                <Button variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => this.goNewTask()}>Nouvelle tâche</Button>

                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>Taches</StyledTableCell>
                            <StyledTableCell align="right">Status</StyledTableCell>
                            <StyledTableCell align="right">Action</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {taches}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Modal
                size="lg"
                show={this.state.taskOpen}
                onHide={() => this.handleClose()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Nouvelle tâche
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <TextField
                            label="Titre"
                            name="title"
                            value={this.state.task.title}
                            fullWidth
                            onChange={(e) => this.handleChange(e)}
                        />
                    <TextField
                        label="Description"
                        name="description"
                        value={this.state.task.description}
                        fullWidth
                        multiline
                        maxRows={12}
                        onChange={(e) => this.handleChange(e)}
                    />
            
                    </Form>
                </Modal.Body>
            </Modal>

            </Container>
        )
    }
}