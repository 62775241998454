import React, { useState } from 'react';
import { HashRouter as Router, Route } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";
import { AuthContext } from "./context/auth";
import { UserProvider } from "./context/user";
import PrivateRoute from './PrivateRoute';

//import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import "./css/finapp.css";
import { Store, Constants } from './components/flux';

import ReactGA from 'react-ga';
ReactGA.initialize('G-H9941XCX6K');

function App(props) {
  let existingTokens;
  try {
    existingTokens = JSON.parse(localStorage.getItem("tokens"));
  } catch (e) {
    existingTokens = "";
  }
  const [authTokens, setAuthTokens] = useState(existingTokens);
  //const [user, setThisUser] = useState({name:"Oh no"});

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  if (typeof params.refId!=="undefined") {
    localStorage.setItem("refId", params.refId);
  }

  const setTokens = (data) => {
    localStorage.setItem("tokens", JSON.stringify(data));
    setAuthTokens(data);
    Store.triggerAction({actionType: Constants.USER_TOKEN, payload: data});
  }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  } 

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
        <UserProvider>
          <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <div>
            {routes.map((route, index) => {
              if (route.PrivateRoute){

                return (
                  <PrivateRoute
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={withTracker(props => {
                      return (
                        <route.layout {...props}>
                          <route.component {...props} />
                        </route.layout>
                      );
                    })}
                  />
                )
              }
              else 
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={withTracker(props => {
                      return (
                        <route.layout {...props}>
                          <route.component {...props} />
                        </route.layout>
                      );
                    })}
                  />
                );
            })}
          </div>
          </Router>
        </UserProvider>
    </AuthContext.Provider>
  );
}

export default App;