import React from 'react'
import {
    Container,
    Row,
    Col,
    FormGroup,
    FormInput,
  } from "shards-react";

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import MoneyIcon from '@material-ui/icons/AttachMoney';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import DeleteIcon from '@material-ui/icons/Delete';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import AddIcon from '@material-ui/icons/Add';
import PeopleIcon from '@material-ui/icons/People';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import {api} from '../../services/autonome';

const useStyles = makeStyles((theme) => ({
    root: {
      height: 380,
      transform: 'translateZ(0px)',
      flexGrow: 1,
    },
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }));

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);
  

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  

export class BusinessSubscriptionView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            speedOpen: false,
            scanOpened: false,
            businessId: props.match.params.id,
            business : {name:""},
            subscriptions: [],
            withCode: null,
            barcode: "",
            filters: {
                withoutPhotos: false
            }
        }

        this.handleScan = this.handleScan.bind(this)
    }
    
    handleScan(data){
        alert(data);
    }
    
    goItem = (item) => {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/item/" + item.id
        });
    }

    goClients() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/clients"
        });
    }

    goInvestors() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/investors"
        });
    }

    goOrders() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/orders"
        });
    }
    
    goFactures() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/factures"
        });
    }

    goInvoice() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/invoice"
        });
    }

    openScan() {
        this.setState({scanOpened: true})
    }

    deleteItem(row) {
        var self = this;
        var url = "store/item/" + row.id;
        api.call('delete', url, {}).then(result => {
            self.fetchData();
        });
    }

    fetchData() {
        var url = "organisation/" + this.state.businessId + "/subscriptions";
        api.call('get', url, {}).then(result => {
            if (typeof result.data.error != "undefined") return;
            this.setState({
                subscriptions: result.data
            });
        });

        api.call('get','businesse/' + this.state.businessId, {}).then(result => {
            //console.log(result.data);
            if (typeof result.data.error != "undefined") return;
            var isAdmin = true;
            if (typeof result.data.perms !== "undefined" && result.data.perms == "items") {
                isAdmin = false;
                //alert('not admin')
            }
            this.setState({
                business: result.data,
                isAdmin: isAdmin
            });
        });
    }

    componentDidMount(){
        this.fetchData()
    }

    createItem() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/item/new"
        });
    }

    handleFilter(event) {
        var self = this;
        console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        var new_form = self.state.filters;
        new_form[name] = value;
        this.setState({filters:new_form});

        this.fetchData();
    }

    handleChange(event) {
        console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    getSubscriptions() {
        var self = this;
        if (this.state.subscriptions.length == 0)
            return (
                <tr>
                    <td colspan="3">Vous n'avez aucun abonnement.</td>
                </tr>

            );
        return this.state.subscriptions.map((row) => (
                <StyledTableRow key={row.name}>
                <StyledTableCell component="th" scope="row">{row.id} - 
                <a onClick={() => self.goItem(row)}>{row.name}</a>
                </StyledTableCell>
                <StyledTableCell align="right">{row.quantity}</StyledTableCell>
                <StyledTableCell align="right">
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => self.goItem(row)}
                >
                    Edit
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<DeleteIcon />}
                    onClick={() => self.deleteItem(row)}
                >
                    Delete
                </Button>

                </StyledTableCell>
                </StyledTableRow>
            ));
    }

    handleClose() {
        this.setState({speedOpen:false});
    };
    
    handleOpen () {
        this.setState({speedOpen:true});
    };

    handleNewTransaction() {
        this.handleClose();
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/transaction"
        });
    }

    goSettings() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/settings"
        });
    }

    goSubscription() {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/subscription"
        });
    }

    setScan(text){
        this.setState({scanCode: text});
    }

    createItemWithCode() {
        api.call('get', "stor/barcode/" + this.state.barcode + "/create/" + this.state.businessId, {}).then(result => {
            if (typeof result.data.error != "undefined") return;
            //alert(JSON.stringify(result.data));
            this.props.history.push({
                pathname: "/business/" + this.state.businessId + "/item/" + result.data.id
            });
        });

    }

    searchCode() {
        api.call('get', "stor/item/" + this.state.businessId + "/barcode/" + this.state.barcode, {}).then(result => {
            if (typeof result.data.error != "undefined") return;
            this.setState({
                items: result.data
            });
        });
        api.call('get', "stor/barcode/" + this.state.barcode, {}).then(result => {
            if (typeof result.data.error != "undefined") return;
            this.setState({
                withCode: result.data
            });
        });
    }

    renderScanner() {
        if (!this.state.scanOpened) return;
        var codeItem;
        if (this.state.withCode)
            codeItem = (
                <div>
                    <p>Titre: {this.state.withCode.title}</p>
                    <p>Categorie: {this.state.withCode.parent_category}</p>
                    <p>Manifacturier: {this.state.withCode.manufacturer}</p>
                    <Button onClick={() => this.createItemWithCode()}>Ajouter un article avec cette description</Button>
                </div>
            );
        return (
            <div>
                <FormGroup>
                    <label>Code barre :</label>
                    <FormInput name="barcode" value={this.state.barcode} onChange={e => this.handleChange(e)} size="lg" placeholder="Code barre" />
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() => this.searchCode()}
                    >
                    Search
                    </Button>
                </FormGroup>

                {codeItem}
            </div>
        )
    }

    render(){
        var abonnements = this.getSubscriptions();

        var client_disabled =  false;
        var invoice_disabled = false;
        var transaction_disabled = false;
        if (!this.state.isAdmin) {
            client_disabled = true;
            invoice_disabled = true;
            transaction_disabled = true;
        }

        return(
            <Container fluid className="main-content-container px-4">
             
                <h1 className="m-4">{this.state.business.name}</h1>

                <Row>
                    <Col>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AddIcon />}
                            onClick={() => this.createItem()}>Ajouter un article</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<ViewWeekIcon />}
                            onClick={() => this.openScan()} disabled={false}>Scan</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<PeopleIcon />}
                            onClick={() => this.goClients()} disabled={client_disabled}>Clients</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<ReceiptIcon />}
                            onClick={() => this.goInvoice()} disabled={invoice_disabled}>Facture</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            enabled={false}
                            onClick={() => this.goOrders()}>Commandes</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            enabled={false}
                            onClick={() => this.goSubscription()}>Abonnements</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.goFactures()}>Factures</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AccountBalanceIcon />}
                            onClick={() => this.handleNewTransaction()} disabled={transaction_disabled}>Transaction</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AccountBalanceIcon />}
                            onClick={() => this.goInvestors()}>Investisseurs</Button>
                        <Button variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<AccountBalanceIcon />}
                            onClick={() => this.goSettings()}>Settings</Button>
                    </Col>
                </Row>

                <br/>

                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                        <TableHead>
                        <TableRow>
                            <StyledTableCell>Client</StyledTableCell>
                            <StyledTableCell align="right">Montant</StyledTableCell>
                            <StyledTableCell align="right">Action</StyledTableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {abonnements}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        )
    }
}