import React from 'react'
import {
    Container,
    Form,
    FormGroup,
    FormCheckbox,
    FormInput,
    FormTextarea,
    FormSelect
} from "shards-react";

import DragAndDrop from '../../../components/filedragdrop';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import FacebookIcon from '@material-ui/icons/Facebook';

import {api} from '../../../services/autonome';
import axios from 'axios';

import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import { API_ROOT } from '../../../api-config';

const states = [
    {id: "", caption: ""},
    {id: "new", caption: "Neuf"},
    {id: "used", caption: "Usagé"},
];

const useStyles = makeStyles((theme) => ({
    root: {
      height: 380,
      transform: 'translateZ(0px)',
      flexGrow: 1,
    },
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
      },
  }));

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const colors = [
    "", "blanc", "noir", "pourpre", "rouge", 
    "orange", "jaune", "vert", "bleu", 
    "violet", "ivoire", "crème", "beige",
    "rose", "kaki", "brun", "marron", "bordeaux", "gris", "saumon", "turquoise", "multi-colore"
];

const grosseurs = [
    "", "XS", "S", "M", "L", "XL", "2XL", "3XL"
];

export class AdminItemView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            tabNdx: 0,
            uploadMsg: "",
            businessId: props.match.params.orgId,
            itemId: props.match.params.id,
            form: {
                orgId: props.match.params.orgId,
                enabled: false,
                name: "",
                cost: 0,
                price: 0,
                quantity: 0,
                etat: "new",
                categoryId: 1,
                availableAt: new Date().toISOString().split(".")[0]
            },
            photos: [],
            categories: []
        }
        this.styles = useStyles;
    }

    goItem = (item) => {
        this.setState({itemId: item.id});
        this.props.history.push({
            pathname: "/business/" + this.state.businessId + "/item/" + item.id
        });
    }

    copyDescription = () => {
        var new_form = this.state.form;
        new_form.auction_description = new_form.description;
        this.setState({
            form: new_form
        });
    }

    goBack = () => {
        this.props.history.push({
            pathname: "/business/" + this.state.businessId
        });
    }

    testAiVoice(texte) {
        api.call("post", "speech/get_from_text", {texte: texte}).then(result => {
            var mp3 = result.data.soundFxUrl;
            //alert(mp3);
            new Audio(mp3).play()
        });
    }

    sendToMarketplace = () => {
        api.call('get', "business/" + this.state.businessId + "/item/" + this.state.itemId + "/send_to_marketplace", {}).then(result => {
            alert("Ca va être fait");
        });
    }

    sendOneToWarehouse = () => {
        //return alert(JSON.stringify(item));
        api.call('get', "business/" + this.state.businessId + "/item/" + this.state.itemId + "/send_one_to_warehouse", {}).then(result => {
            this.fetchData();
        });
    }

    fetchImages() {
        api.call('get', "store/item/" + this.state.itemId + "/thumbs?no_cache=true", {}).then(result => {
            if (typeof result.data.error != "undefined") return;
            this.setState({
                photos: result.data
            });
        });
    }

    fetchData() {
        api.call('get', "encan/categories", {}).then(result => {
            this.setState({
                categories: result.data
            });
        });
        if (this.state.itemId != "new") {
            api.call('get', "store/item/" + this.state.itemId, {}).then(result => {
                if (typeof result.data.error != "undefined") return;
                //result.data.categoryId = result.data.categoryId.toString();
                //alert(typeof result.data.categoryId + " " + result.data.categoryId);                
                result.data.availableAt = new Date(result.data.availableAt).toISOString().split(".")[0]
                //alert(result.data.availableAt);
                this.setState({
                    form: result.data
                });
            });
            this.fetchImages();
        }
    }

    renderUpload() {
        if (this.state.itemId == "new") return;
        return(
          <div>
            <div>
              <input type="file" onChange={this.handleUploadFile} />
            </div>         
            {this.state.uploadMsg}
          </div>
        )
    }

    componentDidMount() {
        this.fetchData()
    }

    saveItem() {
        var self = this;
        //return alert(this.state.form.availableAt);
        if (this.state.itemId == "new") {
            api.call('post', "store/item", this.state.form).then(result => {
                if (typeof result.data.error != "undefined") return;
                //alert(JSON.stringify(result));
                self.goItem(result.data);
            });
        } else {
            //alert(JSON.stringify(this.state.form));
            api.call('patch', "store/item/" + this.state.itemId, this.state.form).then(result => {
                if (typeof result.data.error != "undefined") return;
                //alert(JSON.stringify(result));
                self.goBack();
            });
        }
    }

    changeImage(row) {
        row.enabled = !row.enabled;        
        api.call('post', "store/item/" + this.state.itemId + "/image/" + row.id, {enabled: row.enabled}).then(result => {
            if (typeof result.data.error != "undefined") return;
            this.fetchImages();
        });
    }

    getPhotos() {
        var self = this;
        if (this.state.itemId == "new")
            return (
                <tr>
                    <td colspan="3">Enregistrer l'item pour ajouter des photos</td>
                </tr>
            );

        if (this.state.photos.length == 0)
            return (
                <tr>
                    <td colspan="3">Aucune photo à afficher</td>
                </tr>
            );
        return this.state.photos.map((row) => (
                <StyledTableRow key={row.id}>
                <StyledTableCell component="th" scope="row">
                <img src={row.image} width={250}/>
                </StyledTableCell>
                <StyledTableCell align="right">
                <Switch
                    checked={row.enabled}
                    onChange={e => this.changeImage(row)}
                    name="checkedB"
                    color="primary"
                />
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => self.deletePhoto(row)}
                >
                    Delete
                </Button>

                </StyledTableCell>
                </StyledTableRow>
            ));
    }

    handleChangeCB(e, fruit) {
        const newState = this.state.form;
        newState[fruit] = !this.state.form[fruit];
        this.setState({ ...this.state, form: {...newState }});
    }
    
    handleTab = (event, newValue) => {
        this.setState({tabNdx: newValue});
    };

    handleChange(event) {
        console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        var new_form = this.state.form;
        new_form[name] = value;
        this.setState({
            form: new_form
        });
    }

    deletePhoto = (photo) => {
        var self = this;
        axios.delete(API_ROOT + "store/item/" + this.state.itemId +"/image/" + photo.id, {}).then((response) => {
            this.setState({
              uploadMsg: "Photo supprimé"
            })
            self.fetchImages();
          })
    
    }

    handleUploadFile = (event) => {
        var self = this;

        const data = new FormData()
        data.append('file', event.target.files[0]);
        data.append("itemId",this.state.itemId);
        this.setState({
          uploadMsg: "Upload en cours"
        });
        axios.post(API_ROOT + "store/item/" + this.state.itemId +"/image", data).then((response) => {
          this.setState({
            uploadMsg: "Upload terminé"
          })
          self.fetchImages();
        })
    }

    handleDrop = (files) => {
        var self = this;
        let fileList = this.state.files
        for (var i = 0; i < files.length; i++) {
          if (!files[i].name) return;
            //fileList.push(files[i].name)
            const data = new FormData();
            data.append('file', files[i]);
            data.append("itemId",this.state.itemId);
            this.setState({
            uploadMsg: "Upload en cours"
            });
            axios.post(API_ROOT + "store/item/" + this.state.itemId +"/image", data).then((response) => {
                this.setState({
                    uploadMsg: "Upload terminé"
                })
                self.fetchImages();
            })
        }
        this.setState({files: fileList})
    }

    handleTakePhoto = (dataUri) => {
        var self = this
        const data = new FormData();
        data.append("itemId",this.state.itemId);
        data.append('data', dataUri);
        this.setState({
          uploadMsg: "Upload en cours"
        });
        axios.post(API_ROOT + 'store/item/' + this.state.itemId + '/image_uri', data).then((response) => {
          this.setState({
            uploadMsg: "Upload terminé"
          });
          self.fetchImages();
        });
    }

    getCategories() {
        return this.state.categories.map((row) => (
            <option value={row.id}>{row.name}</option>
        ));
    }

    getEtats() {
        return states.map((row) => (
            <option value={row.id}>{row.caption}</option>
        ));
    }

    getColors() {
        return colors.map((row) => (
            <option value={row}>{row}</option>
        ));
    }

    getGrosseur() {
        return grosseurs.map((row) => (
            <option value={row}>{row}</option>
        ));
    }

    capture() {
        const imageSrc = this.webcamRef.current.getScreenshot();
    }
    
    render(){
        var photos = this.getPhotos();
        return(
            <Container fluid className="main-content-container px-4">

                <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                    <Button onClick={e => this.saveItem()}>Sauvegarder</Button>
                    <Button onClick={e => this.goBack()}>Retour</Button>
                </ButtonGroup>

                <h1 className="m-4">Item {this.state.form.name}</h1>

                <AppBar position="static">
                    <Tabs value={this.state.tabNdx} onChange={this.handleTab} aria-label="simple tabs example">
                        <Tab label="Article" {...a11yProps(0)} />
                        <Tab label="Variance" {...a11yProps(1)} />
                        <Tab label="Encan" {...a11yProps(2)} />
                        <Tab label="Photo" {...a11yProps(3)} />
                    </Tabs>
                </AppBar>

                <TabPanel value={this.state.tabNdx} index={0}>
                <Form>
                    <FormGroup>
                    <FormCheckbox
                        checked={this.state.form.enabled}
                        onChange={e => this.handleChangeCB(e, "enabled")}
                        >
                        Activé
                    </FormCheckbox>
                    </FormGroup>

                    <FormGroup>
                    <label>Nom de l'article :</label>
                    <FormInput name="name" value={this.state.form.name} onChange={e => this.handleChange(e)} size="lg" placeholder="Nom de l'article" />
                    </FormGroup>

                    <FormGroup>
                    <TextField
                        name="availableAt"
                        label="Disponible a partir de"
                        type="datetime-local"
                        defaultValue={this.state.form.availableAt}
                        value={this.state.form.availableAt}
                        className={this.styles.textField}
                        onChange={e => this.handleChange(e)}
                        InputLabelProps={{
                        shrink: true,
                        }}
                    />
                    </FormGroup>

                    <FormGroup>
                    <label>Code barre :</label>
                    <FormInput name="barcode" value={this.state.form.barcode} onChange={e => this.handleChange(e)} size="lg" placeholder="Code barre" />
                    </FormGroup>

                    <FormGroup>
                    <label>État :</label>
                    <FormSelect name="etat" labelId="label" id="select" value={this.state.form.etat} onChange={e => this.handleChange(e)}>
                        {this.getEtats()}
                    </FormSelect>
                    </FormGroup>

                    <FormGroup>
                    <label>Catégorie :</label>
                    <FormSelect name="categoryId" labelId="label" id="select" value={this.state.form.categoryId} onChange={e => this.handleChange(e)}>
                        {this.getCategories()}
                    </FormSelect>
                    </FormGroup>

                    <FormGroup>
                    <label>Quantité :</label>
                    <FormInput name="quantity" value={this.state.form.quantity} onChange={e => this.handleChange(e)} size="lg" placeholder="Quantité" />
                    </FormGroup>

                    <FormGroup>
                    <label>Coût :</label>
                    <FormInput name="cost" value={this.state.form.cost} onChange={e => this.handleChange(e)} size="lg" placeholder="Coût de l'article" />
                    </FormGroup>

                    <FormGroup>
                    <label>Prix :</label>
                    <FormInput name="price" value={this.state.form.price} onChange={e => this.handleChange(e)} size="lg" placeholder="Prix de l'article" />
                    </FormGroup>

                    <FormGroup>
                    <label>Entrepot Tag :</label>
                    <FormInput name="entrepot" value={this.state.form.entrepot} onChange={e => this.handleChange(e)} size="lg" placeholder="Tag pour retrouver l'article dans votre entrepot" />
                    </FormGroup>

                    <FormGroup>
                    <label>Description :</label>
                    <FormTextarea name="description"value={this.state.form.description} onChange={e => this.handleChange(e)} />
                    </FormGroup>

                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                        <Button onClick={e => this.saveItem()}>Sauvegarder</Button>
                    </ButtonGroup>

                </Form>
                </TabPanel>
                <TabPanel value={this.state.tabNdx} index={1}>
                    <FormGroup>
                        <label>Couleur :</label>
                        <FormSelect name="color" labelId="label" id="select" value={this.state.form.color} onChange={e => this.handleChange(e)}>
                            {this.getColors()}
                        </FormSelect>
                    </FormGroup>
                    <FormGroup>
                    <label>Grosseur :</label>
                    <FormSelect name="grosseur" labelId="label" id="select" value={this.state.form.grosseur} onChange={e => this.handleChange(e)}>
                        {this.getGrosseur()}
                    </FormSelect>
                    </FormGroup>
                </TabPanel>
                <TabPanel value={this.state.tabNdx} index={2}>
                <Form>
                    <FormGroup>
                    <label>Quantité dans l'encan:</label>
                    <FormInput name="auction_quantity" value={this.state.form.auction_quantity} onChange={e => this.handleChange(e)} size="lg" placeholder="Quantité dans l'encan" />
                    </FormGroup>

                    <FormGroup>
                    <label>Nombre de personne minimal :</label>
                    <FormInput name="auction_min_people" value={this.state.form.auction_min_people} onChange={e => this.handleChange(e)} size="lg" placeholder="Nombre de personne dans l'encan pour sortir l'item" />
                    </FormGroup>

                    <FormGroup>
                    <label>Description dans l'encan:</label>
                    <FormTextarea name="auction_description"value={this.state.form.auction_description} onChange={e => this.handleChange(e)} />
                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.copyDescription()}
                        >
                            Copier description
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<PlayCircleFilledIcon />}
                            onClick={() => this.testAiVoice(this.state.form.auction_description)}
                        >
                            Play
                        </Button>
                    </ButtonGroup>
                    </FormGroup>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<LocalShippingIcon />}
                        onClick={() => this.sendOneToWarehouse()}
                    >
                        Envoyer un article à l'entrepot de LencanEnLigne
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<FacebookIcon />}
                        onClick={() => this.sendToMarketplace()}
                    >
                        Envoyer à FB marketplace
                    </Button>
                </Form>
                </TabPanel>
                <TabPanel value={this.state.tabNdx} index={3}>
                    <DragAndDrop handleDrop={this.handleDrop}>
                        <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                                <TableHead>
                                <TableRow>
                                    <StyledTableCell>Photo</StyledTableCell>
                                    <StyledTableCell align="right">Action</StyledTableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {photos}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DragAndDrop>
                    <Typography variant="h3" component="h4">
                    Téléverser une photo
                    </Typography>

                    {this.renderUpload()}

                    <Typography variant="h3" component="h4">
                    Photo prise en direct
                    </Typography>

                    <Camera
                        isImageMirror={false}
                        idealFacingMode = {FACING_MODES.ENVIRONMENT}
                        onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
                        />
                </TabPanel>
            <div className="w-100">
            </div>
            <br/><br/>
            </Container>
        )
    }
}