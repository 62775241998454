import {api} from '../../../services/autonome';
import React from "react";
import { Container } from "shards-react";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Chart } from "react-google-charts";

export class NetworkStats extends React.Component {
  state = {
    stats: []
  }

  constructor(props) {
    super(props);
  }

  fetchData() {
    var these = this;
    api.call('get', "ads/network/stats", {}).then(result => {      
      these.setState({stats: result.data});
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    var data = [
            [
              "Day",
              "Impressions",
              "Clicks"
            ]
      ];

    this.state.stats.forEach(function(one){
      data.push([one.day, one.impressions, one.clicks]);
      //data.push(one.day, one.nbrImpressions);
    });

    var options = {
      chart: {
        title: "Statistique du réseau"
      },
    };

    return (
      <Container fluid className="main-content-container px-4">

        <Breadcrumb>
            <Breadcrumb.Item href="#/admin">Admin</Breadcrumb.Item>
            <Breadcrumb.Item active>Network stats</Breadcrumb.Item>
         </Breadcrumb>

        <Chart
            chartType="Line"
            width="100%"
            height="400px"
            data={data}
            options={options}
          />
        </Container>
    );
  }
}