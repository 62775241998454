import React from "react";
import { Form } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from "../../services/autonome";
import { API_ROOT } from '../../api-config';
import {Screen} from "../../components/marketing/screen";

const width = 450;
const height = 780;

export class AddGroup extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.intWall = null;
    this.state = {
      userId: this.getUserId(),
      jobId: this.props.jobId,
      job: this.props.job,
      currentImage: "1.png",
      name: "",
      screenId: this.props.job.virtAccountId,
      onFinish: this.props.onFinish,
      onClicked: false
    }
    //console.log("JOB ID:", this.state.jobId);
    var metas = JSON.parse(this.props.job.metas);
    this.state.metas = metas;    
    this.onDone = this.onDone.bind(this);
  }

  getJob() {
    if (this.state.onClicked) return;
    var these = this;
    api.call('get', "ai/facebook_screen/current?id=" + this.state.screenId, {}).then(result => {
        //alert(JSON.stringify(result.data));
        these.setState({currentImage: result.data.current, count: result.data.count});
        this.intervalTimer = setTimeout(function() {
          these.getJob();
        },1000);
    });
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  componentDidMount() {
    this.getJob();
  }

  componentWillUnmount() {
  }

  handleChange(event) {
    //console.log("Handle change", event.target.name);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
    this.setState({errorMsg: null});
  }

  onNoPage() {
    this.setState({onClicked: true});
    console.log("Ok send it to api");
    var these = this;
    var data = {
        taskId: this.state.jobId,
        noPage: true
    }
    console.log("data to send:", data);
    api.call('post', "virt_job/fbGroupAdded", data).then(result => {
        if (typeof result.data.error !== "undefined") {
            return alert("Une erreure s'est produite");
        }
        these.state.onFinish();
    });
  }

  onDone(records) {
    this.setState({onClicked: true});
    console.log("Ok send it to api");
    var these = this;
    var data = {
        taskId: this.state.jobId,
        records: JSON.stringify(records)
    }
    console.log("data to send:", data);
    api.call('post', "virt_job/fbGroupAdded", data).then(result => {
        if (typeof result.data.error !== "undefined") {
            return alert("Une erreure s'est produite");
        }
        these.state.onFinish();
    });
  }

  onProblem() {
    this.setState({onClicked: true});
    console.log("Ok send problem to api");
    var these = this;
    var data = {
        taskId: this.state.jobId
    }
    api.call('post', "virt_job/problem", data).then(result => {
        if (typeof result.data.error !== "undefined") {
            return alert("Une erreure s'est produite");
        }
        this.state.onFinish();
    });
  }

  render() {
    return (
        <>
            <h1>Ajout de groupe</h1>
            <p>Ajouter ce groupe a ce compte. Rejoignez-le groupe</p>
            <p>Nom du group: {this.state.metas.name}</p>
            <Screen
                screenId={this.state.job.virtAccountId}
                onParentContinue={this.onDone}
                onProblem={this.onProblem}
                texte={this.state.metas.texte}
                showCat={false}
            />
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<NewReleasesSharpIcon />}
                onClick={() => this.onNoPage()}
                disabled = {this.state.onClicked}
            >
                Ce groupe n'accepte pas les pages
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<NewReleasesSharpIcon />}
                onClick={() => this.onProblem()}
                disabled = {this.state.onClicked}
            >
                Il y a un probleme
            </Button>
        </>
    );
  }
}


/*
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<NewReleasesSharpIcon />}
                onClick={() => this.onDone()}
                disabled = {this.state.onClicked}
            >
                C'est fait
            </Button>
*/