import React from "react";
import {api} from '../../services/autonome';
import { Container } from "shards-react";
import Ad from '../../components/ads/AdItem';
import ProfileNotUpdated from '../../components/users/profile_not_updated';
import ShopItem from '../../components/shop/item';
import CardGroup from 'react-bootstrap/CardGroup';

class ShoppingView extends React.Component {
  state = {
    items: [],
    address: "(en chargement)"
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  fetchData() {
    var isValid = true;
    var count = 0;
    api.call('get', "users/profile", {}).then(result => {
      if (typeof result.data.error != "undefined") return;
      this.setState({
          address: result.data.street_adress + "," + result.data.city
      });
    });
    api.call('get', "shopping/products", {}).then(result => {
      count++;
        if (typeof result.data.error !== "undefined") return;
        this.setState({
            items: result.data
        });
    });
  }

  componentDidMount() {
    this.fetchData();
    window.gtag('event', 'page_view', {
          page_title: 'Shopping',
          page_location: '/shop',
          page_path: '/shop',
          //send_to: '<GA_MEASUREMENT_ID>'
    })
  }

  componentWillUnmount() {
  }

  splitArrayIntoChunksOfLen(arr, len) {
    var results = [];
    for (let i = 0; i < arr.length; i += len) {
      results.push(arr.slice(i, i + len));
    }
    return results;
  }

  renderItems() {
    if (this.state.items.length === 0) return (<></>);
    console.log("items:",this.state.items);
    /*
    var showItems = this.state.items.map((item)=>{
      return (
        <ShopItem key={item.id} item={item} address={this.state.address}/>
    )
    });

    return (
      <div class="container">
        <div class="card-deck">
          {showItems}
        </div>
      </div>
    )
    */

    var splited = this.splitArrayIntoChunksOfLen(this.state.items,3);
    console.log("splited:",splited);
    var group = 0;
    return splited.map((row) => {
      group++;
      //console.log(row);
      var j = 0;
      var rowItems = row.map((row2) => {
        j++;
        return (
            <ShopItem key={row2.id} item={row2} address={this.state.address}/>
        )
      });
      if (j==1)
        return (
          <div>          
            {rowItems}
          </div>
        )

      return (
        <CardGroup key={group}>
            {rowItems}
        </CardGroup>
      )
    });
  }

  render() {
    var items = this.renderItems();
    return (
      <Container fluid className="main-content-container px-4">
        <div id='div-gpt-ad-1689183746458-0' style={{width:"300px", height:"250px"}}>
          <script>
            window.googletag.cmd.push(function() {
              window.googletag.display('div-gpt-ad-1689183746458-0')
            });
          </script>
        </div>
        <h1>Boutique</h1>
        <p>Nous aimons l'achat local. Ces articles sont dans un entrepot prêt de chez vous.</p>
        <p><strong>Livraison estimé:</strong> Une heure</p>
        
          {items}
      </Container>
    );
  }
}

export default ShoppingView;