import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import BuildIcon from '@material-ui/icons/Build';
import DirectionsIcon from '@material-ui/icons/Directions';

class MinioBucketView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            bucketId: props.match.params.bucketId,
            files: []
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.callModule("cephfs_s3", "listObjects", {bucketName: this.state.bucketId}, function (err,result) {
            console.log("from LB function:",result);
            these.setState({files: result});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();
    }

    renderBuckets() {
        return this.state.files.map((row) => {
            return (
                <tr>
                <td>{row.name}</td>
                <td>
                {row.size}
                </td>
                </tr>
            );
          });
    }

    goMinio() {
        this.props.history.push({
          pathname: "/admin/minio"
        });
    }

    goServers() {
        this.props.history.push({
          pathname: "/admin/servers"
        });
    }

    edit(row) {
        this.props.history.push({
          pathname: "/admin/minio/" + row.name
        });
    }

    render(){
        return(
            <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goMinio()}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goServers()}
            >
              Servers
            </Button>
            <h1>Buckets</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Bucket name</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderBuckets()}
                </tbody>
            </Table>
            </>
        )
    }
}

export default withRouter(MinioBucketView);