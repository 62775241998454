import React from "react";
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { Input } from "../../components/AuthForm";
import { API_ROOT } from '../../api-config';
import { useAuth } from "../../context/auth";
import { Store, Constants } from '../../components/flux';

export class ForgotView extends React.Component {

    constructor(props) {
        super(props)
        //console.log(props)
        this.state = {
            email: "",
            code: "",
            password: "",
            error: null,
            errorMsg: "",
            sendingEmail: false,
            sendingPassword: false,
            step: 1
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSendEmail = this.handleSendEmail.bind(this);
    }

    handleChange(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
          [name]: value,
          error: false
        });
    }

    handleSendEmail(event) {
        this.setState({sendingEmail: true});
        var these = this;
        axios.post(API_ROOT + "user/forgot-password", {
            email: this.state.email
        }).then(result => {
            if (result.status === 200) {
              console.log(result.data);
              if (result.data.error)
                these.setState({sendingEmail: false, error: true});
              else
                these.setState({step: 2, error: null})
            } else if (result.state === 404) {
                these.setState({sendingEmail: false, error:true});
            } else {
              //alert(result);
              //alert(JSON.stringify(result));
              these.setState({sendingEmail: false, error:true});
            }
        }).catch(e => {
            these.setState({sendingEmail: false, error:true});
        });
    }

    handleChangePassword(event) {
        this.setState({sendingPassword: true});
        var these = this;
        axios.post(API_ROOT + "user/change-password", {
            email: this.state.email,
            code: this.state.code,
            password: this.state.password
        }).then(result => {
            if (result.data.error) return these.setState({sendingPassword: false, error:true, errorMsg: result.data.error.message});
            console.log(result.data);
            // We get a new token here?
            alert("Votre mot de passe a été changé. Connecte toi")
            setTimeout( function () {
                window.location.href = '/login';
            },500);
        }).catch(e => {
            these.setState({sendingPassword: false, error:true, errorMsg: "An error occured"});
        });
    }

    componentDidMount() {
        window.gtag('event', 'page_view', {
            page_title: 'Forgot password',
            page_location: '/user/forgot-password',
            page_path: '/user/forgot-password'
        });
    }

    render(){
        var step;
        //alert(this.state.error)
        if (this.state.step === 1)
            step = (
                <form  onSubmit={e => { e.preventDefault(); this.handleSendEmail(); }}>
                <Input
                  type="email"
                  name="email"
                  value={this.state.email}
                  onChange={(e) => this.handleChange(e)}
                  className="w-100"
                  placeholder="Adresse courriel"
                />
      
                {this.state.error && <div className="alert alert-danger">Le courriel est incorrect</div>}
                <button
                  className="btn btn-lg btn-primary w-100 rounded-0"
                  disabled={this.state.sendingEmail}
                >
                      Envoie moi un code
                </button>
                <Link className="mt-2 d-block text-white text-light text-right" to="/signup">Vous n'avez pas de compte?</Link>
              </form>
            )
        if (this.state.step === 2)
            step = (
                <form  onSubmit={e => { e.preventDefault(); this.handleChangePassword(); }}>

                <Input
                  type="text"
                  name="code"
                  value={this.state.code}
                  onChange={(e) => this.handleChange(e)}
                  className="w-100"
                  placeholder="Le code dans votre courriel"
                />

                <Input
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={(e) => this.handleChange(e)}
                    className="w-100"
                    placeholder="Nouveau mot de passe"
                />
      
                {this.state.error && <div className="alert alert-danger">{this.state.errorMsg}</div>}
                <button
                  className="btn btn-lg btn-primary w-100 rounded-0"
                  disabled={this.state.sendingPassword}
                >
                      Change mot de passe
                </button>
                <Link className="mt-2 d-block text-white text-light text-right" to="/signup">Vous n'avez pas de compte?</Link>
                </form>
            )
        return (
            <div className="col-12 row black-gradiant-bg m-0 p-0 h-100vh">
              <div className="col-xl-4 col-lg-6 col-md-8 col-12 mx-auto d-flex flex-column justify-content-center">
                <h1 className="text-white text-center mb-5" style={{ fontSize: "85px", opacity: 0, animation: "fadeIn 1.5s forwards ease-in-out", fontFamily: "Karla, sans-serif" }}>
                  Liberium
                </h1>
                <h2>Perte du mot de passe</h2>
                {step}
              </div>
            </div>
          );
    }
}

export default ForgotView;