import React from 'react'
import {
    Container,
    Row
} from "shards-react";
import {ListInvestors} from '../../../components/finance/list_investors';

import {api} from '../../../services/autonome';

export class Conseil extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            orgId: api.orgId,
            business: [],
            overview: []
        }
    }

    getUserId() {
        var existingTokens;
        try{
            existingTokens = JSON.parse(localStorage.getItem('tokens'));
            return existingTokens.UserId;
        } catch(e) {
            return null;
        }
    }

    componentDidMount(){
        //console.log("call fetch");
        this.fetchOverview();

        window.gtag('event', 'page_view', {
            page_title: 'Conseil d\'administration',
            page_location: '/ca',
            page_path: '/ca'
        });
    }

    fetchOverview() {
        /*
        api.call('get', "fond/2/tokens/overview", {}).then(result => {
            console.log("Got tokens:", result);
            this.setState({
                overview: result.data,
            });
        });
        */
    }

    render() {
        return(
            <Container fluid className="main-content-container px-4">
            <div className="w-100">
                
                <h1 className="m-4">Conseil d'administration</h1>
                <Row>
                    Vous trouverez ici toutes les informations, ainsi que des modules pour
                    gérer Liberium, tel que sondage et les prises de décisions.
                </Row>
                <Row>
                    J'aimerais aussi toute décortiquer en petite tâche ce qui doit être fait sur Liberium,
                    comme si c'était un plan d'affaire, et pouvoir déléguer très facilement chacune de ces tâches.
                </Row>

            </div>

            <ListInvestors orgId={this.state.orgId}>

            </ListInvestors>

            <h1 className="m-4">Poste a combler</h1>
                
            <div className="w-100 d-flex flex-wrap">
                <Row>
                    Tout commence au Québec. Nous nommerons un directeur par région administrative.
                </Row>
            </div>
            <div className="w-100 d-flex flex-wrap">
                <ul>
                    <li>Bas-St-Laurent</li>
                    <li>Saguenay-Lac-Saint-Jean</li>
                    <li>Capitale-Nationale (Québec)</li>
                    <li>Mauricie'</li>
                    <li>Estrie</li>
                    <li>Montréal</li>
                    <li>Outaouais</li>
                    <li>Abitibi-Témiscamingue</li>
                    <li>Côte-Nord</li>
                    <li>Gaspésie-Iles-de-la-Madeleine</li>
                    <li>Chaudière-Appalaches</li>
                    <li>Laval</li>
                    <li>Lanaudière</li>
                    <li>Laurentides</li>
                    <li>Montérégie</li>
                    <li>Centre-du-Québec</li>
                </ul>
            </div>
            <div className="w-100 d-flex flex-wrap">
                Les applications seront disponible éventuellement. Pour augmenter vos chances d'êtres choisis,
                vous devrez connaître très bien la plateforme, l'utiliser et la faire croître.
            </div>
            <div className="w-100 d-flex flex-wrap">
                Vous gagnerez une commission sur toutes les ventes sur le territoire.
            </div>

            </Container>
        )
    }
}