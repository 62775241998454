import React from "react";
import { Container, Row } from "shards-react";
import Button from '@material-ui/core/Button';
import ReactGA from 'react-ga';
import LibCard from '../../components/LibCard';
import logoCarre from '../../img/logo-carre.jpg';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import {api} from '../../services/autonome';

class PrivacyView extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state= {}
  }

  componentDidMount() {
    api.call('post', "user/usrLog", {module: "views", title:"privacy"});
    //ReactGA.pageview('/home');
    window.gtag('event', 'page_view', {
      page_title: 'Privacy',
      page_location: '/privacy',
      page_path: '/privacy',
      //send_to: '<GA_MEASUREMENT_ID>'
    })
  }

  componentWillUnmount() {
  }

  render() {
    var sty = {
        float: "none", 
        margin: "0 auto"
    }

    return (
      <Container fluid className="main-content-container px-4">
        <div class="row home-intro text-center">
          <div class="col-xs-12 col-md-8" style={sty}>
          <h1><img src={logoCarre}/></h1>

            <Row>
            <div class=" header">
	<p ><br/><br/><span >POLITIQUE DE CONFIDENTIALIT&Eacute;</span><br/><br/><br/><span >liberium.net</span><br/>Grenier &amp; Associe<br/><br/>Type de site&nbsp;: e-commerce
	</p></div><div>
	<p><span>Le but de cette politique de confidentialit&eacute; </span><br/><br/>Le but de cette politique de confidentialit&eacute; est d'informer les utilisateurs de notre site des donn&eacute;es personnelles que nous recueillerons ainsi que les informations suivantes, le cas &eacute;ch&eacute;ant :
	</p>
	<ol start="1" >
		<li  value="1"><span>Les donn&eacute;es personnelles que nous recueillerons</span><span ><br/></span>
		</li>
		<li  value="2"><span>L&rsquo;utilisation des donn&eacute;es recueillies</span><span ><br/></span>
		</li>
		<li  value="3"><span>Qui a acc&egrave;s aux donn&eacute;es recueillies</span><span ><br/></span>
		</li>
		<li  value="4"><span>Les droits des utilisateurs du site</span><span ><br/></span>
		</li>
		<li  value="5"><span>La politique de cookies du site</span><span ><br/></span>
		</li>
	</ol>
	<p >Cette politique de confidentialit&eacute; fonctionne parall&egrave;lement aux conditions g&eacute;n&eacute;rales d&rsquo;utilisation de notre site.
	</p></div><div>
	<p ><span >Lois applicables</span>
	</p>
	<p >Cette politique est conforme aux lois &eacute;nonc&eacute;es dans la <span >loi sur la protection des renseignements personnels et les documents &eacute;lectroniques</span> (LPRPDE).<br/><br/>Pour les r&eacute;sidents des pays de l&rsquo;UE, le <span >R&egrave;glement g&eacute;n&eacute;ral sur la protection des donn&eacute;es</span> (RGDP) r&eacute;git toutes les politiques de protection des donn&eacute;es, quel que soit l&rsquo;endroit o&ugrave; se trouve le site. La pr&eacute;sente politique de confidentialit&eacute; vise &agrave; se conformer au RGDP. S&rsquo;il y a des incoh&eacute;rences entre la pr&eacute;sente politique et le RGDP, le RGDP s&rsquo;appliquera.
	</p>
	<p >Pour les r&eacute;sidents de l&rsquo;&Eacute;tat de Californie, cette politique de confidentialit&eacute; vise &agrave; se conformer &agrave; la <span >California Consumer Privacy Act (CCPA)</span>. S&rsquo;il y a des incoh&eacute;rences entre ce document et la <span >CCPA</span>, la l&eacute;gislation de l&rsquo;&Eacute;tat s&rsquo;appliquera. Si nous constatons des incoh&eacute;rences, nous modifierons notre politique pour nous conformer &agrave; la loi pertinente.
	</p></div><div>
	<p ><span >Consentement</span><br/><br/>Les utilisateurs conviennent qu&rsquo;en utilisant notre site, ils consentent &agrave; :
	</p>
	<ol start="1" >
		<li  value="1"><span>les conditions &eacute;nonc&eacute;es dans la pr&eacute;sente politique de confidentialit&eacute; et</span><span ><br/></span>
		</li>
		<li  value="2"><span>la collecte, l&rsquo;utilisation et la conservation des donn&eacute;es &eacute;num&eacute;r&eacute;es dans la pr&eacute;sente politique.</span><span ><br/></span>
		</li>
	</ol></div><div>
	<p ><span >Donn&eacute;es personnelles que nous collectons</span>
	</p><div><div>
	<p ><span >Donn&eacute;es collect&eacute;es automatiquement</span>
	</p>
	<p >Lorsque vous visitez et utilisez notre site, nous pouvons automatiquement recueillir et conserver les renseignements suivants :
	</p>
	<ol start="1" >
		<li  value="1"><span>Adresse IP</span><span ><br/></span>
		</li>
		<li  value="2"><span>Lieu</span><span ><br/></span>
		</li>
		<li  value="3"><span>Liens un utilisateur clique tout en utilisant le site</span><span ><br/></span>
		</li>
	</ol></div><div>
	<p ><span >Donn&eacute;es recueillies de fa&ccedil;on non automatique</span>
	</p>
	<p >Nous pouvons &eacute;galement collecter les donn&eacute;es suivantes lorsque vous effectuez certaines fonctions sur notre site&nbsp;:
	</p>
	<ol start="1" >
		<li  value="1"><span>Pr&eacute;nom et nom</span><span ><br/></span>
		</li>
		<li  value="2"><span>Email</span><span ><br/></span>
		</li>
		<li  value="3"><span>Num&eacute;ro de t&eacute;l&eacute;phone</span><span ><br/></span>
		</li>
		<li  value="4"><span>Domicile</span><span ><br/></span>
		</li>
		<li  value="5"><span>Information de paiement</span><span ><br/></span>
		</li>
	</ol>
	<p >Ces donn&eacute;es peuvent &ecirc;tre recueillies au moyen des m&eacute;thodes suivantes&nbsp;:
	</p>
	<p >Enregistrement d'un compte
	</p></div>
	<p >Veuillez noter que nous ne collectons que les donn&eacute;es qui nous aident &agrave; atteindre l&rsquo;objectif &eacute;nonc&eacute; dans cette politique de confidentialit&eacute;. Nous ne recueillerons pas de donn&eacute;es suppl&eacute;mentaires sans vous en informer au pr&eacute;alable.
	</p></div></div><div>
	<p ><span >Comment nous utilisons les donn&eacute;es personnelles</span><br/><br/>Les donn&eacute;es personnelles recueillies sur notre site seront utilis&eacute;es uniquement aux fins pr&eacute;cis&eacute;es dans la pr&eacute;sente politique ou indiqu&eacute;es sur les pages pertinentes de notre site. Nous n&rsquo;utiliserons pas vos donn&eacute;es au-del&agrave; de ce que nous divulguerons.
	</p><div>
	<p >Les donn&eacute;es que nous recueillons automatiquement sont utilis&eacute;es aux fins suivantes&nbsp;:
	</p>
	<ol start="1" >
		<li  value="1"><span>Pour les statistiques ainsi que donner une meilleure navigation</span><span ><br/></span>
		</li>
	</ol></div><div>
	<p >Les donn&eacute;es que nous recueillons lorsque l&rsquo;utilisateur ex&eacute;cute certaines fonctions peuvent &ecirc;tre utilis&eacute;es aux fins suivantes&nbsp;:
	</p>
	<ol start="1" >
		<li  value="1"><span>Communication</span><span ><br/></span>
		</li>
	</ol></div></div><div>
	<p ><span >Avec qui nous partageons les donn&eacute;es personnelles</span>
	</p><div>
	<p ><span >Employ&eacute;s</span><br/>Nous pouvons divulguer &agrave; tout membre de notre organisation les donn&eacute;es utilisateur dont il a raisonnablement besoin pour r&eacute;aliser les objectifs &eacute;nonc&eacute;s dans la pr&eacute;sente politique.
	</p></div><div>
	<p ><span >Tierces parties</span><br/>Nous pouvons partager les donn&eacute;es utilisateur avec les tiers suivants&nbsp;:
	</p>
	<ol start="1" >
		<li  value="1"><span>Google Analytics</span><span ><br/></span>
		</li>
	</ol>
	<p >Nous pouvons partager les donn&eacute;es utilisateur avec des tiers aux fins suivantes&nbsp;:
	</p>
	<ol start="1" >
		<li  value="1"><span>Lors des visites</span><span ><br/></span>
		</li>
	</ol>
	<p >Les tiers ne seront pas en mesure d&rsquo;acc&eacute;der aux donn&eacute;es des utilisateurs au-del&agrave; de ce qui est raisonnablement n&eacute;cessaire pour atteindre l&rsquo;objectif donn&eacute;.
	</p></div><div>
	<p ><span >Autres divulgations</span><br/>Nous nous engageons &agrave; ne pas vendre ou partager vos donn&eacute;es avec d'autres tiers, sauf dans les cas suivants&nbsp;:
	</p>
	<ol start="1" >
		<li  value="1"><span>si la loi l'exige</span><span ><br/></span>
		</li>
		<li  value="2"><span>si elle est requise pour toute proc&eacute;dure judiciaire</span><span ><br/></span>
		</li>
		<li  value="3"><span>pour prouver ou prot&eacute;ger nos droits l&eacute;gaux</span><span ><br/></span>
		</li>
		<li  value="4"><span>&agrave; des acheteurs ou des acheteurs potentiels de cette soci&eacute;t&eacute; dans le cas o&ugrave; nous cherchons &agrave; la vendre la soci&eacute;t&eacute;</span><span ><br/></span>
		</li>
	</ol>
	<p >Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas responsables et n&rsquo;avons pas de contr&ocirc;le sur leurs politiques et pratiques de confidentialit&eacute;.
	</p></div></div><div>
	<p ><span >Combien de temps nous stockons les donn&eacute;es personnelles</span><br/><br/>Nous ne conservons pas les donn&eacute;es des utilisateurs au-del&agrave; de ce qui est n&eacute;cessaire pour atteindre les fins pour lesquelles elles sont recueillies.
	</p></div><div>
	<p ><span >Comment nous prot&eacute;geons vos donn&eacute;es personnelles</span><br/><br/>Afin d&rsquo;assurer la protection de votre s&eacute;curit&eacute;, nous utilisons le protocole de s&eacute;curit&eacute; de la couche transport pour transmettre des renseignements personnels dans notre syst&egrave;me.<br/><br/>Toutes les donn&eacute;es stock&eacute;es dans notre syst&egrave;me sont bien s&eacute;curis&eacute;es et ne sont accessibles qu&rsquo;&agrave; nos employ&eacute;s. Nos employ&eacute;s sont li&eacute;s par des accords de confidentialit&eacute; stricts et une violation de cet accord entra&icirc;nerait le licenciement de l'employ&eacute;.<br/><br/>Alors que nous prenons toutes les pr&eacute;cautions raisonnables pour nous assurer que nos donn&eacute;es d&rsquo;utilisateur sont s&eacute;curis&eacute;es et que les utilisateurs sont prot&eacute;g&eacute;s, il reste toujours du risque de pr&eacute;judice. L&rsquo;Internet en sa totalit&eacute; peut &ecirc;tre, parfois, peu s&ucirc;r et donc nous sommes incapables de garantir la s&eacute;curit&eacute; des donn&eacute;es des utilisateurs au-del&agrave; de ce qui est raisonnablement pratique.
	</p></div><div>
	<p ><span >Mineurs</span>
	</p>
	<p >Pour les r&eacute;sidents de l&rsquo;UE, le RGPD pr&eacute;cise que les personnes de moins de 15 ans sont consid&eacute;r&eacute;es comme des mineurs aux fins de la collecte de donn&eacute;es. Les mineurs doivent avoir le consentement d&rsquo;un repr&eacute;sentant l&eacute;gal pour que leurs donn&eacute;es soient recueillies, trait&eacute;es et utilis&eacute;es.
	</p>
	<p >Nous n&rsquo;avons pas l&rsquo;intention de recueillir ou d&rsquo;utiliser des donn&eacute;es aupr&egrave;s d&rsquo;utilisateurs mineurs. Si nous d&eacute;couvrons que nous avons recueilli des donn&eacute;es d&rsquo;un mineur, ces donn&eacute;es seront supprim&eacute;es imm&eacute;diatement.
	</p></div><div>
	<p ><span >Vos droits en tant qu&rsquo;utilisateur</span>
	</p>
	<p >En tant qu&rsquo;utilisateur, vous avez le droit d&rsquo;acc&eacute;der &agrave; toutes vos donn&eacute;es personnelles que nous avons collect&eacute;es. En outre, vous avez le droit de mettre &agrave; jour ou de corriger toute donn&eacute;e personnelle en notre possession &agrave; condition qu&rsquo;elle soit acceptable en vertu de la loi.<br/><br/>Vous pouvez choisir de supprimer ou de modifier votre consentement &agrave; la collecte et &agrave; l&rsquo;utilisation des donn&eacute;es en tout temps, pourvu qu&rsquo;il soit l&eacute;galement acceptable de le faire et que vous nous en ayez inform&eacute; dans un d&eacute;lai raisonnable.<br/>
	</p></div><div>
	<p ><span >Comment modifier, supprimer ou contester les donn&eacute;es recueillies</span><br/><br/>Si vous souhaitez que vos renseignements soient supprim&eacute;s ou modifi&eacute;s d&rsquo;une fa&ccedil;on ou d&rsquo;une autre, veuillez communiquer avec notre agent de protection de la vie priv&eacute;e ici :
	</p>
	<p >Grenier & Associe<br/>630 113e Rue, Shawinigan, QC G9P 2W7<br/>eric@grenierassocie.com<br/>(819) 536-5334
	</p></div><div>
	<p ><span >Politique sur les cookies</span><br/><br/>Un cookie est un petit fichier, stock&eacute; sur le disque dur d&rsquo;un utilisateur par le site Web. Son but est de recueillir des donn&eacute;es relatives aux habitudes de navigation de l&rsquo;utilisateur.
	</p>
	<p >Nous utilisons les types de cookies suivants sur notre site&nbsp;:
	</p>
	<ol start="1" >
		<li  value="1"><span >Cookies fonctionnels</span><br/>Nous les utilisons pour m&eacute;moriser toutes les s&eacute;lections que vous faites sur notre site afin qu&rsquo;elles soient sauvegard&eacute;es pour vos prochaines visites.<span ><br/></span>
		</li>
		<li  value="2"><span >Cookies analytiques</span><br/>Cela nous permet d&rsquo;am&eacute;liorer la conception et la fonctionnalit&eacute; de notre site en recueillant des donn&eacute;es sur le contenu auquel vous acc&eacute;dez et sur lequel vous vous accrochez en utilisant notre site.<span ><br/></span>
		</li>
		<li  value="3"><span >Cookies de ciblage</span><br/>Ces cookies collectent des donn&eacute;es sur la fa&ccedil;on dont vous utilisez le site et vos pr&eacute;f&eacute;rences. Cela nous permet de rendre les informations que vous voyez sur notre site plus promotionnelles et cibl&eacute;es pour vous.<span ><br/></span>
		</li>
	</ol>
	<p >Vous pouvez choisir d&rsquo;&ecirc;tre averti chaque fois qu&rsquo;un cookie est transmis. Vous pouvez &eacute;galement choisir de d&eacute;sactiver les cookies enti&egrave;rement dans votre navigateur Internet, mais cela peut diminuer la qualit&eacute; de votre exp&eacute;rience d&rsquo;utilisation.
	</p></div><div>
	<p ><span >Cookies tiers</span><br/><br/>Nous pouvons utiliser des cookies tiers sur notre site pour atteindre les objectifs suivants&nbsp;:
	</p>
	<ol start="1" >
		<li  value="1"><span>analytics</span><span ><br/></span>
		</li>
	</ol></div><div>
	<p ><span >Modifications</span>
	</p>
	<p >Cette politique de confidentialit&eacute; peut &ecirc;tre modifi&eacute;e &agrave; l&rsquo;occasion afin de maintenir la conformit&eacute; avec la loi et de tenir compte de tout changement &agrave; notre processus de collecte de donn&eacute;es. Nous recommandons &agrave; nos utilisateurs de v&eacute;rifier notre politique de temps &agrave; autre pour s&rsquo;assurer qu&rsquo;ils soient inform&eacute;s de toute mise &agrave; jour. Au besoin, nous pouvons informer les utilisateurs par courriel des changements apport&eacute;s &agrave; cette politique.
	</p></div><div>
	<p ><span >Contact</span>
	</p>
	<p >Si vous avez des questions &agrave; nous poser, n&rsquo;h&eacute;sitez pas &agrave; communiquer avec nous en utilisant ce qui suit&nbsp;:<br/>(819) 536-5334<br/>eric@grenierassocie.com<br/>630 113E RUE, Shawinigan, Qc, G9P 2W7
	</p></div>
	<p ><br/><span >Date d&rsquo;entr&eacute;e en vigueur&nbsp;: </span>le 2 ao&ucirc;t 2022
	</p>
            </Row>            
          </div>
        </div>  

    </Container>
    );
  }
}

export default PrivacyView;