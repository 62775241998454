import React from "react";
import {
    Form,
    FormGroup,
    FormInput,
} from "shards-react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from "shards-react";
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from '../../services/autonome';

export class VirtCreateService extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            jobId: this.props.jobId,
            job: this.props.job,
            onFinish: this.props.onFinish,
            servicePort: "",
            errorMsg: null,
            metas: JSON.parse(this.props.job.metas)
        }
    }

    handleChange(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    onDone() {
        var valid = this.validateForm();
        if (!valid) return;

        console.log("Ok send it to api");
        var these = this;
        var data = {
            taskId: this.state.jobId,
            screen_port: this.state.servicePort
        }
        api.call('post', "virt_job/serviceIsCreated", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            alert("Merci")
            this.state.onFinish();
        });
    }

    abandon() {
        var data = {
            taskId: this.state.jobId
        }
        api.call('post', "virt_job/abandon", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            this.state.onFinish();
        });
    }

    validateForm() {
        var errorMsg = null;

        if (this.state.servicePort.length === 0) errorMsg = "Le port doit être spécifié";

        this.setState({errorMsg: errorMsg});
        if (errorMsg) return false;
        return true;
    }

    componentDidMount(){
    }

    render () {
        return (
            <>
            <Card>
                <CardHeader>Création de port sur K8s</CardHeader>
                <CardBody>
                    <p>Vous devez exposer un deploiement.</p>

                    {this.state.errorMsg && <div className="alert alert-danger">{this.state.errorMsg}</div>}

                    <p>
                        kubectl expose deployment/{this.state.metas.deploymentName} --type="NodePort" --port 3069
                    </p>

                    <Form>
                        <FormGroup>
                        <label>Port :</label>
                        <FormInput name="servicePort" value={this.state.servicePort} onChange={e => this.handleChange(e)} size="lg" placeholder="Service port" />
                        </FormGroup>
                    </Form>

                    {this.state.errorMsg && <div className="alert alert-danger">{this.state.errorMsg}</div>}
                </CardBody>
                <CardFooter>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<NewReleasesSharpIcon />}
                            onClick={() => this.onDone()}
                        >
                            C'est fait
                        </Button>
                </CardFooter>
            </Card>
            </>         
        )
    }
}

export default VirtCreateService;
