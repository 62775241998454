import React from 'react'
import {
    Container,
    Row,
    Col,
    Button
} from "shards-react";

import EditIcon from '@material-ui/icons/Edit';
import {Alert, Card, Form, Modal } from "react-bootstrap";
import {api} from '../../services/autonome';

export class Organisation extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            showNew: false,
            business: [],
            org: {
                name: "",
                totalTokens: "1000000"
            }
        }
    }

    handleChange(event) {
        //console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state.org;
        new_form[name] = value;
        this.setState({org:new_form, errorMsg: null});
    }

    goBusiness = (business) => {
        this.props.history.push({
            pathname: "/business/" + business.id
        });
    };

    fetchData() {
        api.call('get', "businesses", {}).then(result => {
            if (typeof result.data.error != "undefined") return;
            this.setState({
                business: result.data
            });
        });
    }

    createOrg() {
        var these = this;
        if (this.alrdyClicked) return;
        this.alrdyClicked = true;
        api.call('post', "organisation/create", this.state.org).then(result => {
            if (typeof result.data.error != "undefined") return;
            if (result.data.success) {
                these.props.history.push({
                    pathname: "/business/" + result.data.id
                });
            }
        });
    }

    componentDidMount(){
        this.fetchData()
    }

    getBusiness() {
        var self = this;
        if (this.state.business.length == 0)
            return (
                <tr>
                    <td colspan="3">Vous n'avez aucune entreprise.</td>
                </tr>

            );
        return this.state.business.map(row => {
            return ( 
                <tr key={row.name}>
                    <td> <a onClick={() => self.goBusiness(row)}>{row.name}</a></td>
                    <td align='right'>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() => self.goBusiness(row)}
                    >
                        Selectionner
                    </Button>
                    </td>
                </tr>
            );
        });
    }

    openCreate() {
        this.setState({showNew: true});
    }

    render(){
        var business = this.getBusiness();
        return(
            <div>
                
                <h1 className="m-4">Vos organisations</h1>

                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.openCreate()}
                        >
                            Créer une organisation
                    </Button>


                <Row>
                    <table className="table">
                        <tr>
                            <th>Organisation</th>
                            <th><center>Action</center></th>
                        </tr>
                        {business}
                    </table>
                </Row>

                <Modal
                    size="lg"
                    show={this.state.showNew}
                    onHide={() => this.setState({showNew: false})}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Nouvelle organisation
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>                        
                        <h1>Remplissez le formulaire</h1>

                        <Form.Group className="mb-3" controlId="name" key="name">
                            <Form.Label>Nom de l'organisation</Form.Label>
                            <Form.Control 
                                type="text"
                                name="name"
                                placeholder="Nom de l'organisation"
                                value={this.state.org.name}
                                onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="name" key="name">
                            <Form.Label>Total Token</Form.Label>
                            <Form.Control 
                                type="text"
                                name="totalTokens"
                                placeholder="TotalToken"
                                value={this.state.org.totalTokens}
                                onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>

                        <center>
                            <Button
                                variant="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() => this.createOrg()}
                            >
                                Créer
                            </Button>
                        </center>
                    </Modal.Body>
                </Modal>
            </div>            
        )
    }
}