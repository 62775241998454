import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from "shards-react";
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from '../../services/autonome';
import {Screen} from "../../components/marketing/screen";

export class MarketingAttention extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            jobId: this.props.jobId,
            job: this.props.job,
            image: "",
            errorMsg: null,
            onFinish: this.props.onFinish
        }
        var metas = JSON.parse(this.props.job.metas);
        this.state.image = metas.image;
        this.onDone = this.onDone.bind(this);
        this.onProblem = this.onProblem.bind(this);
    }

    onProblem() {
        // Un probleme ici ne peut pas être refait car si on restart
        // le screen ne sera plus a la bonne place

        console.log("Ok send it to api");
        var these = this;
        var data = {
            taskId: this.state.jobId,
            redo: false
        }
        api.call('post', "virt_job/problem", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            these.state.onFinish();
        });
    }

    onDone(records) {
        console.log("Ok send it to api");
        var these = this;
        var data = {
            taskId: this.state.jobId,
            records: JSON.stringify(records)
        }
        api.call('post', "virt_job/marketingDone", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            this.state.onFinish();
        });
    }

    componentDidMount(){
    }

    render () {
        return (
            <>
            <Card>
                <CardHeader>Marketing?</CardHeader>
                <CardBody>
                <Screen
                    screenId={this.state.job.virtAccountId}
                    onParentContinue={this.onDone}
                    onProblem={this.onProblem}
                    showDone={true}
                />
                </CardBody>
            </Card>
            </>         
        )
    }
}

export default MarketingAttention;
