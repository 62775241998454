import React from "react";
import Chip from '@material-ui/core/Chip';
import Table from 'react-bootstrap/Table';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import DoneIcon from '@material-ui/icons/Done';
import { api } from "../../services/autonome";

export class VirtualJobDashBoard extends React.Component {
  state = {
    screens: []
  }  

  constructor(props) {
    super(props);
    this.state = {
      userId: this.getUserId(),
      memo: "",
      showAddAccount: false,
      job: null,
      last_24h: "",
      myAccounts: [],
      formations: [
        { id: 1, name: "L'histoire de Liberium", groupToGive: "history_liberium" },
        { id: 2, name: "Virtual Job", groupToGive: "virtual-job" },
      ],
      formDisponibles: [
        { id: 3, name: "La base de notre Marketing", groupToGive: "marketing", pricePerJobAverage: 0.02, formationNeeded: [1, 2] },
        { id: 4, name: "Vérification de FaceBook Login", groupToGive: "fb_marketing_logging", pricePerJobAverage: 0.03, formationNeeded: [1, 2, 3] },
      ]
    }
    this.goJob = this.goJob.bind(this);
  }

  clean() {
    this.setState({ job: null });
  }

  getUserId() {
    var existingTokens;
    try {
      existingTokens = JSON.parse(localStorage.getItem('tokens'));
      //alert(JSON.stringify(existingTokens));
      return existingTokens.UserId;
    } catch (e) {
      return null;
    }
  }

  componentDidMount() {
    var these = this;

    window.gtag('event', 'page_view', {
      page_title: 'Virtual Job',
      page_location: '/virtual-job',
      page_path: '/virtual-job'
    });

    api.call('get', "user/meta/virtual-job-last-24h", {}).then(result => {
      these.setState({ last_24h: result.data.value });
    });

    api.call('get', "virt_job/getMyAccounts", {}).then(result => {
      these.setState({ myAccounts: result.data });
    });
  }

  componentWillUnmount() {
  }

  handleChange(event) {
    //console.log("Handle change", event.target.name);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
    this.setState({ errorMsg: null });
  }

  btnAddAccount() {
    this.setState({showAddAccount: true});
  }

  goJob = () => {
    this.props.history.push({
      pathname: "/virtual-job/job"
    });
  }

  goFormation = (id) => {
    this.props.history.push({
      pathname: "/virtual-job/formation/" + id
    });
  }

  formationDone(id) {
    var found = false;
    this.state.formations.forEach(function (one) {
      if (id === one.id)
        found = true;
    });
    return found;
  }

  canDoFormation(formation) {
    var self = this;
    var ok = true;
    formation.formationNeeded.forEach(function (forId) {
      var isDone = self.formationDone(forId);
      if (!isDone)
        ok = false;
    });
    return ok;
  }

  renderFormations() {
    return this.state.formations.map((row) => {
      return (
        <Chip
          label={row.name}
          clickable
          color="primary"
          deleteIcon={<DoneIcon />}
        />
      )
      return (
        <tr>
          <td>{row.name}</td>
          <td></td>
        </tr>
      );
    });
  }

  renderMyAccounts() {
    return this.state.myAccounts.map((row) => {
      return (
        <tr>
          <td>{row.prenom} {row.nom}</td>
          <td>{row.platform}</td>
          <td>{row.credits}</td>
          <td></td>
        </tr>
      );
    });
  }

  renderFormationsDisponible() {
    return this.state.formDisponibles.map((row) => {
      return (
        <tr>
          <td>{row.name}</td>
          <td>{row.pricePerJobAverage}$</td>
          <td>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              startIcon={<AttachMoneySharpIcon />}
              disabled={!this.canDoFormation(row)}
              onClick={() => this.goFormation(row.id)}
            >
              Suivre formation
            </Button>
          </td>
        </tr>
      );
    });
  }

  showStats() {
    return (
      <div class="col-xl-3 col-sm-6 col-12">
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <div class="media d-flex">
                <div class="align-self-center">
                  <WorkOutlineIcon />
                </div>
                <div class="media-body text-right">
                  <h3>{this.state.last_24h}$</h3>
                  <span>Gagné durant les 24 dernières heures</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        <h1>Assistant Virtuel</h1>

        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<EditIcon />}
          onClick={() => this.goJob()}
        >
          Lancer l'assistant de job
        </Button>

        <h2>Vos statistiques</h2>

        {this.showStats()}

        <h2>Vos formations</h2>


        {this.renderFormations()}

        <h2>Formations disponibles</h2>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nom de formation</th>
              <th>Prix moyen</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.renderFormationsDisponible()}
          </tbody>
        </Table>

        <h2>Mes comptes <div class="btn-group" role="group"
          aria-label="Basic outlined example">
          <button 
            type="button"
            class="btn btn-lg btn-outline-primary"
            onClick={(e)=>this.btnAddAccount()}
          >
            <i class="fa fa-plus"></i>
          </button></div></h2>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nom complet</th>
              <th>Plateforme</th>
              <th>Crédits</th>
              <th>Actif</th>
            </tr>
          </thead>
          <tbody>
            {this.renderMyAccounts()}
          </tbody>
        </Table>
      </>
    );
  }
}