import React from "react";
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router';
import ChatIcon from '@material-ui/icons/Chat';
import { Form, Modal } from "react-bootstrap";

export class ChatBtn extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            withUserId: props.withUserId,
            showModal: false
        }
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
    }

    closeModal() {
        this.setState({showModal: false})
        window.location.href = "#/discussion/" + this.state.withUserId;
    }

    onClick() {
        this.setState({showModal: true})
        /* // Not working, I dont know why
        this.props.history.push({
            pathname: "/discussion/" + this.state.withUserId
        });
        */
    }

    renderButton() {
                return (
                    <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<ChatIcon />}
                    onClick={() => this.onClick()}
                    >
                    
                    </Button>
                )
    }

    render () {
        return (
            <>
            {this.renderButton()}
            <Modal
                size="lg"
                show={this.state.showModal}
                onHide={() => this.closeModal()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Vous devez savoir
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <ul>
                            <li>Communiquez sur Liberium autant que possible</li>
                            <li>Ne demander pas d'être payé hors Liberium</li>
                            <li>Vous serez noté a la fin du travail</li>
                            <li>Gardez un langage approprié : Vous ne devez pas utiliser de langage vulgaire, obscène ou offensant sur l'application.</li>
                        </ul>
                    </Form>
                </Modal.Body>
            </Modal>
            </>
        )
    }
}

export default withRouter(ChatBtn);