import React from 'react';
import {api} from '../../services/autonome';

import {
    Container
} from "shards-react";

import Breadcrumb from 'react-bootstrap/Breadcrumb'

export class Notifications extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            notifications: []
        }
        this.state.userId = this.getUserId();
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    fetchData() {
      api.call('get', "user/notifications", {}).then(result => {
        if (typeof result.data.error != "undefined") return;
        this.setState({
            notifications: result.data
        });
      });
    }

    componentDidMount() {
        api.call('post', "user/usrLog", {module: "views", title:"notifications"});
        this.fetchData();
        window.gtag('event', 'page_view', {
            page_title: 'Notifications',
            page_location: '/notifications',
            page_path: '/notifications',
            //send_to: '<GA_MEASUREMENT_ID>'
          })
    }

    renderNotifs() {
        return this.state.notifications.map((row) => {
            var iconName = "";
            var iconBg = "bg-primary";
            
            if (row.module === "interest-in") {
                iconName = "cash-outline";
                iconBg = "bg-success";
            }
            if (row.module === "transfer_in")
                iconName = "arrow-down-outline"; // Download
            if (row.module === "transfer_out")
                iconName = "arrow-forward-outline"; // Send
            if (row.module === "chat") {
                iconName = "chatbubble-outline";
                iconBg = "bg-warning";
            }
            if (row.module === "task") {
                iconName = "code-working-outline";
                iconBg = "bg-danger";
            }
            return (
                <li class="active">
                <a href={row.link} class="item">
                    <div class={"icon-box " + iconBg}>
                        <ion-icon name={iconName} role="img" class="md hydrated" aria-label="arrow down outline"></ion-icon>
                    </div>
                    <div class="in">
                        <div>
                            <div class="mb-05"><strong>{row.title}</strong></div>
                            <div class="text-small mb-05">{row.description}</div>
                            <div class="text-xsmall">{row.createdAt}</div>
                        </div>
                        <span class="badge badge-primary badge-empty"></span>
                    </div>
                </a>
                </li>
            );
      });
    }

    render(){
        return(
            <Container fluid className="main-content-container px-4">

                <Breadcrumb>
                    <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>                
                    <Breadcrumb.Item active>Notifications</Breadcrumb.Item>
                </Breadcrumb>


                <div class="section full">
                    <ul class="listview image-listview flush">
                        {this.renderNotifs()}
                    </ul>

                    </div>

            </Container>
        )
    }
}
