import React from 'react';
import {api} from '../../services/autonome';
import {MyTaskOverview} from '../../components/liberium/my_task_overview'

export class MyTasksOverview extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            jobs: [],
            loaded: false,
            tasks: [],
            newTaskEnabled: false
        }
        this.state.userId = this.getUserId();
        this.getMyWorks = this.getMyWorks.bind(this)
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    getMyWorks() {
      api.call('get', "tasks/myTasks", {}).then(result => {                
        this.setState({loaded:true});
        if (typeof result.data.error != "undefined") return;
        this.setState({
            tasks: result.data
        });
      });
    }

    fetchData() {
      this.getMyWorks();
    }

    getTask(task, reloadFcn, cb) {
      api.call('get', "tasks/get/" + task.id, {}).then(result => {     
        //alert(typeof self2.getNewWork);
        reloadFcn();
        if (typeof cb == "function")
          cb();
      });
    }

    componentDidMount(){
        this.fetchData();
    }

    allWorks() {
      if (!this.state.loaded)
        return (
          <>Veuillez patienter nous chargeons les données...</>
        )
      if (this.state.tasks.length === 0)
        return (
            <tr>
                <td colspan="3">Vous n'avez demandé aucun service</td>
            </tr>
        );

      return this.state.tasks.map((row) => {
        var newRow = JSON.parse(JSON.stringify(row));
        newRow.details = JSON.parse(row.details);
        newRow.reward = JSON.parse(row.reward);
        //alert(JSON.stringify(newRow));
        if (row.status !== 2)
        return (
          <MyTaskOverview
            key={newRow.id}
            history={this.props.history}
            task={newRow}>
          </MyTaskOverview>
        );
      });
    }

    allClosed() {
      return this.state.tasks.map((row) => {
        var newRow = JSON.parse(JSON.stringify(row));
        newRow.details = JSON.parse(row.details);
        newRow.reward = JSON.parse(row.reward);
        //alert(JSON.stringify(newRow));
        if (row.status === 2)
        return (
          <MyTaskOverview
            key={newRow.id}
            history={this.props.history}
            task={newRow}>
          </MyTaskOverview>
        );
      });
    }

    renderTasks() {
      if (this.state.tasks.length == 0)
        return;
      return (
        <>
        <div class="listview-title mt-2">Vos demandes actives</div>
        <ul class="listview image-listview">
            {this.allWorks()}
        </ul>
        <div class="listview-title mt-2">Vos demandes terminées</div>
        <ul class="listview image-listview">
            {this.allClosed()}
        </ul>
        </>
      );
    }

    render(){
      var tasks = this.renderTasks();
        return(
            <div>
                {tasks}
            </div>
        )
    }
}
