export default function() {
  return [
    {
      title: "Fil d'actualité",
      to: "/home",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    {
      title: "Travail",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/work",
    },
    {
      title: "Services",
      htmlBefore: '<i class="material-icons">arrow_circle_up</i>',
      to: "/services",
    },
    {
      title: "Finance",
      htmlBefore: '<i class="material-icons">attach_money</i>',
      to: "/wallet",
    },
    {
      title: "Inscription travailleur",
      htmlBefore: '<i class="material-icons">edit</i>',
      to: "/inscription",
    },
    {
      title: "Profile",
      htmlBefore: '<i class="material-icons">edit</i>',
      to: "/user-profile",
    },
    {
      title: "Deconnexion",
      htmlBefore: '<i class="material-icons">edit</i>',
      to: "/logout",
    },
  ];
}
