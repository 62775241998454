import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { Input } from "../components/AuthForm";
import { useAuth } from "../context/auth";
import { API_ROOT } from '../api-config';
import logoCarre from '../img/logo-carre.jpg';
import {GoogleButton} from 'react-google-button';

//import firebase from '../services/firebase.js'
//import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

//const auth = getAuth();

function Login() {
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setAuthTokens } = useAuth();

  window.gtag('event', 'page_view', {
    page_title: 'Login',
    page_location: '/login',
    page_path: '/login'
  });

  function postLogin() {
    axios.post(API_ROOT + "user/login", {
      email: email,
      password: password
    }).then(result => {
      if (result.status === 200) {
        console.log(result.data);
        setAuthTokens(result.data);
      } else {
        alert(result);
        alert(JSON.stringify(result));
        setIsError(true);
      }
    }).catch(e => {
      setIsError(true);
    });
  }
  /*
  const handleGoogleConnect = e => {
    console.log("try to connect");
    e.preventDefault()
    signInWithPopup()
      .then(res => {
        console.log(res.user)
      })
      .catch(err => {
        const { code, message } = err
        console.log(code, message)
      })
  }
  */


  let existingTokens;
  try {
    existingTokens = JSON.parse(localStorage.getItem('tokens'));
  } catch (e) {
    existingTokens = null;
  }

  if (existingTokens !== null) {
    return <Redirect to="/home" />;
  }

  return (
    <div id="appCapsule">
        <div class="section mt-2 text-center">
            <img src={logoCarre} class="image"/>
            <h1>Connexion</h1>
        </div>

        <div class="section mb-5 p-2">
            <form onSubmit={e => { e.preventDefault(); postLogin(); }}>
                <div class="card">
                    <div class="card-body pb-1">
                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="email1">Courriel</label>
                                <input 
                                  type="email" 
                                  value={email}
                                  class="form-control" 
                                  id="email1"
                                  placeholder="Votre courriel"
                                  onChange={e => {
                                    setEmail(e.target.value);
                                    setIsError(false);
                                  }}
                                />
                                <i class="clear-input">
                                    <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                                </i>
                            </div>
                        </div>

                        <div class="form-group basic">
                            <div class="input-wrapper">
                                <label class="label" for="password1">Mot de passe</label>
                                <input 
                                  type="password"
                                  class="form-control"
                                  id="password1"
                                  autoComplete="off"
                                  placeholder="Votre mot de passe" 
                                  value={password}
                                  onChange={e => {
                                    setPassword(e.target.value);
                                    setIsError(false);
                                  }}
                                />
                                <i class="clear-input">
                                    <ion-icon name="close-circle" role="img" class="md hydrated" aria-label="close circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </div>
                </div>

                {isError && <div className="alert alert-danger">Le nom d'usager ou mot de passe est incorrect</div>}

                <div class="form-links mt-2">
                    <div>
                      <Link className="" to="/signup">Vous n'avez pas de compte?</Link>
                    </div>
                    <div><Link className="text-muted" to="/user/forgot-password">Mot de passe oublié?</Link></div>
                </div>

                <div class="form-button-group  transparent">
                    <button type="submit" class="btn btn-primary btn-block btn-lg">Log in</button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default Login;