import React from "react";
import { withRouter } from 'react-router'; 
import {api} from '../services/autonome';

export class Cagnotte extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            montant: "$?.??"
        }
    }

    componentDidMount(){
        this.fetchData();
    }

    fetchData() {
        var these = this;
        var body = {
            cmd:"askFunction",
            module: "cagnotte",
            name: "getValue"
        }
        api.callLb('post', "apis/internal/function", body).then(result => {
            console.log("from LB function:",result);
            these.setState({montant: parseFloat(result.data.balance).toFixed(2) + " $"});
        });
    }

    render () {
        return (
            <></>
        )
        return (
            <div class="col-sm-6 col-12">
                <div class="card">
                    <img src={this.state.imgUrl} alt="Card image cap" width="100%" height="122" class="card-img-top" />
                    <div class="card-body">
                        <h5 class="card-title">Cagnotte</h5>
                        <p class="card-text">{this.state.montant}</p>
                        <p>La cagnotte est gagné chaque début de mois. Pour avoir des chances de la gagner, faite des demandes de services ou soyer engager le plus souvent possible.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Cagnotte);
