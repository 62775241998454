import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Store, Constants } from "./components/flux";

function PrivateRoute({ component: Component, ...rest }) {
  let isAuthenticated;
  var existingTokens;

  try{
    existingTokens = JSON.parse(localStorage.getItem('tokens'));
    Store.triggerAction({actionType: Constants.USER_TOKEN, payload: existingTokens});

    // console.log(existingTokens);
    if (typeof existingTokens === "undefined" || existingTokens == null)
      isAuthenticated = false
    else
      isAuthenticated = true;
  } catch(e) {
    isAuthenticated = false;
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
            <Redirect to="/signup" />
        )
      }
    />
  );
}

export default PrivateRoute;