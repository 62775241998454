import {api} from '../../services/autonome';
import React from "react";
import { FormInput, Container, Row } from "shards-react";
import Button from '@material-ui/core/Button';
import AttachMoneySharpIcon from '@material-ui/icons/AttachMoneySharp';
import { Alert } from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Wallet from '../../components/finance/wallet';

export class WalletView extends React.Component {
  state = {
    errorMsg: null,
    transactions: []
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    window.gtag('event', 'page_view', {
        page_title: 'Wallet',
        page_location: '/finance/wallet',
        page_path: '/finance/wallet',
        //send_to: '<GA_MEASUREMENT_ID>'
    });
    this.fetchData();
  }

  fetchData() {
    api.call('get', "accounting/transactions/users", {}).then(result => {
      this.setState({transactions: result.data});
    });
  }

  goDeposit() {
    this.props.history.push({
        pathname: "/finance/deposit"
    });
  }

  goRetrait() {
    this.props.history.push({
        pathname: "/finance/retrait"
    });
  }

  goSendMoney() {
    this.props.history.push({
        pathname: "/finance/send_money"
    });
  }

  componentWillUnmount() {
  }

  renderTransactions() {
    var transactions = this.state.transactions.map((transaction) => {
      var classes = "prices";
      if (transaction.amount < 0)
        classes = classes + " text-danger"
      else
        classes = classes + " text-success"
      return (
        <a class="item">
          <div class="detail">
              <div>
                  <strong>Transaction #{transaction.id}</strong>
                  <p>{transaction.Transaction.description}</p>
              </div>
          </div>
          <div class="right">
              <div class={classes}>{parseFloat(transaction.amount).toFixed(2)}$</div>
          </div>

        </a>
      )
    });
    return (
      <div class="section mt-4">
            <div class="section-heading">
                <h2 class="title">Transactions</h2>

            </div>
            <div class="transactions">
              {transactions}
            </div>
      </div>
    )
  }

  render() {
    var sty = {
        float: "none", 
        margin: "0 auto"
    }

    var errorMsg;
    if (this.state.errorMsg !== null)
        errorMsg = (
            <Alert variant="danger">
                {this.state.errorMsg}
            </Alert>
        );

    return (
      <Container fluid className="main-content-container px-4">
        <Breadcrumb>
            <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>
            <Breadcrumb.Item active>Porte feuille</Breadcrumb.Item>
        </Breadcrumb>

        <div class="wallet-card">
                <Wallet/>
                <div class="wallet-footer">
                    <div class="item">
                        <a onClick={() => this.goDeposit()} data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
                            <div class="icon-wrapper">
                                <ion-icon name="add-outline" role="img" class="md hydrated" aria-label="add outline"></ion-icon>
                            </div>
                            <strong>Dépôt</strong>
                        </a>
                    </div>

                    <div class="item">
                        <a onClick={() => this.goRetrait()}  data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
                            <div class="icon-wrapper bg-danger">
                                <ion-icon name="arrow-down-outline" role="img" class="md hydrated" aria-label="arrow down outline"></ion-icon>
                            </div>
                            <strong>Retrait</strong>
                        </a>
                    </div>
                    <div class="item">
                        <a onClick={() => this.goSendMoney()} data-bs-toggle="modal" data-bs-target="#sendActionSheet">
                            <div class="icon-wrapper">
                                <ion-icon name="arrow-forward-outline" role="img" class="md hydrated" aria-label="arrow forward outline"></ion-icon>
                            </div>
                            <strong>Send</strong>
                        </a>
                    </div>
                </div>
            </div>
        {this.renderTransactions()}
      </Container>
    );
  }
}