import React from "react";
import logoImg from "../../img/Liberium.png";

export class WelcomeView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            loaded: false
        }
    }

    postLogin() {

    }

    render() {
        return (
            <div className="col-12 row black-gradiant-bg m-0 p-0 h-100vh">
              <div className="col-xl-4 col-lg-6 col-md-8 col-12 mx-auto d-flex flex-column justify-content-center">
                <h1 className="text-white text-center mb-5" style={{ fontSize: "85px", opacity: 0, animation: "fadeIn 1.5s forwards ease-in-out", fontFamily: "Karla, sans-serif" }}>
                    Liberium
                </h1>

                <img src={logoImg} alt="logo"/>

                <h1 className="text-white text-center mb-5" >Qui êtes-vous?</h1>
              </div>
            </div>
          );
    }
}


