import React from "react";
import { Nav } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../flux";
import routes from "../../../routes";
import userGroupService from "../../../services/user-group-service";

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems(),
      user: Store.getUserToken()
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems(),
      user: Store.getUserToken()
    });
  }

  render() {
    let { navItems: items } = this.state;
    var filteredItems = [];
    var groups = this.state.user && this.state.user.groups;
    items.forEach(x=>{
      let route = routes.filter(r=> r.path === x.to)[0];
      filteredItems.push({
        ...route,
        ...x
      });
    });
    filteredItems = filteredItems.filter(item => {
      return !item.PrivateRoute || userGroupService.routeContainsGroups(item.groups, groups)
    })

    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {filteredItems.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
          ))}
        </Nav>
      </div>
    )
  }
}

export default SidebarNavItems;
