import React from "react";
import { Nav, NavbarBrand } from "shards-react";

import Notifications from "./Notifications";
import UserActions from "./UserActions";
import logoCarre from '../../../../img/logo-carre.jpg';
export default () => (
  <Nav navbar className="border-left flex-row">
    <NavbarBrand href="/home"><img src={logoCarre} height={40}/></NavbarBrand>
    <Notifications />
    <UserActions />
  </Nav>
);
