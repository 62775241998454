import React from 'react';
import {api} from '../../services/autonome';

import {
  Alert
} from "shards-react";

import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Button from '@material-ui/core/Button';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import Ad from '../../components/ads/AdItem';

import {MyTasksOverview} from '../../components/liberium/my_tasks_overview'
import ProfileNotUpdated from '../../components/users/profile_not_updated';
import { ModelOverview } from '../../components/liberium/model';

export class ServiceView extends React.Component {

    constructor(props){
        // Sert à rien mais il parait que ça fonctionne mieux le 2eme coup
        // https://stackoverflow.com/questions/3397585/navigator-geolocation-getcurrentposition-sometimes-works-sometimes-doesnt
        navigator.geolocation.getCurrentPosition(function () {}, function () {}, {});
        
        super(props)
        this.state = {
            profile: {phonenumber: "", street_adress: ""},
            models: [],
            jobs: [],
            loaded: false,
            tasks: [],
            newTaskEnabled: false,
            validate: false,
            initialised: false
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
      var isValid = true;
      var count = 0;
      api.call('get', "users/profile", {}).then(result => {
        count++;
          if (typeof result.data.error !== "undefined") return;
          this.setState({
              profile: result.data
          });
          if (result.data.phonenumber.length < 5)
            isValid = false;

          if (count === 2)
            this.setState({validate: isValid, initialised: true});
        });
        api.call('get', "finance/cad_wallet", {}).then(result => {
            count++;
            this.setState({loaded:true});

            console.log("Wallet overview:", result);
            if (typeof result.data.error !== "undefined") return;
            this.setState({
                wallets: result.data
            });
            if (this.state.userId !== 1 && parseFloat(result.data[0].quantity) < 5)
              isValid = false;

            if (count === 2)
             this.setState({validate: isValid, initialised: true});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    goMyWork = () => {
        this.props.history.push({
            pathname: "/myWork"
        });
    };

    getModels() {      
      var these = this;
      api.call('get', "tasks/models", {}).then(result => {
        var models = [];
        //alert(JSON.stringify(result.data));
        result.data.forEach(function (one) {
          try {
            var parsed = JSON.parse(one.fields);
            one.fields = parsed.fields;
            if (typeof parsed.quote !== "undefined")
              one.quote = parsed.quote
            else
              one.quote = false;
            if (typeof parsed.quote_svc !== "undefined")
              one.quote_svc = parsed.quote_svc
            else
              one.quote_svc = "";
          } catch (e)  {
            alert(e);
          }
          models.push(one);
          //alert(JSON.stringify(one));
        });
        these.setState({models: models})
      });
    }

    getTask(task, reloadFcn, cb) {
      api.call('get', "tasks/get/" + task.id, {}).then(result => {
        reloadFcn();
        if (typeof cb === "function")
          cb();
      });
    }

    componentDidMount() {
      api.call('post', "user/usrLog", {module: "views", title:"services"});
        this.fetchData();
        this.getModels();

        window.gtag('event', 'page_view', {
            page_title: 'Services',
            page_location: '/services',
            page_path: '/services',
            //send_to: '<GA_MEASUREMENT_ID>'
        });
    }

    goDeposit() {
      this.props.history.push({
          pathname: "/finance/depot"
      });
    }

    renderModels() {
      var these = this;
      return this.state.models.map((row) => {
            return (
              <ModelOverview
                key={row.id}
                model={row}
                history={these.props.history}
                userProfile={these.state.profile}
              ></ModelOverview>
            );
      });
    }

    render(){
      if (!this.state.initialised) return (<h1>Chargement de la page</h1>);
        var content;
        if (this.state.validate) {
        } else {
          if (this.state.profile.phonenumber.length < 5) {
            content = (
              <ProfileNotUpdated>
              </ProfileNotUpdated>
            )
          } else {
            // Forcement a cause de l'argent
            content = (
              <Alert theme="danger">Il n'y pas suffisament d'argent dans votre compte!

              <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  startIcon={<AcUnitIcon />}
                  onClick={() => this.goDeposit()}
              >
                  Faite un dépôt
              </Button>

              </Alert>
            )
          }
        }
        return(
            <div>
                <Breadcrumb>
                  <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>
                  <Breadcrumb.Item active>Services</Breadcrumb.Item>
                </Breadcrumb>

                <MyTasksOverview
                  history={this.props.history}
                />

                <div class="listview-title mt-2">Vous voulez que l'on vous rendre un service?</div>
                <ul class="listview image-listview">
                  {this.renderModels()}
                </ul>

                <center>
                <Ad 
                  pos={1}
                  size={"728x90"}
                  page="services"
                />
                </center>
            </div>
        )
    }
}