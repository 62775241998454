import React, { Component } from 'react';
import axios from 'axios';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { API_ROOT } from '../../api-config';

class Uploader extends Component {
  constructor(props) {
    super(props);
  }

  sendTest = () => {
    var the_data = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAoAAAAHgCAYAAAA10dzkAAAgAElEQVR";
    const data = new FormData()
    data.append('data', the_data);
    axios.post(API_ROOT + 'impot/upload_uri', data).then((response) => {
      this.setState({
        imageUrl: response.data.data.fileUrl,
        uploadMsg: "Upload terminé"
      })
    });
  }

  handleTakePhoto = (dataUri) => {
    // Do stuff with the photo...
    console.log('takePhoto', dataUri);
    const data = new FormData();
    if (typeof this.props.clientId !== "undefined")
      data.clientId = this.props.clientId;
    data.append('data', dataUri);
    this.setState({
      uploadMsg: "Upload en cours"
    });
    axios.post(API_ROOT + 'impot/upload_uri', data).then((response) => {
      this.setState({
        uploadMsg: "Upload terminé"
      })
    });
  }

  state = {
    imageUrl: "",
    uploadMsg: "Aucun upload en cours"
  }
  
  handleUploadFile = (event) => {
    const data = new FormData()
    data.append('file', event.target.files[0]);
    if (typeof this.props.clientId !== "undefined") {
      data.append("clientId",this.props.clientId);
    }
    this.setState({
      uploadMsg: "Upload en cours"
    });
    axios.post(API_ROOT + 'impot/upload_image', data).then((response) => {
      this.setState({
        uploadMsg: "Upload terminé"
      })
    })
  }

  render() {
    return(
      <div>
        <div>
          <input type="file" onChange={this.handleUploadFile} />
        </div>         
        {this.state.uploadMsg}
      </div>
    )
  }
}

export default Uploader

/*
<div>
<Camera
   onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } } />
</div>
*/
