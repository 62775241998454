import React from "react";
import { Container } from "shards-react";
import Button from '@material-ui/core/Button';
import {api} from '../services/autonome';

class WorkerPage extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state= {
        modelId: parseInt(props.match.params.id),
        model: {name:"",description:"",pubUrl: "", icon:""}
    }
    localStorage.setItem("go_to_inscription", true);
  }

  connect() {
    this.props.history.push({
      pathname: "/login"
    });
  }

  signup() {    
    this.props.history.push({
      pathname: "/signup"
    });
  }

  componentDidMount() {
    window.gtag('event', 'page_view', {
      page_title: 'Landing Worker Page',
      page_location: '/landing_worker',
      page_path: '/landing_worker'
    })
    this.fetchData();
  }

  fetchData() {
    var these = this;
    console.log(this.state);
    api.call('get', "tasks/model/" + this.state.modelId, {}).then(result => {
        these.setState({model: result.data});
        console.log("model:",result.data)
    });
  }

  render() {
    var contract;
    if (this.state.model.isContract)
        contract = (
            <div>
                <h2>Contrat chez Liberium</h2>

                <p>Liberium révolutionne les contrats. Lorsqu'on vous donne un contrat
                    provenant d'un de nos clients, vous recevrez une partie du montant
                    chaque jour. Bref, votre paie rentre tous les jours jusqu'a la fin du
                    ou des contrat(s).
                </p>

                <p>Prenez note que si vous ne satisfaisez pas au exigence du client,
                    il pourra annuler le contrat en tout moment. Assurez-vous de bien
                    communiquer avec le client dès le départ.</p>

            </div>
        )

    return (
      <Container fluid className="main-content-container px-4" style={{ backgroundColor: 'white' }}>        

        <p><img src={this.state.model.pubUrl} class="img-fluid" alt="pub"/></p>

        <h1>{this.state.model.name}</h1>
        
        <p>{this.state.model.descriptionWorker}</p>

        <h2>Envoyer des soumissions</h2>

        <p>Lorsqu'une demande sera disponible dans votre secteur, vous recevrez un SMS.
           Il vous sera donc possible de soumissionner rapidement sur la demande de service.
           Communiquez avec le client au besoin.
        </p>

        {contract}

        <center>
            <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => this.signup()}
            >
                La magie est a l'intérieur<br/>
                Abonne-toi
            </Button>
        </center>

        <br/><br/>

        <center>Grenier & associé - Copyright 2023</center>
    </Container>
    );
  }
}

export default WorkerPage;