import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ViewListIcon from '@material-ui/icons/ViewList';
import ButtonGroup from '@material-ui/core/ButtonGroup';

class FormationsView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            formations: [],
            showNew: true,
            showForm: true,
            formation: {
                id: null,
                name: "",
                formationsRequired: [],
                formation: "",
                questions: [],
                groupToGive: ""
            }
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "formations/getList", {}).then(result => {
            these.setState({formations: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();
    }

    selectFormation(formation) {
        //alert(JSON.stringify(server));
        this.props.history.push({
            pathname: "/admin/formation/" + formation.id
        });
    }

    renderForm() {

    }

    renderFormations() {
        return this.state.formations.map((row) => {
            return (
                <tr onClick={(e)=>this.selectFormation(row)}>
                <td>{row.id}</td>
                <td>{row.name}</td>
                <td></td>
                </tr>
            );
          });
    }

    render(){
        return(
            <>
            <h1>Formations</h1>
            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                <Button 
                    startIcon={<AddIcon />}
                    onClick={e => this.handleNew()}
                >
                    Ajouter nouvelle formation
                </Button>

                </ButtonGroup>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Nom de formations</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderFormations()}
                </tbody>
            </Table>
            </>
        )
    }
}

export default withRouter(FormationsView);