import React from "react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from "shards-react";
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from '../../services/autonome';
import { API_ROOT } from '../../api-config';
import {Screen} from "../../components/marketing/screen";

export class CheckFbGroup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            jobId: this.props.jobId,
            job: this.props.job,
            image: "",
            errorMsg: null,
            onFinish: this.props.onFinish,
            onClicked: false
        }
        var metas = JSON.parse(this.props.job.metas);
        this.state.metas = metas;
        this.state.image = metas.image;
        console.log("job",this.props.job);
        this.state.image = API_ROOT + "ai/facebook_screen/currentImg?id=" + this.props.job.virtAccountId;
        this.records = [];
        this.setRecords = this.setRecords.bind(this);
    }

    onDone(isOk) {        
        this.setState({onClicked: true});
        var these = this;
        var data = {
            taskId: this.state.jobId,
            fbGroupAccountId: this.state.metas.id,
            isOk: isOk,
            records: this.records
        }
        console.log("Ok send it to api:", data);
        api.call('post', "virt_job/isGroupOk", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            these.state.onFinish();
        });
    }

    onDontExist() {
        this.setState({onClicked: true});
        var these = this;
        var data = {
            taskId: this.state.jobId,
            fbGroupAccountId: this.state.metas.id,
            dontExist: true,
            records: this.records
        }
        console.log("Ok send it to api:", data);
        api.call('post', "virt_job/isGroupOk", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            these.state.onFinish();
        });
    }

    onEnAttente() {        
        this.setState({onClicked: true});
        var these = this;
        var data = {
            taskId: this.state.jobId,
            fbGroupAccountId: this.state.metas.id,
            enAttente: true,
            records: this.records
        }
        console.log("Ok send it to api:", data);
        api.call('post', "virt_job/isGroupOk", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            these.state.onFinish();
        });
    }

    onProblem() {
        this.setState({onClicked: true});

        console.log("Ok send it to api");
        var these = this;
        var data = {
            taskId: this.state.jobId,
            redo: false
        }
        api.call('post', "virt_job/problem", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            these.state.onFinish();
        });
    }

    setRecords(records) {
        this.records = records;
    }

    componentDidMount(){
        var self = this;

        function changeImage() {
            //if (API_ROOT.indexOf("//api")===0) 
            //    var url = "http://10.1.99.218:31774/ai/facebook_screen/currentImg?id=" + self.props.job.virtAccountId + "&ts=" + Math.floor(Date.now() / 1000);
            //else
                var url = API_ROOT + "ai/facebook_screen/currentImg?id=" + self.props.job.virtAccountId + "&ts=" + Math.floor(Date.now() / 1000);
            self.setState({image: url});
            setTimeout(function(){
                changeImage();
            },2000);
        }
        changeImage();
    }

    render () {
        var image = this.state.image;
        return (
            <>
            <Card>
                <CardHeader>Sommes-nous abonné au groupe?</CardHeader>
                <CardBody>
                    <p>Est-ce que ce groupe facebook semble être bien accepté?</p>
                    <p>Nom du group: <a href={"https://facebook.com/" + this.state.metas.groupId} target="_blank">{this.state.metas.name}</a></p>
                    <Screen
                        screenId={this.state.job.virtAccountId}
                        onParentContinue={this.onDone}
                        onProblem={this.onProblem}
                        setRecords={this.setRecords}
                        texte={this.state.metas.texte}
                        showCat={false}
                        showDone={false}
                    />
                </CardBody>
                <CardFooter>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<NewReleasesSharpIcon />}
                        onClick={() => this.onDone(true)}
                        disabled = {this.state.onClicked}
                    >
                        Oui
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<NewReleasesSharpIcon />}
                        onClick={() => this.onDone(false)}
                        disabled = {this.state.onClicked}
                    >
                        Non
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<NewReleasesSharpIcon />}
                        onClick={() => this.onEnAttente()}
                        disabled = {this.state.onClicked}
                    >
                        Toujours en attente
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<NewReleasesSharpIcon />}
                        onClick={() => this.onDontExist()}
                        disabled = {this.state.onClicked}
                    >
                        Ce groupe n'existe plus
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<NewReleasesSharpIcon />}
                        onClick={() => this.onProblem()}
                        disabled = {this.state.onClicked}
                    >
                        Probleme
                    </Button>
                </CardFooter>
            </Card>
            </>         
        )
    }
}

export default CheckFbGroup;