import React from "react";
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import {api} from '../../services/autonome';
import { withRouter } from 'react-router';
import { Button, Alert, Card, Form, Modal } from "react-bootstrap";
import StarRatings from 'react-star-ratings';
import CheckIcon from '@material-ui/icons/Check';
import {ShowValue} from '../../components/liberium/showValue';
import Avatar from "../../img/avatar1.jpg";
import MakeAdeposit from '../../components/finance/make_a_deposit';
import StripeCheckout from 'react-stripe-checkout';
import UserCard from "../../components/liberium/userCard";
import {confirm} from 'react-bootstrap-confirmation';

const hostname = window && window.location && window.location.hostname;
var STRIPE_PUBLISHABLE;
if (hostname === "liberium.net" || hostname === "www.liberium.net" || hostname === "app.liberium.net") {
    STRIPE_PUBLISHABLE = "pk_live_51L8OrVDmbzE3RrkO4qzUY7SBpSyivYf5cAIUYPoFAB6HNsR7SViZzwpjsTTefjsstLU16w4EeyOmvCITk4tJEeJC00p9vt8VgN";
} else {
    STRIPE_PUBLISHABLE = "pk_test_51L8OrVDmbzE3RrkOEDQ20XrQaLwOmvrigsAEDNB9P45wcWcZ6MrDda4GKQkJko4NBBiKCXu28pRiORduQbc8bHZR00iy5JdqUw";
}
export class TaskOwner extends React.Component {    
    constructor(props){
        super(props)

        this.state = {
            task: this.props.task,
            show_no_payment_engage: false,
            disabled_apply: false,
            show_abandon: false,
            workerId: 0,
            wallets: {wallets:[]},
            paymentOk: false,
            walletSelected: false,
            ratings: {},
            show_stripe: false,
            clientSecret: "",
            stripeOptions: {},
            cancelTask: false,
            email: ""
        }
        this.elements = null;
        this.handleWallet = this.handleWallet.bind(this);
        this.changeRating = this.changeRating.bind(this);
        this.payment = this.payment.bind(this);
    }

    onToken = (amountToPay, amountToWorker, description) => token => {
        //alert(amount+description);
        var data = {
            description,
            source: token.id,
            currency: "CAD",
            amount: amountToPay*100,
            amountToWorker: amountToWorker,
            workerId: this.state.workerId
        }
        api.call('post', "liberium/task/" + this.state.task.id + "/stripe/payment", data).then(result => {  
            if (result.error) alert(result.error.message);
        });
        window.gtag('event', 'purchase', {
            currency: "CAD",
            value: amountToPay,
        });
        window.fbq('track', 'Purchase', {value: amountToPay, currency: 'CAD'});
    }

    async display (message) {
        const result = await confirm(message);
        return result;
    }

    handleWallet(event) {
        const target = event.target;
        //if (target.type === "radio") alert(JSON.stringify(target))
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        //alert(name + "=" + value);
    
        this.setState({ walletSelected: value, paymentOk: true });
    }

    changeRating( newRating, name ) {
        //alert(name + " " + newRating);
        var new_rating = this.state.ratings;
        new_rating[name] = newRating;
        this.setState({
          ratings: new_rating
        });
    }

    isAllRated() {
        var list = this.state.task.model.reviews.rate_worker;
        if (Object.keys(this.state.ratings).length == Object.keys(list).length)
          return true;
        return false;
      }
    
    sendRating() {
        //alert(JSON.stringify(this.state.ratings));
        var these = this;
        api.call('post', "task/" + this.state.task.id + "/rating_worker", {ratings:this.state.ratings}).then(result => {  
            if (result.error) alert(result.error.message);
            window.location.reload();
        });
    }

    acceptContract() {
        //accept_worker
        var these = this;
        these.setState({show_engage: false});
        api.call('post', "task/accept_worker/" + this.state.task.id, {workerId: this.state.workerId}).then(result => {
            window.location.reload();
        });
    }

    payment() {
        var these = this;
        this.setState({paymentOk: false});
        if (this.state.walletSelected === "stripe") {
            these.setState({show_engage: false});
            api.call('post', "liberium/task/" + this.state.task.id + "/stripe", {workerId: this.state.workerId, amountToWorker: this.state.amountToWorker, amountToPay: this.state.amountToPay}).then(result => {
                //alert(result.data.clientSecret);
                var opt = {
                    clientSecret: result.data.clientSecret
                }
                these.setState({
                    stripeOptions: opt                    
                });
                //setTimeout( function () {
                    these.setState({show_stripe: true});
                //},1000)
            });
        } else {
            api.call('post', "tasks/payment/" + this.state.task.id, {walletId: this.state.walletSelected, workerId: this.state.workerId, amountToWorker: this.state.amountToWorker, amountToPay: this.state.amountToPay}).then(result => {
                window.gtag('event', 'purchase', {
                    currency: "CAD",
                    value: this.state.amountToPay,
                });
                window.fbq('track', 'Purchase', {value: this.state.amountToPay, currency: 'CAD'});
                window.location.reload();
            });
        }
    }

    applyTask(task) {
        return;
        this.setState({disabled_apply: true});
        api.call('get', "tasks/apply/" + task.id, {}).then(result => {
            this.state.task.applied = true;
            this.setState({disabled_apply: false});
        });
    }

    unapplyTask(task) {
        return;
        this.setState({disabled_apply: true});
        api.call('get', "tasks/unapply/" + task.id, {}).then(result => {
            this.state.task.applied = false;
            this.setState({disabled_apply: false});
        });
    }

    engage(user) {
        console.log(this.state.task);
        //alert(this.state.task.model.commission);
        if (this.state.task.model.commission === 0) {
            window.fbq('track', 'InitiateHiring');
            this.setState({disabled_engage: true, show_no_payment_engage: true, winner: user.name, workerId: user.userId});
            return;
        }
        this.dblClick = true;
        var these = this;
        var details = JSON.parse(user.details);
        if (typeof details.amount === "undefined")
            details.amount = parseFloat(this.state.task.reward.amount)
        else
            details.amount = parseFloat(details.amount);
        var amountToPay = ((parseFloat(details.amount)*0.15)+details.amount).toFixed(2);
        this.setState({disabled_engage: true, show_engage: true, winner: user.name, workerId: user.userId, amountToWorker: details.amount, amountToPay: amountToPay});
        api.getWallet(function(wallets) {
            these.setState({wallets: wallets});
        });
        window.fbq('track', 'InitiateCheckout');
    }

    componentDidMount(){
        var me = JSON.parse(localStorage.getItem('user'));
        if (me === null) return;
        this.setState({
          email: me.email
        });
        
    }

    handleStripe() {
    }

    renderButton() {
        if (this.state.task.status === 0) {
            if (this.state.task.applied)
                return (
                    <Button
                    variant="primary"
                    disabled={this.state.disabled_apply}
                    size="small"
                    startIcon={<CancelIcon />}
                    onClick={() => this.unapplyTask(this.state.task)}
                    >
                    Annuler l'application
                    </Button>
                )
            else
                return (
                        <Button
                        variant="primary"
                        disabled={this.state.disabled_apply}
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() => this.applyTask(this.state.task)}
                        >
                        J'applique
                        </Button>
                )
        }
        if (this.state.task.workerId === this.props.user)
          return (
                <Button
                variant="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => this.jobDone()}
                >
                Terminé
                </Button>
          )
    }

    abandonTask(sentiment) {
        var these = this;
        this.setState({cancelTask: true});
        api.call('get', "task/" + this.state.task.id + "/cancel?sentiment=" + sentiment, {}).then(result => {
            this.props.history.push({
                pathname: "/home"
            });
        });
        window.gtag('event', 'abandon_task', {});
    }

    async workerIsDone() {
        var response = await this.display("Etes-vous certain qu'il a terminé?");
        if (response) {
            var these = this;
            this.setState({cancelTask: true});
            api.call('post', "task/" + this.state.task.id + "/done", {}).then(result => {
                this.props.history.push({
                    pathname: "/home"
                });
            });
        }
    }

    chatWith(row) {
        window.location.href = "#/discussion/" + row.userId;
        /* // Not working, I dont know why
        this.props.history.push({
            pathname: "/discussion/" + this.state.withUserId
        });
        */
    }

    abandonWorker() {
        this.setState({show_abandon: true});
    }

    render () {
        if (this.state.task.status === -1) {
            const divStyle = {
                color: "#ff0000"
              };
              
            var annul = (
                <p style={divStyle}>
                    La quest a été annulé.
                </p>
            )
            return (
                <ul class="listview image-listview">
                    <ShowValue
                        header={annul}
                    />
                </ul>
            )
        } else if (this.state.task.status !== 0) {
            var adding;
            if (this.state.task.status === 2) {
                if (this.state.task.reviewedByCreator)
                    return (
                        <Card.Body>
                            <div>Tout est terminé ici avec {this.state.task.worker.name}</div>
                        </Card.Body>
                    );
                var reviews = this.state.task.model.reviews.rate_worker.map((field) => {
                    return (
                        <div>{field.label}<StarRatings
                            rating={this.state.ratings[field.name]}
                            starRatedColor="blue"
                            changeRating={this.changeRating}
                            numberOfStars={5}
                            starDimension="30px"
                            starSpacing="10px"
                            name={field.name}
                            />
                        </div>
                    )
                });
                var adding = (
                    <>
                        <h4>Evaluer {this.state.task.worker.name}</h4>
                        {reviews}
                        <Button
                            disabled={!this.isAllRated()}
                            variant="primary"
                            size="small"
                            startIcon={<CheckIcon />}
                            onClick={() => this.sendRating()}
                            >
                                Terminé
                            </Button>
                    </>
                )
            } else {
                if (this.state.task.model.isContract) {
                    adding = (
                        <>
                            <ShowValue
                                header={"Ceci est un contrat. Votre travailleur effectura l'ouvrage que vous avez demandé durant le contrat. Contactez-le au besoin."}
                            />
                            
                            <ShowValue>
                                <Button
                                    variant="primary"
                                    size="small"
                                    startIcon={<CancelIcon />}
                                    onClick={() => this.abandonWorker()}
                                >
                                    Annuler le contrat
                                </Button>
                            </ShowValue>
                        </>
                    )
                } else {
                    adding = (
                        <>
                            <ShowValue
                                header={"Votre travailleur effectura bientôt l'ouvrage que vous avez demandé..."}
                            />
                            
                            <ShowValue>
                                <Button
                                    variant="primary"
                                    size="small"
                                    startIcon={<CancelIcon />}
                                    onClick={() => this.abandonWorker()}
                                >
                                    Annuler ce travailleur
                                </Button>
                            </ShowValue>
                            <ShowValue>
                                <Button
                                    variant="primary"
                                    size="small"
                                    disabled={this.state.cancelTask}
                                    startIcon={<CheckIcon />}
                                    onClick={() => this.workerIsDone()}
                                >
                                    Le travailleur a terminé
                                </Button>
                            </ShowValue>
                        </>
                    )
                }
            }
            var outputs;
            //alert(JSON.stringify(this.state.task.outputs["graphiques"]));
            if (this.state.task.outputs && this.state.task.outputs["graphiques"])
                outputs = this.state.task.outputs["graphiques"].map((file) => {
                    return (
                        <img src={file} width={300}/>
                    )
                })
            if (this.state.task.model.isContract) {
                var modal = (
                    <Modal
                    size="lg"
                    show={this.state.show_abandon}
                    onHide={() => this.setState({show_abandon: false})}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Annuler le contrat
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Vous recevrez un remboursement du montant restant du contrat directement dans votre compte Liberium.</p>
                        <h1>Faites un choix</h1>                        
                        <center>
                            <p>Moi et le travailleur, nous avons convenu que le contrat ne pouvait pas être terminé</p>
                            <Button
                                variant="primary"
                                size="small"
                                startIcon={<CancelIcon />}
                                onClick={() => this.abandonTask("good")}
                            >
                                Annuler le contrat
                            </Button>
                            <hr/>
                            <p>J'ai une mauvaise expérience avec ce travailleur.</p>
                            <Button
                                variant="primary"
                                size="small"
                                startIcon={<CancelIcon />}
                                onClick={() => this.abandonTask("bad")}
                            >
                                Pas fiable
                            </Button>
                        </center>
                    </Modal.Body>
                </Modal>
                )
            } else {
                var modal = (
                    <Modal
                    size="lg"
                    show={this.state.show_abandon}
                    onHide={() => this.setState({show_abandon: false})}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Abandon du travailleur
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Vous recevrez un remboursement directement dans votre compte Liberium.</p>
                        <h1>Faites un choix</h1>                        
                        <center>
                            <p>Moi et le travailleur, nous avons convenu que la quête ne peut être faite</p>
                            <Button
                                variant="primary"
                                size="small"
                                startIcon={<CancelIcon />}
                                onClick={() => this.abandonTask("good")}
                            >
                                Annuler la quête
                            </Button>
                            <hr/>
                            <p>Le travailleur ne s'est pas présenté.</p>
                            <Button
                                variant="primary"
                                size="small"
                                startIcon={<CancelIcon />}
                                onClick={() => this.abandonTask("bad")}
                            >
                                Pas fiable
                            </Button>
                        </center>
                    </Modal.Body>
                </Modal>
                )
            }
            return (
                <>
                <div class="listview-title mt-1">Votre travailleur</div>
                <ul class="listview image-listview">
                    <UserCard
                        user={this.state.task.worker}
                    />
                    {adding}
                    {outputs}
                </ul>
                {modal}
                </>
            )
        }
        var paiements = this.state.wallets.wallets.map((wallet) => {
            //alert("wallet " + JSON.stringify(wallet));
            var disabled;
            var addTxt;
            //console.log("============");
            //console.log(this.state.amountToPay,">", parseFloat(wallet.quantity));
            if (this.state.amountToPay > parseFloat(wallet.quantity)) {
                disabled = true;
                addTxt = (
                    <>
                    <br/>
                    <MakeAdeposit/>
                    <br/>
                    </>
                )
            }
            return (
            <div>
                <Form.Check
                    type="radio"
                    name="walletId"
                    label={wallet.asset}
                    id={`compte_$wallet.asset`}
                    disabled={disabled}
                    checked={wallet.walletId == this.state.walletSelected}
                    onChange={this.handleWallet}
                    value={wallet.walletId}
                />
                ${parseFloat(wallet.quantity).toFixed(2)} disponible
                {addTxt}
            </div>
            )
        });

        var appliers;
        if (this.state.task.nbr_appliers > 0)
                appliers = this.state.task.appliers.map((row) => {
                    var details = JSON.parse(row.details);
                    if (typeof details.amount === "undefined")
                        details.amount = this.state.task.reward.amount;
                    var color = "primary";                    
                    if (details.amount > this.state.task.reward.amount)
                        color = "danger";
                    if (details.amount < this.state.task.reward.amount)
                        color = "success";
                    var usrAvatar = Avatar;
                    if (row.avatar.length > 0)
                        usrAvatar = row.avatar;
                    //alert(JSON.stringify(row));
                    //alert(row.rated);
                    return (
                        <li>
                            <a onClick={() => this.engage(row)} class="item">
                                <a onClick={() => this.chatWith(row)}>
                                    <img src={usrAvatar} alt="image" class="image" />
                                </a>
                                <div class="in">
                                    <div>
                                        <header>{row.name}</header>
                                        
                                        <footer>
                                            <StarRatings
                                                rating={row.rated}
                                                starRatedColor="gold"
                                                numberOfStars={5}
                                                starDimension="20px"
                                                starSpacing="3px"
                                            />
                                            <Button
                                                variant={color}
                                                disabled={this.state.disabled_engage}
                                                size="small"
                                                startIcon={<EditIcon />}
                                                onClick={() => this.engage(row)}
                                            >
                                                Engager à {details.amount}$
                                            </Button>

                                        </footer>
                                    </div>
                                    <span class="text-muted"></span>
                                </div>
                            </a>
                        </li>
                    )
                })
        else {
            if (this.state.task.model.isContract) {
                appliers = ( 
                    <tr>
                        <td colspan='2'>
                            Personne n'a soumissionné sur votre contrat.
                            Nous vous envoyons un SMS dès qu'une personne soumissionne.
                        </td>
                    </tr>
                );
            } else {
                appliers = ( 
                    <tr>
                        <td colspan='2'>
                            Personne n'a encore appliqué.
                            Nous vous envoyons un SMS dès qu'une personne veut faire votre quête.
                        </td>
                    </tr>
                );
            }
        }
        if (this.state.walletSelected === "stripe")
            var paymentBtn = (
                <StripeCheckout
                    name={"Task " + this.state.task.id}
                    description={this.state.task.model.name}
                    amount={parseFloat(this.state.amountToPay)*100}
                    token={this.onToken(this.state.amountToPay, this.state.amountToWorker, this.state.task.model.name)}
                    currency={"CAD"}
                    stripeKey={STRIPE_PUBLISHABLE}
                    email={this.state.email}
                />
            )
        else
            var paymentBtn = (
                <Button
                    variant="primary"
                    disabled={!this.state.paymentOk}
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => this.payment()}
                >
                    Paiment ${parseFloat(this.state.amountToPay).toFixed(2)}
                </Button>
            )
        var otherTxt;
        var acceptBtn = (
            <Button
                    variant="primary"
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => this.acceptContract()}
                >
                    Donner le contrat
                </Button>
        )
        if (this.state.amountToWorker > this.state.task.reward.amount)
            otherTxt = (
                <Alert variant="danger">
                    Le montant est suppérieur à ce que vous vouliez payer au départ.
                </Alert>
            )
        if (this.state.amountToWorker < this.state.task.reward.amount)
            otherTxt = (
                <Alert variant="success">
                    Le montant est inférieur à ce que vous vouliez payer au départ.
                </Alert>
            )
        var paymentMsg = (<></>);
        if (this.state.task.model.isContract) {
            var aucunMontant = (
                <>Aucun montant n'est envoyé pour le moment à {this.state.winner}. Un montant sera versé a {this.state.winner} quotidiennement jusqu'a la fin du contrat.</>
            )
        } else {
            var aucunMontant = (
                <>Aucun montant n'est envoyé pour le moment à {this.state.winner}. Le montant sera libéré lorsque le travail sera effectué.</>
            )
        }
        if (this.state.task.fromUserTxId === null)
                paymentMsg = (
                    <></>
                )
        /*
                paymentMsg = (
                    <li>
                    <a class="item">
                        <div class="in">
                            <div>
                            </div>
                            Paiment cash au travailleur
                            <span class="text-muted"></span>
                        </div>
                    </a>
                </li>
                )
        */
        return (
            <>
                <div>
                <ul class="listview image-listview">
                {paymentMsg}
                <li>
                    <a class="item">
                        <div class="in">
                            <div>
                            </div>
                            <Button
                                variant="primary"
                                size="small"
                                startIcon={<EditIcon />}
                                onClick={() => this.abandonTask("")}
                            >
                                Annuler la demande de service
                            </Button>
                            <span class="text-muted"></span>
                        </div>
                    </a>
                </li>
                </ul>
                    <center>
                        
                    </center>
                    <div class="listview-title mt-2">Vos prospects</div>
                    <ul class="listview image-listview">
                        {appliers}
                    </ul>
                </div>
                <Modal
                    size="lg"
                    show={this.state.show_engage}
                    onHide={() => this.setState({show_engage: false, disabled_engage: false})}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Paiement
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Afin d'officialiser le contrat entre vous et {this.state.winner}, vous devez nous effectuer le paiement.<br/><br/>
                        {aucunMontant}<br/><br/>
                        {otherTxt}
                        <b>Paiements:</b> ${parseFloat(this.state.amountToWorker).toFixed(2)}<br/>
                        <b>Total:</b> ${this.state.amountToPay}<br/>
                        <h3>Choisissez une méthode de paiement</h3>
                        <Form>
                        {paiements}
                        <div>
                            <Form.Check
                                type="radio"
                                name="walletId"
                                label="Par carte de crédit"
                                id={`stripe`}
                                checked={"stripe" == this.state.walletSelected}
                                onChange={this.handleWallet}
                                value={"stripe"}
                            />
                        </div>
                        </Form>
                        <div>
                            <center>
                                {paymentBtn}
                            </center>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    size="lg"
                    show={this.state.show_no_payment_engage}
                    onHide={() => this.setState({show_no_payment_engage: false, disabled_engage: false})}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Officialiser le contrat
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Afin d'officialiser le contrat entre vous et {this.state.winner}, vous devez accepter.<br/><br/>
                        <div>
                            <center>
                                {acceptBtn}
                            </center>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    size="lg"
                    show={this.state.show_stripe}
                    onHide={() => this.setState({show_stripe: false})}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Paiement Carte de crédit
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <StripeCheckout
                        name={"Task " + this.state.task.id}
                        description={this.state.task.model.name}
                        amount={parseFloat(this.state.amountToPay).toFixed(2)*100}
                        token={this.onToken(parseFloat(this.state.amountToPay).toFixed(2), this.state.amountToWorker, this.state.task.model.name)}
                        currency={"CAD"}
                        stripeKey={STRIPE_PUBLISHABLE}
                    />
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default withRouter(TaskOwner);