import React from "react";
import {api} from '../../services/autonome';

import { Form, Modal } from "react-bootstrap";
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import SendIcon from '@material-ui/icons/Send';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import TextField from '@material-ui/core/TextField';
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export class ImpotProfile extends React.Component {

  state = {
    screens: []
  }

  constructor(props) {
    super(props);
    this.state = {
        userId: this.getUserId(),
        profil: {
          prenom: "",
          nom: "",
          nas: "",
          sexe: "",
          telephone: "",
          adresse: "",
          city: "",
          province: "qc",
          date_naissance: "",
          country: "Canada",
          vitSeul: "0",
          has_insurance: null,
          conjoint_name: "",
          has_childrens: "0",
          is_proprio: null,
          childrens: []
        },
        page: 1,
        year: this.props.year,
        nouvelEnfant: this.newKid(),
        showNewKid: false
    }
    this.onProfilDone = this.props.onProfilDone;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeKid = this.handleChangeKid.bind(this);
    this.handleChangeKidDate = this.handleChangeKidDate.bind(this);
    if (typeof this.props.profil !== "undefined" && this.props.profil !== null)
      this.state.profil = this.props.profil;
  }

  newKid() {
    return {
      prenom: "",
      nom: "",
      nas: "",
      date_naissance: new Date()
    }
  }

  closeModal() {
    this.setState({showNewKid: false});
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  newChildren() {
    var profil = this.state.profil;
    var childs = profil.childrens;
    childs.push({prenom:"", nom:"", date_naissance: "", nas: ""});
    this.setState({profil: profil});
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    var new_form = this.state.profil;
    new_form[name] = value;
    //alert(target.value);
    this.setState({profil:new_form});
  }

  handleChangeKid(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    var new_form = this.state.nouvelEnfant;
    new_form[name] = value;
    //alert(JSON.stringify(new_form));
    this.setState({nouvelEnfant: new_form});
  }

  handleChangeKidDate(date) {
    var new_form = this.state.nouvelEnfant;
    new_form["date_naissance"] = date;
    console.log(typeof date);
    //alert(date.toString());
    this.setState({nouvelEnfant: new_form});
  }

  handleChangeDNS(date) {
    console.log("date de naissance:", date);
    var profil = this.state.profil;
    profil.date_naissance = date;
    this.setState({profil: profil});
  }

  handleSupprimerEnfant(index) {
    var profil = this.state.profil;
    var enfants = profil.childrens;
    enfants.splice(index, 1);
    this.setState({profil: profil});
  }

  handleAjouterEnfant() {
    var profil = this.state.profil;
    var enfants = profil.childrens;
    var ddn = this.state.nouvelEnfant.date_naissance;
    this.state.nouvelEnfant.date_naissance = `${ddn.getMonth()+1}/${ddn.getDate()}/${ddn.getFullYear()}`;
    enfants.push(this.state.nouvelEnfant);
    this.setState({profil: profil, showNewKid:false, nouvelEnfant: this.newKid()});
  }

  setNouvelEnfant() {

  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  validateForm() {
    if (this.state.page === 1) {
      if (this.state.profil.prenom.length < 3) return false;
      if (this.state.profil.nom.length < 3) return false;
      if (this.state.profil.nas.length < 9) return false;

      return true;
    }
    if (this.state.page === 2) {
      if (this.state.profil.adresse.length < 3) return false;
      if (this.state.profil.city.length < 3) return false;
      if (this.state.profil.province.length < 2) return false;
      if (this.state.profil.country.length < 2) return false;
      if (this.state.profil.zipcode.length < 2) return false;

      return true;
    }
    if (this.state.page === 3) {
      if (this.state.profil.vitSeul === "0")
        if (this.state.profil.avecQuiVit !== "coloc")
          if (this.state.profil.conjoint_name.length < 5)
            return false;
      return true;
    }
    if (this.state.page === 4) {
      //console.log()
      if (this.state.profil.has_childrens === "1" && this.state.profil.childrens.length === 0) return false;
      return true;
    }
    if (this.state.page === 5) {
      if (this.state.profil.has_insurance === null) return false;
      return true;
    }
    if (this.state.page === 6) {
      if (this.state.profil.is_proprio === null) return false;
      return true;
    }
  }

  validateNewKid() {
    if (this.state.nouvelEnfant.prenom.length < 3) return false;
    if (this.state.nouvelEnfant.nom.length < 3) return false;
    if (this.state.nouvelEnfant.date_naissance.length < 3) return false;
    return true;
  }

  onNextPage() {
    if (this.validateForm()) {
      var newPage = this.state.page + 1;
      this.setState({page: newPage});
    }
  }

  onPrevPage() {
    var newPage = this.state.page -1;
    this.setState({page: newPage});
  }

  onDone() {
    //alert(this.state.year);
    var these = this;
    this.state.profil.year = this.state.year;
    api.call('post', "impot/profile", this.state.profil).then(result => {
      these.onProfilDone(this.state.profil);
    });
  }

  bottomForm() {
    var previous =  (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<SkipPreviousIcon />}
          onClick={() => this.onPrevPage()}
        >
          Précédent
        </Button>
    );
    var next = (
      <Button
        variant="contained"
        color="primary"
        disabled={!this.validateForm()}
        size="small"
        startIcon={<SkipNextIcon />}
        onClick={() => this.onNextPage()}
      >
        Suivant
      </Button>
    )
    var terminate = (
      <Button
        variant="contained"
        color="primary"
        disabled={!this.validateForm()}
        size="small"
        startIcon={<SendIcon />}
        onClick={() => this.onDone()}
      >
        Terminé
      </Button>
    )
    if (this.state.page === 1)
      return (
        <center>
          <br/><br/>
          {next}
          <br/><br/>
        </center>
      )
    if (this.state.page === 6)
      return (
        <center>
          <br/><br/>
          {previous}
          {terminate}
          <br/><br/>
        </center>
      )
    return (
      <center>
        <br/><br/>
        {previous}
        {next}
        <br/><br/>
      </center>
    )
  }

  renderPage1() {
    return (
      <>
          <h2>A propos de vous?</h2>
          <TextField
            label="Prénom"
            name="prenom"
            value={this.state.profil.prenom}
            onChange={(e) => this.handleChange(e)}
          />
          <TextField
            label="Nom"
            name="nom"
            value={this.state.profil.nom}
            onChange={(e) => this.handleChange(e)}
          />

          <div>
            Date de naissance:<br/>
            <DatePicker
              selected={this.state.profil.date_naissance}
              name="date_naissance"
              value={this.state.profil.date_naissance}
              onChange={(date) => this.handleChangeDNS(date)} 
            />
          </div>

        <h2>Quel est votre sexe ?</h2>
        <RadioGroup
          row
          aria-label="sexe"
          name="sexe"
          value={this.state.profil.sexe}
          onChange={(e) => this.handleChange(e)}
        >
          <FormControlLabel value={'homme'} control={<Radio />} label="Homme" />
          <FormControlLabel value={'femme'} control={<Radio />} label="Femme" />
        </RadioGroup>

          <TextField
            label="Numéro de téléphone"
            name="telephone"
            value={this.state.profil.telephone}
            onChange={(e) => this.handleChange(e)}
          />
          <TextField
            label="Numéro d'assurance social"
            name="nas"
            value={this.state.profil.nas}
            onChange={(e) => this.handleChange(e)}
          />
          <div>
            {this.bottomForm()}
          </div>
      </>
    )
  }

  renderPage2() {
    return (
      <>
        <FormControl component="fieldset">
          <h2>Où habitez-vous en ce moment?</h2>
          <TextField
            label="Adresse"
            name="adresse"
            value={this.state.profil.adresse}
            onChange={(e) => this.handleChange(e)}
          />
          <TextField
            label="Ville"
            name="city"
            value={this.state.profil.city}
            onChange={(e) => this.handleChange(e)}
          />
          <TextField
            label="Code postal"
            name="zipcode"
            value={this.state.profil.zipcode}
            onChange={(e) => this.handleChange(e)}
          />
          <TextField
            label="Province"
            name="province"
            value={this.state.profil.province}
            onChange={(e) => this.handleChange(e)}
          />
          <TextField
            label="Pays"
            name="country"
            value={this.state.profil.country}
            onChange={(e) => this.handleChange(e)}
          />
          <div>
            {this.bottomForm()}
          </div>
        </FormControl>
      </>
    )
  }

  renderPage3() {
    var other;
    if (this.state.profil.vitSeul === "0") {
      var other2;
      if (this.state.profil.avecQuiVit === "marie" || this.state.profil.avecQuiVit === "conjoint")
        other2 = (
          <>
            <b>Votre partenaire doit faire son impôt. Demandez-lui de créer un compte Liberium et de remplir les formulaires comme vous le faites.</b>
            <TextField
              label="Nom complet du partenaire"
              name="conjoint_name"
              value={this.state.nouvelEnfant.conjoint_name}
              onChange={(e) => this.handleChange(e)}
            />
          </>
        )
      other = (
        <>
          <h2>Avec qui vivez-vous ?</h2>
          <RadioGroup
            row
            aria-label="avecQuiVit"
            name="avecQuiVit"
            value={this.state.profil.avecQuiVit}
            onChange={(e) => this.handleChange(e)}
          >
            <FormControlLabel value={'marie'} control={<Radio />} label="Marié(e)" />
            <FormControlLabel value={'conjoint'} control={<Radio />} label="Conjoint(e)" />
            <FormControlLabel value={'coloc'} control={<Radio />} label="Colocataire" />
          </RadioGroup>
          {other2}
        </>
      )
    }
    return (
      <div>
        <FormControl component="fieldset" style={{ marginTop: '20px' }}>
          <h2>Est-ce que vous vivez seul(e) ?</h2>
          <RadioGroup
            row
            aria-label="vitSeul"
            name="vitSeul"
            value={this.state.profil.vitSeul}
            onChange={(e) => this.handleChange(e)}
          >
            <FormControlLabel value={"1"} control={<Radio />} label="Oui" />
            <FormControlLabel value={"0"} control={<Radio />} label="Non" />
          </RadioGroup>
          {other}
        </FormControl>        
        {this.bottomForm()}
      </div>
    )
  }

  renderPage4() {
    var has_childrens;
    if (this.state.profil.has_childrens==="1") {
      has_childrens = (
        <>
        <h2>Ajouter vos enfants</h2>
        <TableContainer component={Paper}>
          <Table className={useStyles.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Prénom</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Date de naissance</TableCell>
                <TableCell>Num. assurance social</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.profil.childrens.map((enfant, index) => (
                <TableRow key={index}>
                  <TableCell>{enfant.prenom}</TableCell>
                  <TableCell>{enfant.nom}</TableCell>
                  <TableCell>{enfant.date_naissance}</TableCell>
                  <TableCell>{enfant.ssn}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => this.handleSupprimerEnfant(index)}
                    >
                      Supprimer
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          </TableContainer>
          <br/>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={(e)=>this.setState({showNewKid: true})}
          >
            Ajouter un enfant
          </Button>
        </>
      )
    }
    return (
      <>
        <FormControl component="fieldset">
          <h2>Avez-vous des enfants à votre charge?</h2>
          <RadioGroup
            row
            aria-label="has_childrens"
            name="has_childrens"
            value={this.state.profil.has_childrens}
            onChange={(e) => this.handleChange(e)}
          >
            <FormControlLabel value={"1"} control={<Radio />} label="Oui" />
            <FormControlLabel value={"0"} control={<Radio />} label="Non" />
          </RadioGroup>
          {has_childrens}
          <div>
            {this.bottomForm()}
          </div>
        </FormControl>
        <Modal
          size="lg"
          show={this.state.showNewKid}
          onHide={() => this.closeModal()}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Ajout d'un enfant
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className={useStyles.root}>
              <div>
              <TextField
                label="Prénom"
                name="prenom"
                value={this.state.nouvelEnfant.prenom}
                onChange={(e) => this.handleChangeKid(e)}
              />
              </div>
              <div>
              <TextField
                label="Nom"
                name="nom"
                value={this.state.nouvelEnfant.nom}
                onChange={(e) => this.handleChangeKid(e)}
              />
              </div>
              <div>
                Date de naissance:<br/>
                <DatePicker
                  selected={this.state.nouvelEnfant.date_naissance}
                  name="date_naissance"
                  value={this.state.nouvelEnfant.date_naissance}
                  onChange={(date) => this.handleChangeKidDate(date)} 
                />
              </div>
              <div>
              <TextField
                label="Numéro d'assurance social"
                name="ssn"
                value={this.state.nouvelEnfant.ssn}
                onChange={(e) => this.handleChangeKid(e)}
              />
              </div>
            </Form>
            <br/><br/>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={(e)=>this.handleAjouterEnfant(e)}
              disabled={!this.validateNewKid()}
            >
              Ajouter un enfant
            </Button>
          </Modal.Body>
        </Modal>
      </>
    )
  }

  renderPage5() {
    return (
      <FormControl component="fieldset">
          <h2>Avez-vous une autre assurance que la RAMQ ?</h2>
          <RadioGroup
            row
            aria-label="has_insurance"
            name="has_insurance"
            value={this.state.profil.has_insurance}
            onChange={(e) => this.handleChange(e)}
          >
            <FormControlLabel value={"1"} control={<Radio />} label="Oui" />
            <FormControlLabel value={"0"} control={<Radio />} label="Non" />
          </RadioGroup>
          <div>
            {this.bottomForm()}
          </div>
        </FormControl>
    )
  }

  renderPage6() {
    return (
      <FormControl component="fieldset">
          <h2>Êtes-vous propriétaire de votre logement ?</h2>
          <RadioGroup
            row
            aria-label="is_proprio"
            name="is_proprio"
            value={this.state.profil.is_proprio}
            onChange={(e) => this.handleChange(e)}
          >
            <FormControlLabel value={"1"} control={<Radio />} label="Oui" />
            <FormControlLabel value={"0"} control={<Radio />} label="Non" />
          </RadioGroup>
          <div>
            {this.bottomForm()}
          </div>
        </FormControl>
    )
  }

  renderPage() {
    //alert(this.state.page);
    if (this.state.page === 1)
      return this.renderPage1();
    if (this.state.page === 2)
      return this.renderPage2();
    if (this.state.page === 3)
      return this.renderPage3();
    if (this.state.page === 4)
      return this.renderPage4();
    if (this.state.page === 5)
      return this.renderPage5();
    if (this.state.page === 6)
      return this.renderPage6();
  }

  render() {
    return (
      <>
      <div>
        <center>Mettez votre profil à jour</center>        
      </div>
      <form className={useStyles.root} noValidate autoComplete="off">
        {this.renderPage()}
      </form>
      </>
    );
  }
}