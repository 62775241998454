import React from "react";
import { Container, Row } from "shards-react";
import {api} from '../../services/autonome';

export class MarketPlaceHome extends React.Component {
  state = {
    screens: []
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    api.call('post', "user/usrLog", {module: "views", title:"marketplace"});
    window.gtag('event', 'page_view', {
      page_title: 'Market Place',
      page_location: '/marketplace',
      page_path: '/marketplace',
      //send_to: '<GA_MEASUREMENT_ID>'
    })
  }

  componentWillUnmount() {
  }

  render() {
    var sty = {
        float: "none", 
        margin: "0 auto"
    }

    return (
      <Container fluid className="main-content-container px-4">

        <div class="row home-intro text-center">
          <div class="col-xs-12 col-md-8" style={sty}>
            <h1>Liberium Marketplace</h1>
            <br/>
            <hr class="small"/>
            Imaginez que vous vendez quelques une de vos affaires, un travailleur sur Liberium
            vient les chercher et les livres directement a l'acheteur. On va le faire pour la mi 2023.
          </div>
        </div>

    </Container>
    );
  }
}