import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router';
import CryptoModalExchange from "./exchange";
import { Form, Modal } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

class CryptoOrdersView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            orders: [
            ]
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "v2/cryptoExchanger/orders", {}).then(result => {            
            these.setState({orders: result.data});
        });
    }

    cancelOrder(row) {
        var these = this;
        api.call('get', "v2/cryptoExchanger/cancelOrder?orderId=" + row.id, {}).then(result => {            
            these.fetchData();
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    handleChange(event) {
        console.log("Handle change", event.target);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    componentDidMount() {
        this.fetchData();
    }

    renderOrders() {
        return this.state.orders.map((row) => {
            var btn = (<></>)
            if (row.status !== "canceled" && row.status !== "closed")
                btn = (
                    <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.cancelOrder(row)}
                        >
                            Cancel
                        </Button>
                )
            return (
                <tr>
                    <td>{row.exchanger}</td>
                    <td>{row.symbol}</td>
                    <td>{row.orderId}</td>
                    <td>{row.status}</td>
                    <td>{row.side}</td>
                    <td>{row.amount}</td>
                    <td>{row.price}</td>
                    <td>
                        {btn}
                    </td>
                </tr>
            );
        });
    }

    render(){
        return(
            <>
            <h1>Orders</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Exchanger</th>
                    <th>Symbol</th>
                    <th>Order Id</th>
                    <th>Status</th>
                    <th>Side</th>
                    <th>Amount</th>
                    <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderOrders()}
                </tbody>
            </Table>
            </>
        )
    }
}

export default withRouter(CryptoOrdersView);