import axios from 'axios';
import { API_ROOT } from '../api-config';
import {Store, Constants} from '../components/flux';

const LB_ROOT = "https://api.liberium.net/";
//const LB_ROOT = "http://localhost:8080/";

class AutonomeAPI {
    _userToken;
    orgId = null;
    constructor() {
        console.log("AutonomeAPI constructor")
        Store.addListener(Constants.USER_TOKEN, (token) => {
                this._userToken = token && token.token;
        });
        this._userToken = Store.getUserToken();
        console.log("API_ROOT:", API_ROOT);
        if (API_ROOT==="//api.liberium.net/") {
            this.orgId = 20
        } else {
            this.orgId = 178;
        }
    }

    getWallet(cb) {
        this.call('get', "finance/cad_wallet", {}).then(result => {
            if (typeof result.data.error != "undefined") return cb([]);
            
            var total = 0;
            result.data.forEach(function(res) {
                total = total + parseFloat(res.quantity);
            });

            return cb({
                wallets: result.data,
                total: total
            });
        });
    }

    getUserId() {
        var existingTokens;
        try{
            existingTokens = JSON.parse(localStorage.getItem('tokens'));
            //alert(JSON.stringify(existingTokens));
            return existingTokens.UserId;
        } catch(e) {
            return null;
        }
    }

    callModule(module, name, body, callback) {
        body.cmd = "askFunction";
        body.module = module;
        body.name = name;        
        api.callLb('post', "apis/internal/function", body).then(result => {
            result = result.data;
            callback(null, result);
        });
    }

    callLb(method, path, body) {
        return new Promise(function(resolve, reject) {
            var existingTokens;
            try{
                existingTokens = JSON.parse(localStorage.getItem('tokens'));
            } catch(e) {
                return resolve(null);
            }

            var headers = {}
            if (existingTokens != null)
                headers = {
                    headers: {
                    'authorization':  existingTokens.token
                    }
                }
            //alert(path + JSON.stringify(headers))
            //if (typeof body !== "undefined" && body != null && Object.entries(body).length > 0) {
            var the_path = "";
            if (path.indexOf("http://") > -1)
                the_path = path
            else
                the_path = LB_ROOT + path

            if (process.env.NODE_ENV=="development" && typeof body.noDev === "undefined") {
                body.moduleName = body.module;
                body.cmd = "askFunction";
                body.module = "test_function";        
                body.functionToTest = body.name;
                body.name = "testFunction";
                //console.log("After", datas);
            }

            if (method === "post") {
                axios[method](the_path, body, headers).then(result => {
                    if (process.env.NODE_ENV=="development" && typeof body.noDev === "undefined") {
                        console.log(result);
                        var splited = result.data.split(/\r?\n/);
                        console.log(splited);
                        result.data = JSON.parse(splited[splited.length - 2]);
                        resolve(result);
                    } else {
                        resolve(result);
                    }
                    
                }).catch(function (e) {
                    reject(e);
                });
            } else if (method === "patch") {
                axios[method](the_path, body, headers).then(result => {
                    resolve(result);
                }).catch(function (e) {
                    reject(e);
                });
            } else {
                axios[method](the_path, headers).then(result => {
                    resolve(result);
                }).catch(function (e) {
                    reject(e);
                });
            }
        });
    }

    call(method, path, body) {
        return new Promise(function(resolve, reject) {
            var existingTokens;
            try{
                existingTokens = JSON.parse(localStorage.getItem('tokens'));
            } catch(e) {
                return resolve(null);
            }            

            var headers = {}
            if (existingTokens != null)
                headers = {
                    headers: {
                    'authorization':  existingTokens.token
                    }
                }
            //alert(path + JSON.stringify(headers))
            //if (typeof body !== "undefined" && body != null && Object.entries(body).length > 0) {
            var the_path = "";
            if (path.indexOf("http://") > -1)
                the_path = path
            else {
                if (path.indexOf("hosting/") > -1) 
                    //the_path = "http://localhost:9055/" + path
                    the_path = "http://10.1.20.151:9055/" + path
                else
                    the_path = API_ROOT + path
            }
            if (method === "post") {
                axios[method](the_path, body, headers).then(result => {
                    resolve(result);
                }).catch(function (e) {
                    reject(e);
                });
            } else if (method === "patch") {
                axios[method](the_path, body, headers).then(result => {
                    resolve(result);
                }).catch(function (e) {
                    reject(e);
                });
            } else {
                axios[method](the_path, headers).then(result => {
                    resolve(result);
                }).catch(function (e) {
                    reject(e);
                });
            }
        });
    }
}

const api = new AutonomeAPI();

export {api};