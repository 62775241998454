import React from 'react';
import Linkify from 'react-linkify';

export class Message extends React.Component {

    render() {
        var ligne;
        console.log(this.props);
        //alert(this.props.other.id,"===", this.props.fromUserId);
        var msg = this.props.text;
        if (typeof this.props.img !== "undefined" && this.props.img !== null && this.props.img.length > 10) {
            //alert("pass");
            console.log(this.props.img)
            msg = (
                <a href={this.props.img} target="_blank"><img src={this.props.img} width="100%"/></a>
            )
        }
        if (this.props.other.id === this.props.fromUserId)
            ligne = (
                <Linkify>
                <div class="message-data">
                    <img src={"https://api.liberium.net/avatar/"+this.props.fromUserId} alt="avatar" />
                    <span class="message-data-time">{this.props.receivedAt}</span>                    
                </div>
                <div class="message my-message">{msg}</div>
                </Linkify>
            )
        else
            ligne = (
                <Linkify>
                <div class="message-data text-right">
                    <span class="message-data-time">{this.props.receivedAt}</span>
                </div>
                <div class="message other-message float-right">{msg}</div>
                </Linkify>
            )
        return (
            <li class="clearfix">
                {ligne}
            </li>            
        )
    }
}