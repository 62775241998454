import React, { useState, useEffect, Fragment, useRef } from 'react'
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router'; 

import { Form, Modal } from "react-bootstrap";
import Table from 'react-bootstrap/Table';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DirectionsIcon from '@material-ui/icons/Directions';
import EditIcon from '@material-ui/icons/Edit';

import { FormInput, FormGroup } from "shards-react";

import {api} from '../../../services/autonome';

const businessId = 19;

class AdminImpotView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            showNewClient: false,
            showClients: true,
            clients: [],
            statusFilter : "begining",
            newClient: {prenom:"", nom:""},
            search: ""
        }
        var impotCurFilter = localStorage.getItem('impotCurFilter');
        console.log("currentFilter:",impotCurFilter);
        if (impotCurFilter !== null)
            this.state.statusFilter = impotCurFilter;
        this.firstField = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        this.fetchClients();

        window.gtag('event', 'page_view', {
            page_title: 'Impot',
            page_location: '/admin/impot',
            page_path: '/admin/impot'
        });
    }

    fetchClients() {
        api.call('get', "business/" + businessId + "/clients", {}).then(result => {
            if (result.status === 200) {
              console.log("New client", result.data);
              this.setState({clients:result.data});
            } 
        }).catch(e => {
        });
    }

    saveNewClient() {
        var these = this;
        var body = {
            businessId: businessId,
            prenom: this.state.newClient.prenom,
            nom: this.state.newClient.nom,
            adresse: '',
            city: '',
            zipcode: '',
            state: 'QC',
            country: 'Canada',
            email: '',
            telephone: '',
            delivery_details: ''
        }
        api.call('post', "client", body, {}).then(result => {
            if (result.status === 200) {
                console.log("New client", result.data);              
                these.props.history.push({
                    pathname: "/admin/impot/" + result.data.id
                });
            }
        }).catch(e => {
        });
    }

    handleChange(event) {
        var these = this;
        console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state.newClient;
        new_form[name] = value;

        this.setState({newClient:new_form});
    }

    handleFilter(event) {
        var these = this;
        console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({statusFilter:value});
        localStorage.setItem('impotCurFilter', value);
    }

    goNewClient() {
        this.setState({showNewClient: true});
        var these = this;        
        setTimeout( function () {
            console.log(these.firstField);
            these.firstField.current. focus();
        },200);
    }

    goClients() {
        this.fetchClients();
        this.setState({showClients: true});
    }

    goClient(client) {
        this.props.history.push({
            pathname: "/admin/impot/" + client.id
        });
    }

    closeNewClient() {
        this.setState({showNewClient: false});
    }

    handleSearch(event) {
        console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({search: target.value});
    }

    listClients() {
        if (!this.state.showClients) return (<></>)
        var clients = this.state.clients.map((client) => {
            if (this.state.search.length > 0) {
                var found = false;
                if (client.prenom.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) found = true;
                if (client.nom.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) found = true;
                if (!found) return;
            } else if (client.status !== this.state.statusFilter) return;
            return (
                <tr>
                    <td>{client.prenom} {client.nom}</td>
                    <td></td>
                    <td>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.goClient(client)}
                        >
                            Gérer
                        </Button>
                    </td>
                </tr>
            )
        });
        return (
            <>
            <h2>Liste des clients</h2>
            
            <FormGroup>
                    <label htmlFor="#search">Recherche :</label>
                    <FormInput type="text" name="search" value={this.state.search} onChange={this.handleSearch} />
            </FormGroup>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Status</Form.Label>
                <Form.Select 
                    aria-label="Default select example" 
                    value={this.state.statusFilter}
                    onChange={e=>this.handleFilter(e)}
                >
                    <option value=""></option>
                    <option value="numerisation">Étape de numérisation</option>
                    <option value="begining">Entrer les chiffres</option>
                    <option value="waiting">En attente</option>
                    <option value="closing">Finaliser l'impôt</option>                    
                    <option value="invoice">Impression & Facturation</option>
                    <option value="terminate">Terminé</option>
                </Form.Select>
            </Form.Group>

            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Nom</th>
                    <th></th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {clients}
                </tbody>
            </Table>
            </>
        )
    }

    render() {
        return(
            <>
                <h1>Administration Impot</h1>
                <p>
                <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<DirectionsIcon />}
                onClick={() => this.goNewClient()}
                >
                    Nouveau clients
                </Button>
                <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<DirectionsIcon />}
                onClick={() => this.goClients()}
                >
                    Clients
                </Button>
                </p>
                {this.listClients()}
                <Modal
                size="lg"
                show={this.state.showNewClient}
                onHide={() => this.closeNewClient()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Nouveau client
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                    <Form.Group className="mb-3" controlId="timeout">
                            <Form.Control
                            ref={this.firstField}
                            type="text"
                            name="prenom"
                            placeholder="prenom"
                            autoComplete="off"
                            value={this.state.newClient.prenom}
                            onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="timeout">
                            <Form.Control
                            type="text"
                            name="nom"
                            autoComplete="off"
                            placeholder="nom de famille"
                            value={this.state.newClient.nom}
                            onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>
                        <div class="container">
                            <div class="row">
                                <div class="col text-center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<EditIcon />}
                                        onClick={() => this.saveNewClient()}
                                    >
                                        Sauvegarder
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            </>
        )
    }
}

export default withRouter(AdminImpotView);
/*
const AdminImpotView = (props) => {
  // Data
  const businessId = props.match.params.orgId;
  const clientsData = []
  const initialFormState = { id: null, prenom: '', nom: '' }

  // Setting state
  const [ showClient, setShowClient ] = useState(false);
  const [ isError, setIsError ] = useState(false);
  const [ clients, setClients ] = useState(clientsData)
  const [ photos, setPhotos ] = useState([]);
	const [ currentClient, setCurrentClient ] = useState(initialFormState)
  const [ editing, setEditing ] = useState(false)

    // CRUD operations
    const cancel = (client) => {
        setEditing(false);
        setShowClient(false);
    }

    const goBack = () => {
      props.history.push({
          pathname: "/business/" + businessId
      });
    }

    const getPhotos = (client, cb) => {
        axios.get(API_ROOT + "impot/client/" + client.id, {}).then(result => {
            if (result.status === 200) {
              console.log("Got images", result.data);
              var photos = [];
              result.data.forEach(function(one) {
                photos.push({src: one.url});
              });
              setPhotos(photos);
            }
            cb();
        }).catch(e => {
            cb();
        });
	}
    
	const addClient = client => {
    client.businessId = businessId;
        api.call('post', "client", client).then(result => {
            if (result.status === 200) {
              console.log("New client", result.data);
              client.id = result.data.id;
              setEditing(true);
              setClients([ ...clients, result.data ])
              setCurrentClient(client);      
            } else {
              setIsError(true);
            }
          }).catch(e => {
            setIsError(true);
          });
	}

	const deleteClient = id => {
		setEditing(false)
		setClients(clients.filter(client => client.id !== id))
	}

	const updateClient = (id, updatedClient) => {
    axios.patch(API_ROOT + "client/" + id, updatedClient).then(result => {
      // Reload list
      axios.get(API_ROOT + "business/" + businessId + "/clients", {}  ).then(result => {
        if (result.status === 200) {
          console.log("New client", result.data);
          setClients(result.data);
        } 
      }).catch(e => {
      });
    }).catch(e => {
      alert("OOPS");
    });

    setEditing(false);
    setShowClient(false);
		setClients(clients.map(client => (client.id === id ? updatedClient : client)))
	}

	const editRow = client => {
        getPhotos(client, function() {
            setEditing(true)
            setShowClient(true);
            setCurrentClient(client);
        });        
    }
    
    const handleNewClient = event => {
        setEditing(false);
        setShowClient(true);
    }

    // Faut que ca sexecute une seule fois
    useEffect(() => {
        api.call('get', "business/" + businessId + "/clients", {}).then(result => {
            if (result.status === 200) {
              console.log("New client", result.data);
              setClients(result.data);
            } 
          }).catch(e => {
          });
    }, [businessId]);

	return (
		<Container fluid className="main-content-container px-4">

            <Row noGutters className="page-header py-4">
                <PageTitle sm="4" title="Clientele" className="text-sm-left" />
            </Row>

            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
              <Button onClick={e => goBack()}>Retour</Button>
              <Button onClick={e => handleNewClient()}>Ajouter nouveau client</Button>
            </ButtonGroup>

			<Row><Col>
				<div className={showClient ? 'hidden' : ''}>
					<ClientTable clients={clients} editRow={editRow} deleteUser={deleteClient}/>
				</div>
        <div className={showClient ? '' : 'hidden'} >
					{editing ? (
						<Fragment>
							<h2>Edit client</h2>
							<EditClientForm
								editing={editing}
								setEditing={setEditing}
								currentClient={currentClient}
                                updateClient={updateClient}
                                cancel={cancel}
                                photos={photos}
							/>
						</Fragment>
					) : (
						<Fragment>
							<h2>Add client</h2>
							<AddClientForm addClient={addClient} cancel={cancel}/>
						</Fragment>
					)}
				</div>
      </Col></Row>
		</Container>
	)
}

export default AdminImpotView;
*/