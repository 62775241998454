import React from "react";
import { Container } from "shards-react";
import {api} from '../../services/autonome';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

const components = [
  {name: "div"},
  {
    name: "text",
    fields: [
      {name:"text"}
    ]
  },
  {name: "p"},
  {name: "a"},
]

export class CreatorPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        userId: this.getUserId(),
        curComponent: null,
        curId: null,
        page : {
          c_name: "div",
          id: 1,
          maxId: 2,
          childrens: [
            {
              c_name: "text",
              id: 2,
              options: {
                text: "Allo tout le monde"
              },
              childrens: []
            }
          ]
        }
    }
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  componentDidMount() {   
  }

  componentWillUnmount() {
  }

  selectComponent(comp) {
    console.log("selected:", comp.id);
    this.setState({curComponent: comp, curId: comp.id});
  }

  renderComponent(comp) {
    console.log("comp:",comp);
    var childrens = comp.childrens.map((e)=>this.renderComponent(e));
    return (
      <Row>
        <div 
          class="d-inline p-2 bg-primary text-white"
          onClick={(e)=>this.selectComponent(comp)}
        >
          {comp.c_name}
        </div>
        {childrens}
      </Row>
    )
  }

  renderFields() {
    var found = null;
    var self = this;
    components.forEach(function(one){
      if (one.name === self.state.curComponent.c_name)
        found = one;
    });
    console.log(found.fields);
    return (
      <></>
    )
  }

  changeComponent(c) {
    console.log(c);
  }

  renderPageTree() {
    return this.renderComponent(this.state.page);
  }

  renderEditComponent() {
    return (
      <>
      <h2>Edit Component</h2>
      {this.state.curComponent && 
      <>
        <Form.Select 
          aria-label="Default select example"
          value={this.state.curComponent.c_name}
          onChange={(e)=>this.changeComponent(e)}
        >
          {components.map((co)=>{
            return (
              <option value={co.name}>{co.name}</option>
            )
          })}          
        </Form.Select>
        {this.renderFields()}
        </>
      }
      </>
    )
  }

  render() {
    var sty = {
        float: "none", 
        margin: "0 auto"
    }
    return (
        <Container fluid className="main-content-container px-4">
            <h1>Page creator</h1>
            <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridGap: 20 }}>
              <div>
                Hierchachie
                {this.renderPageTree()}
              </div>
              <div>
                {this.renderEditComponent()}
              </div>
              <div>

              </div>
            </div>
        </Container>
    );
  }
}