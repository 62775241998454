import React from 'react';
import {api} from '../../services/autonome';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Form } from "react-bootstrap";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { API_ROOT } from '../../api-config';

class StorageView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            addingNew: false,
            entrepot: "Boite #",
            items: [],
            uploadMsg: "",
            server: null
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "objectsStored", {}).then(result => {
            these.setState({items: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();
    }

    handleField(event) {
        //console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
        this.setState({errorMsg: null});
    }

    renderItems() {
        return this.state.items.map((row) => {
            var img = row.images[0].image;
            return (
                <tr>
                <td>{row.id}</td>
                <td>{row.entrepot}</td>
                <td><img src={img}/></td>
                </tr>
            );
        });
    }

    addNew() {
        this.setState({addingNew: true});
    }

    handleTakePhoto = (dataUri) => {
        var self = this
        const data = new FormData();
        //data.append("itemId",this.state.itemId);
        data.append("entrepot",this.state.entrepot);
        data.append('data', dataUri);
        this.setState({
          uploadMsg: "Upload en cours"
        });
        //alert(API_ROOT + 'objectStorage_add');
        axios.post(API_ROOT + 'objectStorage_add', data).then((response) => {
          this.setState({
            uploadMsg: "Upload terminé"
          });
          //self.fetchData()();
        });
    }

    render(){
        var adds = (<></>)
        if (this.state.addingNew)
            adds = (
                <div>
                    <Form.Group className="mb-3" controlId="texte" key="texte">
                        <Form.Label>Entrepot</Form.Label>
                        <Form.Control
                            type="text"
                            name="entrepot"
                            value={this.state.entrepot}
                            onChange={e => this.handleField(e)}
                        >
                        </Form.Control>
                    </Form.Group>


                    <Camera
                        isImageMirror={false}
                        idealFacingMode = {FACING_MODES.ENVIRONMENT}
                        onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
                        />
                    {this.state.uploadMsg}
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => this.addObject()}
                    >
                        Add
                    </Button>
                </div>
            )
        return(
            <>
            <h1>Storage</h1>
            <p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => this.addNew()}
            >
              Add new
            </Button>
            </p>
            {adds}
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Entrepot</th>
                    <th>Image</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderItems()}
                </tbody>
            </Table>
            </>
        )
    }
}

export default withRouter(StorageView);