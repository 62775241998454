import React from "react";
import {
    Form,
    FormGroup,
    FormInput,
} from "shards-react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from "shards-react";
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from '../../services/autonome';

export class QuebecNewVerif extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            jobId: this.props.jobId,
            job: this.props.job,
            onFinish: this.props.onFinish,
            onClicked: false,
            metas: JSON.parse(this.props.job.metas)
        }
    }

    handleChange(event) {
        //console.log("Handle change", event);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state;
        new_form[name] = value;
        this.setState(new_form);
    }

    onDone(isQuebecCity) {
        this.setState({onClicked: true});
        console.log("Ok send it to api");
        var these = this;
        var data = {
            taskId: this.state.jobId,
            isQuebecCity: isQuebecCity
        }
        api.call('post', "virt_job/qcNewNews", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            this.state.onFinish();
        });
    }

    componentDidMount(){
    }

    render () {
        return (
            <>
            <Card>
                <CardHeader>Est-ce une nouvelle de la ville de Québec</CardHeader>
                <CardBody>
                    <p>C'est difficile pour l'algorithme de différencier la ville de Québec, la province de Québec et toute organisme mentionnant Québec dans son titre.</p>

                    <h2>
                        {this.state.metas.texte}
                    </h2>

                    <p>Est-ce une nouvelle de la ville de Québec?</p>
                </CardBody>
                <CardFooter>
                <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<NewReleasesSharpIcon />}
                            onClick={() => this.onDone(true)}
                            disabled={this.state.onClicked}
                        >
                            Oui
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<NewReleasesSharpIcon />}
                            onClick={() => this.onDone(false)}
                            disabled={this.state.onClicked}
                        >
                            Non
                        </Button>
                </CardFooter>
            </Card>
            </>         
        )
    }
}

export default QuebecNewVerif;