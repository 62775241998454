import React from "react";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import { format } from "date-fns";
import { withRouter } from 'react-router';
import noServiceIcon from '../../img/service-icon.png'

export class ContractOverview extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            task: this.props.task,
            disabled_apply: false,
            details: this.props.task.details
        }
    }

    componentDidMount(){
    }

    renderButton() {
        if (this.state.task.status === 0) {
            if (this.state.task.applied)
                return (
                    <Button
                    variant="contained"
                    disabled={this.state.disabled_apply}
                    color="primary"
                    size="small"
                    startIcon={<CancelIcon />}
                    onClick={() => this.unapplyTask(this.state.task)}
                    >
                    Annuler l'application
                    </Button>
                )
            else
                return (
                        <Button
                        variant="contained"
                        disabled={this.state.disabled_apply}
                        color="primary"
                        size="small"
                        startIcon={<EditIcon />}
                        onClick={() => this.applyTask(this.state.task)}
                        >
                        J'applique
                        </Button>
                )
        }
        if (this.state.task.workerId === this.props.user)
          return (
                <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => this.jobDone()}
                >
                Terminé
                </Button>
          )
    }

    click() {
        this.props.history.push({
            pathname: "/task/" + this.state.task.id
        });
    }

    render () {
        var img = (
            <img src={noServiceIcon} width='75' class='image'/>
          )
          if (this.state.task.model.icon.length > 0)
            img = (
              <img width="75" height="75" src={this.state.task.model.icon} class='image' style={{margin: "10px 10px 10px 10px"}}/>
            )
        var lastTime = (<></>);
        if (typeof this.state.details.last_did_at !== "undefined")
                lastTime = (
                    <>
                        Dernière visite: {format(new Date(this.state.details.last_did_at),"dd MMMM")}<br/>
                    </>
                )
        return (
            <li onClick={()=> this.click()} id={this.state.task.id}>
                <a class="item">
                   {img}                   
                    <div class="in">
                        <div>
                            <header>{this.state.task.model.name}</header>
                            Client: {this.state.task.clientName}<br/>
                            {lastTime}
                            <footer>{this.state.task.details.description}</footer>
                        </div>
                    </div>
                </a>
            </li>
        )
    }
}

export default withRouter(ContractOverview);