//import NoSleep from 'nosleep.js';
import React from "react";
//import NoSleep from 'nosleep.js';
import { Container, Row } from "shards-react";
import { Card, Table, Modal } from "react-bootstrap";
import {api} from '../../services/autonome';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloseIcon from '@material-ui/icons/Close';
import DehazeIcon from '@material-ui/icons/Dehaze';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ReplayIcon from '@material-ui/icons/Replay';
import Spinner from 'react-bootstrap/Spinner';
import { API_ROOT } from '../../api-config';
import BlueBox from "../../components/chasse/blue-box";
import GreenBox from "../../components/chasse/green-box";
import HintBox from "../../components/chasse/hint-box";

import play_btn from '../../img/chasse/play.png';
import leader_btn from '../../img/chasse/leader.png';
import exit_btn from '../../img/chasse/exit.png';


//var noSleep = new NoSleep();

const debug = false;
const maxDistance = 0.2;

const hint = {
  "lodging" : {message:"C'est un endroit ou dormir"},
  "park" : {message:"C'est un endroit pour s'amuser en famille"},
  "spa" : {message:"C'est un endroit relaxant"},
  "grocery_or_supermarket" : {message: "Un endroit pour combler nos besoins primaires"},
  "pharmacy" : {message: "Y pleut de la pillulle"},
  "real_estate_agency" : {message: "Quand tu veux vendre ta maison"},
  "amusement_park" : {message: "Un endroit pour s'amuser"},
  "aquarium" : {message: "On peut y voir des poissons"},
  "bakery" : {message: "Ca sent toujours bon"},
  "bar" : {message: "Un petit coup c'est agréable"},
  "beauty_salon" : {message: "Elle se sent plus belle quand elle y sort"},
  "book_store" : {message: "Un peu de lecture?"},
  "cafe" : {message: "On peut y acheter une boisson populaire"},
  "car_dealer" : {message: "On peut y acheter une auto"},
  "car_wash" : {message: "Un endroit pour laver ton auto"},
  "car_repair" : {message: "Un endroit pour réparer ton auto"},
  "casino" : {message: "Un endroit pour parier de l'argent"},
  "cemetery" : {message: "Repose en paix"},
  "church" : {message: "Amen!"},
  "city_hall" : {message: "L'endroit ou on paie nos taxes"},
  "clothing_store" : {message: "Si tu veux du nouveau linge"},
  //"drugstore" : {message: ""},
  "embassy" : {message: "Tu auras besoin de ton passeport"},
  "fire_station" : {message: "Ils ne protégeront en cas de feu"},
  "florist" : {message: "Ca sent bon et ça s'offre bien"},
  "gym" : {message: "C'est la place pour te mettre en forme"},
  "hair_care" : {message: "Tu veux une nouvelle coupe?"},
  "hardware_store" : {message: "Il y a beaucoup de chose qui se vende à cette endroit"},
  "library" : {message: "Un peu de lecture?"},
  "movie_theater" : {message: "On peut y regarder un film"},
  "museum" : {message: "On peut y voir de vieille chose"},
  "night_club" : {message: "Party! Tu veux danser?"},
  "restaurant" : {message: "Un endroit ou manger"},
  "school" : {message: "On y apprend pas mal de chose"},
  "secondary_school" : {message: "On y apprend pas mal de chose"},
  "shopping_mall" : {message: "Grande surface pour acheter des choses"},
  "stadium" : {message: "Quel équipe y joue?"},
  "supermarket" : {message: "C'est un super marché"},
  "travel_agency" : {message: "Aimerais-tu partir en voyage?"},
  "veterinary_care" : {message: "Pour les soins de nos animaux"},
  "university" : {message: "Tu peux y apprendre beaucoup de chose"},
  "zoo" : {message: "Tu y trouveras plusieurs animaux"}
}

export class ChasseHome extends React.Component {
  state = {
    hideLeaderBoard: true,
    showWaiting: false,
    score: 0,
    working: true,
    position: "n/d",
    enabled: true,
    terminate: false,
    accuracy: false,
    coords: {
      latitude: 46.5233536,
      longitude: -72.7526788
    },
    currentTarget: 0,
    finalScore: "n/d",
    game: null,
    currentObjectif: null,
    showOther: false,
    showWin: false,
    leaders: []
    /*
    game: {
      metas: {
        totalDistance: 10
      }
    }
    */
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.watchProcess = null;
  }

  tryAgain() {
    this.setState({showWaiting: true});
    this.getLocalisation();
  }

  getLeader() {
    api.call('get', "chasse/getLeaderBoard").then(result => {
      this.setState({leaders:result.data});
    });
  }

  getState() {
    api.call('get', "chasse/getState").then(result => {
      var newGame = result.data;
      if (newGame === null) return;

      var terminate = false;
      var current = null;

      newGame.metas = JSON.parse(newGame.metas);
      newGame.places = JSON.parse(newGame.places);
      if (newGame.state >= newGame.places.length) {        
        terminate = true;
      } else {
        current = newGame.places[newGame.state];
      }

      this.setState({enabled: false, places: newGame.places, score: newGame.metas.score, game: newGame, distanceObjectif: 1, currentObjectif: current, terminate: terminate});

      if (debug) {
        var c = {
          latitude: 46.5233536,
          longitude: -72.7526788
        }
        this.onPosition(c);
      }
    });
  }

  componentDidMount() {
    this.getLocalisation();

    //noSleep.enable();
    api.call('post', "user/usrLog", {module: "views", title:"chasse"});
    window.gtag('event', 'page_view', {
      page_title: 'Chasse au trésor',
      page_location: '/chasse',
      page_path: '/chasse',
      //send_to: '<GA_MEASUREMENT_ID>'
    })    

    this.getState();
  }

  closeModal() {
    this.setState({showOther: false})
  }

  onPosition(coords) {
    var these = this;
    this.setState({position: coords.latitude + "," + coords.longitude});
    if (this.state.game!==null)
      api.call('get', "chasse/getDistanceFrom?gameId=" + this.state.game.id + "&lat=" + coords.latitude + "&long=" + coords.longitude).then(result => {
        this.setState({distanceObjectif: result.data.distance});
      });
  }

  getLocalisation() {
    var these = this;
    if (debug) {
      return;
    }
    if ("geolocation" in navigator) {
      //console.log("geolocation Available");
    } else {
      alert("geolocation Not Available");
      return console.log("geolocation Not Available");
    }
    
    const options = {
        enableHighAccuracy: true,
        timeout: 1000,
        maximumAge: 0
    }

    navigator.geolocation.getCurrentPosition(function(position) {
      if (position.coords.accuracy>=50) {
        // TODO - Alerte, impossible de jouer
        these.setState({showWaiting: false, working:false, accuracy: true});
        return;
      }      
      these.onPosition(position.coords);
    }, function (err) {
      these.setState({showWaiting: false, working: false});
      return //alert("Oops problem 100");
    }, options);

      var watchProcess = navigator.geolocation.watchPosition(function(position) {
        if (position.coords.accuracy>=50) {
          // TODO - Alerte, impossible de jouer
          these.setState({showWaiting: false, working:false, accuracy: true});
          return //alert("accuracy is too low-2")
        }
        these.setState({showWaiting: false, working: true});
        these.onPosition(position.coords);
      },function () {
        these.setState({working: false});
        //return alert("oops problem 101");
      }, options);
  }

  componentWillUnmount() {
    //noSleep.disable();
    //navigator.geolocation.clearWatch(this.watchProcess);
  }

  startGame() {
    //noSleep.enable();
    api.call('post', "chasse/create", {lat: this.state.coords.latitude, long: this.state.coords.longitude}).then(result => {
      if (!result.data.success)
        return alert("Impossible de lancer une partie");
      var newGame = result.data.game;
      newGame.metas = JSON.parse(newGame.metas);
      newGame.places = JSON.parse(newGame.places);
      var current = newGame.places[0];

      this.setState({enabled: false, currentTarget:0, game: newGame, distanceObjectif: 1, currentObjectif: current});
    });
  }

  goBack = () => {
    this.props.history.push({
        pathname: "/home"
    });
  }

  skipStep() {
    this.setState({showOther: false});
    /*
    this.setState({terminate: true});
    return;
    */
    api.call('post', "chasse/skipStep", {gameId: this.state.game.id, lat: this.state.coords.latitude, long: this.state.coords.longitude}).then(result => {
      var newGame = result.data.game;
      newGame.metas = JSON.parse(newGame.metas);
      newGame.places = JSON.parse(newGame.places);

      var current = null;
      var terminate = false;
      if (newGame.state >= newGame.places.length) {
        terminate = true;
        // We should got the final score
        this.setState({finalScore: result.data.game.finalScore});
      } else {
        current = newGame.places[newGame.state];
      }

      this.setState({game: newGame, distanceObjectif: 1, currentObjectif: current, terminate: terminate});
      this.getState();
    });
  }

  nextStep() {
    if (this.state.distanceObjectif > maxDistance) {
      this.setState({showOther:true});
      return;
    }
    api.call('post', "chasse/nextStep", {gameId: this.state.game.id, lat: this.state.coords.latitude, long: this.state.coords.longitude}).then(result => {
      var newGame = result.data.game;
      newGame.metas = JSON.parse(newGame.metas);
      newGame.places = JSON.parse(newGame.places);

      var current = null;
      var terminate = false;
      if (newGame.state >= newGame.places.length) {
        terminate = true;
        this.setState({finalScore: result.data.game.finalScore});
      } else {
        current = newGame.places[newGame.state];
      }

      this.setState({game: newGame, distanceObjectif: 1, currentObjectif: current, terminate: terminate});
      this.getState();
    });
  }

  closeGame() {
    this.setState({enabled: true, game: null, terminate: false, hideLeaderBoard: true});
    this.getState();
  }

  finishShowLeader() {
    this.getLeader();
    this.setState({enabled: true, game: null, terminate: false, hideLeaderBoard: false});
    this.getState();
  }

  showLeaderBoard() {
    var i=0;
    var tableau = this.state.leaders.map((row) => {
      i++;
      return (
        <tr>
          <td class="bg-primary">{i}</td>
          <td class="bg-primary">{row.name}</td>
          <td class="bg-primary">{row.score}</td>
        </tr>
      );
    });
    return (
      <>
      <div class="appHeader">
            <div class="left">
                <a onClick={() => this.clickLeader()} class="headerButton goBack">
                    <ion-icon name="chevron-back-outline" role="img" class="md hydrated" aria-label="chevron back outline"></ion-icon>
                </a>
            </div>
            <div class="pageTitle">
              Tableau des résultats
            </div>
            <div class="right">
          </div>
        </div>
        <br/><br/>
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th class="bg-primary">#</th>
            <th class="bg-primary">Nom</th>
            <th class="bg-primary">Score</th>
          </tr>
        </thead>
        <tbody>
          {tableau}
        </tbody>
      </Table>
      <hr/>
      <center>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<CloseIcon />}
        onClick={() => this.clickLeader()}
      >
        Fermer
      </Button>
      </center>
      </>
    );
  }

  closeFinish() {
    window.location.reload();
  }

  showTerminated() {
    var trajets = this.state.game.places.map((row) => {
      var rowUrl = (<></>);
      if (typeof row.photos !== "undefined")
        rowUrl = API_ROOT + "chasse/placesPhoto/" + row.photos[0].photo_reference;
        //rowUrl = "http://10.1.244.2:9090/chasse/placesPhoto/" + row.photos[0].photo_reference;
      return (
        <Card style={{ width: '18rem' }}>
          <Card.Img variant="top" src={rowUrl} />
          <Card.Body>
            <Card.Title>{row.name}</Card.Title>
          </Card.Body>
        </Card>

      );
    });
    return (
      <Modal
        size="lg"
        show={true}
        onHide={() => this.closeFinish()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Félicitation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Bravo, vous avez terminé le jeu avec un score de {this.state.finalScore}.</p>
          <h1>Récapitulatif</h1>
          {trajets}
          <p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<NavigateNextIcon />}
              onClick={() => this.finishShowLeader()}
            >
              Voir le tableau
            </Button>
          </p>
        </Modal.Body>
      </Modal>
    )
  }

  renderGame() {
    if (!this.state.working) {
      var texte = (<></>);
      if (this.state.showWaiting)
        texte = (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Je réessaie...</span>
          </Spinner>
        )
      else
        texte = (
          <>
          <p>Etes-vous sur un téléphone portable? Avez-vous activé votre GPS (localisation)? Avez-vous autorisé notre application d'utiliser votre localisation?</p>
          <p>Etes-vous dehors? Pour que le GPS fonctionne bien, vous devez être dehors.</p>
          <p>Puisque vous serez sur la route, votre cellulaire doit avoir des données. Désactiver aussi votre WiFi.</p>
          <p>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<ReplayIcon />}
              onClick={() => this.tryAgain()}
            >
              Ressayer
            </Button>
          </p>
          </>
        )
      return (
        <Modal
        size="lg"
        show={true}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Désolé
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h1>Désolé, ça ne fonctionne pas.</h1>
          {texte}
        </Modal.Body>
      </Modal>
      )
    }
    if (this.state.terminate)
        return this.showTerminated()
    if (this.state.game === null) return (
      <center>
      </center>
    )
    var other = (<></>)
    if (this.state.currentObjectif !== null) {
      var isButtonOk = false;
      if (this.state.distanceObjectif <= maxDistance)
        isButtonOk = true;
        var reachBtn = (
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<NavigateNextIcon />}
            onClick={() => this.nextStep()}
          >
            Prochaine étape
          </Button>
        )
      other = (
        <div>
          <HintBox
            text={hint[this.state.currentObjectif.types[0]].message}
          />
          <p style={{color:"#FFFF00"}}>Indice: {hint[this.state.currentObjectif.types[0]].message}</p>
          {reachBtn}
        </div>
      )
    }
    return (
      <div>
        <table width="100%">
          <tr>
            <td>
            <BlueBox
              caption="Total Km"
              text={"~"+(this.state.game.metas.totalDistance*1.15).toFixed(2)+" km"}
            />
            </td>
            <td>
            <BlueBox
              caption="Pointage actuel"
              text={(this.state.score).toFixed(2)}
            />
            </td>
          </tr>
        </table>
        
        <GreenBox
              caption="Objectif"
              text={this.state.distanceObjectif.toFixed(2)+" km"}
        />
        {other}
      </div>
    )
  }

  showOther() {
    if (!this.state.showOther) return (<></>);
    return (
      <Modal
        size="lg"
        show={this.state.showOther}
        onHide={() => this.closeModal()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Ooops
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Désolé, vous n'êtes pas assez proche.</p>

          <p>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<NavigateNextIcon />}
            onClick={() => this.closeModal()}
          >
            Je vais me rapprocher
          </Button>
          </p>
          <p>
            Vous ne trouvez pas? Passer à l'endroit suivant. Vous n'accumulerez pas de points.
          </p>
          <p>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<NavigateNextIcon />}
            onClick={() => this.skipStep()}
          >
            Passer à l'étape suivante
          </Button>
          </p>
        </Modal.Body>
      </Modal>
    )
  }
  
  clickLeader() {
    this.getLeader();
    this.setState({hideLeaderBoard: !this.state.hideLeaderBoard});
  }

  renderBottom() {
    if (this.state.game !== null) return (
      <div>
      <img src={exit_btn} style={{margin: "5px 5px 5px 5px"}} onClick={(e)=>this.goBack()}/>
      </div>
    )
    return (
      <div align="center">
        <img src={play_btn} style={{margin: "0px 5px 0px 5px"}} onClick={() => this.startGame()}/>
        <img src={leader_btn} style={{margin: "0px 5px 0px 5px"}} onClick={() => this.clickLeader()}/>
        <img src={exit_btn} style={{margin: "0px 5px 0px 5px"}} onClick={(e)=>this.goBack()}/>
      </div>
    )
  }

  render() {
    var sty = {
        float: "none", 
        margin: "0 auto"
    }
    if (!this.state.hideLeaderBoard)
      return this.showLeaderBoard();

    var intro;
    if (this.state.game === null)
      intro = (
        <>
            <p style={{color:"red"}}>Partez à la recherche du trésor. Vous devrez rouler jusqu'à 10 endroits différents dans votre région.</p>
            <p style={{color:"red"}}>Faites-le rapidement et courrer la chance de gagner.</p>
            <p style={{color:"red"}}>Prix de participation ainsi que des prix pour les 3 premières places.</p>
        </>
      )

    return (
      <Container fluid className="main-content-container px-4">
        <h3>Chasse au trésor image</h3>
        <div style={{
          height: '100vh'
        }}>
          {intro}
          {this.renderGame()}
        </div>
        {this.showOther()}
        {this.renderBottom()}
      </Container>
    );
  }
}