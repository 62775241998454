import React from 'react';
import {api} from '../../services/autonome';

import {
    Container
} from "shards-react";

import Breadcrumb from 'react-bootstrap/Breadcrumb'
import {CopyToClipboard} from 'react-copy-to-clipboard';

export class Referral extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            copied: false,
            userId: this.getUserId(),
            errorMsg: ""
        }
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    fetchData() {

    }

    componentDidMount() {
        api.call('post', "user/usrLog", {module: "views", title:"refferal"});
        this.fetchData();
        window.gtag('event', 'page_view', {
            page_title: 'Referral',
            page_location: '/referral',
            page_path: '/referral',
            //send_to: '<GA_MEASUREMENT_ID>'
        })
    }

    render(){
        var url = "https://liberium.net/?refId=" + this.state.userId;
        return(
            <Container fluid className="main-content-container px-4">

                <Breadcrumb>
                    <Breadcrumb.Item href="#/home">Accueil</Breadcrumb.Item>                
                    <Breadcrumb.Item active>Referral</Breadcrumb.Item>
                </Breadcrumb>

                <h1>Refère nous et gagne gros.</h1>

                <p>En référant Liberium à vos ami(e)s, vous gagnerez un certain montant d'argent chaque
                    fois qu'il gagnera de l'argent ou qu'il dépensera de l'argent sur la plateforme.
                </p>

                <h2>Votre lien</h2>

                <div>
                    {url}
                    <CopyToClipboard text={url}
                        onCopy={() => this.setState({copied: true})}>
                        <button>Copy</button>
                    </CopyToClipboard>
                    {this.state.copied ? <span style={{color: 'red'}}>Copié.</span> : null}
                </div>

                <h2>Graphiques</h2>

                <p>...</p>

            </Container>
        )
    }
}