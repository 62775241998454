import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import BuildIcon from '@material-ui/icons/Build';
import DirectionsIcon from '@material-ui/icons/Directions';
import EditIcon from '@material-ui/icons/Edit';
import { Form, Modal } from "react-bootstrap";

class ModulesView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            showNew: false,
            modules: [],
            moduleName: this.props.moduleName
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        var body = {
            cmd:"askFunction",
            module: "modules",
            name: "getList"
        }
        api.callLb('post', "apis/internal/function", body).then(result => {
            console.log("from LB function:",result);
            these.setState({modules: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();

        window.gtag('event', 'page_view', {
            page_title: 'Modules',
            page_location: '/admin/modules',
            page_path: '/admin/modules'
        });
    }

    createModule() {
        var these = this;
        var body = {
            cmd:"askFunction",
            module: "modules",
            name: "create",
            newModuleName: this.state.name
        }
        api.callLb('post', "apis/internal/function", body).then(result => {
            console.log("from LB function:",result);
            these.setState({showNew: false});
            these.fetchData();
        });
    }

    renderModules() {
        return this.state.modules.map((row) => {
            return (
                <tr>
                <td>{row.id}</td>
                <td>{row.name}</td>
                <td>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<BuildIcon />}
                        onClick={() => this.edit(row)}
                    >
                        Edit
                    </Button>
                </td>
                </tr>
            );
          });
    }

    goServers() {
        this.props.history.push({
          pathname: "/admin/servers"
        });
    }


    edit(row) {
        this.props.history.push({
          pathname: "/admin/module/" + row.name
        });
    }

    handleNew(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({name:value});
    }

    doShowNew() {
        this.setState({showNew: true});
    }

    render(){
        return(
            <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.doShowNew()}
            >
              Nouveau module
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goServers()}
            >
              Servers
            </Button>
            <h1>Modules</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Module name</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderModules()}
                </tbody>
            </Table>
            <Modal
                    size="lg"
                    show={this.state.showNew}
                    onHide={() => this.setState({showNew: false})}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Nouveau module
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Créer un nouveau module</p>
                        <Form.Group className="mb-3" controlId="timeout">
                            <Form.Control
                            type="text"
                            name="name"
                            placeholder="Nom du module"
                            value={this.state.name}
                            onChange={e => this.handleNew(e)}
                            />
                        </Form.Group>
                        <Button
                            variant="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.createModule()}
                        >
                            Créer module
                        </Button>
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}

export default withRouter(ModulesView);