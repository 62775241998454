import React from "react";
import { Form } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import {api} from '../../services/autonome';

export class MyTaskOverview extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            task: this.props.task,
            showAll: false,
            disabled_apply: false,
            paymentOk: false
        }
        this.dblClick = false;
    }

    abandonTask() {        
        var these = this;
        this.setState({disabled_apply: true});
        api.call('get', "task/" + this.state.task.id + "/cancel", {}).then(result => {
            these.state.task.applied = true;
            these.setState({disabled_apply: false});
        });
    }

    showAll() {
        if (this.dblClick) {
            this.dblClick = false;
            return;
        }
        this.setState({showAll: !this.state.showAll});
    }

    componentDidMount(){
    }

    renderButton() {
        var status;
        if (this.state.task.status === 2) {
            return "Termine";
        } else if (this.state.task.status === 0) {
            return (
                <Button
                variant="contained"
                disabled={this.state.disabled_apply}
                color="primary"
                size="small"
                startIcon={<CancelIcon />}
                onClick={() => this.abandonTask()}
                >
                Annule
                </Button>
            );
        }
    }

    showAll() {
        this.props.history.push({
            pathname: "/task/" + this.state.task.id
        });
    }

    render () {
        return (
            <li onClick={() => this.showAll()} id={this.state.task.id}>
                <a class="item">
                <img src={this.state.task.model.icon} width={75} style={{margin: "10px 10px 10px 10px"}}/>                   
                    <div class="in">
                        <div>
                            <header>{this.state.task.model.name}</header>
                            Récompence: {parseFloat(this.state.task.reward.amount).toFixed(2)} {this.state.task.reward.crypto}
                            <footer>{this.state.task.details.description}</footer>
                        </div>
                        <span class="text-muted">{this.state.task.nbr_appliers}</span>
                    </div>
                </a>
            </li>      
        )
    }
}

export default MyTaskOverview;