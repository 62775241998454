import React from "react";
import { Container, Row } from "shards-react";
import Button from '@material-ui/core/Button';
import LibCard from '../../components/LibCard';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {api} from '../../services/autonome';
import Quote from '../../components/ads/quote';
import logo from "../../img/liberium-en-tete.png";

//const iOSUrl = 'https://itunes.apple.com/us/app/all-of-the-lights/id959389722?mt=8';
const iOSUrl = 'https://app.liberium.net/#/apple';
const PlayStoreUrl = 'https://play.google.com/store/apps/details?id=net.liberium.app';

class PublicHome extends React.Component {

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state= {stats: {
      users: 'n/d',
      tasks: 'n/d',
      workers: 'n/d'
    }}
  }

  connect() {
    this.props.history.push({
      pathname: "/login"
    });
  }

  signup() {
    this.props.history.push({
      pathname: "/signup"
    });
  }

  componentDidMount() {
    window.gtag('event', 'page_view', {
      page_title: 'Home Page',
      page_location: '/',
      page_path: '/'
    })
    this.fetchData();
  }

  componentWillUnmount() {
  }

  fetchData() {
    api.call('get', "liberium/stats", {}).then(result => {
      this.setState({
          stats: result.data
      });
    });
  }

  render() {
    var sty = {
        float: "none", 
        margin: "0 auto"
    }

    return (
      <Container fluid className="main-content-container px-4">

                  <div class="appHeader bg-liberium text-light">
                    <div className="left">
                    </div>
                    <div className="pageTitle">
                        <img src={logo} alt="logo" height={50}/>
                    </div>
                    <div className="right">
                    </div>
                </div>

                <div id="appCapsule">
        <div class="row home-intro text-center">
          <div class="col-xs-12 col-md-8" style={sty}>

          <Row>
            <center>

            <LibCard
            imgUrl="https://api.liberium.net/s/liberium-public-img/49af91f5-19af-45dc-a28c-540cfc737ea9.jpeg"
            title="Vos impôts 2023"
            description="Faites faire vos impôts 100% en ligne. Pas de déplacement!"
            buttonTxt="Je l'essaie"
            path="/impot"
            icon={(<AttachMoneyIcon/>)}
            >
            </LibCard>

            <LibCard
            imgUrl="https://api.liberium.net/s/liberium-public-img/handshake.png"
            title="Vous chercher une personne pour vous aider"
            description="Donner de l'ouvrage à une personne en échange d'argent"
            buttonTxt="Je veux engager"
            path="/services"
            >
            </LibCard>

            <LibCard
            imgUrl="https://api.liberium.net/s/liberium-public-img/352593313_785468153172590_5045944043450060822_n.png"
            title="Appliquer sur du travaille à effectuer"
            description="Vous êtes payé pour accomplir les tâches de nos clients."
            buttonTxt="Je veux être payé"
            path="/work"
            >
            </LibCard>
            </center>
            </Row>
            
            <br/>
            <hr class="small"/>
            <Row>
              <center>
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => this.connect()}
              >
                  Connexion
              </Button>
              </center>
              </Row>
              <br/>
              <Row>
              <center>
              <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => this.signup()}
              >
                  Enregistrement
              </Button>
              </center>
            </Row>
            <br/>
            <center>
            <Quote/>
            </center>
            <hr class="small"/>
            <Row>
              <center>
              
              <div class="col-xl-3 col-sm-6 col-12">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-flex">
                        <div class="align-self-center">
                          <WorkOutlineIcon/>
                        </div>
                        <div class="media-body text-right">
                          <h3>{this.state.stats.tasks}</h3>
                          <span>Quêtes effectuées</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-sm-6 col-12">
                <div class="card">
                  <div class="card-content">
                    <div class="card-body">
                      <div class="media d-flex">
                        <div class="align-self-center">
                          <PeopleAltIcon/>
                        </div>
                        <div class="media-body text-right">
                          <h3>{this.state.stats.workers}</h3>
                          <span>Travailleurs</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </center>
            </Row>
            <Row>
              Notre objectif est clair. Vous avez un besoin; nous aimerions le combler.
              Nous mettons les gens en relation. Vous voulez gagner de l'argent avec
              votre métier? Inscrivez-vous et commencez aujourd'hui.
            </Row>

            <br/><br/>
            <center><a href="https://peupleqc.com/">Peuple Qc</a></center>

            <center>Grenier & associé - Copyright 2023 <a href="/en/terms">Terms and conditions</a></center>
          </div>
        </div>  
        </div>

    </Container>
    );
  }
}

export default PublicHome;

/*
            <LibCard
            imgUrl="https://api.lencanenligne.com/s/stor-img/inscription.png"
            title="Jouez à la chasse au trésor"
            description="Parter en famille à la recherche du trésor"
            buttonTxt="Je veux jouer"
            path="/chasse"
            >
            </LibCard>

                        <br/>
            <Row>
              <center>
              <MobileStoreButton
                store="ios"
                url={iOSUrl}
                linkProps={{ title: 'iOS Store Button' }}
              />
              <MobileStoreButton
                store="android"
                url={PlayStoreUrl}
                linkProps={{ title: 'PlayStore Store Button' }}
              />
              </center>
            </Row>
*/