import React from "react";
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import {
    Alert
} from "shards-react";
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';

export class NoInscription extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            task: this.props.task
        }
    }

    componentDidMount(){
    }

    goProfile() {
        this.props.history.push({
            pathname: "/inscription"
        });
    }

    render () {
        return (
            <Alert theme="danger">Votre n'êtes pas inscrit à aucune catégorie!

            <Button
                variant="contained"
                color="secondary"
                size="small"
                startIcon={<AccountCircleSharpIcon />}
                onClick={() => this.goProfile()}
            >
                Inscription
            </Button>

            </Alert>
        )
    }
}

export default withRouter(NoInscription);