import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import Button from '@material-ui/core/Button';
import BuildIcon from '@material-ui/icons/Build';
import DirectionsIcon from '@material-ui/icons/Directions';

class ProjectsView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            projects: []
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "codes-servers/all", {}).then(result => {
            these.setState({projects: result.data});
        });
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();
    }

    build(row) {
        api.call('get', "codes-servers/build/" + row.id, {}).then(result => {
        });
    }

    renderProjects() {
        return this.state.projects.map((row) => {
            return (
                <tr>
                <td>{row.id}</td>
                <td>{row.name}</td>
                <td>{row.gitRepository}</td>
                <td>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<BuildIcon />}
                        onClick={() => this.build(row)}
                    >
                        Build
                    </Button>
                </td>
                </tr>
            );
          });
    }

    goServers() {
        this.props.history.push({
          pathname: "/admin/servers"
        });
    }

    render(){
        return(
            <>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DirectionsIcon />}
              onClick={() => this.goServers()}
            >
              Servers
            </Button>
            <h1>Projects</h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Project name</th>
                    <th>GIT</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderProjects()}
                </tbody>
            </Table>
            </>
        )
    }
}

export default withRouter(ProjectsView);