import React from 'react';
import {api} from '../../../services/autonome';
import Table from 'react-bootstrap/Table';
import { withRouter } from 'react-router'; 
import { Form, Modal } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';

class CrawlerView extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            domains: [],
            domain: null,
            showModal: false,
            showAddUrl: false
        }
        this.state.userId = this.getUserId();
    }

    fetchData() {
        var these = this;
        api.call('get', "crawler/getDomains", {}).then(result => {
            these.setState({domains: result.data});
        });
    }

    addUrl() {
        var self = this;
        api.call('post', "crawler/addUrl", {url: this.state.url}).then(result => {
            self.setState({showAddUrl: false});
        });
    }

    save() {
        var self = this;
        if (typeof this.state.domain !== "undefined" && this.state.domain.id !== null) {
            // C'est une mise a jour
            var domain = this.state.domain;
            if (domain.addToFaceBookGroup !== null && domain.addToFaceBookGroup.length === 0)
                domain.addToFaceBookGroup = null;
            if (domain.addToPeupleQcPage !== null && domain.addToPeupleQcPage.length === 0)
                domain.addToPeupleQcPage = null;
            api.call('post', "crawler/setDomain", domain).then(result => {
                self.setState({showModal: false});
                self.fetchData();
            });
        } else {
            var domain = this.state.domain;
            if (typeof domain.addToFaceBookGroup === "undefined" || (domain.addToFaceBookGroup !== null && domain.addToFaceBookGroup.length === 0))
                domain.addToFaceBookGroup = null;
            if (typeof domain.addToPeupleQcPage === "undefined" || (domain.addToPeupleQcPage !== null && domain.addToPeupleQcPage.length === 0))
                domain.addToPeupleQcPage = null;
            api.call('post', "crawler/addDomain", domain).then(result => {
                self.setState({showModal: false});
                self.fetchData();
            });
        }
    }

    handleChange(event) {
        //console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var new_form = this.state.domain;
        new_form[name] = value;
        this.setState({domain: new_form, errorMsg: null});
    }

    handleUrl(event) {
        //console.log("Handle change", event.target.name);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({url: value});
    }

    getUserId() {
      var existingTokens;
      try{
          existingTokens = JSON.parse(localStorage.getItem('tokens'));
          //alert(JSON.stringify(existingTokens));
          return existingTokens.UserId;
      } catch(e) {
          return null;
      }
    }

    componentDidMount() {
        this.fetchData();
    }

    closeModal() {
        this.setState({showModal: false});
    }

    closeAddUrl() {
        this.setState({showAddUrl: false});
    }

    selectDomain(domain) {
        this.setState({domain: domain, showModal: true});
    }

    addNew() {
        var domain = {
            domain: "",
            id: null,
            ignoresInUrl: "",
            addToFaceBookGroup: null,
            addToPeupleQcPage: null
        }
        this.setState({domain: domain, showModal: true});
    }

    showAddUrl() {
        this.setState({showAddUrl: true, url:""});
    }

    renderDomains() {
        return this.state.domains.map((row) => {            
            return (
                <tr onClick={(e)=>this.selectDomain(row)}>
                    <td>{row.id}</td>
                    <td>{row.domain}</td>
                    <td>{row.enabled}</td>
                    <td>{row.addToPeupleQcPage}</td>
                    <td>{row.addToFaceBookGroup}</td>
                </tr>
            );
          });
    }

    render(){
        return(
            <>
            <h1>Domains</h1>
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => this.addNew()}
            >
                Ajouter domain
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => this.showAddUrl()}
            >
                Ajouter URL
            </Button>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Domain</th>
                    <th>Enabled</th>
                    <th>addToPeupleQcPage</th>
                    <th>addToFaceBookGroup</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderDomains()}
                </tbody>
            </Table>
            {this.state.domain !== null &&
            <Modal
                size="lg"
                show={this.state.showModal}
                onHide={() => this.closeModal()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Domain
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>                    
                    {this.state.domain.id !== null &&
                        <>
                        <p>Id: {this.state.domain.id}</p>
                        <p>Domain: {this.state.domain.domain}</p>
                        </>
                    }
                    {this.state.domain.id === null &&
                        <Form.Group className="mb-3">
                        <Form.Label>Nom de domaine</Form.Label>
                        <Form.Control 
                            type="text"
                            name="domain"
                            placeholder="blabla.com"
                            value={this.state.domain.domain} 
                            onChange={e => this.handleChange(e)}
                        />
                        </Form.Group>
                    }
                        <Form.Group className="mb-3">
                        <Form.Label>IgnoresInUrl</Form.Label>
                        <Form.Control 
                            type="text"
                            name="ignoresInUrl"
                            placeholder="/blabla/,/bla/"
                            value={this.state.domain.ignoresInUrl} 
                            onChange={e => this.handleChange(e)}
                        />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Add to Peuple Qc Page</Form.Label>
                            <Form.Control 
                                type="text"
                                name="addToPeupleQcPage"
                                placeholder=""
                                value={this.state.domain.addToPeupleQcPage} 
                                onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Add to Facebook Group</Form.Label>
                            <Form.Control 
                                type="text"
                                name="addToFaceBookGroup"
                                placeholder=""
                                value={this.state.domain.addToFaceBookGroup} 
                                onChange={e => this.handleChange(e)}
                            />
                        </Form.Group>

                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.save()}
                            >
                            Sauvegarder
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            }
            <Modal
                size="lg"
                show={this.state.showAddUrl}
                onHide={() => this.closeAddUrl()}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Ajout d'une URL
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>                    
                        <Form.Group className="mb-3">
                        <Form.Label>URL</Form.Label>
                        <Form.Control 
                            type="text"
                            name="url"
                            placeholder="https://blabla.com"
                            value={this.state.url} 
                            onChange={e => this.handleUrl(e)}
                        />
                        </Form.Group>
                    
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<EditIcon />}
                            onClick={() => this.addUrl()}
                            >
                            Ajouter
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            </>
        )
    }
}

export default withRouter(CrawlerView);