import React from "react";
import {
    Form,
    FormGroup,
    FormInput,
} from "shards-react";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter
} from "shards-react";
import Button from '@material-ui/core/Button';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import {api} from '../../services/autonome';

export class ScreenStopped extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            jobId: this.props.jobId,
            job: this.props.job,
            image: "",
            errorMsg: null,
            onFinish: this.props.onFinish,
            onClicked: false
        }
        var metas = JSON.parse(this.props.job.metas);
        this.state.image = metas.image;
        if (this.state.image.indexOf("http")===-1) {
            this.state.image = "https://api.liberium.net/s/facebook-screens/" + this.state.image;
        }
    }

    onDone(needMsg) {
        this.setState({onClicked: true});
        console.log("Ok send it to api");
        var these = this;
        var data = {
            taskId: this.state.jobId,
            needMsg: needMsg
        }
        api.call('post', "virt_job/onStopStat", data).then(result => {
            if (typeof result.data.error !== "undefined") {
                return alert("Une erreure s'est produite");
            }
            this.state.onFinish();
        });
    }

    componentDidMount(){
    }

    render () {
        return (
            <>
            <Card>
                <CardHeader>Marketing?</CardHeader>
                <CardBody>
                    <p>Est-ce qu'on devrait envoyer un message publicitaire?</p>
                    <img src={this.state.image}/>
                </CardBody>
                <CardFooter>
                <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<NewReleasesSharpIcon />}
                            onClick={() => this.onDone(true)}
                            disabled = {this.state.onClicked}
                        >
                            Oui
                        </Button>

                <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<NewReleasesSharpIcon />}
                            onClick={() => this.onDone(false)}
                            disabled = {this.state.onClicked}
                        >
                            Non
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<NewReleasesSharpIcon />}
                            onClick={() => this.onDone("not_yet")}
                            disabled = {this.state.onClicked}
                        >
                            Pas tout de suite
                        </Button>
                </CardFooter>
            </Card>
            </>         
        )
    }
}

export default ScreenStopped;
