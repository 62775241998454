import React from 'react'

const quotes = [
    {text:"Tk je te lève encore mon chapeau c’est vraiment cool votre concept pour la job", who:"Phil Cloutier, St-Narcisse"},
    {text:"C'est tellement une bonne idée cette plate-forme là 👌", who:"Isabelle Campanozzi, Shawinigan"},
    {text:"Votre plateforme est bien fait!", who:"Mélanie Filion"},
]

export default class Quote extends React.Component {
    state = {
        quote: quotes[Math.floor(Math.random() * quotes.length)]
    }

    constructor(props){
        super(props)
    }

    componentDidMount() {
    }

    render(){
        return (
            <div class="col-sm-6 col-12">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">" {this.state.quote.text} "</h5>
                        <p class="card-text">{this.state.quote.who}</p>
                    </div>
                </div>
            </div>
        )
    }
}