import React from "react";
import { Redirect } from "react-router-dom";
import logo from "../img/logo-carre.jpg";
import avatar1 from "../img/avatar1.jpg";
import {api} from '../services/autonome';

export class DefaultLayout extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            notifs: 0,
            userNotifs: 0,
            showAppBar: true,
            profile: {firstname: "", lastname: ""},
            avatar: avatar1
        }
        let existingTokens;
        try {
          existingTokens = JSON.parse(localStorage.getItem("tokens"));
          this.state.connected = true;
        } catch (e) {
          this.state.connected = false;
        }
    }

    handleClick = (path) => {
        //e.preventDefault();
        //$('#sidebarPanel').modal('hide');
        this.props.history.push({
            pathname: path
        });
    }

    componentDidMount(){
        var these = this;
        this.fetchData();
    }

    fetchData() {
        api.call('get', "users/profile", {}).then(result => {
            if (typeof result.data.error != "undefined") return;
            this.setState({
                profile: result.data
            });
            if (result.data.avatar.length > 5)
                this.setState({avatar: result.data.avatar});
        });
        api.call('get', "finance/cad_wallet", {}).then(result => {
            console.log("Wallet overview:", result);
            if (typeof result.data.error != "undefined") return;
            this.setState({
                wallets: result.data
            });

            var total = 0;
            result.data.forEach(function(res) {
                total = total + parseFloat(res.quantity);
            });

            this.setState({total: total});
        });
    }

    render () {
        if (!this.state.connected)
            return (<Redirect to="/login" />)
        return (
            <>
                <div class="appHeader bg-primary text-light">
                    <div className="left">
                        <a className="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                            <ion-icon name="menu-outline"></ion-icon>
                        </a>
                    </div>
                    <div className="pageTitle">
                        <img src={logo} alt="logo" className="logo" />
                    </div>
                    <div className="right">
                        <a className="headerButton">
                            <ion-icon className="icon" name="notifications-outline"></ion-icon>
                            <span className="badge badge-danger">{this.state.userNotifs}</span>
                        </a>
                        <a className="headerButton">
                            <img src={this.state.avatar} alt="image" class="imaged w32" />
                            <span class="badge badge-danger">{this.state.notifs}</span>
                        </a>
                    </div>
                </div>
                <div id="appCapsule">
                    {this.props.children}
                </div>

                <div class="modal fade panelbox panelbox-left" id="sidebarPanel" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body p-0">
                            <div class="profileBox pt-2 pb-2">
                                <div class="image-wrapper">
                                    <img src={this.state.avatar} alt="image" class="imaged  w36"/>
                                </div>
                                <div class="in">
                                    <strong>{this.state.profile.firstname + " " + this.state.profile.lastname}</strong>
                                    <div class="text-muted">{this.state.profile.id}</div>
                                </div>
                                <a class="btn btn-link btn-icon sidebar-close" data-bs-dismiss="modal">
                                    <ion-icon name="close-outline"></ion-icon>
                                </a>
                            </div>

                            <div class="sidebar-balance">
                                <div class="listview-title">Balance</div>
                                <div class="in">
                                    <h1 class="amount">$ {parseFloat(this.state.total).toFixed(2)}</h1>
                                </div>
                            </div>

                            <div class="action-group">
                                <a onClick={(e) => {this.handleClick("/finance/deposit")}} data-bs-dismiss="modal" class="action-button">
                                    <div class="in">
                                        <div class="iconbox">
                                            <ion-icon name="add-outline"></ion-icon>
                                        </div>
                                        Dépôt
                                    </div>
                                </a>
                                <a onClick={() => {this.handleClick("/finance/retrait")}} data-bs-dismiss="modal" class="action-button">
                                    <div class="in">
                                        <div class="iconbox">
                                            <ion-icon name="arrow-down-outline"></ion-icon>
                                        </div>
                                        Retrait
                                    </div>
                                </a>
                                <a onClick={() => {this.handleClick("/finance/send_money")}} data-bs-dismiss="modal" class="action-button">
                                    <div class="in">
                                        <div class="iconbox">
                                            <ion-icon name="arrow-forward-outline"></ion-icon>
                                        </div>
                                        Envoie
                                    </div>
                                </a>

                            </div>

                            <div class="listview-title mt-1">Menu</div>
                            <ul class="listview flush transparent no-line image-listview">
                                <li>
                                    <a onClick={() => {this.handleClick("/work")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="code-working-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Travail
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {this.handleClick("/services")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="hand-left-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Services
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {this.handleClick("/inscription")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="apps-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Inscription
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {this.handleClick("/wallet")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="wallet-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Portefeuille
                                        </div>
                                    </a>
                                </li>
                            </ul>

                            <div class="listview-title mt-1">Autres</div>
                            <ul class="listview flush transparent no-line image-listview">
                                <li>
                                    <a onClick={() => {this.handleClick("/user-profile")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="settings-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Profile
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {this.handleClick("/support")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="chatbubble-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Support
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => {this.handleClick("/logout")}} data-bs-dismiss="modal" class="item">
                                        <div class="icon-box bg-primary">
                                            <ion-icon name="log-out-outline"></ion-icon>
                                        </div>
                                        <div class="in">
                                            Log out
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default DefaultLayout;
