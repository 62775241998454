import React from "react";
import {api} from '../../../services/autonome';
import { Container } from "shards-react";
import { Form, Modal } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

export class Screen extends React.Component {
  state = {
    screens: []
  }

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.intWall = null;
    this.state = {
      id: parseInt(props.match.params.id),
      loaded: false,
      port: 3001,
      userId: this.getUserId(),
      rootUrl: "http://localhost:3001",
      currentImage: "/currentImg?ts=0",
      texte: "",
      texteNum: 0,
      name: "",
      textes: [],
      newUrl: ""
    }
    this.img = React.createRef();
    //this.ref = React.useRef(null)
    this.onClick = this.onClick.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  clean() {
    this.setState({aiText: [], score: "n/d"});
  }

  getUserId() {
    var existingTokens;
    try{
        existingTokens = JSON.parse(localStorage.getItem('tokens'));
        //alert(JSON.stringify(existingTokens));
        return existingTokens.UserId;
    } catch(e) {
        return null;
    }
  }

  loadScreen() {
    var these = this;
    api.call('get', "screens/" + this.state.id, {}).then(result => {
        //alert(JSON.stringify(result));
        var metas = JSON.parse(result.data.metas);
        these.setState({loaded: true,rootUrl:result.data.rootUrl,textes:metas.keywords});
        this.getJob();    
        setInterval(function() {
            these.getJob();
        },300);
    });
  }

  componentDidMount() {
    var these = this;
    this.loadScreen();

    document.addEventListener("wheel", function (e) {
        // get the old value of the translation (there has to be an easier way than this)
        //var oldVal = parseInt(document.getElementById("body").style.transform.replace("translateY(","").replace("px)",""));
        console.log(e.deltaY);
        if (e.deltaY < 0) {
            these.scrollUp(e.deltaY*-1);
        } else {
            these.scrollDown(e.deltaY);
        }
        return false;
      }, true);
  }

  componentWillUnmount() {
  }

  getCurrentTimestamp () {
    return Date.now()
  }

  getJob() {
    var these = this;
    this.clean();
    var url = this.state.rootUrl + "/currentImg/" + this.state.id + "?timestamp=" + this.getCurrentTimestamp();
    this.setState({currentImage: url});
  }

  FindPosition(oElement) {
    if(typeof( oElement.offsetParent ) != "undefined")
    {
      for(var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent)
      {
        posX += oElement.offsetLeft;
        posY += oElement.offsetTop;
      }
        return [ posX, posY ];
      }
      else
      {
        return [ oElement.x, oElement.y ];
      }
  }

  onClick(e) {
    console.log(e)
    var PosX = 0;
    var PosY = 0;
    var ImgPos;
    const imgElement = document.querySelector('#currentImage');
    ImgPos = this.FindPosition(imgElement);
    if (!e) var e = window.event;
    if (e.pageX || e.pageY)
    {
      PosX = e.pageX;
      PosY = e.pageY;
    }
    else if (e.clientX || e.clientY)
      {
        PosX = e.clientX + document.body.scrollLeft
          + document.documentElement.scrollLeft;
        PosY = e.clientY + document.body.scrollTop
          + document.documentElement.scrollTop;
      }
    PosX = PosX - ImgPos[0];
    PosY = PosY - ImgPos[1];
    console.log(PosX + "x" + PosY);
    api.call('get', this.state.rootUrl + "/click/" + this.state.id + "?x=" + PosX + "&y=" + PosY, {}).then(result => {
        //these.getJob();
    });
  }

  handleChange(event) {
    //console.log("Handle change", event.target.name);
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    var new_form = this.state;
    new_form[name] = value;
    this.setState(new_form);
    this.setState({errorMsg: null});
  }

  sendText() {
    console.log("Sending text:", this.state.texte);
    var splited = this.state.texte.split("\n");
    console.log(splited);
    var timerSec = 0;
    var these = this;
    splited.forEach( function (one) {
      setTimeout( function () {
        if (one.length > 0) {
          console.log("sending:", one)
          api.call('get', these.state.rootUrl + "/sendText/" + these.state.id + "?text=" + one, {}).then(result => {
            //console.log("afterSendText")
            //api.call('get', these.state.rootUrl + "/shiftEnter/" + these.state.id, {}).then(result => {
            //  console.log("after Shift+Enter")
            //});
          });
        } else {
          //api.call('get', these.state.rootUrl + "/shiftEnter/" + these.state.id, {}).then(result => {
          //});
        }
      }, timerSec)
      timerSec += 1000;
    });
  }

  sendRestart() {
    console.log("Sending Restart")
    api.call('get', this.state.rootUrl + "/restart/" + this.state.id, {}).then(result => {
      //these.getJob();
    });
  }

  sendEnter() {
    console.log("Sending Enter")
    api.call('get', this.state.rootUrl + "/sendEnter/" + this.state.id, {}).then(result => {
      //these.getJob();
    });
  }

  sendShiftEnter() {
    console.log("Sending Shitf+Enter")
    api.call('get', this.state.rootUrl + "/shiftEnter/" + this.state.id, {}).then(result => {
      //these.getJob();
    });
  }

  scrollDown() {
    api.call('get', this.state.rootUrl + "/scrollDown/" + this.state.id + "?px=53", {}).then(result => {
      //these.getJob();
    });
  }

  scrollUp() {
    api.call('get', this.state.rootUrl + "/scrollUp/" + this.state.id + "?px=53", {}).then(result => {
      //these.getJob();
    });
  }

  scrollRight() {
    api.call('get', this.state.rootUrl + "/scrollRight/" + this.state.id, {}).then(result => {
      //these.getJob();
    });
  }

  scrollLeft() {
    api.call('get', this.state.rootUrl + "/scrollLeft/" + this.state.id + "?px=53", {}).then(result => {
      //these.getJob();
    });
  }

  changeText() {
    var these = this;
    setTimeout( function () {
      var texte = these.state.textes[these.state.texteNum];
      these.setState({texte:texte});
    },100);
  }

  onSelect(e) {
    this.setState({texteNum:e});
    this.changeText();
  }

  prevScreen() {
    var these = this;
    var newId = this.state.id - 1;
    this.setState({id: newId});
    setTimeout(function () {
        these.loadScreen()
    },20);
  }

  nextScreen() {
    var these = this;
    var newId = this.state.id+1;
    this.setState({id: newId});
    setTimeout(function () {
        these.loadScreen()
    },20);
  }

  addUrl() {
    var these = this;
    var newScreen = {
        name: "rename me",
        surfUrl: this.state.newUrl,
        rootUrl: this.state.rootUrl,
        metas: '{"keywords":[]}'
    }
    console.log("newScreen:",newScreen)
    api.call('post', "screen/new", newScreen).then(result => {
        //these.getJob();
        console.log("new screen result:");
        console.log(result);
        console.log("data:",result.data);
        api.call('get', this.state.rootUrl + "/addScreen/" + result.data.id, {}).then(result2 => {
            setTimeout( function () {
                these.setState({id: result.data.id});
            },2000);
        });
    });
  }

  renderJob() {
    var url = this.state.currentImage;
    var newS = {
      "vertical-align":"top"
    }
    var j = -1;
    var items = this.state.textes.map((one) => {
      j++;
      return (
        <Dropdown.Item eventKey={j}>{one}</Dropdown.Item>
      )
    })
    return (
      <>
        <h2>Screen #1</h2>
        <table>
          <tr>
            <td style={newS}>
            <img
              id="currentImage"
                ref={this.img}
                src={url}
                onMouseDown={(e) => this.onClick(e)}
            />
            </td>
            <td >
            <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.prevScreen()}
              >
                  Prev
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.nextScreen()}
              >
                  Next
              </Button>
              <DropdownButton
                variant="secondary"
                name="selText"
                title={` Texte `}
                onSelect={this.onSelect}
              >
                {items}
              </DropdownButton>

              <Form.Group className="mb-3" controlId="texte" key="texte">
                <Form.Label>Texte</Form.Label>
                  <Form.Control as="textarea" rows={10}
                    type="textarea"
                    name="texte"
                    value={this.state.texte} 
                    onChange={e => this.handleChange(e)}
                  >
                  </Form.Control>
              </Form.Group>

              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.sendText()}
              >
                  Envoyé
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.sendEnter()}
              >
                  Enter
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.sendShiftEnter()}
              >
                  Shitf+Enter
              </Button>

              <hr/>

              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.sendRestart()}
              >
                  Restart
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.scrollDown()}
              >
                  Scroll Down
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.scrollUp()}
              >
                  Scroll Up
              </Button>


              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.scrollLeft()}
              >
                  Scroll Left
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.scrollRight()}
              >
                  Scroll Right
              </Button>

              <hr/>
              <Form.Group className="mb-3">
                <Form.Label>Nouvelle url</Form.Label>
                  <Form.Control
                    type="text"
                    name="newUrl"
                    value={this.state.newUrl} 
                    onChange={e => this.handleChange(e)}
                  >
                  </Form.Control>
              </Form.Group>
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CheckIcon />}
                onClick={() => this.addUrl()}
              >
                  Add
              </Button>
            </td>
          </tr>
        </table>
      </>
    )
  }

  render() {
    if (!this.state.loaded)
        return (
            <>Loading...</>
        )
    return (
        <Container fluid className="main-content-container px-4">
            {this.renderJob()}
        </Container>
    );
  }
}