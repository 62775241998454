import React from "react";
import { Redirect } from "react-router-dom";
import avatar1 from "../img/avatar1.jpg";
import {api} from '../services/autonome';
import background from "../img/chasse/background_yellow.png";

export class ChasseLayout extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    render () {
        return (
            <>
            <div style={{ backgroundImage: `url(${background})`,
                backgroundRepeat: 'no-repeat',
                width:'100%' 
            }}>
                {this.props.children}
            </div>        
            </>
        )
    }
}

export default ChasseLayout;
