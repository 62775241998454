import React, {Fragment} from 'react'
import {api} from '../../services/autonome';
import packageJson from '../../../package.json';

const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

function generateString(length) {
    let result = ' ';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export default class AdItem extends React.Component {
    state = {
    }

    constructor(props){
        super(props)
        this.zone = this.props.page;
        this.size = this.props.size;
        this.random = generateString(5).trim();
    }

    componentDidMount() {
        var url = "https://liberium.net/adserver/banner?zone=" + this.zone + "&size=" + this.size + "&id=" + this.random;
        
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = url;
        s.async = true;
        this.instance.appendChild(s);
    }

    getUserId() {
        var existingTokens;
        try{
            existingTokens = JSON.parse(localStorage.getItem('tokens'));
            //alert(JSON.stringify(existingTokens));
            return existingTokens.UserId;
        } catch(e) {
            return null;
        }
    }

    render(){
        return <div id={this.random} ref={el => (this.instance = el)} />;
    }
}